import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
  Box,
} from "@mui/material";
import { useQuery } from "react-query";
import { getClientClinicians } from "api/customers";

/**
 * ClinicianSelect component.
 * Handles the selection of clinicians for a client, with support for primary clinician and multiple selection.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.clinicians - List of all available clinicians.
 * @param {Object} props.values - Form values including the client information.
 * @param {Array} props.selectedClinicians - List of selected clinicians.
 * @param {Function} props.setSelectedClinicians - Function to set the selected clinicians.
 * @param {Object} props.touched - Formik touched object for form validation.
 * @param {Object} props.errors - Formik errors object for form validation.
 * @param {Function} props.handleBlur - Formik handleBlur function for form fields.
 * @param {Function} props.handleChange - Formik handleChange function for form fields.
 * @param {Function} props.setClinicianOptions - Function to set the clinician options.
 * @param {Array} props.clinicianOptions - List of clinician options to display in the select menu.
 * @param {Object} props.videoChatInfo - Video chat information.
 * @returns {React.ReactElement} - The rendered ClinicianSelect component.
 */
const ClinicianSelect = ({
  clinicians,
  values,
  selectedClinicians,
  setSelectedClinicians,
  touched,
  errors,
  handleBlur,
  handleChange,
  setClinicianOptions,
  clinicianOptions,
  videoChatInfo,
}) => {
  const [disabledOptions, setDisabledOptions] = useState({});

  const previousClientIdRef = useRef(values?.client?.client_id);

  const { data, refetch } = useQuery(
    ["clientClinicians", values?.client?.client_id],
    () => getClientClinicians(values?.client?.client_id, { providerType: "primary" }),
    {
      enabled: !!values?.client?.client_id,
      refetchOnWindowFocus: false, // Disable refetching on window focus
    }
  );

  // Refetch clinicians when the client changes and clear selection if client has changed
  useEffect(() => {
    if (
      values?.client?.client_id &&
      previousClientIdRef.current &&
      values.client.client_id !== previousClientIdRef.current
    ) {
      previousClientIdRef.current = values.client.client_id;
      refetch();
      setSelectedClinicians([]);
      handleChange({
        target: {
          name: "bcba",
          value: "",
        },
      });
    }
  }, [values?.client?.client_id, refetch, setSelectedClinicians, handleChange]);

  useEffect(() => {
    if (data) {
      if (selectedClinicians?.length > 0) {
        // Check if all clinicians already have isPrimary defined
        const allCliniciansHavePrimary = selectedClinicians.every(
          (clinician) => clinician.isPrimary != null
        );

        // If any clinician does not have isPrimary defined, proceed with the mapping
        if (!allCliniciansHavePrimary) {
          const selectedCliniciansMapped = selectedClinicians.map((clinician) => {
            return {
              ...clinician,
              isPrimary: isPrimaryForClient(clinician?.user_id),
            };
          });

          setSelectedClinicians(selectedCliniciansMapped);
        }
      }
      getClinicianOptions();
    }
  }, [data, clinicians]);

  // Auto-select the primary clinician when clinicianOptions are set
  useEffect(() => {
    if (clinicianOptions.length > 0 && !videoChatInfo?.clinician_user_id) {
      autoSelectPrimaryClinician();
    }
  }, [clinicianOptions]);

  useEffect(() => {
    const updateDisabledOptions = () => {
      const disabledMap = {};

      clinicianOptions.forEach((option) => {
        // Always enable the primary clinician
        if (option?.isPrimary) {
          disabledMap[option.user_id] = false;
        }
        // Enable all options if no clinicians are selected
        else if (selectedClinicians.length === 0) {
          disabledMap[option.user_id] = false;
        }
        // Enable all options if only the primary clinician is selected
        else if (selectedClinicians.length === 1 && selectedClinicians[0]?.isPrimary) {
          disabledMap[option.user_id] = false;
        }
        // Disable all except the selected and primary if one non-primary is selected
        else if (selectedClinicians.length === 1 && !selectedClinicians[0]?.isPrimary) {
          disabledMap[option.user_id] =
            option.user_id !== selectedClinicians[0].user_id && !option.isPrimary;
        }
        // Disable all except the two selected clinicians
        else if (selectedClinicians.length >= 2) {
          disabledMap[option.user_id] = !selectedClinicians.some(
            (clinician) => clinician.user_id === option.user_id
          );
        }
        // Default case, disable the option
        else {
          disabledMap[option.user_id] = true;
        }
      });

      // Update state with the new disabled options
      setDisabledOptions(disabledMap);
    };

    // Update disabled options whenever selectedClinicians or clinicianOptions change
    updateDisabledOptions();
  }, [selectedClinicians, clinicianOptions]);

  const isPrimaryForClient = (clinicianUserId) => {
    return clinicianUserId === data?.data?.[0]?.user_id;
  };

  const getClinicianOptions = () => {
    let options = clinicians?.map((clinician) => ({
      name: `${clinician.first_name} ${clinician.last_name}`,
      isPrimary: isPrimaryForClient(clinician.user_id),
      ...clinician,
    }));

    // Move primary clinician to the top of the list
    options = options.sort((a, b) => b.isPrimary - a.isPrimary);

    setClinicianOptions(options);
  };

  const autoSelectPrimaryClinician = () => {
    if (data?.data.length > 0) {
      const primaryClinician = data.data[0];
      const primaryClinicianOption = clinicianOptions?.find(
        (option) => option.user_id === primaryClinician?.user_id
      );

      if (primaryClinicianOption) {
        setSelectedClinicians([{ ...primaryClinicianOption, isPrimary: true }]);
        handleChange({
          target: {
            name: "bcba",
            value: primaryClinicianOption.user_id,
          },
        });
      } else {
        setSelectedClinicians([]);
        handleChange({
          target: {
            name: "bcba",
            value: "",
          },
        });
      }
    } else {
      setSelectedClinicians([]);
      handleChange({
        target: {
          name: "bcba",
          value: "",
        },
      });
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    const updatedSelectedClinicians = clinicianOptions
      .filter((clinician) => value.includes(clinician.user_id))
      .map((clinician) => ({
        ...clinician,
        isPrimary: isPrimaryForClient(clinician.user_id),
      }));

    const primarySelected = updatedSelectedClinicians.some((cl) => cl.isPrimary);
    if (
      updatedSelectedClinicians.length > 2 ||
      (!primarySelected && updatedSelectedClinicians.length > 1)
    ) {
      return;
    }

    setSelectedClinicians(updatedSelectedClinicians);
    handleChange({
      target: {
        name: "bcba",
        value: updatedSelectedClinicians.map((cl) => cl.user_id).join(","),
      },
    });
  };

  return (
    <FormControl fullWidth error={touched.bcba && Boolean(errors.bcba)}>
      <InputLabel>Provider(s)</InputLabel>
      <Select
        multiple
        value={(selectedClinicians || []).map((cl) => cl.user_id)}
        onChange={handleSelectChange}
        onBlur={handleBlur}
        disabled={!values?.client?.client_id}
        renderValue={(selected) => {
          return (selectedClinicians || [])
            .filter((cl) => selected.includes(cl.user_id))
            .map((cl) => cl.name || `${cl.first_name} ${cl.last_name}`)
            .join(", ");
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "235px",
              overflowY: "auto",
            },
          },
        }}
      >
        {clinicianOptions.map((option) => (
          <MenuItem
            key={option.user_id}
            value={option.user_id}
            disabled={disabledOptions[option.user_id]}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={(selectedClinicians || []).some((cl) => cl.user_id === option.user_id)}
              color="primary"
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" noWrap>
                {option.name}
              </Typography>
              {option.isPrimary && (
                <Typography variant="body2" color="primary" sx={{ marginLeft: 1 }}>
                  Primary
                </Typography>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{touched.bcba && errors.bcba}</FormHelperText>
    </FormControl>
  );
};

export default ClinicianSelect;
