import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Logo from "../../../assets/an_purple_gray.png";
import SignatureCanvas from "react-signature-canvas";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import format from "date-fns/format";
import Modal from "elements/Modal";

import { convertTokens } from "@date-fns/upgrade/v2";

const ECommConsentForm = ({
  classes,
  consentToECommSignatureRef,
  startSignature,
  signatureStarted,
  consentToEComm,
  loading,
  success,
  error,
  firstName,
  lastName,
  eCommConsentFormOpen,
  onSkipConsentToEComm,
  eCommConsentSubmitting,
}) => {
  const [skipDialogOpen, setSkipDialogOpen] = useState(false);
  return (
    <>
      <Modal
        open={eCommConsentFormOpen}
        loading={loading}
        width={800}
        content={
          <div className={classes.consentToECommContainer}>
            <img className={classes.consentLogo} src={Logo} alt="AnswersNow" title="AnswersNow" />
            <Typography component="p" className={classes.consentPageTitle}>
              CONSENT FOR ELECTRONIC COMMUNICATION
            </Typography>
            <Typography component="p" className={classes.consentText}>
              Date: {format(new Date(), "PP")}
            </Typography>
            <Typography component="p" className={classes.consentText}>
              AnswersNow (AN) hereby informs{" "}
              <span style={{ textDecoration: "underline" }}>{`  ${firstName} ${lastName}  `}</span>{" "}
              (parent/legal guardian/client) that unencrypted email/sms(text) message is not a
              secure form of communication. There is some risk that any individually identifiable
              health information and other sensitive or confidential information that may be
              contained in such email/sms may be misdirected, disclosed to, or intercepted by
              unauthorized parties. However, you might consent to receive email/sms from us
              regarding your treatment. We will use the minimum necessary amount of protected health
              information in any communication, such as first names only.
            </Typography>

            <ul className={classes.consentUnorderedList}>
              <li>
                <Typography component="p" className={classes.consentBulletText}>
                  I consent and accept the risk in receiving information via email/text message. I
                  understand I can withdraw my consent at any time.
                </Typography>
              </li>
            </ul>
            <Typography component="p" className={classes.consentText}>
              I understand that this consent is effective as of the date signed, will remain{" "}
              <span style={{ fontWeight: 500 }}>valid for one year</span> from the date of
              signature, and may be revoked by the parent/legal guardian at any time during this
              timeframe.
            </Typography>
            <div className={classes.divider} />
            <Typography
              component="p"
              className={classes.phiListItem}
              style={{ marginTop: 20, fontWeight: 500 }}
            >
              Parent/Guardian Signature:
            </Typography>
            <div className={classes.signatureCanvas}>
              <SignatureCanvas
                ref={consentToECommSignatureRef}
                onBegin={startSignature}
                penColor="#000"
                canvasProps={{
                  width: window.innerWidth < 961 ? window.innerWidth * 0.9 : 700,
                  height: 120,
                }}
              />
            </div>
            <Typography
              component="p"
              className={classes.phiListItem}
              style={{ marginBottom: 20, fontWeight: 500 }}
            >
              Date: {format(new Date(), convertTokens("MMM DD, YYYY"))}
            </Typography>
          </div>
        }
        primaryActionOnClick={consentToEComm}
        primaryActionText="I Consent"
        primaryActionDisabled={!signatureStarted || eCommConsentSubmitting}
        secondaryActionOnClick={() => setSkipDialogOpen(true)}
        secondaryActionText="Skip For Now"
        error={error}
        errorMessage="There was an error sending your signature. Please try again."
        success={success}
      />
      <Modal
        open={skipDialogOpen}
        title="Skip Consent to Electronic Communications"
        description="You can update your consent to receive protected health information via email/text message at any time from your Account Page."
        onClose={() => setSkipDialogOpen(false)}
        primaryActionText="Skip for Now"
        primaryActionOnClick={() => {
          onSkipConsentToEComm();
          setSkipDialogOpen(false);
        }}
        primaryActionOutlined
        secondaryActionText="Cancel"
        secondaryActionOnClick={() => setSkipDialogOpen(false)}
      />
    </>
  );
};

export default withStyles(styles)(ECommConsentForm);
