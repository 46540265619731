import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import PageContainer from "elements/PageContainer";
import { Box } from "@mui/material";
import InsuranceUtility from "components/InsuranceUtility";
import { PhysiciansPage } from "components/PhysiciansPage";
import { getUserPermissionsList } from "selectors";
import { useSelector, useDispatch } from "react-redux";
import actions from "actions";
import Divider from "elements/Divider";
import Assessments from "./Assessments";

export const AnLibraries = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setPageDetails({
        pageName: "Libraries",
        hideDivider: true,
      })
    );
    return () => dispatch(actions.setPageDetails({ hideDivider: false }));
  }, []);

  const userPermissions = useSelector(getUserPermissionsList);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <PageContainer loading={false} horizontalPadding={false}>
      <Box padding={"0 16px"}>
        <Tabs
          value={selectedTab}
          onChange={(e, value) => setSelectedTab(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          {userPermissions?.view_insurance_utility && <Tab label="Insurance" />}
          {userPermissions?.view_physician_utility && <Tab label="Providers" />}
          {userPermissions?.view_assessment_library && <Tab label="Assessments" />}
        </Tabs>
        <Divider />
      </Box>
      {selectedTab == 0 && <InsuranceUtility {...props} />}
      {selectedTab == 1 && <PhysiciansPage {...props} />}
      {selectedTab == 2 && <Assessments />}
    </PageContainer>
  );
};
