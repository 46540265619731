import React from "react";
import Modal from "elements/Modal";
import Typography from "@mui/material/Typography";

const ResendHealthieInviteDialog = (props) => {
  const {
    resendHealthieInviteOpen,
    inviteHealthieUserLoading,
    inviteHealthieUserSuccess,
    inviteError,
    onSubmitResendHealthieInvite,
    onCloseResendHealthieInvite,
  } = props;

  return (
    <Modal
      open={resendHealthieInviteOpen}
      onClose={onCloseResendHealthieInvite}
      title="Resend Healthie Invitation"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          <Typography>
            Are you sure you would like to resend this invitation? This will email the user a new
            link to set up their Healthie Account.
          </Typography>
        </div>
      }
      loading={inviteHealthieUserLoading}
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitResendHealthieInvite}
      primaryActionDisabled={inviteHealthieUserSuccess || inviteError}
      secondaryActionText={inviteHealthieUserSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onCloseResendHealthieInvite}
      success={inviteHealthieUserSuccess}
      successMessage="Healthie invitation has been resent to the user"
      error={inviteError}
      errorMessage={"There was an error resending the invitation. Please try again."}
    />
  );
};

export default ResendHealthieInviteDialog;
