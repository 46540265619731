import { Divider, IconButton, Slide, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Calendar, Clock, IdentificationBadge, Repeat, User, X } from "@phosphor-icons/react";
import { getAllClinicians } from "api/clinicians";
import React from "react";
import { useQuery } from "react-query";
import LogElement from "../LogElement";
import { useGetClientVideoCallLogs } from "hooks/useGetClientVideoCallLogs";
import { formatLogText } from "../Utils";
import ANLoadingScreen from "elements/ANLoadingScreen";
import { BILLING_TYPES } from "constants";
import { clearLogs, getEventTime, getRepeatUntilDateFormatted, splitDataChanges } from "./Utils";
import { format } from "date-fns";
import { getFormattedRepeatType } from "components/WeeklyCalendarSessions/CalendarUtils";
import { convertNumbersToDays } from "utils/convertNumbersToDays";

export default function EventDetailsPanel({ open, setOpen, clientId, parentLog, clientName }) {
  const theme = useTheme();
  const { data: cliniciansResponse, isLoading: loadingClinicians } = useQuery(
    "allClinicians",
    getAllClinicians
  );
  const clinicians = cliniciansResponse?.data ?? [];
  const { data: logsResponse, isLoading: loadingLogs } = useGetClientVideoCallLogs(
    clientId,
    parentLog?.video_call_id
  );
  const logsData = logsResponse?.data?.logs ?? [];
  const latestEventData = logsResponse?.data?.latest ?? null;
  const logs = splitDataChanges(logsData);
  // Cleaning logs (removing falsy values from data_changes) to display only the changes
  // that the event has (for example avoid displaying a log for repeat_days
  // for non recurring events)
  const cleanLogs = clearLogs(logs);
  const iconProps = {
    size: "16px",
    weight: "duotone",
    color: theme.palette.action.active,
  };

  const latestSection = latestEventData
    ? [
        { icon: <Calendar {...iconProps} />, text: BILLING_TYPES[latestEventData?.billing_type] },
        { icon: <Clock {...iconProps} />, text: getEventTime(latestEventData) },
        { icon: <User {...iconProps} />, text: clientName },
        {
          icon: <IdentificationBadge {...iconProps} />,
          text: getClinicianName(latestEventData.clinician_user_id),
        },
        {
          icon: <Repeat {...iconProps} />,
          text: getFormattedRepeatType(latestEventData.repeat_days),
        },
      ]
    : [];

  function getClinicianName(clinicianId) {
    const clinician = clinicians.find((clinician) => clinician.clinician_user_id === clinicianId);
    return `${clinician?.first_name} ${clinician?.last_name}`;
  }

  const getLogTitle = ({ data_changes, action }) => {
    if (action === "RESCHEDULED") {
      if (data_changes.clinician_user_id) {
        return formatLogText(
          "changed the provider to",
          getClinicianName(data_changes.clinician_user_id.new),
          "from",
          getClinicianName(data_changes.clinician_user_id.old)
        );
      }
      if (data_changes.billing_type) {
        return formatLogText(
          "changed the service type to",
          BILLING_TYPES[data_changes.billing_type.new],
          "from",
          BILLING_TYPES[data_changes.billing_type.old]
        );
      }
      if (data_changes.begin_date) {
        return formatLogText(
          "changed the date and time to",
          format(new Date(data_changes.begin_date.new), "MMMM do p"),
          "from",
          format(new Date(data_changes.begin_date.old), "MMMM do p")
        );
      }
      if (data_changes.repeat_days) {
        return formatLogText(
          "changed the days to",
          convertNumbersToDays(data_changes.repeat_days.new, " and "),
          "from",
          convertNumbersToDays(data_changes.repeat_days.old, " and ")
        );
      }
      if (data_changes.repeat_until_date) {
        return formatLogText(
          "changed the end date to",
          getRepeatUntilDateFormatted(data_changes.repeat_until_date.new),
          "from",
          getRepeatUntilDateFormatted(data_changes.repeat_until_date.old)
        );
      }
      if (data_changes.off_platform) {
        return formatLogText(
          "changed the event type to",
          data_changes.off_platform.new ? "Doxy session" : "AnswersNow Video Call",
          "from",
          data_changes.off_platform.old ? "Doxy session" : "AnswersNow Video Call"
        );
      }
      if (data_changes.billing_type_single) {
        return formatLogText(
          "changed the service type to",
          BILLING_TYPES[data_changes.billing_type_single.new],
          "from",
          BILLING_TYPES[data_changes.billing_type_single.old],
          "for",
          format(new Date(data_changes.billing_type_single?.date), "MMMM do p")
        );
      }
      if (data_changes.clinician_user_id_single) {
        return formatLogText(
          "changed the provider to",
          getClinicianName(data_changes.clinician_user_id_single.new),
          "from",
          getClinicianName(data_changes.clinician_user_id_single.old),
          "for",
          format(new Date(data_changes.clinician_user_id_single?.date), "MMMM do p")
        );
      }
    } else if (action === "SCHEDULED") {
      if (data_changes.clinician_user_id) {
        return formatLogText(
          "added",
          getClinicianName(data_changes.clinician_user_id),
          "as the provider"
        );
      }
      if (data_changes.billing_type) {
        return formatLogText(
          "set",
          BILLING_TYPES[data_changes.billing_type],
          "as the service type for the event"
        );
      }
      if (data_changes.begin_date) {
        return formatLogText(
          "set the date to",
          format(new Date(data_changes.begin_date), "MMMM do")
        );
      }
      if (data_changes.start_time) {
        return formatLogText("set the time to", format(new Date(data_changes.start_time), "p"));
      }
      if (data_changes.repeat_days) {
        return formatLogText(
          "set the days to",
          convertNumbersToDays(data_changes.repeat_days, " and ")
        );
      }
      if (data_changes.repeat_until_date) {
        return formatLogText(
          "set the end date to",
          getRepeatUntilDateFormatted(data_changes.repeat_until_date)
        );
      }
      if (data_changes.client_id) {
        return formatLogText("added", clientName, "to the event");
      }
      if (data_changes && data_changes.hasOwnProperty("off_platform")) {
        return formatLogText(
          "set",
          data_changes.off_platform ? "Doxy session" : "AnswersNow Video Call",
          "as the event type"
        );
      }
      return null;
    } else if (action === "CANCELED") {
      if (data_changes.canceled) {
        return formatLogText(
          `canceled ${data_changes.canceled.all ? "recurring event" : "a single event for"}`,
          data_changes.canceled.all ? "" : format(new Date(data_changes.canceled.date), "MMMM do")
        );
      }
    }
    return "changed event details ";
  };

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Stack
        sx={{ borderLeft: `1px solid ${theme.palette.divider}`, overflowY: "auto" }}
        gap={theme.spacing(7)}
        width="400px"
        minWidth="400px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding={theme.spacing(7, 7, 0, 7)}
          sx={{ position: "sticky", top: 0, background: "white" }}
        >
          <Typography variant="subtitle1">Event Details</Typography>
          <IconButton size="small" fontSize="small" onClick={() => setOpen(false)}>
            <X />
          </IconButton>
        </Stack>
        {loadingLogs || loadingClinicians ? (
          <ANLoadingScreen maxHeight="300px" />
        ) : (
          <>
            <Stack gap={theme.spacing(5)} padding={theme.spacing(0, 7)}>
              <Typography color="text.secondary" variant="caption">
                Latest
              </Typography>
              {latestSection.map((section, i) => {
                if (!section.text) return null;
                return (
                  <Stack direction="row" key={i} alignItems="center" gap={theme.spacing(3)}>
                    {section.icon}
                    <Typography variant="body2">{section.text}</Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Divider />
            <Stack padding={theme.spacing(0, 7, 7, 7)}>
              <Typography variant="subtitle2" color="text.secondary" mb={theme.spacing(5)}>
                Activity
              </Typography>
              {cleanLogs?.map((log, i) => (
                <LogElement
                  key={i}
                  logTitle={getLogTitle(log)}
                  logDate={new Date(log.created_date)}
                  icon={<Calendar {...iconProps} />}
                  logAuthor={log.author}
                  showDivider={i !== cleanLogs.length - 1}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Slide>
  );
}
