import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import Button from "@mui/material/Button";
import { STATES } from "../../constants";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ANDatePicker from "elements/ANDatePicker";
import { getTimeZoneOptions } from "utils/getTimeZoneOptions";

function getInitialState(props) {
  const { user, login } = props;
  let customerInfo = user?.userDetails;
  let locationInfo = user?.userDetails?.location;
  let notificationContacts = user?.userDetails?.notification_contacts;
  let guardianInfo = notificationContacts?.filter((contact) => contact.email === login)[0];

  return {
    firstName: customerInfo?.name || "",
    lastName: customerInfo?.child_last_name || "",
    dob: dateWithoutTimezone(customerInfo?.dob) || null,
    gender: customerInfo?.gender || "",
    timezone: customerInfo?.timezone || "",
    address1: locationInfo?.line1 || "",
    address2: locationInfo?.line2 || "",
    city: locationInfo?.city || "",
    state: locationInfo?.state || "",
    zipCode: locationInfo?.zip || "",
    guardianId: notificationContacts ? guardianInfo?.id || "" : "",
    guardianFirstName: notificationContacts ? guardianInfo?.first_name || "" : "",
    guardianLastName: notificationContacts ? guardianInfo?.last_name || "" : "",
    guardianEmail: notificationContacts ? guardianInfo?.email || "" : "",
    guardianPhone: notificationContacts ? guardianInfo?.phone_number || "" : "",
    guardianRelationship: notificationContacts ? guardianInfo?.relationship || "" : "",
    buttonDisabled: true,
    zipError: false,
    guardianPhoneError: false,
  };
}

class EditInfo extends Component {
  state = {
    ...getInitialState(this.props),
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;

    this.setState({ [name]: val, buttonDisabled: false });
  };

  onChangeDate = (name, date) => {
    this.setState({ [name]: date, buttonDisabled: false });
  };

  onChangeNumber = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: value.replace(/[^0-9]/g, ""),
      buttonDisabled: false,
    });
  };

  onChangePhone = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    this.setState({
      [name]: numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "$1-$2-$3",
      ),
      buttonDisabled: false,
    });
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  editClientInfo = () => {
    const { updateClientInfo, user, userId, clientId, toggleClientEdit, toggleGuardianEdit } =
      this.props;
    const {
      firstName,
      lastName,
      dob,
      gender,
      address1,
      address2,
      city,
      state,
      zipCode,
      zipError,
      timezone,
    } = this.state;
    updateClientInfo({
      clientId,
      dob,
      firstName,
      lastName,
      timezone,
      gender,
      location: {
        id: user?.userDetails?.location.id,
        state,
        zip: zipCode,
        line1: address1,
        line2: address2,
        city,
      },
    });
    toggleClientEdit();
  };
  editCaregiverInfo = () => {
    const { updateCaregiverInfo, user, userId } = this.props;
    const {
      guardianFirstName,
      guardianLastName,
      guardianPhone,
      guardianPhoneError,
      guardianRelationship,
      guardianId,
    } = this.state;
    updateCaregiverInfo({
      userId,
      healthieCaregiverId: guardianId,
      firstName: guardianFirstName,
      lastName: guardianLastName,
      phoneNumber: guardianPhone,
      relationship: guardianRelationship,
    });
  };

  render() {
    const {
      classes,
      user,
      clientInfoEditOpen,
      guardianInfoEditOpen,
      toggleClientEdit,
      toggleGuardianEdit,
      formatPhoneNumber,
      isClientOpen,
      isGuardianOpen,
    } = this.props;
    const {
      firstName,
      lastName,
      dob,
      gender,
      address1,
      address2,
      city,
      state,
      zipCode,
      zipError,
      timezone,
      guardianFirstName,
      guardianLastName,
      guardianEmail,
      guardianPhone,
      guardianPhoneError,
      guardianRelationship,
      buttonDisabled,
      updatingInfoInHealthie,
    } = this.state;
    const clientButtonDisabled =
      !firstName ||
      !lastName ||
      !dob ||
      !gender ||
      !address1 ||
      !timezone ||
      !city ||
      !state ||
      !zipCode ||
      zipCode.length < 5 ||
      zipError ||
      updatingInfoInHealthie ||
      buttonDisabled;

    const guardianButtonDisabled =
      !guardianFirstName ||
      !guardianLastName ||
      !guardianEmail ||
      !guardianPhone ||
      guardianPhone.length < 10 ||
      guardianPhoneError ||
      updatingInfoInHealthie ||
      !guardianRelationship ||
      buttonDisabled;
    return (
      <Grid container item xs={12} sm={12} spacing={4} classes={{ root: classes.gridRoot }}>
        {clientInfoEditOpen && isClientOpen && (
          <>
            <Grid item xs={6} sm={3}>
              <Typography>First Name</Typography>
              <TextField
                id="firstName"
                placeholder="First Name"
                onChange={this.onChange("firstName")}
                value={firstName}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>Last Name</Typography>
              <TextField
                id="lastName"
                placeholder="Last Name"
                onChange={this.onChange("lastName")}
                value={lastName}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>Date of Birth</Typography>
              <ANDatePicker
                value={new Date(dob)}
                onChange={(date) => this.onChangeDate("dob", date)}
                placeholder="Date of Birth"
                format="PP" //"MMM d, yyyy"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>Gender</Typography>
              <TextField
                select
                id="gender"
                onChange={this.onChange("gender")}
                value={gender}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <MenuItem disabled value={""} />
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography>Address</Typography>
              <TextField
                id="address1"
                placeholder="Address"
                onChange={this.onChange("address1")}
                value={address1}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>Address 2</Typography>
              <TextField
                id="address2"
                placeholder="Address(optional)"
                onChange={this.onChange("address2")}
                value={address2}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>Time Zone</Typography>
              <TextField
                select
                id="timezone"
                placeholder="timezone"
                onChange={this.onChange("timezone")}
                value={timezone}
                size="small"
                fullWidth
                autoComplete="off"
              >
                <MenuItem disabled value={""}>
                  Select a Time Zone
                </MenuItem>
                {getTimeZoneOptions().map(({ label, value }, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>City</Typography>
              <TextField
                id="city"
                placeholder="city"
                onChange={this.onChange("city")}
                value={city}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>State</Typography>
              <TextField
                select
                id="state"
                onChange={this.onChange("state")}
                value={state}
                fullWidth
                autoComplete="off"
              >
                <MenuItem disabled value={""}>
                  Select a state
                </MenuItem>
                {Object.keys(STATES).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {STATES[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>ZIP Code</Typography>
              <TextField
                id="zipCode"
                placeholder="ZIP Code"
                onChange={this.onChangeNumber("zipCode")}
                value={zipCode}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ maxLength: 5 }}
                onFocus={() => this.setError("zipError", false)}
                onBlur={() =>
                  zipCode.trim().length === 5 || !zipCode
                    ? this.setError("zipError", false)
                    : this.setError("zipError", true)
                }
                helperText={zipError ? "Invalid ZIP Code" : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <div className={classes.editButtonContainer}>
              <Button disabled={clientButtonDisabled} onClick={this.editClientInfo}>
                Save Changes
              </Button>
              <Button variant="text" onClick={toggleClientEdit}>
                Cancel
              </Button>
            </div>
          </>
        )}
        {guardianInfoEditOpen && isGuardianOpen && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography>First Name</Typography>
              <TextField
                id="guardianFirstName"
                placeholder="First Name"
                onChange={this.onChange("guardianFirstName")}
                value={guardianFirstName}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>Last Name</Typography>
              <TextField
                id="guardianLasttName"
                placeholder="First Name"
                onChange={this.onChange("guardianLastName")}
                value={guardianLastName}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>Relationship to Client</Typography>
              <TextField
                select
                id="guardianRelationship"
                onChange={this.onChange("guardianRelationship")}
                value={guardianRelationship}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <MenuItem disabled value={""} />
                <MenuItem value="caregiver">Caregiver</MenuItem>
                <MenuItem value="child">Child</MenuItem>
                <MenuItem value="dependent">Dependent</MenuItem>
                <MenuItem value="family_member">Family Member</MenuItem>
                <MenuItem value="legal_guardian">Legal Guardian</MenuItem>
                <MenuItem value="is_parent">Parent</MenuItem>
                <MenuItem value="spouse">Spouse</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography>Email</Typography>
              <TextField
                id="guardianEmail"
                placeholder="Email"
                value={guardianEmail}
                variant="outlined"
                size="small"
                disabled={true}
                helperText={<span>Can't edit. Reach out to support if a change is needed.</span>}
                className={classes.disabledEmail}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>Phone Number</Typography>
              <TextField
                id="guardianPhone"
                placeholder="Phone Number"
                onChange={this.onChangeNumber("guardianPhone")}
                value={formatPhoneNumber(guardianPhone)}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ maxLength: 10 }}
                onFocus={() => this.setError("guardianPhoneError", false)}
                onBlur={() =>
                  guardianPhone.trim().length === 10 || !guardianPhone
                    ? this.setError("guardianPhoneError", false)
                    : this.setError("guardianPhoneError", true)
                }
                helperText={guardianPhoneError ? "Invalid Phone Number" : null}
              />
            </Grid>
            <div className={classes.editButtonContainer}>
              <Button disabled={guardianButtonDisabled} onClick={this.editCaregiverInfo}>
                Save Changes
              </Button>
              <Button variant="text" onClick={toggleGuardianEdit}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Grid>
    );
  }
}

export default EditInfo;
