/**
 * The function `convertToISO` takes a date string in the format "YYYY-MM-DD HH:MM:SS TZ" and returns a
 * Date object in ISO format.
 * @param dateString - The `dateString` parameter is a string that represents a date and time in a
 * specific format.
 * @returns The function `convertToISO` returns a new `Date` object that represents the provided
 * `dateString` in ISO format.
 */
export const convertToISO = (dateString) => {
  const [date, time, tz] = dateString.split(" ");
  return new Date(`${date}T${time}${tz}`);
};
