import React from "react";
import {
  Box,
  Badge,
  Avatar,
  Popover,
  Button,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { CaretDown, CaretUp, Bell } from "@phosphor-icons/react";
import ClientSearch from "components/ClientSearch";
import AvatarInitials from "./AvatarInitials";
import Typography from "@mui/material/Typography";

export const Tools = ({
  handleSearchChange,
  handleBellClicked,
  unreadNotifications,
  userAvatarSrc,
  menuItems,
  userFullName,
  userInitials,
  userPermissions,
}) => {
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClicked = (clickHandler) => {
    setOpen(false);
    clickHandler();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      justifyContent={"space-between"}
      paddingTop={"16px"}
      paddingBottom={"16px"}
    >
      {userPermissions?.search_clients ? (
        <ClientSearch onSelectSearchOption={handleSearchChange} />
      ) : (
        <div />
      )}
      <Box
        minWidth={"50%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {handleBellClicked && (
          <IconButton sx={{ marginRight: 2 }} onClick={handleBellClicked}>
            <Badge badgeContent={unreadNotifications} color="primary" variant="dot">
              <Bell />
            </Badge>
          </IconButton>
        )}

        <Button
          ref={anchorEl}
          startIcon={
            userAvatarSrc || !userInitials ? (
              <Avatar src={userAvatarSrc} sx={{ width: 24, height: 24 }} />
            ) : (
              <AvatarInitials userInitials={userInitials} />
            )
          }
          endIcon={open ? <CaretUp size={20} /> : <CaretDown size={20} />}
          variant="text"
          onClick={handleClick}
          color="inherit"
          // sx={{
          //   height: 24,
          //   color: "#31333B",
          //   fontSize: 14,
          //   fontWeight: 500,
          //   letterSpacing: ".4px",
          // }}
        >
          {userFullName}
        </Button>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open || false}
          anchorEl={anchorEl.current}
          onClose={handleClick}
          PaperProps={{
            style: { width: "150px", minWidth: "fit-content" },
          }}
        >
          <MenuList>
            {menuItems.map(
              (
                {
                  value,
                  label,
                  disabled = undefined,
                  icon = "",
                  divider = false,
                  typography = false,
                  clickHandler,
                },
                i,
              ) => (
                <MenuItem
                  key={i}
                  value={value}
                  onClick={() => handleMenuItemClicked(clickHandler)}
                  disabled={disabled}
                  divider={divider}
                >
                  {icon !== "" ? (
                    <ListItemIcon>
                      {React.createElement(icon, {
                        weight: "duotone",
                        color: "text.secondary",
                      })}
                    </ListItemIcon>
                  ) : null}
                  {typography ? (
                    <Typography variant="caption" color="text.secondary">{`${label}`}</Typography>
                  ) : (
                    label
                  )}
                </MenuItem>
              ),
            )}
          </MenuList>
        </Popover>
      </Box>
    </Box>
  );
};
