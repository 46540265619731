import { getInsurancePlansAsync } from "../../../../../api/insurance";
import { useQuery } from "react-query";
import {
  getInsuranceListState,
  getInsurancePlansPage,
  getInsurancePlansRowsPerPage,
  getInsurancePlansSort,
  getInsurancePlansSortDirection,
} from "../../../../../selectors";
import { useSelector } from "react-redux";

const useGetInsurancePlans = ({ insuranceid }) => {
  const state = useSelector(getInsuranceListState);
  const page = useSelector(getInsurancePlansPage);
  const size = useSelector(getInsurancePlansRowsPerPage);
  const sort = useSelector(getInsurancePlansSort);
  const direction = useSelector(getInsurancePlansSortDirection);

  const response = useQuery({
    queryKey: ["getInsurancePlansAsync", insuranceid],
    queryFn: () =>
      getInsurancePlansAsync({
        insuranceid,
        state,
        page,
        size,
        sort,
        direction,
      }),
    placeholderData: [],
    enabled: !!insuranceid,
  });

  return response?.data?.data ?? [];
};

export default useGetInsurancePlans;
