import React from "react";
import Grid from "@mui/material/Grid";
import Modal from "elements/Modal";
import TextField from "@mui/material/TextField";

const InviteHealthieUserDialog = (props) => {
  const {
    classes,
    onCloseInviteHealthieUser,
    onSubmitInviteHealthieUser,
    onChange,
    firstName,
    lastName,
    email,
    inviteHealthieUserOpen,
    inviteHealthieUserLoading,
    inviteHealthieUserSuccess,
    inviteError,
    inviteHealthieUserErrorMessage,
  } = props;

  const disabled = () => {
    return (
      !firstName ||
      !lastName ||
      !email ||
      inviteHealthieUserLoading ||
      inviteHealthieUserSuccess ||
      inviteError
    );
  };

  return (
    <Modal
      open={inviteHealthieUserOpen}
      onClose={onCloseInviteHealthieUser}
      title="Invite User to Healthie"
      titleCentered
      content={
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name*"
              className={classes.textField}
              value={firstName}
              onChange={onChange("firstName")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name*"
              className={classes.textField}
              value={lastName}
              onChange={onChange("lastName")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email (used as login)*"
              className={classes.textField}
              value={email}
              onChange={onChange("email")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      }
      loading={inviteHealthieUserLoading}
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitInviteHealthieUser}
      primaryActionDisabled={disabled()}
      secondaryActionText={inviteHealthieUserSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onCloseInviteHealthieUser}
      success={inviteHealthieUserSuccess}
      successMessage="User has been emailed an invitation to Healthie"
      error={inviteError}
      errorMessage={
        inviteHealthieUserErrorMessage || "There was an error inviting this user. Please try again."
      }
    />
  );
};

export default InviteHealthieUserDialog;
