const moment = require("moment-timezone");

/**
 * Merges overlapping or contiguous availability slots from Healthie into a single list of availability periods.
 *
 * @param {Array<Object>} healthieAvailability - Array of availability slots with start times.
 * @param {number} duration - Duration of each slot in minutes.
 * @param {string} timezone - Timezone to be used for merging.
 * @returns {Array<Object>} - Merged availability periods with start and end times.
 */
const mergeHealthieAvailability = (healthieAvailability, duration, timezone) => {
  // Sort availability slots by their start time
  const sortedAvailability = healthieAvailability
    .map((slot) => ({
      start: moment.tz(slot.start, timezone).toISOString(), // Normalize to ISO 8601
    }))
    .sort((a, b) => moment(a.start).diff(moment(b.start)));

  // Merge overlapping or contiguous slots
  const merged = [];
  sortedAvailability.forEach((slot) => {
    const slotStart = moment.tz(slot.start, timezone);
    const slotEnd = slotStart.clone().add(duration, "minutes");

    if (merged.length > 0) {
      const last = merged[merged.length - 1];
      const lastEnd = moment.tz(last.end, timezone);

      // Extend the last slot if it overlaps or is contiguous with the current slot
      if (slotStart.isSameOrBefore(lastEnd)) {
        last.end = moment.max(lastEnd, slotEnd).toISOString();
      } else {
        merged.push({
          start: slotStart.toISOString(),
          end: slotEnd.toISOString(),
        });
      }
    } else {
      merged.push({
        start: slotStart.toISOString(),
        end: slotEnd.toISOString(),
      });
    }
  });

  return merged;
};

/**
 * Merges timeslots with Healthie availability for each clinician, considering timezone and duration.
 *
 * @param {Object} healthieAvailabilities - Object containing Healthie availability for each clinician.
 * @param {Object} timeslots - Object containing timeslots for each clinician.
 * @param {string} timezone - Timezone to be used for merging.
 * @param {number} duration - Duration of each slot in minutes.
 * @returns {Object} - Valid time slots for each clinician.
 */
export const mergeAvailability = (healthieAvailabilities, timeslots, timezone, duration) => {
  const allValidSlots = {};

  for (const clinicianId in healthieAvailabilities) {
    if (
      healthieAvailabilities.hasOwnProperty(clinicianId) &&
      timeslots.hasOwnProperty(clinicianId)
    ) {
      const clinicianHealthieAvailability = healthieAvailabilities[clinicianId];
      const clinicianTimeslots = timeslots[clinicianId];

      if (
        !Array.isArray(clinicianHealthieAvailability) ||
        clinicianHealthieAvailability.length === 0 ||
        !Array.isArray(clinicianTimeslots) ||
        clinicianTimeslots.length === 0
      ) {
        continue;
      }

      const mergedHealthieAvailability = mergeHealthieAvailability(
        clinicianHealthieAvailability,
        duration,
        timezone
      );
      const validSlots = [];

      clinicianTimeslots.forEach((slot) => {
        if (!slot.startDateTime || !slot.endDateTime) {
          return;
        }

        const slotStart = moment.tz(slot.startDateTime, timezone);
        const slotEnd = moment.tz(slot.endDateTime, timezone);

        mergedHealthieAvailability.forEach((range) => {
          if (!range.start || !range.end) {
            return;
          }

          const rangeStart = moment.tz(range.start, timezone);
          const rangeEnd = moment.tz(range.end, timezone);

          // Check if the slot is within the availability range
          if (
            slotStart.isValid() &&
            slotEnd.isValid() &&
            rangeStart.isValid() &&
            rangeEnd.isValid()
          ) {
            if (slotStart.isSameOrAfter(rangeStart) && slotEnd.isSameOrBefore(rangeEnd)) {
              for (let m = moment(slotStart); m.isBefore(slotEnd); m.add(duration, "minutes")) {
                validSlots.push(m.format()); // ISO 8601 string format
              }
            }
          }
        });
      });

      allValidSlots[clinicianId] = validSlots.map((slot) =>
        moment.tz(slot, timezone).toISOString()
      ); // Ensure consistent timezone formatting
    }
  }

  return allValidSlots;
};

/**
 * Generates unique 15-minute time slots for a client's availability for a specific day.
 * Maintains the original timezone and converts the slots to a new timezone if provided.
 *
 * @param {Array<Object>} ranges - The availability ranges for the specific day.
 * @param {string} [newTimezone] - The new timezone to convert the slots to. Defaults to the user's current timezone.
 * @returns {Array<Object>} An array of unique and sorted 15-minute time slots for the specific day.
 */
export const mergeClientAvailability = (ranges, newTimezone = moment.tz.guess()) => {
  if (ranges?.length > 0) {
    const slotsSet = new Set();

    ranges.forEach((range) => {
      let current = moment.utc(range.start).tz(newTimezone);
      const end = moment.utc(range.end).tz(newTimezone);
      const preferred = range.preferred;

      while (current.isBefore(end)) {
        const next = moment(current).add(15, "minutes");
        const slotIdentifier = `${current.toISOString()}|${next.toISOString()}|${preferred}`;
        slotsSet.add(slotIdentifier);
        current = next;
      }
    });

    // Convert the set back to an array of slot objects
    const slotsArray = Array.from(slotsSet).map((slot) => {
      const [start, end, preferred] = slot.split("|");
      return {
        start,
        end,
        preferred: preferred === "true",
      };
    });

    // Sort the slots by start time in the new timezone
    slotsArray.sort((a, b) => moment(a.start).diff(moment(b.start)));

    return slotsArray;
  } else {
    return [];
  }
};
