import React from "react";
import BasicInfo from "./Widgets/BasicInfo";
import Bio from "./Widgets/Bio";
import { Box } from "@mui/material";
import CalendarSync from "./Widgets/CalendarSync";
import { isDirector, isSuperAdmin } from "selectors";
import EmploymentDetails from "./Widgets/EmploymentDetails";

const ClinicianDetail = (props) => {
  const {
    classes,
    userPermissions,
    isActive,
    sentEmailList,
    sentEmailsLoading,
    saveClinicianNote,
    editClinicianNote,
    currentUser,
    noteSaving,
    noteSuccess,
    isDirector,
    isAdmin,
    isSuperAdmin,
    ...otherProps
  } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box width="80%" maxWidth="1200px" padding={"16px 0px"}>
        {userPermissions?.view_clinician_bio && (
          <Bio {...props} {...otherProps} userPermissions={userPermissions} />
        )}
        {userPermissions?.view_clinician_basic_info && (
          <BasicInfo
            {...props}
            {...otherProps}
            isActive={isActive}
            userPermissions={userPermissions}
          />
        )}
        {otherProps.isClinicianProfile && <CalendarSync />}
        {(isDirector || isAdmin || isSuperAdmin) && <EmploymentDetails {...props} />}
      </Box>
    </Box>
  );
};

export default ClinicianDetail;
