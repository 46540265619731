import React from "react";
import PersonalInformation from "../Widgets/Profile/PersonalInformation";
import FamilyAndContacts from "../Widgets/FamilyAndContacts";

const ClientInfo = (props) => {
  const { classes, customerDetails } = props;

  return (
    <div className={classes.accordionContainer}>
      <PersonalInformation {...props} />
      <FamilyAndContacts
        notificationContacts={customerDetails.details?.notification_contacts}
        locationInfo={customerDetails.details?.location}
        customerInfo={customerDetails.details}
        {...props}
      />
    </div>
  );
};

export default ClientInfo;
