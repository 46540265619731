import actions from "../../actions";
import { bindActionCreators } from "redux";

export const mapDispatchToProps = (dispatch) =>
bindActionCreators(
  {
    loadCustomerDetails: actions.loadCustomerDetails,
    getAllClinicians: actions.getAllClinicians,
    setCustomerDetails: actions.setCustomerDetails,
    checkAuthState: actions.checkAuthState,
    setPageDetails: actions.setPageDetails,
    switchClinician: actions.switchClinician,
    clearCliniciansList: actions.clearCliniciansList,
    scheduleVideoCall: actions.scheduleVideoCall,
    markEligible: actions.markEligible,
    markApproved: actions.markApproved,
    uploadCustomerDocument: actions.uploadCustomerDocument,
    getDocumentTypes: actions.getDocumentTypes,
    updateUserInsuranceStatus: actions.updateUserInsuranceStatus,
    resendLoginEmail: actions.resendLoginEmail,
    getInsuranceCodesByUser: actions.getInsuranceCodesByUser,
    getAvailableInsuranceCodes: actions.getAllAvailableInsuranceCodes,
    getApprovedUnits: actions.getApprovedUnits,
    createApprovedUnits: actions.createApprovedUnits,
    updateApprovedUnits: actions.updateApprovedUnits,
    assignClinician: actions.assignClinician,
    assignSecondaryClinician: actions.assignSecondaryClinician,
    toggleCustomerActivation: actions.toggleCustomerActivation,
    getAuthorizationCodes: actions.getAuthorizationCodes,
    createAuthorizationCode: actions.createAuthorizationCode,
    updateAuthorizationCode: actions.updateAuthorizationCode,
    resetCustomerPassword: actions.resetUserPassword,
    clearResetUserPasswordInfo: actions.clearResetUserPasswordInfo,
    getUserVideoCalls: actions.getUserVideoCalls,
    setUserVideoCallsPage: actions.setUserVideoCallsPage,
    setUserVideoCallsPerPage: actions.setUserVideoCallsPerPage,
    setUserVideoCallsSort: actions.setUserVideoCallsSort,
    getUserInsurance: actions.getUserInsurance,
    updateUserInsurance: actions.updateUserInsurance,
    createUserInsurance: actions.createUserInsurance,
    disableUserInsurance: actions.disableUserInsurance,
    getInsurance: actions.getInsuranceProviders,
    createSupportChat: actions.createSupportChat,
    getClinician: actions.getClinician,
    loadClinicianDetails: actions.loadClinicianDetails,
    loadCliniciansList: actions.loadCliniciansList,
    setCliniciansPerPage: actions.setCliniciansPerPage,
    setCliniciansSort: actions.setCliniciansSort,
    // updateEligibility: actions.updateEligibility,
    cancelVideoCall: actions.cancelVideoCall,
    saveNotes: actions.saveNotes,
    setAuthCodesLoading: actions.setAuthorizationCodesLoading,
    getCustomerApprovedUnits: actions.getCustomerApprovedUnits,
    getBillableTime: actions.getBillableTime,
    createUnsignedDocumentRequest: actions.createUnsignedDocumentRequest,
    clearDocumentRequestSuccess: actions.clearDocumentRequestSuccess,
    getAllDocumentRequests: actions.getAllDocumentRequests,
    getSessionNotesByUser: actions.getSessionNotesByUser,
    getDocumentMatrix: actions.getDocumentMatrix,
    approveCustomerDocument: actions.approveCustomerDocument,
    getCustomerDeactivationReasons: actions.getCustomerDeactivationReasons,
    getCustomerDepartureOptions: actions.getCustomerDepartureOptions,
    uploadTreatmentPlan: actions.uploadTreatmentPlan,
    updateTreatmentPlan: actions.updateTreatmentPlan,
    getAssignableClinicians: actions.getAssignableClinicians,
    removeSecondaryClinician: actions.removeSecondaryClinician,
    clearOneTimeVideoInfo: actions.clearOneTimeVideoInfo,
    // updatePatientInfo: actions.updatePatientInfo,
    // updateGuardianInfo: actions.updateGuardianInfo,
    // saveCustomerPhysician: actions.saveCustomerPhysician,
    // updateCustomerPhysician: actions.updateCustomerPhysician,
    setOneTimeVideoInfo: actions.setOneTimeVideoInfo,
    clearVideoInfo: actions.clearVideoInfo,
    getNotesList: actions.getNotesList,
    // getNotes: actions.getNotes,
    createOffPlatformWork: actions.createOffPlatformWork,
    getCustomerPastClinicians: actions.getCustomerPastClinicians,
    getClinicianClients: actions.getClinicianClients,
    updateClientEmail: actions.updateCustomerEmail,
    getCustomerSignedForm: actions.getCustomerSignedForm,
    updateCustomerTimezone: actions.updateCustomerTimezone,
    clearTimezoneInfo: actions.clearTimezoneInfo,
    getHealthieDocument: actions.getHealthieDocument,
    clearHealthieDocument: actions.clearHealthieDocument,
    importHealthieClientFamilyMember: actions.importHealthieClientFamilyMember,
    resetImportHealthieClientFamilyMemberInfo: actions.resetImportHealthieClientFamilyMemberInfo,
    switchPrimaryAccountHolder: actions.switchPrimaryAccountHolder,
    updateClientInfo: actions.updateClientInfo,
    updateCaregiverInfo: actions.updateCaregiverInfo,
    deactivateClient: actions.deactivateClient,
    sendSMSToCaregiver: actions.sendSMSToCaregiver,
    getSMSThreadByClient: actions.getSMSThreadByClient,
    clearCustomerForm: actions.clearCustomerForm,
    enableClientStaticPage: actions.enableClientStaticPage,
    disableClientStaticPage: actions.disableClientStaticPage,
    markClientTaskComplete: actions.markClientTaskComplete,
    getClientSessionStatistics: actions.getClientVideoSessionStatistics,
    dismissClinicianNotification: actions.dismissClinicianNotification,
    getInsuranceProvidersList: actions.getInsuranceProvidersList,
    getInsurancePlans: actions.getInsurancePlans,
    setInsuranceListProviderId: actions.setInsuranceListProviderId,
    clearSMSThread: actions.clearSMSThread,
    updateLinkPhysician: actions.updateLinkPhysician,
    getAllPhysicians: actions.getAllPhysicians,
    getCustomerPhysicians: actions.getCustomerPhysicians,
  },
  dispatch
);