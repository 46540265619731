import React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { TIMEZONES } from "../../constants";

const SessionConfirmation = (props) => {
  const {
    classes,
    open,
    closeDialog,
    clinicianTime,
    customerDetails,
    callType,
    timezone,
    demoCall,
    oneTimeVideoInfo,
    rescheduleCall,
    selectScheduledClinician,
    rescheduleDetails,
  } = props;

  const mapCallTypeToDisplay = (callType) => {
    switch (callType) {
      case "CAREGIVER_TRAINING":
        return "Caregiver Training";
      case "ORIENTATION":
        return "Orientation";
      case "DIRECT_THERAPY":
        return "Direct Therapy";
      case "ASSESSMENT":
        return "Assessment";
    }
  };
  let clientName = demoCall
    ? "Demo Client"
    : oneTimeVideoInfo.child_name && oneTimeVideoInfo.child_name != "undefined"
      ? oneTimeVideoInfo.child_name
      : customerDetails?.details?.name
        ? `${customerDetails?.details?.name} ${customerDetails?.details?.child_last_name}`
        : "";
  let sessionTime = rescheduleCall
    ? clinicianTime?.startDate
      ? clinicianTime.startDate
      : rescheduleDetails?.scheduled_date
    : clinicianTime?.startDate;

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          border: `1px solid #c3c6d2`,
          backgroundColor: "#fafafa",
          borderRadius: 3,
          minWidth: "30%",
        },
      }}
    >
      <div className={classes.confirmationHeaderContainer}>
        <Typography component="h5">
          Session Successfully {rescheduleCall ? "Rescheduled" : "Scheduled"}
        </Typography>
        {selectScheduledClinician ? (
          <Typography component="p">
            This session will now show up on the calendar for both the clinician and client.
          </Typography>
        ) : (
          <Typography component="p">
            This session will now show up on the calendar for both you and client.
          </Typography>
        )}
      </div>
      <div className={classes.confirmationInfoContainer}>
        {selectScheduledClinician && (
          <div>
            <Typography>Clinician</Typography>
            <Typography>{`${clinicianTime?.bcbaFirstName} ${clinicianTime?.bcbaLastName}`}</Typography>
          </div>
        )}
        <div>
          <Typography>Client</Typography>
          <Typography>{clientName || ""}</Typography>
        </div>
        <div>
          <Typography>Session Type</Typography>
          <Typography>{mapCallTypeToDisplay(callType)}</Typography>
        </div>
      </div>
      <div className={classes.confirmationDateInfoContainer}>
        <div>
          <EventNoteIcon className={classes.icon} />
          <Typography component="p">
            {moment.tz(clinicianTime?.startDate, timezone).format("dddd, MMMM Do, YYYY")}
          </Typography>
        </div>
        <div className={classes.eventTime}>
          <AccessTimeIcon className={classes.icon} />
          <Typography component="h5">
            {moment.tz(sessionTime, timezone).format("h:mm a")}
            {"    "}
            {TIMEZONES[timezone]}
          </Typography>
        </div>
      </div>
      <div className={classes.confirmationButtonsContainer}>
        <Button variant="text" size="small" onClick={closeDialog}>
          Close
        </Button>
      </div>
    </Dialog>
  );
};
export default SessionConfirmation;
