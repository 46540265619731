import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { BreadBox, PageTitle, Tools } from "./HeaderSections";
import { useSelector } from "react-redux";
import Divider from "elements/Divider";

/**
 *
 * @param {{
 *  handleSearchChange: Function,
 *  handleMenuClicked: Function,
 *  handleAvatarClicked: Function,
 *  handleBellClicked: Function,
 *  userAvatarSrc: String,
 *  menuExpanded: Boolean,
 *  unreadNotifications: Number,
 *  menuItems: Array<{ value: String, label: String, clickHandler: Function}>,
 *  breadcrumbs: Array,
 *  contextButtons Any
 * }} props
 * @returns
 */
export const Header = (props) => {
  const {
    // handlers
    handleSearchChange,
    handleMenuClicked,
    handleAvatarClicked,
    handleBellClicked,
    // values
    menuExpanded,
    userAvatarSrc,
    unreadNotifications,
    menuItems,
    breadcrumbs,
    contextButtons,
    userFullName,
    userInitials,
    userPermissions,
  } = props;
  const pageTitle = useSelector((state) => state.system.pageName);
  const hideDivider = useSelector((state) => state.system?.hideDivider ?? false);
  const altTitle = useSelector((state) => state.system?.altTitle);
  const customContextButtons = useSelector((state) => state.system?.customContextButtons);
  const onEditButton = useSelector((state) => state.system?.onEditButton ?? false);
  const editButtonAction = useSelector((state) => state.system?.editButtonAction);

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          boxShadow: "none",
          maxWidth: "100%",
          display: { xs: "none", lg: "flex" },
          backgroundColor: "#fff",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            padding: "0",
            minHeight: 72,
          }}
        >
          <Tools
            handleSearchChange={handleSearchChange}
            handleBellClicked={handleBellClicked}
            unreadNotifications={unreadNotifications}
            handleAvatarClicked={handleAvatarClicked}
            userAvatarSrc={userAvatarSrc}
            menuExpanded={menuExpanded}
            menuItems={menuItems}
            handleMenuClicked={handleMenuClicked}
            userFullName={userFullName}
            userInitials={userInitials}
            userPermissions={userPermissions}
          />
          <BreadBox breadcrumbs={breadcrumbs} />
          <PageTitle
            pageTitle={altTitle ?? pageTitle}
            contextButtons={customContextButtons ?? contextButtons}
            hideDivider={hideDivider}
            onEditButton={onEditButton}
            editButtonAction={editButtonAction}
          />
        </Toolbar>
      </AppBar>
      {!hideDivider && <Divider />}
    </>
  );
};
