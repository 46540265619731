import React from "react";
import Grid from "@mui/material/Grid";
import ANEditItem from "./ANEditItem";

const AnFormEdit = ({ inputsForm, classes }) => {
  return (
    <Grid container spacing={5}>
      {inputsForm &&
        inputsForm.map(
          (item, index) =>
            !item.hide && (
              <ANEditItem
                classes={classes}
                key={index}
                input={item.input}
                title={item.title}
                xs={12}
                sm={item.cols}
                panelMode={false}
                divider={item.divider}
              />
            )
        )}
    </Grid>
  );
};

export default AnFormEdit;
