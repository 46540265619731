import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { format, isSameDay, isSameWeek } from "date-fns";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import { StyledInput } from "./StyledInput";
import { Transition } from "./Transition";
import { styles } from "./styles";
import { convertTokens } from "@date-fns/upgrade/v2";
import SendConsentButton from "./SendConsentButton";

const ClientSMSDialog = (props) => {
  const {
    classes,
    onSendSMSMessage,
    smsThread,
    onOpenSMSFacilityDialog,
    onCloseSMSFacilityDialog,
    smsFacilityDialogOpen,
    eCommConsentFormSigned,
    canMessageAnytime,
    clientId,
  } = props;
  const [smsMessage, setSMSMessage] = useState("");
  const messagesEnd = useRef(null);
  const messageRefs = useRef({});
  const familyMember = smsThread.find((sms) => sms.sender_is_family);
  const familyName = familyMember ? `${familyMember.first_name} ${familyMember.last_name}` : "";
  const onSubmitSMS = () => {
    onSendSMSMessage(smsMessage);
    setSMSMessage("");
  };
  const keyPress = (e) => {
    if (e.keyCode == 13 && !!smsMessage.trim()) {
      e.preventDefault();
      onSubmitSMS();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (smsFacilityDialogOpen && !params.get("sms-open")) {
      window.setTimeout(() => {
        messagesEnd.current.scrollIntoView({ behavior: "smooth" });
      }, 400);
    } else if (smsFacilityDialogOpen && params.get("sms-open")) {
      setTimeout(() => {
        autoScroll(params.get("date"));
      }, 1000);
    }
  }, [smsThread.length, smsFacilityDialogOpen]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (
      params.get("sms-open") &&
      params.get("date") &&
      props.smsThread?.length > 0 &&
      !smsFacilityDialogOpen
    ) {
      onOpenSMSFacilityDialog();
      setTimeout(() => {
        autoScroll(params.get("date"));
      }, 1000);
    }
  }, [props.location.search]);

  const autoScroll = (date) => {
    let scrollDate = Object.keys(messageRefs.current)?.find((ref) =>
      isSameDay(new Date(date), new Date(ref))
    );
    messageRefs.current[scrollDate]?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <Dialog
      open={smsFacilityDialogOpen}
      onClose={onCloseSMSFacilityDialog}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 1100,
          height: "80%",
          maxWidth: "95%",
          overflow: "visible",
          padding: 0,
        },
      }}
      TransitionComponent={Transition}
      TransitionProps={{ direction: "up" }}
      style={{ padding: "0px 0px 0px 0px !important" }}
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.smsFacilityContainer}>
          <div className={classes.header}>
            <Typography component="p" className={classes.headerText}>
              {familyName || "SMS Facility"}
            </Typography>

            <IconButton
              onClick={onCloseSMSFacilityDialog}
              className={classes.closeIcon}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.smsThread}>
            {smsThread.map((sms, i) => {
              return (
                <React.Fragment key={i}>
                  {(!smsThread[i - 1] ||
                    !isSameDay(
                      new Date(sms.created_date),
                      new Date(smsThread[i - 1].created_date)
                    )) && (
                    <div className={classes.newDayContainer}>
                      <Typography component="p">
                        {isSameWeek(new Date(sms.created_date), new Date())
                          ? format(new Date(sms.created_date), convertTokens("ddd h:mm a"))
                          : format(
                              new Date(sms.created_date),
                              convertTokens("MMM D, YYYY, h:mm a")
                            )}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={
                      sms.sender_is_family ? classes.smsContainerFamily : classes.smsContainer
                    }
                    ref={(ref) => (messageRefs.current[sms.created_date] = ref)}
                  >
                    <div className={classes.smsMessageContainer}>
                      <div
                        className={
                          sms.sender_is_family ? classes.smsMessageFamily : classes.smsMessage
                        }
                      >
                        <Typography component="p" className={classes.message}>
                          {sms.message}
                        </Typography>
                      </div>
                      <div className={classes.timeSender}>
                        {sms.sender_is_family
                          ? `${format(new Date(sms.created_date), "p")}, ${sms.first_name}`
                          : `Sent ${format(new Date(sms.created_date), "p")}`}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div ref={messagesEnd} />
          </div>
          {!eCommConsentFormSigned && canMessageAnytime && (
            <div className={classes.consentWarningContainer}>
              <Typography component="p" className={classes.unsignedConsentWarning}>
                MISSING CONSENT TO RECEIVE ELECTRONIC COMMUNICATIONS: Please avoid sending any
                Personal Identifiable Information (PII). Click here to send a new consent form for
                the user to sign from their Home page.
              </Typography>
              <SendConsentButton clientId={clientId} />
            </div>
          )}
          <div className={classes.inputContainer}>
            <StyledInput
              variant="outlined"
              value={smsMessage}
              onChange={(e) => setSMSMessage(e.target.value)}
              placeholder="Text Message"
              fullWidth
              multiline
              style={{ marginRight: 15 }}
              onKeyDown={keyPress}
            />
            <SendIcon
              disabled={!smsMessage}
              onClick={onSubmitSMS}
              className={!!smsMessage ? classes.sendIcon : classes.sendIconDisabled}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ClientSMSDialog);
