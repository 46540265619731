import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import actions from "../../../actions";
import AnswersNowChat from "../../AnswersNowChat";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getSignedinState,
  getLogin,
  isAlpha,
  isBeta,
  isSubscribed,
  isExpired,
  canMessage,
  userClinician,
  getUserPermissionsList,
  userLoaded,
  getUser,
  getCurrentChannelUrl,
  getClinicianHealthieProviderId,
} from "../../../selectors";

const mapStateToProps = (state) => ({
  user: getUser(state),
  userLoaded: userLoaded(state),
  chatUrl: getCurrentChannelUrl(state),
  signedInState: getSignedinState(state),
  login: getLogin(state),
  alpha: isAlpha(state),
  beta: isBeta(state),
  subscribed: isSubscribed(state),
  expired: isExpired(state),
  canMessage: canMessage(state),
  clinician: userClinician(state),
  userPermissions: getUserPermissionsList(state),
  clinicianHealthieProviderId: getClinicianHealthieProviderId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      sendMessage: actions.sendMessage,
      setVideoRoomId: actions.setVideoRoomId,
    },
    dispatch
  );

class ChatDrawer extends Component {
  constructor() {
    super();
    this.anchorRef = React.createRef();
    this.getUnreadMessageCountInterval = null;
  }

  onMessageSend = (message, currentChannel, userId, online) => {
    this.props.sendMessage(userId, {
      message,
      conversationUrl: currentChannel.url,
      clinicianOnline: online,
    });
  };

  canSendMessage = () => {
    return true;
  };

  onChatClick = () => {
    this.props.toggleChatDrawer();
  };

  render() {
    const {
      user,
      classes,
      chatOpen,
      toggleChatDrawer,
      oneTimeVideoInfo,
      isCaregiver,
      clientJoined,
      isAssignedClinician,
      readyToJoinCall,
      hasJoinedRoom,
      videoChatUrl,
    } = this.props;
    const appId = process.env.SENDBIRD_APP_ID;
    let channelUrl = oneTimeVideoInfo.conversation_url || videoChatUrl;
    if (videoChatUrl && (hasJoinedRoom || (!isCaregiver && !isAssignedClinician))) {
      channelUrl = videoChatUrl;
    }
    return (
      <>
        <Drawer
          className={classes.chatDrawer}
          anchor="right"
          open={chatOpen}
          ModalProps={{ hideBackdrop: true }}
          onClose={toggleChatDrawer}
          classes={{
            paper: classes.chatDrawerPaper,
          }}
        >
          <div className={classes.chatDrawerHeader}>
            Chat
            <IconButton onClick={toggleChatDrawer} aria-label="Previous Page">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.chatDrawerContent}>
            <AnswersNowChat
              userId={user.id}
              name={user.first_name}
              displayLeftDrawer={false}
              displayNotes={false}
              onMessageSend={this.onMessageSend}
              displayBack={false}
              appId={appId}
              channelUrl={channelUrl}
              canSendMessage={this.canSendMessage}
              chatType="all"
              onClientStartVideo={this.onClientStartVideo}
              miniDisplay={true}
              minimized={false}
            />
          </div>
        </Drawer>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatDrawer));
