import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getParentInfo,
  getClinicianBio,
  getClinicianSpecialties,
  getDashboard,
  cancelSubscription,
  getCancelReasons,
  agreeToPolicies,
  approveTreatmentPlan,
  getActiveTreatmentPlan,
  getInsuranceDashboard,
  updateToFullCustomer,
  getInsuranceRoadmap,
  sendDocumentRequestSignature,
  sendEligibilityRejectionInfo,
  logAWSUploadError,
  getEligibilityFields,
  getUnsignedRequests,
  signDocumentRequest,
  checkRecaptchaVerification,
  getCustomerSignedForm,
  saveSignedCustomerForm,
  updateClientInfo,
  updateCaregiverInfo,
  getCustomerCallsByMonth,
  getCustomerDashboardNotifications,
  markNotificationAsRead,
  requestTreatmentPlanReview,
  requestVideoCallReschedule,
  getClientStaticPage,
  getPrimaryAccountEmail,
  updateClientTimeZone,
} from "../api/parent";
import {
  getCustomer,
  getCustomerChildren,
  uploadCustomerDocument,
  getCustomerClinicians,
} from "../api/customers";
import { getClientId } from "../selectors";
import cookie from "react-cookies";

function* getParentInfoAsync() {
  try {
    const result = yield call(getParentInfo);
    const details = yield call(getCustomer, result.data.client_id);
    const children = yield call(getCustomerChildren, result.data.client_id);
    const user = { ...result.data };
    user.userDetails = { ...details.data.customer };
    user.childDetails = { ...children.data.children[0] };
    yield put(actions.getParentInfoSuccess(user));
    if (result.data.referred_clinician_user_id && result.data.referred_clinician_id) {
      yield put(actions.getClinicianBio(result.data.referred_clinician_user_id));
      yield put(actions.referredClinician());
    }
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getUserClinicianAsync({ payload }) {
  try {
    const result = yield call(getCustomerClinicians, payload);
    if (result.data && result.data.clinician) {
      yield put(actions.getUserClinicianSuccess(result.data.clinician));
      yield put(actions.getClinicianSpecialties(result.data.clinician.clinician_id));
    }
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getClinicianBioAsync({ payload }) {
  try {
    const bio = yield call(getClinicianBio, payload.clinicianUserId);
    const specialties = yield call(getClinicianSpecialties, bio.data[0].id);
    yield put(actions.getClinicianBioSuccess(bio.data[0], specialties.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getClinicianSpecialtiesAsync({ payload }) {
  try {
    const result = yield call(getClinicianSpecialties, payload.clinicianId);
    yield put(actions.getClinicianSpecialtiesSuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getDashboardAsync() {
  try {
    const result = yield call(getDashboard);
    yield put(actions.getDashboardSuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* cancelSubscriptionAsync({ payload }) {
  try {
    yield call(cancelSubscription, payload);
    yield put(actions.cancelSubscriptionSuccess());
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.cancelSubscriptionFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCancelReasonsAsync() {
  try {
    const reasons = yield call(getCancelReasons);
    yield put(actions.getCancelReasonsSuccess(reasons.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* agreeToPoliciesAsync({ payload }) {
  try {
    yield call(agreeToPolicies, payload);
    yield put(actions.agreeToPoliciesSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getActiveTreatmentPlanAsync() {
  try {
    const treatmentPlan = yield call(getActiveTreatmentPlan);
    yield put(actions.getActiveTreatmentPlanSuccess(treatmentPlan.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* approveTreatmentPlanAsync({ payload }) {
  try {
    yield call(approveTreatmentPlan, payload);
    yield put(actions.getParentInfo());
    yield put(actions.approveTreatmentPlanSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceDashboardAsync() {
  try {
    const dashboard = yield call(getInsuranceDashboard);
    yield put(actions.getInsuranceDashboardSuccess(dashboard.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* updateToFullCustomerAsync() {
  try {
    yield call(updateToFullCustomer);
    yield put(actions.updateToFullCustomerSuccess());
  } catch (e) {
    yield put(actions.updateToFullCustomerFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* uploadUserDocumentAsync({ payload }) {
  try {
    for (let i = 0; i < payload.length; i++) {
      yield call(uploadCustomerDocument, payload[i]);
    }
    yield put(actions.uploadUserDocumentsSuccess());
    const clientId = yield select(getClientId);
    if (clientId) {
      yield put(actions.getCustomerDocuments(clientId));
    }
  } catch (e) {
    yield put(actions.uploadUserDocumentsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceRoadmapAsync() {
  try {
    const roadmap = yield call(getInsuranceRoadmap);
    yield put(actions.getInsuranceRoadmapSuccess(roadmap.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendDocumentRequestSignatureAsync({ payload }) {
  try {
    yield call(sendDocumentRequestSignature, payload);
    yield put(actions.sendDocumentRequestSignatureSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendRejectionEligibilityInfoAsync({ payload }) {
  try {
    yield call(sendEligibilityRejectionInfo, payload);
    yield put(actions.sendRejectionEligibilityInfoSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* logAWSUploadErrorAsync({ payload }) {
  try {
    yield call(logAWSUploadError, payload);
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getEligibilityFieldsAsync() {
  try {
    const roadmap = yield call(getInsuranceRoadmap);
    yield put(actions.getInsuranceRoadmapSuccess(roadmap.data));
    const fields = yield call(getEligibilityFields);
    yield put(actions.getEligibilityFieldsSuccess(fields.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getUnsignedDocumentRequestsAsync() {
  try {
    const requests = yield call(getUnsignedRequests);
    yield put(actions.getUnsignedDocumentRequestsSuccess(requests.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* signDocumentRequestAsync({ payload }) {
  try {
    yield call(signDocumentRequest, payload);
    yield put(actions.signDocumentRequestSuccess());
  } catch (e) {
    yield put(actions.signDocumentRequestFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* checkRecaptchaVerificationAsync({ payload }) {
  try {
    const response = yield call(checkRecaptchaVerification, payload);
    yield put(actions.checkRecaptchaVerificationSuccess(response.data));
  } catch (e) {
    yield put(actions.checkRecaptchaVerificationFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getCustomerSignedFormAsync({ payload }) {
  try {
    let form = yield call(getCustomerSignedForm, payload);
    yield put(actions.getCustomerSignedFormSuccess(form.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* saveSignedCustomerFormAsync({ payload }) {
  try {
    yield call(saveSignedCustomerForm, payload);
    yield put(actions.saveSignedCustomerFormSuccess());
  } catch (e) {
    yield put(actions.saveSignedCustomerFormFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateClientInfoAsync({ payload }) {
  try {
    yield call(updateClientInfo, payload);
    yield call(updateClientTimeZone, {
      timezone: payload.clientTimezone,
      clientId: payload.clientId,
    });
    yield put(actions.updateClientInfoSuccess());
    if (payload.isAdmin) {
      yield put(actions.loadCustomerDetails(payload.clientId));
    } else {
      yield put(actions.getParentInfo());
    }
  } catch (e) {
    yield put(actions.updateClientInfoFail(e?.response?.data?.error));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerCallsByMonthAsync({ payload }) {
  try {
    const calls = yield call(getCustomerCallsByMonth, payload);
    yield put(actions.getCustomerCallsByMonthSuccess(calls.data));
  } catch (e) {
    yield put(actions.getCustomerCallsByMonthFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateCaregiverInfoAsync({ payload }) {
  try {
    yield call(updateCaregiverInfo, payload);
    yield put(actions.updateCaregiverInfoSuccess());
    if (payload.byAdminUser) {
      yield put(actions.loadCustomerDetails(payload.clientId));
    } else {
      yield put(actions.getParentInfo());
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.updateCaregiverInfoFail(e.response.data.error));
    }
    console.error(e);
  }
}

function* getCustomerDashboardNotificationsAsync() {
  try {
    const notifications = yield call(getCustomerDashboardNotifications);
    yield put(actions.getCustomerDashboardNotificationsSuccess(notifications.data));
  } catch (e) {
    yield put(actions.getCustomerDashboardNotificationsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markCustomerNotificationAsReadAsync({ payload }) {
  try {
    yield call(markNotificationAsRead, payload);
    yield put(actions.getCustomerDashboardNotifications());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* requestTreatmentPlanReviewAsync() {
  try {
    yield call(requestTreatmentPlanReview);
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* requestVideoCallRescheduleAsync({ payload }) {
  try {
    yield call(requestVideoCallReschedule, payload);
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClientStaticPageAsync({ payload }) {
  try {
    const staticPage = yield call(getClientStaticPage, payload);
    const username = staticPage.data.client_email;
    const password = staticPage.data.client_key;
    yield put(actions.startSignin({ username, password, skipGetUser: true }));
    yield put(actions.getClientStaticPageSuccess(staticPage.data.video_key));
  } catch (e) {
    let errorMessage = "We were unable to find your next call. Please try again.";
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage = e.response.data.message;
    }
    yield put(actions.getClientStaticPageFail(errorMessage));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* setClientStaticPageStateAsync({ payload }) {
  try {
    const customer = yield call(getPrimaryAccountEmail, payload);
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 2);
    cookie.save("userId", customer.data.user_id, { path: "/", expires });
    cookie.save("fromStaticVideoPage", customer.data.email, { path: "/" });
    yield put(actions.setStaticPageStateSuccess({ email: customer.data.email }));
    yield put(actions.getUser());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* userSaga() {
  yield takeEvery(actions.GET_PARENT_INFO, getParentInfoAsync);
  yield takeEvery(actions.GET_USER_CLINICIAN, getUserClinicianAsync);
  yield takeEvery(actions.GET_CLINICIAN_BIO, getClinicianBioAsync);
  yield takeEvery(actions.GET_CLINICIAN_SPECIALTIES, getClinicianSpecialtiesAsync);
  yield takeEvery(actions.GET_DASHBOARD, getDashboardAsync);
  yield takeEvery(actions.CANCEL_SUBSCRIPTION, cancelSubscriptionAsync);
  yield takeEvery(actions.GET_CANCEL_REASONS, getCancelReasonsAsync);
  yield takeEvery(actions.AGREE_TO_POLICIES, agreeToPoliciesAsync);
  yield takeEvery(actions.GET_ACTIVE_TREATMENT_PLAN, getActiveTreatmentPlanAsync);
  yield takeEvery(actions.APPROVE_TREATMENT_PLAN, approveTreatmentPlanAsync);
  yield takeEvery(actions.GET_INSURANCE_DASHBOARD, getInsuranceDashboardAsync);
  yield takeEvery(actions.UPDATE_FULL_CUSTOMER, updateToFullCustomerAsync);
  yield takeEvery(actions.UPLOAD_USER_DOCUMENT, uploadUserDocumentAsync);
  yield takeEvery(actions.GET_INSURANCE_ROADMAP, getInsuranceRoadmapAsync);
  yield takeEvery(actions.SEND_DOCUMENT_REQUEST_SIGNATURE, sendDocumentRequestSignatureAsync);
  yield takeEvery(actions.SEND_REJECTION_ELIGIBILITY_INFO, sendRejectionEligibilityInfoAsync);
  yield takeEvery(actions.LOG_AWS_UPLOAD_ERROR, logAWSUploadErrorAsync);
  yield takeEvery(actions.GET_ELIGIBILITY_FIELDS, getEligibilityFieldsAsync);
  yield takeEvery(actions.GET_UNSIGNED_DOCUMENT_REQUESTS, getUnsignedDocumentRequestsAsync);
  yield takeEvery(actions.SIGN_DOCUMENT_REQUEST, signDocumentRequestAsync);
  yield takeEvery(actions.CHECK_RECAPTCHA_VERIFICATION, checkRecaptchaVerificationAsync);
  yield takeEvery(actions.GET_CUSTOMER_SIGNED_FORM, getCustomerSignedFormAsync);
  yield takeEvery(actions.SAVE_SIGNED_CUSTOMER_FORM, saveSignedCustomerFormAsync);
  yield takeEvery(actions.UPDATE_CLIENT_INFO, updateClientInfoAsync);
  yield takeEvery(actions.UPDATE_CAREGIVER_INFO, updateCaregiverInfoAsync);
  yield takeEvery(actions.GET_CUSTOMER_CALLS_BY_MONTH, getCustomerCallsByMonthAsync);
  yield takeEvery(
    actions.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS,
    getCustomerDashboardNotificationsAsync
  );
  yield takeEvery(actions.MARK_CUSTOMER_NOTIFICATION_AS_READ, markCustomerNotificationAsReadAsync);
  yield takeEvery(actions.REQUEST_TREATMENT_PLAN_REVIEW, requestTreatmentPlanReviewAsync);
  yield takeEvery(actions.REQUEST_VIDEO_CALL_RESCHEDULE, requestVideoCallRescheduleAsync);
  yield takeEvery(actions.GET_CLIENT_STATIC_PAGE, getClientStaticPageAsync);
  yield takeEvery(actions.SET_STATIC_PAGE_STATE, setClientStaticPageStateAsync);
}
