import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import { CustomSelect } from "elements/DEPRECATED_elements";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const FamilyMemberActivation = (props) => {
  const {
    onSubmitToggleFamilyMemberActivation,
    reason,
    note,
    onChangeDeactivationInfo,
    onClearDeactivationInfo,
    customerDeactivationReasons,
    classes,
    contact,
    onToggleFamilyActivation,
  } = props;

  let deactivationReasons = customerDeactivationReasons;

  return (
    <div>
      <Typography className={classes.serviceTypesHeading}>
        Are you sure that you want to deactivate this family member?
      </Typography>
      <TextField
        select
        fullWidth
        id="reason-menu"
        value={reason}
        name="reason"
        onChange={onChangeDeactivationInfo}
        style={{ width: "40%", margin: "10px 0px 20px" }}
      >
        <MenuItem disabled value="">
          Select a Reason for Deactivation
        </MenuItem>
        {deactivationReasons?.map((el) => (
          <MenuItem key={el.id} value={el.reason}>
            {el.reason}
          </MenuItem>
        ))}
      </TextField>
      <Typography className={classes.serviceTypesHeading}>
        Provide additional notation for deactivation of family member.
      </Typography>
      <TextField
        fullWidth
        id="reason-note"
        name="note"
        value={note}
        onChange={onChangeDeactivationInfo}
        style={{ marginTop: 10 }}
        multiline={true}
        rows={4}
        placeholder="Type your note here"
      />
      <div className={classes.buttonContainer} style={{ marginTop: 15, marginBottom: 10 }}>
        <Button
          color="secondary"
          onClick={() => {
            onClearDeactivationInfo();
            onSubmitToggleFamilyMemberActivation(contact.user_id);
          }}
          disabled={!note || !reason}
        >
          Deactivate
        </Button>
        <Button variant="text" onClick={onToggleFamilyActivation}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(FamilyMemberActivation);
