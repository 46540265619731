import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CheckCircle } from "@phosphor-icons/react";

export const PasswordValidationMarkers = (props) => {
  const { classes, upperCase, lowerCase, properLength, includesNumber, matches } = props;

  const requirements = [
    { label: "8 characters", isValid: properLength },
    { label: "1 number", isValid: includesNumber },
    { label: "1 uppercase letter", isValid: upperCase },
    { label: "1 lowercase letter", isValid: lowerCase },
    { label: "Passwords match", isValid: matches },
  ];

  const listItems = requirements.map(({ label, isValid }, index) => {
    return (
      <ListItem key={index}>
        <ListItemIcon className={classes.listIcon}>
          <CheckCircle
            size={24}
            color={isValid ? "#2E7D32" : null}
            weight={isValid ? "fill" : "regular"}
          />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={label}
          className={isValid ? classes.listTextValid : classes.listText}
        />
      </ListItem>
    );
  });
  return (
    <Grid container spacing={1}>
      <Grid item>
        <List dense={true}>{listItems}</List>
      </Grid>
    </Grid>
  );
};
