import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { X } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import { Alert, Stack, Typography } from "@mui/material";
import { cancelReasons, findCancelReasonByResponsible } from "../../constants/cancelReasons";
import Divider from "@mui/material/Divider";

const CancelSessionDialog = (props) => {
  const {
    sessionDetails,
    open,
    closeDialog,
    onChangeCancelReason,
    cancelReasonText,
    submitCancelSession,
    responsibleForCancellation,
    onChangeResponsibleForCancellation,
    disableClose,
    cancelAllInstances,
    onChangeCancelSelection,
  } = props;

  const theme = useTheme();
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle>
        Cancel Therapy Session
        {!disableClose && (
          <IconButton aria-label="close" onClick={closeDialog}>
            <X />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: "20px" }}>
        <Stack gap={theme.spacing(5)}>
          <FormControl>
            {sessionDetails?.is_recurring && (
              <>
                <RadioGroup
                  aria-label="responsibleForCancellation"
                  name="plan"
                  value={cancelAllInstances}
                  onChange={onChangeCancelSelection}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={<Typography>Cancel only this session</Typography>}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={<Typography>Cancel this and all future sessions</Typography>}
                  />
                </RadioGroup>
                <Divider variant="fullWidth" style={{ marginTop: "16px", marginBottom: "16px" }} />
              </>
            )}
            <FormLabel sx={{ color: theme.palette.text.primary }}>
              Who needs to cancel the session?
            </FormLabel>
            <RadioGroup
              aria-label="responsibleForCancellation"
              name="responsibleForCancellation"
              value={responsibleForCancellation}
              onChange={(e) => {
                onChangeResponsibleForCancellation(e);
              }}
            >
              {cancelReasons.map((reason, i) => {
                const isObject = !Array.isArray(reason.cancelReasons);
                const labelContent =
                  isObject && reason.cancelReasons.label
                    ? `${reason.label} ${reason.cancelReasons.label}`
                    : reason.label;
                return (
                  <FormControlLabel
                    value={reason.responsibleForCancellation}
                    control={<Radio color="primary" size="small" />}
                    label={labelContent}
                    key={i}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {!(
            responsibleForCancellation &&
            !Array.isArray(findCancelReasonByResponsible(responsibleForCancellation).cancelReasons)
          ) && (
            <TextField
              id="cancelReasonText"
              name="cancelReasonText"
              label="Reason for Cancelling"
              autoComplete="off"
              onChange={(e) => onChangeCancelReason(e)}
              value={cancelReasonText}
              select
              sx={{ width: 256 }}
              SelectProps={{ required: true }}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {cancelReasons
                .filter(
                  (item) =>
                    item.responsibleForCancellation === responsibleForCancellation &&
                    Array.isArray(item.cancelReasons)
                )
                .flatMap((item) => item.cancelReasons)
                .map((cancelReason, i) => {
                  return (
                    <MenuItem key={i} value={cancelReason}>
                      {cancelReason}
                    </MenuItem>
                  );
                })}
            </TextField>
          )}
          <Alert severity="warning">
            Cancelling a therapy session will send a confirmation email to the client about the
            cancellation
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={submitCancelSession}
          color="error"
          disabled={!cancelReasonText || !responsibleForCancellation}
        >
          Cancel therapy session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSessionDialog;
