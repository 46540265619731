import { getAge } from "../../utils/getAge";
import {
  getCustomerDetails,
  getSignedinState,
  getCliniciansList,
  getCustomerLoaded,
  getCustomerUserId,
  getCustomerLegacy,
  getCustomerWorkflowStatus,
  getCustomerVerified,
  getMarkingEligible,
  getMarkingApproved,
  getDocumentTypes,
  getResendingLoginEmail,
  scheduleCallLoading,
  scheduleCallSuccess,
  getAuthorizationCodes,
  getSendingResetPassword,
  getResetPasswordSent,
  getUserVideoCallsType,
  getUserVideoCallsPage,
  getUserVideoCallsPerPage,
  getUserVideoCallsSort,
  getUserVideoCallsSortDirection,
  getUserVideoCallsCount,
  getUserVideoCalls,
  getInsuranceCodesByUser,
  getUserInsurance,
  getInsuranceProviders,
  isCustomerCare,
  getClincicianBasicDetails,
  getUser,
  getClientId,
  // getNotesClientId,
  getCustomerCliniciansLoading,
  getCustomerDocumentsLoading,
  getCustomerPhysiciansLoading,
  getCustomerTreatmentPlansLoading,
  getCustomerApprovedUnitsLoading,
  getCustomerVideoCallsLoading,
  getCustomerPastCliniciansLoading,
  getCustomerAuthorizationCodesLoading,
  getUserInsuranceLoading,
  getUserBillableTime,
  getBillableTimeLoading,
  getDocumentRequestLoading,
  getDocumentRequestSuccess,
  getDocumentRequests,
  getSessionNotesByUser,
  getOldSessionNotesByUser,
  getDocumentMatrix,
  getDocumentMatrixLoading,
  getCustomerDeactivationReasons,
  getCustomerDepartureOptions,
  isSupervisor,
  getUserPermissionsList,
  isClinician,
  isDirector,
  isAdmin,
  getAssignableClinicians,
  getAssignableCliniciansLoading,
  getUpdatingCustomerInfo,
  getUpdatingCustomerInfoSuccess,
  getSavingCustomerPhysician,
  getSavingCustomerPhysicianSuccess,
  getSavingCustomerPhysicianError,
  rescheduleVideoDetails,
  getUploadingTreatmentPlan,
  getUserId,
  getNotesSaving,
  getOffPlatformLoading,
  getOffPlatformSuccess,
  getTreatmentPlanLoading,
  getTreatmentPlanSuccess,
  getSentEmails,
  getSentEmailsLoading,
  getClinicianClientsList,
  getUpdateCustomerEmailSuccess,
  getUpdateCustomerEmailError,
  getUpdateCustomerEmailLoading,
  getForm,
  getUpdateCustomerEmailErrorMessage,
  getUpdateCustomerTimezoneLoading,
  getUpdateCustomerTimezoneSuccess,
  getUpdateCustomerTimezoneError,
  getAvailableInsuranceCodes,
  getCustomerClientId,
  getCustomerHealthieDocuments,
  getHealthieDocumentLoading,
  getHealthieDocumentError,
  getHealthieDocument,
  getUserIsAssignedClinician,
  getImportHealthieClientFamilyMemberLoading,
  getImportHealthieClientFamilyMemberSuccess,
  getImportHealthieClientFamilyMemberError,
  getImportHealthieClientFamilyMemberErrorMessage,
  getPrimaryAccountHolderSwitchLoading,
  getPrimaryAccountHolderSwitchSuccess,
  getUpdatingInfoInHealthie,
  getUpdatingInfoInHealthieSuccess,
  getUpdatingInfoInHealthieFail,
  getCustomerSMSThread,
  getEnableClientStaticPageLoading,
  getEnableClientStaticPageSuccess,
  getEnableClientStaticPageError,
  getCustomerAdminNotes,
  getClientTaskList,
  getClientTaskListLoading,
  getMarkTaskCompleteLoading,
  getMarkTaskCompleteSuccess,
  getMarkTaskCompleteError,
  getClientSessionStatistics,
  getClientStatisticsLoading,
  getNotesSaved,
  getInsurancePlans,
  getInsuranceProvidersList,
  getInsuranceListProviderId,
  getUpdatingLinkPhysician,
  getUpdatingLinkPhysicianSuccess,
  isCaregiver,
} from "../../selectors";

export const mapStateToProps = (state) => ({
  client_id: getClientId(state),
  isCaregiver: isCaregiver(state),
  signedInState: getSignedinState(state),
  clinicianList: getCliniciansList(state),
  customerDetails: getCustomerDetails(state),
  customerName: getCustomerDetails(state).details.name || "",
  organization: getCustomerDetails(state).details.organization || "",
  childName: getCustomerDetails(state).details.name || "",
  childLastName:
    getCustomerDetails(state).children === undefined
      ? ""
      : getCustomerDetails(state).children.child_last_name,
  age: getCustomerDetails(state).details.dob ? getAge(getCustomerDetails(state).details.dob) : 0,
  notes:
    getCustomerDetails(state).children === undefined
      ? ""
      : getCustomerDetails(state).children.notes,
  previousTherapyAge:
    getCustomerDetails(state).children === undefined
      ? ""
      : getCustomerDetails(state).children.previous_therapy_age,
  previousTherapy:
    getCustomerDetails(state).children === undefined
      ? false
      : getCustomerDetails(state).children.previous_therapy,
  clinician:
    getCustomerDetails(state).clinician === undefined
      ? 0
      : getCustomerDetails(state).clinician.clinician_id,
  loaded: getCustomerLoaded(state),
  customerUserId: getCustomerUserId(state),
  isLegacy: getCustomerLegacy(state),
  workflowStatus: getCustomerWorkflowStatus(state),
  verified: getCustomerVerified(state),
  markingEligible: getMarkingEligible(state),
  markingApproved: getMarkingApproved(state),
  documentTypes: getDocumentTypes(state),
  resendingLoginEmail: getResendingLoginEmail(state),
  insuranceCodes: getInsuranceCodesByUser(state),
  authorizationCodes: getAuthorizationCodes(state),
  sendingResetPassword: getSendingResetPassword(state),
  resetPasswordSent: getResetPasswordSent(state),
  userVideoCalls: getUserVideoCalls(state),
  userVideoPage: getUserVideoCallsPage(state),
  userVideoRowsPerPage: getUserVideoCallsPerPage(state),
  userVideoSort: getUserVideoCallsSort(state),
  userVideoDirection: getUserVideoCallsSortDirection(state),
  userVideoCount: getUserVideoCallsCount(state),
  userVideoType: getUserVideoCallsType(state),
  userInsurance: getUserInsurance(state),
  insuranceProviders: getInsuranceProviders(state),
  isCustomerCare: isCustomerCare(state),
  isSupervisor: isSupervisor(state),
  isDirector: isDirector(state),
  isAdmin: isAdmin(state),
  clinicianDetails: getClincicianBasicDetails(state),
  currentUser: getUser(state),
  // clientId: getNotesClientId(state),
  clientId: getCustomerClientId(state),
  cliniciansLoading: getCustomerCliniciansLoading(state),
  documentsLoading: getCustomerDocumentsLoading(state),
  physiciansLoading: getCustomerPhysiciansLoading(state),
  treatmentPlansLoading: getCustomerTreatmentPlansLoading(state),
  approvedUnitsLoading: getCustomerApprovedUnitsLoading(state),
  videoCallsLoading: getCustomerVideoCallsLoading(state),
  pastCliniciansLoading: getCustomerPastCliniciansLoading(state),
  authorizationCodesLoading: getCustomerAuthorizationCodesLoading(state),
  userInsuranceLoading: getUserInsuranceLoading(state),
  billableTime: getUserBillableTime(state),
  billableTimeLoading: getBillableTimeLoading(state),
  documentRequestLoading: getDocumentRequestLoading(state),
  documentRequestSuccess: getDocumentRequestSuccess(state),
  documentRequests: getDocumentRequests(state),
  sessionNotesByUser: getSessionNotesByUser(state),
  oldSessionNotesByUser: getOldSessionNotesByUser(state),
  documentMatrix: getDocumentMatrix(state),
  documentMatrixLoading: getDocumentMatrixLoading(state),
  customerDeactivationReasons: getCustomerDeactivationReasons(state),
  customerDropReasons: getCustomerDeactivationReasons(state, "drop"),
  customerChurnReasons: getCustomerDeactivationReasons(state, "churn"),
  customerDepartureOptions: getCustomerDepartureOptions(state),
  userPermissions: getUserPermissionsList(state),
  isClinician: isClinician(state),
  assignableClinicians: getAssignableClinicians(state),
  assignableCliniciansLoading: getAssignableCliniciansLoading(state),
  updatingCustomerInfo: getUpdatingCustomerInfo(state),
  updatingCustomerInfoSuccess: getUpdatingCustomerInfoSuccess(state),
  savingCustomerPhysician: getSavingCustomerPhysician(state),
  savingCustomerPhysicianSuccess: getSavingCustomerPhysicianSuccess(state),
  savingCustomerPhysicianError: getSavingCustomerPhysicianError(state),
  scheduleCallLoading: scheduleCallLoading(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  rescheduleVideoDetails: rescheduleVideoDetails(state),
  uploadingTreatmentPlan: getUploadingTreatmentPlan(state),
  clinicianUserId: getUserId(state),
  notesSaving: getNotesSaving(state),
  noteSaved: getNotesSaved(state),
  offPlatformLoading: getOffPlatformLoading(state),
  offPlatformSuccess: getOffPlatformSuccess(state),
  treatmentPlanLoading: getTreatmentPlanLoading(state),
  treatmentPlanSuccess: getTreatmentPlanSuccess(state),
  sentEmailList: getSentEmails(state),
  sentEmailsLoading: getSentEmailsLoading(state),
  clients: getClinicianClientsList(state),
  updateClientEmailLoading: getUpdateCustomerEmailLoading(state),
  updateClientEmailSuccess: getUpdateCustomerEmailSuccess(state),
  updateClientEmailError: getUpdateCustomerEmailError(state),
  updateClientEmailErrorMessage: getUpdateCustomerEmailErrorMessage(state),
  form: getForm(state),
  updateClientTimezoneLoading: getUpdateCustomerTimezoneLoading(state),
  updateClientTimezoneSuccess: getUpdateCustomerTimezoneSuccess(state),
  updateClientTimezoneError: getUpdateCustomerTimezoneError(state),
  availableInsuranceCodes: getAvailableInsuranceCodes(state),
  healthieDocuments: getCustomerHealthieDocuments(state),
  healthieDocumentLoading: getHealthieDocumentLoading(state),
  healthieDocumentError: getHealthieDocumentError(state),
  healthieDocument: getHealthieDocument(state),
  importHealthieClientFamilyMemberLoading: getImportHealthieClientFamilyMemberLoading(state),
  importHealthieClientFamilyMemberSuccess: getImportHealthieClientFamilyMemberSuccess(state),
  importHealthieClientFamilyMemberError: getImportHealthieClientFamilyMemberError(state),
  importHealthieClientFamilyMemberErrorMessage:
    getImportHealthieClientFamilyMemberErrorMessage(state),
  primaryAccountHolderSwitchLoading: getPrimaryAccountHolderSwitchLoading(state),
  primaryAccountHolderSwitchSuccess: getPrimaryAccountHolderSwitchSuccess(state),
  updatingInfoInHealthie: getUpdatingInfoInHealthie(state),
  updateHealthieClientInfoSuccess: getUpdatingInfoInHealthieSuccess(state),
  updateHealthieClientInfoFail: getUpdatingInfoInHealthieFail(state),
  smsThread: getCustomerSMSThread(state),
  enableStaticPageLoading: getEnableClientStaticPageLoading(state),
  enableStaticPageSuccess: getEnableClientStaticPageSuccess(state),
  enableStaticPageError: getEnableClientStaticPageError(state),
  adminNotes: getCustomerAdminNotes(state),
  clientTaskList: getClientTaskList(state),
  clientTaskListLoading: getClientTaskListLoading(state),
  markTaskCompleteLoading: getMarkTaskCompleteLoading(state),
  markTaskCompleteSuccess: getMarkTaskCompleteSuccess(state),
  markTaskCompleteError: getMarkTaskCompleteError(state),
  clientStatistics: getClientSessionStatistics(state),
  clientStatisticsLoading: getClientStatisticsLoading(state),
  insurancePlans: getInsurancePlans(state),
  insuranceProvidersList: getInsuranceProvidersList(state),
  insuranceListProviderId: getInsuranceListProviderId(state),
  updateLinkPhysicianLoading: getUpdatingLinkPhysician(state),
  updateLinkPhysicianSuccess: getUpdatingLinkPhysicianSuccess(state),
  assignedToLearner: getUserIsAssignedClinician(state),
});
