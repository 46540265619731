import React from "react";
import withStyles from "@mui/styles/withStyles";
import DetailAccordion from "elements/DetailAccordion";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { format } from "date-fns";
import HealthieFolderAccordion from "elements/HealthieFolderAccordion";

import { convertTokens } from "@date-fns/upgrade/v2";
import { convertToISO } from "utils/convertToISO";

const HealthieDocuments = (props) => {
  const { classes, healthieDocuments, healthieDocumentError, getHealthieDocument } = props;
  const documents = healthieDocuments?.documents || [];
  const folders = healthieDocuments?.folders || [];
  return (
    <DetailAccordion title="Healthie Documents">
      {healthieDocumentError && (
        <div className={classes.errorContainer}>
          <Typography component="p" className={classes.error}>
            An error has occurred while opening the selected document. Please try again.
          </Typography>
        </div>
      )}
      {documents.length > 0 || folders.length > 0 ? (
        <>
          {folders.map((folder) => {
            return (
              <HealthieFolderAccordion key={folder.id} title={folder.name}>
                {folder?.documents?.map((document) => {
                  return (
                    <React.Fragment key={document.id}>
                      <div className={classes.document}>
                        <div className={classes.documentInfo}>
                          <Typography component="p" className={classes.documentName}>
                            {document.display_name}
                          </Typography>

                          <Typography
                            component="p"
                            className={classes.documentCreatedDate}
                            style={{
                              fontSize: 15,
                            }}
                          >
                            <ScheduleIcon
                              style={{
                                fontSize: 18,
                                marginRight: 5,
                              }}
                            />
                            Uploaded{" "}
                            {format(
                              new Date(convertToISO(document.created_at)),
                              convertTokens("MMM D, YYYY h:mm a"),
                            )}
                          </Typography>
                        </div>
                        <div className={classes.buttonContainer}>
                          <Button variant="text" onClick={() => getHealthieDocument(document.id)}>
                            View Document
                          </Button>
                        </div>
                      </div>
                      <div className={classes.divider} />
                    </React.Fragment>
                  );
                })}
              </HealthieFolderAccordion>
            );
          })}
          {documents.map((document) => {
            return (
              <React.Fragment key={document.id}>
                <div className={classes.document}>
                  <div className={classes.documentInfo}>
                    <Typography component="p" className={classes.documentName}>
                      {document.display_name}
                    </Typography>

                    <Typography
                      component="p"
                      className={classes.documentCreatedDate}
                      style={{
                        fontSize: 15,
                      }}
                    >
                      <ScheduleIcon
                        style={{
                          fontSize: 18,
                          marginRight: 5,
                        }}
                      />
                      Uploaded{" "}
                      {format(
                        new Date(convertToISO(document.created_at)),
                        convertTokens("MMM D, YYYY h:mm a"),
                      )}
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button variant="text" onClick={() => getHealthieDocument(document.id)}>
                      View Document
                    </Button>
                  </div>
                </div>
                <div className={classes.divider} />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <React.Fragment key={document.document_requirements_user_id}>
          <div className={classes.document}>
            <div className={classes.documentInfo}>
              <Typography component="p" className={classes.documentName}>
                No documents found in Healthie for this client
              </Typography>
            </div>
          </div>
        </React.Fragment>
      )}
    </DetailAccordion>
  );
};
const styles = (theme) => ({
  document: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "2px solid #cbcbcb",
    margin: "10px 0",
  },
  documentInfo: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  documentName: {
    color: "#3e3e3e",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5,
  },
  documentCreatedDate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    "& button": {
      marginRight: 10,
    },
  },
  errorContainer: {
    width: "100%",
    marginBottom: 15,
  },
  error: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#dc2626",
  },
});

export default withStyles(styles)(HealthieDocuments);
