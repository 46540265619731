import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { bindActionCreators } from "redux";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import actions from "../../actions";
import styles from "../../theme/globalStyles";
import {
  getCustomerBillableItems,
  getCustomerBillableItemsCount,
  getCustomerBillableItemsLoading,
  getCustomerBillableItemsPage,
  getCustomerBillableItemsRowsPerPage,
  getUserPermissionsList,
  getInsuranceCodesByUser,
  getUpdateBillableTimeInsuranceLoding,
  getUpdateBillableTimeInsuranceSuccess,
  getUpdateBillableTimeInsuranceError,
  getBillableTimeSubmitting,
  getUserInsurance,
} from "../../selectors";
import Auth from "@aws-amplify/auth";
import { TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const mapStateToProps = (state) => ({
  billableItems: getCustomerBillableItems(state),
  billableItemsLoading: getCustomerBillableItemsLoading(state),
  page: getCustomerBillableItemsPage(state),
  rowsPerPage: getCustomerBillableItemsRowsPerPage(state),
  count: getCustomerBillableItemsCount(state),
  userPermissions: getUserPermissionsList(state),
  insuranceCodes: getInsuranceCodesByUser(state),
  updateBillableTimeInsuranceCodeLoading: getUpdateBillableTimeInsuranceLoding(state),
  updateBillableTimeInsuranceCodeSuccess: getUpdateBillableTimeInsuranceSuccess(state),
  updateBillableTimeInsuranceCodeError: getUpdateBillableTimeInsuranceError(state),
  billableTimeSubmitting: getBillableTimeSubmitting(state),
  userInsurance: getUserInsurance(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCustomerBillableItems: actions.getCustomerBillableItems,
      setPage: actions.setCustomerBillableItemsPage,
      setRowsPerPage: actions.setCustomerBillableItemsRowsPerPage,
      getInsuranceCodesByUser: actions.getInsuranceCodesByUser,
      updateInsuranceCode: actions.updateBillableTimeInsuranceCode,
      clearUpdateBillableTimeInsuranceCodeInfo: actions.clearUpdateBillableTimeInsuranceCodeInfo,
      submitBillableItemToHealthie: actions.submitBillableItemToHealthie,
    },
    dispatch,
  );

class BillableItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editBillingTypeDialogOpen: false,
      selectedBillableItem: null,
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getCustomerBillableItems(this.props.match.params.id);
      this.props.getInsuranceCodesByUser({
        clientId: this.props.match.params.id,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page || prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.props.getCustomerBillableItems(this.props.match.params.id);
    }
    if (
      prevState.editBillingTypeDialogOpen &&
      !this.state.editBillingTypeDialogOpen &&
      this.props.updateBillableTimeInsuranceCodeSuccess
    ) {
      this.props.getCustomerBillableItems(this.props.match.params.id);
      this.props.clearUpdateBillableTimeInsuranceCodeInfo();
    }
  }

  componentWillUnmount() {
    this.props.setRowsPerPage(25);
    this.props.setPage(0);
  }

  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setRowsPerPage(parseInt(event.target.value));
    this.props.setPage(0);
  };

  viewVideoCallDetails = (id) => {
    let videoCall = "/video-calls/" + id;
    this.props.history.push(videoCall);
  };

  downloadSessionNote = async (billableTimeId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/session-note/${billableTimeId}`;
  };

  viewSessionNoteDetails = (id) => {
    let sessionNote = "/session-notes/" + id;
    this.props.history.push(sessionNote);
  };

  onOpenEditBillingTypeDialog = (item) => {
    this.setState({
      editBillingTypeDialogOpen: true,
      selectedBillableItem: item,
    });
  };
  onCloseEditBillingTypeDialog = () => {
    this.setState({
      editBillingTypeDialogOpen: false,
      selectedBillableItem: "",
    });
  };

  updateBillingType = (billingDetails) => {
    this.props.updateInsuranceCode(billingDetails);
  };

  mapStatusToBillingDisplay = (billableItem) => {
    const { userInsurance, submitBillableItemToHealthie, billableTimeSubmitting } = this.props;
    const superBill = userInsurance?.some((insurance) => insurance.insurance_name == "Private Pay");
    if (billableItem.item_code == "00000" || billableItem.billable_units == 0) {
      return (
        <Typography component="p" style={{ textAlign: "center", fontSize: 15 }}>
          Non-Billable
        </Typography>
      );
    } else if (billableItem.billing_submitted) {
      return (
        <Typography
          component="p"
          style={{
            textAlign: "center",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          Submitted
        </Typography>
      );
    } else {
      return (
        <Button
          variant="text"
          onClick={() =>
            submitBillableItemToHealthie({
              timeId: billableItem.id,
              userId: billableItem.user_id,
              clientId: this.props.match.params.id,
              superBill,
            })
          }
          style={{ fontWeight: 500 }}
          disabled={!billableItem.note_id || !billableItem.approved || billableTimeSubmitting}
        >
          Submit
        </Button>
      );
    }
  };

  render() {
    const {
      billableItems,
      billableItemsLoading,
      page,
      rowsPerPage,
      count,
      userPermissions,
      classes,
      insuranceCodes,
      updateBillableTimeInsuranceCodeLoading,
      updateBillableTimeInsuranceCodeSuccess,
      updateBillableTimeInsuranceCodeError,
      submitBillableItemToHealthie,
      billableTimeSubmitting,
    } = this.props;
    const rows = billableItems;
    return (
      <PageContainer loading={billableItemsLoading}>
        <SectionContainer noPadding columnDirection>
          <div className={classes.header}>
            <Button
              onClick={() => this.props.history.goBack()}
              variant="text"
              startIcon={<ArrowBackOutlinedIcon style={{ fill: "#000" }} />}
              style={{ color: "#000" }}
            >
              Go Back
            </Button>
            <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
              Billable Items
            </Typography>
            <div style={{ width: 100 }} />
          </div>
          <CustomTable
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            noResultsMessage="No Billable Items Found"
            headerCells={[
              <CustomHeaderCell label="Client Name" />,
              <CustomHeaderCell label="Billing Type" />,
              userPermissions?.edit_cpt_code && (
                <CustomHeaderCell label="Update Billing Type" align="center" />
              ),
              <CustomHeaderCell label="Start Date" />,
              <CustomHeaderCell label="End Date" />,
              <CustomHeaderCell label="View Note" align="center" />,
              userPermissions?.download_session_notes && (
                <CustomHeaderCell label="Download" align="center" />
              ),
              <TableCell align="center">Submit to Billing</TableCell>,
            ]}
          >
            {rows.map((row, i) => (
              <TableRow key={row.id} style={i % 2 === 0 ? { backgroundColor: "#ECFAFA" } : null}>
                <TableCell align="left">{`${row.child_first_name} ${row.child_last_name}`}</TableCell>
                <TableCell align="left">{`${row.item_type}${
                  row.video_call_id ? "" : " - Off Platform"
                }`}</TableCell>
                <TableCell align="left">
                  {row.start_date
                    ? format(new Date(row.start_date), convertTokens("MMM D, YYYY h:mm a"), {
                        awareOfUnicodeTokens: true,
                      })
                    : ""}
                </TableCell>
                <TableCell align="left">
                  {row.end_date
                    ? format(new Date(row.end_date), convertTokens("MMM D, YYYY h:mm a"), {
                        awareOfUnicodeTokens: true,
                      })
                    : ""}
                </TableCell>
                <TableCell align="center">
                  {row.id && row.note_id ? (
                    <Button
                      onClick={() => this.viewSessionNoteDetails(row.id)}
                      variant="text"
                      style={{ fontWeight: 500 }}
                      disabled={!userPermissions?.view_video_call_details}
                    >
                      View
                    </Button>
                  ) : (
                    <span style={{ color: "red" }}>Missing Note</span>
                  )}
                </TableCell>
                {userPermissions?.download_session_notes && (
                  <TableCell align="center">
                    <Button
                      variant="text"
                      onClick={() => this.downloadSessionNote(row.id)}
                      style={{ fontWeight: 500 }}
                      disabled={!row.note_id}
                    >
                      Download
                    </Button>
                  </TableCell>
                )}
                {userPermissions?.create_cms1500_from_billable_time && (
                  <TableCell align="center">{this.mapStatusToBillingDisplay(row)}</TableCell>
                )}
              </TableRow>
            ))}
          </CustomTable>
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillableItems));
