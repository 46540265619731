import {
  getHealthieOrgMembersOffset,
  getUsersPage,
  getUsersPerPage,
  getUsersSort,
  getUsersSortDirection,
} from "../selectors";
import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";

export function* getUserList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getUsersPage);
  const size = yield select(getUsersPerPage);
  const sort = yield select(getUsersSort);
  const direction = yield select(getUsersSortDirection);
  const params = {
    page,
    size,
    sort,
    direction,
  };

  return yield Request.get(`/users`, { params });
}

export function* createUser(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/users/admin", params);
}

export function* getHealthieOrgMembers() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const offset = yield select(getHealthieOrgMembersOffset);
  return yield Request.get(`/users/healthie/members?offset=${offset}`);
}

export function* inviteHealthieSupportUser(user) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/users/healthie/support-role", user);
}

export function* resendSupportUserHealthieInvite(user) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/users/healthie/${user.id}/resend-invite`, user);
}

export function* resetSupportUserHealthiePassword(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/users/healthie/${id}/reset-password`, {});
}

export function* getUserDeactivationReasons() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("users/deactivation/reasons");
}

export const searchUsers = async (userId, params) => {
  const url = `/v2/users/${userId}/search-users`;

  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(url, { params });
};
