import React from "react";
import { Box } from "@mui/material";
import PracticeArea from "./PracticeArea";
import { ScheduleFilterArea } from "./ScheduleFiltering";
import { useSelector } from "react-redux";
import { getUserPermissionsList } from "selectors";

const ScheduleUtilityDrawer = (props) => {
  const {
    searchClient,
    filteredList,
    getClientSessions,
    showWeekCanceledCalls,
    handleSwitchChange,
    toggleScheduleUtilityDrawer,
    getUserSchedule,
    userScheduleColorMap,
    setUserScheduleColorMap,
    userId,
    peopleFilters,
    setPeopleFilters,
    selectedServices,
    setSelectedServices,
    showScheduleUtilityDrawer,
    isClinician,
    showSupervisoryEvents,
    handleToggleShowSupervisoryEvents,
  } = props;

  const userPermissions = useSelector(getUserPermissionsList);

  return (
    showScheduleUtilityDrawer && (
      <Box width={"300px"} sx={{ overflowY: "auto", borderRight: "1px solid #CDD3DA" }}>
        <ScheduleFilterArea
          toggleScheduleUtilityDrawer={toggleScheduleUtilityDrawer}
          userPermissions={userPermissions}
          showNoShows={showWeekCanceledCalls}
          setShowNoShows={handleSwitchChange}
          getUserSchedule={getUserSchedule}
          searchResults={filteredList}
          searchPerson={searchClient}
          getClientSessions={getClientSessions}
          userScheduleColorMap={userScheduleColorMap}
          setUserScheduleColorMap={setUserScheduleColorMap}
          userId={userId}
          peopleFilters={peopleFilters}
          setPeopleFilters={setPeopleFilters}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          showSupervisoryEvents={showSupervisoryEvents}
          handleToggleShowSupervisoryEvents={handleToggleShowSupervisoryEvents}
        />
        {isClinician && <PracticeArea {...props} />}
      </Box>
    )
  );
};

export default ScheduleUtilityDrawer;
