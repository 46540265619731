import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import {
  getUserPermissionsList,
  getClinicianWorkflowStatus,
} from "../../selectors";
import styles from "./styles";
import AdminDashboard from "../AdminDashboard";
import Clinicians from "../Clinicians";
import ClinicianDashboard from "../ClinicianDashboard";
import ClientDashboard from "../ClientDashboard";
import PageContainer from "elements/PageContainer";

const mapStateToProps = (state) => ({
  userPermissions: getUserPermissionsList(state),
  clinicianWorkflowStatus: getClinicianWorkflowStatus(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: false,
    };
  }

  render() {
    const {
      userPermissions,
      classes,
      history,
      clinicianWorkflowStatus,
    } = this.props;
    let clinicianOnboarded = true;
    if (
      userPermissions?.view_clinician_dashboard && !clinicianWorkflowStatus
    ) {
      clinicianOnboarded = false;
    }
    return userPermissions ? (
      <>
        {userPermissions?.view_clinician_dashboard && (
          <ClinicianDashboard history={history} location={this.props.location} />
        )}
        {userPermissions?.view_admin_dashboard && clinicianOnboarded && (
          <AdminDashboard history={history} />
        )}
        {userPermissions?.view_clinician_view_only_dashboard && <Clinicians history={history} />}

        {userPermissions?.view_client_dashboard && <ClientDashboard history={history} />}
      </>
    ) : (
      <PageContainer loading={true} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardContainer));
