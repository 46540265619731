import React from "react";
import Grid from "@mui/material/Grid";
import Modal from "elements/Modal";
import TextField from "@mui/material/TextField";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";

const CreateAdminUserDialog = (props) => {
  const {
    classes,
    closeDialog,
    onSubmit,
    onChange,
    firstName,
    lastName,
    email,
    adminType,
    inviteToHealthie,
    open,
    createUserLoading,
    createUserSuccess,
    createUserError,
  } = props;

  const disabled = () => {
    return !firstName || !lastName || !email || createUserLoading || createUserSuccess;
  };

  return (
    <Modal
      open={open}
      onClose={closeDialog}
      title="Add an Admin"
      titleCentered
      content={
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name"
              className={classes.textField}
              value={firstName}
              onChange={onChange("firstName")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              className={classes.textField}
              value={lastName}
              onChange={onChange("lastName")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email (used as login)"
              className={classes.textField}
              value={email}
              onChange={onChange("email")}
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              id="adminType"
              label="Admin User Type"
              name="adminType"
              value={adminType}
              onChange={onChange("adminType")}
              fullWidth
              style={{ marginTop: 10 }}
            >
              <MenuItem disabled value={""} />
              <MenuItem value={"Admins"}>Admin</MenuItem>
              <MenuItem value={"Superadmins"}>Super Admin</MenuItem>
              <MenuItem value={"CustomerCare"}>Care Manager</MenuItem>
              <MenuItem value={"ClinicalSupervisor"}>Clinical Supervisor</MenuItem>
              <MenuItem value={"ClinicalDirector"}>Clinical Director</MenuItem>
              <MenuItem value={"ClinicianViewOnly"}>Clinician View Only</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={inviteToHealthie}
                  onClick={onChange("inviteToHealthie")}
                />
              }
              label={"Invite User to Healthie"}
            />
          </Grid>
        </Grid>
      }
      loading={createUserLoading}
      primaryActionText="Submit"
      primaryActionOnClick={onSubmit}
      primaryActionDisabled={disabled()}
      secondaryActionText="Cancel"
      secondaryActionOnClick={closeDialog}
      success={createUserSuccess}
      successMessage="User has been invited successfully"
      error={createUserError}
      errorMessage={"An error has occurred creating the user. Please try again."}
    />
  );
};

export default CreateAdminUserDialog;
