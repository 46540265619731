import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";
import * as AWS from "aws-sdk";
import CircularProgress from "@mui/material/CircularProgress";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import SubHeading from "elements/SubHeading";
import {
  getSignedinState,
  getParentInfo,
  getClientId,
  isLoaded,
  getLogin,
  completedQuestionnaire,
  joinedCommunities,
  isSubscribed,
  isLegacyUser,
  workflowStatus,
  rescheduleVideoDetails,
  getUserId,
  getUserPermissionsList,
  getParentPendingSignatures,
  scheduleCallSuccess,
  getForm,
  getAgreeToPolicyLoading,
  getSendingDocumentRequestSignatureLoading,
  getSendingDocumentRequestSignatureSuccess,
  getSendingDocumentRequestSignatureError,
  getFormSaving,
  getFormSuccess,
  getCustomerVideoCallsLoading,
  getUserCognitoId,
  getCustomerCalendarCalls,
  getCustomerCalendarCallsLoading,
  getCustomerVideoCalls,
  insuranceRoadmap,
  getCustomerDashboardNotifications,
  getCustomerDashboardNotificationsLoading,
  getDocumentMatrix,
  getDocumentMatrixLoading,
  getUnreadMessageCount,
  phiReleaseReadAndAccepted,
  treatmentPlan,
  getEligibilityFields,
  userClinician,
} from "selectors";
import Dashboard from "./Dashboard";
import ForceClientSignatures from "../ForceClientSignatures";
import DocumentRequest from "../DocumentRequest";
import { format, isSameDay, isSameMonth, isToday } from "date-fns";
import FullRoadmap from "./FullRoadmap";
import { DropzoneDialog } from "mui-file-dropzone";
import TreatmentPlan from "./TreatmentPlan";
import PhiReleaseForm from "./forms/PhiReleaseForm";
import WelcomeMessage from "./WelcomeMessage";
import { v4 as uuidv4 } from "uuid";
import { convertTokens } from "@date-fns/upgrade/v2";
import FormsComponent from "./Form";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  login: getLogin(state),
  loaded: isLoaded(state),
  user: getParentInfo(state),
  clientId: getClientId(state),
  completedQuestionnaire: completedQuestionnaire(state),
  joinedCommunities: joinedCommunities(state),
  subscribed: isSubscribed(state),
  legacy: isLegacyUser(state),
  workflowStatus: workflowStatus(state),
  rescheduleDetails: rescheduleVideoDetails(state),
  userId: getUserId(state),
  userPermissions: getUserPermissionsList(state),
  parentPendingSignatures: getParentPendingSignatures(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  form: getForm(state),
  formSaving: getFormSaving(state),
  formSuccess: getFormSuccess(state),
  agreeToPolicyLoading: getAgreeToPolicyLoading(state),
  sendingDocumentRequestSignatureLoading: getSendingDocumentRequestSignatureLoading(state),
  sendingDocumentRequestSignatureSuccess: getSendingDocumentRequestSignatureSuccess(state),
  sendingDocumentRequestSignatureError: getSendingDocumentRequestSignatureError(state),
  videoCallsLoading: getCustomerVideoCallsLoading(state),
  videoCalls: getCustomerVideoCalls(state),
  userCognitoId: getUserCognitoId(state),
  calendarCalls: getCustomerCalendarCalls(state),
  calendarCallsLoading: getCustomerCalendarCallsLoading(state),
  insuranceRoadmap: insuranceRoadmap(state),
  notifications: getCustomerDashboardNotifications(state),
  notificationsLoading: getCustomerDashboardNotificationsLoading(state),
  documentMatrix: getDocumentMatrix(state),
  documentMatrixLoading: getDocumentMatrixLoading(state),
  unreadMessageCount: getUnreadMessageCount(state),
  phiReleaseReadAndAccepted: phiReleaseReadAndAccepted(state),
  treatmentPlan: treatmentPlan(state),
  eligibilityFields: getEligibilityFields(state),
  userClinician: userClinician(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSigninDetails: actions.setSigninDetails,
      startSignin: actions.startSignin,
      checkAuthState: actions.checkAuthState,
      getUser: actions.getParentInfo,
      signOut: actions.signOut,
      getDashboard: actions.getDashboard,
      clearDashboard: actions.clearDashboard,
      getInsuranceDashboard: actions.getInsuranceDashboard,
      getUpcomingCallInfo: actions.getCustomerUpcomingCalls,
      setVideoDetails: actions.getVideoCallInfoSuccess,
      getClinician: actions.getUserClinician,
      setPageDetails: actions.setPageDetails,
      getCustomerSignedForm: actions.getCustomerSignedForm,
      saveCustomerSignedForm: actions.saveSignedCustomerForm,
      getCustomerCallsByMonth: actions.getCustomerCallsByMonth,
      getInsuranceRoadmap: actions.getInsuranceRoadmap,
      getNotifications: actions.getCustomerDashboardNotifications,
      markNotificationAsRead: actions.markCustomerNotificationAsRead,
      getDocumentMatrix: actions.getDocumentMatrix,
      uploadCustomerDocument: actions.uploadCustomerDocument,
      getTreatmentPlan: actions.getActiveTreatmentPlan,
      approveTreatmentPlan: actions.approveTreatmentPlan,
      requestTreatmentPlanReview: actions.requestTreatmentPlanReview,
      agreeToPhiRelease: actions.agreeToPhiRelease,
      sendDocumentRequestSignature: actions.sendDocumentRequestSignature,
      getEligibilityFields: actions.getEligibilityFields,
      requestVideoCallReschedule: actions.requestVideoCallReschedule,
    },
    dispatch
  );

function getInitialState(window) {
  return {
    columns: window.innerWidth > 480 ? 3.5 : 2.25,
    open: false,
    goals: [],
    rescheduleCall: false,
    assessmentCall: false,
    allowedAssessment: false,
    consentFormOpen: false,
    consentFormHasOpened: false,
    saveAgreeToTermsSignatureSuccess: false,
    saveAgreeToTermsSignatureError: false,
    signatureStarted: false,
    saveFormError: false,
    uploading: false,
    calendarDisplayDate: new Date(),
    selectedDayCalls: [],
    fullRoadmapOpen: false,
    displayUnreadMessageNotification: false,
    uploadDoc: null,
    uploadDocType: "",
    uploadDropzoneOpen: false,
    uploadError: false,
    treatmentPlanOpen: false,
    treatmentPlanUrl: null,
    confirmTreatmentPlanOpen: false,
    clientName: "",
    clientLastName: "",
    clientDOB: "",
    clientAddress1: "",
    clientAddress2: "",
    clientCity: "",
    clientState: "",
    clientZip: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    phiFormDialogOpen: false,
    savePhiReleaseSignatureSuccess: false,
    savePhiReleaseSignatureError: false,
    welcomeDialogOpen: false,
    eCommConsentFormOpen: false,
    showForms: true,
    consentFormsCompleted: false,
    phiReleaseSaving: false,
    insuranceCardUploadSkipped: false,
    eCommConsentSubmitting: false,
    telehealthConsentFormId: null,
  };
}

class ClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { ...getInitialState(window) };
    this.agreeToTermsSignatureRef = null;
    this.phiReleaseFormSignatureRef = null;
    this.consentToECommSignatureRef = null;
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Home",
      currentPage: "dashboard",
      menu: "caregiverHome",
    });

    this.props.checkAuthState();
    if (this.props.login) {
      this.props.getUser();
    }

    if (this.props.loaded && this.props.userId && this.props.clientId) {
      this.props.getClinician(this.props.clientId);
      this.props.getUpcomingCallInfo(this.props.clientId);
      this.props.getNotifications();
      this.mapStatusToDocumentMatrix();
    }

    if (this.props.workflowStatus) {
      if (this.props.workflowStatus !== "FULL_CUSTOMER") {
        this.props.getEligibilityFields();
      } else {
        this.setState({ showForms: true });
      }
    }

    if (this.props.clientId) {
      this.props.getCustomerCallsByMonth({
        clientId: this.props.clientId,
        month: format(new Date(), convertTokens("YYYY-MM-DD")),
      });
    }

    if (this.props.unreadMessageCount > 0) {
      this.setState({ displayUnreadMessageNotification: true });
    }

    if (this.props.workflowStatus && this.props.workflowStatus === "APPROVE_PLAN") {
      this.props.getTreatmentPlan();
    }

    const { eligibilityFields } = this.props;
    let clientLocationInfo = eligibilityFields?.location;
    this.setState({
      firstName: eligibilityFields?.parent_name || "",
      lastName: eligibilityFields?.parent_last_name || "",
      email: eligibilityFields?.email || "",
      phone: eligibilityFields?.phone || "",
      clientAddress1: clientLocationInfo?.line1 || "",
      clientAddress2: clientLocationInfo?.line2 || "",
      clientCity: clientLocationInfo?.city || "",
      clientState: clientLocationInfo?.state || "",
      clientZip: clientLocationInfo?.zip || "",
      clientName: eligibilityFields?.name || "",
      clientLastName: eligibilityFields?.child_last_name || "",
      clientDOB: eligibilityFields?.dob ? dateWithoutTimezone(eligibilityFields?.dob) : "",
    });

    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.login && this.props.login) {
      this.props.getUser();
    }

    if (!prevProps.loaded && this.props.loaded) {
      if (this.props.clientId) {
        this.props.getCustomerCallsByMonth({
          clientId: this.props.clientId,
          month: format(new Date(), convertTokens("YYYY-MM-DD")),
        });
        this.props.getClinician(this.props.clientId);
        this.props.getUpcomingCallInfo(this.props.clientId);
      }
      this.props.getNotifications();
      this.mapStatusToDocumentMatrix();

      if (this.props.workflowStatus !== "FULL_CUSTOMER") {
        this.props.getEligibilityFields();
      } else {
        this.setState({ showForms: true });
      }
    }

    if (
      prevProps.workflowStatus !== "APPROVE_PLAN" &&
      this.props.workflowStatus === "APPROVE_PLAN"
    ) {
      this.props.getTreatmentPlan();
    }

    if (
      prevProps.calendarCallsLoading &&
      !this.props.calendarCallsLoading &&
      isToday(new Date(this.state.calendarDisplayDate))
    ) {
      const { calendarCalls } = this.props;
      let selectedDayCalls = [...calendarCalls].filter((call) =>
        isSameDay(new Date(call.scheduled_date), new Date())
      );
      this.setState({ selectedDayCalls });
    }

    if (prevProps.unreadMessageCount !== this.props.unreadMessageCount) {
      if (this.props.unreadMessageCount > 0) {
        this.setState({ displayUnreadMessageNotification: true });
      } else {
        this.setState({ displayUnreadMessageNotification: false });
      }
    }

    if (
      !prevState.savePhiReleaseSignatureSuccess &&
      this.state.savePhiReleaseSignatureSuccess &&
      !this.state.phiFormDialogOpen
    ) {
      this.setState({ showForms: true });
    }

    if (
      !prevProps.eligibilityFields &&
      this.props.eligibilityFields &&
      this.props.user.primary_account
    ) {
      const { eligibilityFields } = this.props;
      let clientLocationInfo = eligibilityFields?.location;

      this.setState({
        firstName: eligibilityFields?.parent_name,
        lastName: eligibilityFields?.parent_last_name,
        email: eligibilityFields?.email,
        phone: eligibilityFields?.phone,
        clientAddress1: clientLocationInfo?.line1,
        clientAddress2: clientLocationInfo?.line2,
        clientCity: clientLocationInfo?.city,
        clientState: clientLocationInfo?.state,
        clientZip: clientLocationInfo?.zip,
        clientName: eligibilityFields?.name,
        clientLastName: eligibilityFields?.child_last_name,
        clientDOB: eligibilityFields?.dob ? dateWithoutTimezone(eligibilityFields?.dob) : "",
      });
    }
  }

  componentWillUnmount() {
    this.props.clearDashboard();
    window.removeEventListener("resize", this.updateDimensions);
  }

  dismissUnreadMessageNotification = () => {
    this.setState({ displayUnreadMessageNotification: false });
  };

  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({
      columns: window.innerWidth > 480 ? 3.5 : 2.25,
    });
  };

  goToSignin() {
    this.props.history.push("/");
  }

  getPartofDayLabel = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      return "morning";
    } else if (hour >= 12 && hour < 17) {
      return "afternoon";
    } else {
      return "evening";
    }
  };

  onJoinVideoCall = (call) => {
    if (call?.off_platform) {
      window.location.replace(`${process.env.DOXY_URL}`);
    } else {
      this.props.setVideoDetails(call);
      this.props.history.push(`/video/${call.video_key}`);
    }
  };

  closeSchedulingDialog = () => {
    this.setState({
      ...getInitialState(window),
    });
  };

  startSignature = () => {
    this.setState({ signatureStarted: true });
  };

  sendAgreeToTermsSignatures = async () => {
    this.setState({ eCommConsentSubmitting: true });
    const signatureImageData = this.agreeToTermsSignatureRef
      .getTrimmedCanvas()
      .toDataURL("image/png");
    const credentials = await getAWSCredentialsForCurrentUserSession();
    let s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const buf = new Buffer(signatureImageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${this.props.userCognitoId}/agree_to_terms_signature.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      const formId = this.state.telehealthConsentFormId || this.props.form.formId;
      this.props.saveCustomerSignedForm({
        formId: formId,
        userId: this.props.userId,
        clientId: this.props.clientId,
        signatureUrl: s3url.Location,
      });
      this.setState({ signatureStarted: false });
    } catch (error) {
      console.log(error);
    }
  };

  setUploading = (uploading) => {
    this.setState({ uploading });
  };

  onSelectCalendarDay = (day) => {
    const { calendarCalls } = this.props;
    const { calendarDisplayDate } = this.state;
    let selectedDayCalls = calendarCalls.filter((call) =>
      isSameDay(new Date(call.scheduled_date), new Date(calendarDisplayDate))
    );
    this.setState({ calendarDisplayDate: new Date(day), selectedDayCalls });
  };

  onCalendarNavigate = (date) => {
    const { calendarCalls } = this.props;
    const { calendarDisplayDate } = this.state;
    let selectedDayCalls = [...calendarCalls].filter((call) =>
      isSameDay(new Date(call.end_date ? call.end_date : call.scheduled_date), new Date(date))
    );
    this.setState({ calendarDisplayDate: new Date(date), selectedDayCalls });
    if (!isSameMonth(new Date(date), new Date(calendarDisplayDate))) {
      this.props.getCustomerCallsByMonth({
        clientId: this.props.clientId,
        month: format(new Date(date), convertTokens("YYYY-MM-DD")),
      });
    }
  };

  onToggleRoadmapDialog = () => {
    this.setState({ fullRoadmapOpen: !this.state.fullRoadmapOpen });
  };

  mapStatusToDocumentMatrix = () => {
    const { clientId, workflowStatus } = this.props;
    let userStatus;
    if (
      workflowStatus === "ASSESSMENT_COMPLETED" ||
      workflowStatus === "APPROVE_PLAN" ||
      workflowStatus === "AWAITING_INSURANCE_APPROVAL" ||
      workflowStatus === "INSURANCE_APPROVED" ||
      workflowStatus === "FULL_CUSTOMER"
    ) {
      userStatus = "TREATMENT";
    } else {
      userStatus = "ASSESSMENT";
    }
    if (userStatus) {
      this.props.getDocumentMatrix({ clientId, userStatus });
    }
  };

  uploadUserDocument = (document) => {
    this.setState({ uploadDoc: document[0], uploadDropzoneOpen: false }, () => {
      this.submitDocumentUpload();
    });
  };

  onToggleUploadDropzone = () => {
    this.setState({
      uploadDoc: null,
      uploadDropzoneOpen: false,
      uploadError: false,
    });
  };

  onOpenUploadUserDocument = (docType) => {
    this.setState({
      uploadDocType: docType,
      uploadDropzoneOpen: true,
      uploadError: false,
    });
  };

  submitDocumentUpload = async () => {
    const { uploadDoc, uploadDocType } = this.state;
    const { userId, userCognitoId, clientId } = this.props;

    this.setUploading(true);
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const fileUUID = uuidv4();
      const [fileName, fileExtension] = uploadDoc.name.split(".");
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentType: uploadDoc.type,
        Key: `${userCognitoId}/${fileName}-${fileUUID}.${fileExtension}`,
      };
      const upload = await s3.upload(params).promise();
      const document = {
        filename: upload.Key.split("/")[1],
        display_filename: uploadDoc.name,
        clientId: clientId,
        type: uploadDocType,
        internal: false,
        billing_related: false,
        file_size: uploadDoc.size,
        uploaded_by: userId,
      };
      this.props.uploadCustomerDocument(document);
      if (uploadDocType === "DIAGNOSIS_FORM") {
        const notification = this.props.notifications?.find(
          (notification) => notification.type === "UPLOAD_DIAGNOSIS_FORM"
        );
        if (notification) {
          this.props.markNotificationAsRead(notification.id);
        }
      }

      this.setState({
        uploadDoc: null,
        uploadDocType: "",
      });
      this.mapStatusToDocumentMatrix();
      this.setUploading(false);
    } catch (e) {
      console.error(e);
      this.setState({ uploadError: true });
      this.setUploading(false);
    }
  };

  onSkipInsuranceCardUpload = () => {
    this.setState({ insuranceCardUploadSkipped: true });
  };

  onToggleTreatmentPlan = async () => {
    const { treatmentPlan } = this.props;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({ credentials });
    const params = {
      Bucket: process.env.AWS_USER_DOC_BUCKET,
      Key: `${treatmentPlan.cognito_id}/${treatmentPlan.filename}`,
    };
    var url = await s3.getSignedUrl("getObject", params);
    this.setState({
      treatmentPlanUrl: url,
      treatmentPlanOpen: !this.state.treatmentPlanOpen,
    });
  };

  onSubmitTreatmentPlanApproval = () => {
    this.props.approveTreatmentPlan(this.props.treatmentPlan.id);
    this.setState({
      treatmentPlanOpen: false,
      confirmTreatmentPlanOpen: false,
    });
  };

  onToggleConfirmTreatmentPlanApproval = () => {
    this.setState({
      confirmTreatmentPlanOpen: !this.state.confirmTreatmentPlanOpen,
    });
  };

  messageClinicianAboutTreatmentPlan = () => {
    this.props.requestTreatmentPlanReview();
    this.props.history.push(`/chat/${this.props.user?.active_conversation_url || ""}`);
  };

  messageClinicianRequestingReschedule = (id) => {
    this.props.requestVideoCallReschedule(id);
    this.props.history.push(`/chat/${this.props.user?.active_conversation_url || ""}`);
  };

  savePhiReleaseSignature = async () => {
    this.setState({ phiReleaseSaving: true });
    const signatureImageData = this.phiReleaseFormSignatureRef
      .getTrimmedCanvas()
      .toDataURL("image/png");
    const credentials = await getAWSCredentialsForCurrentUserSession();
    let s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const buf = new Buffer(signatureImageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${this.props.userCognitoId}/document_request_signature.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      await this.props.sendDocumentRequestSignature({
        signatureURL: s3url.Location,
        requestType: "INITIAL_DIAGNOSIS",
        clientId: this.props.clientId,
      });
      await this.props.agreeToPhiRelease();
      this.setState({
        phiFormDialogOpen: false,
        savePhiReleaseSignatureSuccess: true,
        phiReleaseSaving: false,
        signatureStarted: false,
      });
    } catch (error) {
      this.setState({
        savePhiReleaseSignatureError: true,
        phiReleaseSaving: false,
      });
      console.log(error);
    }
  };

  formatPhoneNumber = (val) => {
    let formatedPhone = val.replace(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
      "$1-$2-$3"
    );
    return formatedPhone;
  };

  closeWelcomeDialog = () => {
    this.setState({ welcomeDialogOpen: false });
  };

  sendConsentToECommSignatures = async () => {
    this.setState({
      eCommConsentSubmitting: true,
    });
    const signatureImageData = this.consentToECommSignatureRef
      .getTrimmedCanvas()
      .toDataURL("image/png");
    const credentials = await getAWSCredentialsForCurrentUserSession();
    let s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const buf = new Buffer(signatureImageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${this.props.userCognitoId}/consent_to_electronic_communications_signature.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      this.props.saveCustomerSignedForm({
        formId: this.props.form.formId,
        userId: this.props.userId,
        clientId: this.props.clientId,
        signatureUrl: s3url.Location,
      });
      this.setState({ signatureStarted: false });
    } catch (error) {
      console.log(error);
      this.setState({
        eCommConsentSubmitting: false,
      });
    }
  };

  onSkipConsentToEComm = () => {
    this.props.saveCustomerSignedForm({
      formId: this.props.form.formId,
      userId: this.props.userId,
      clientId: this.props.clientId,
      skip: true,
    });
    this.setState({
      signatureStarted: false,
      eCommConsentFormOpen: false,
      eCommConsentSubmitting: false,
      consentFormsCompleted: true,
      eCommConsentSkipped: true,
    });
  };

  onOpenPHIRelease = () => {
    this.setState({ phiFormDialogOpen: true, consentFormHasOpened: true });
  };

  onShowForms = () => {
    this.setState({ showForms: true });
  };

  renderDropzoneDialogTitle = () => {
    const { uploadDocType } = this.state;
    let dialogTitle = "Upload file";
    if (uploadDocType === "INSURANCE_CARD_FRONT") {
      dialogTitle = "Upload FRONT of Insurance Card";
    } else if (uploadDocType === "INSURANCE_CARD_BACK") {
      dialogTitle = "Upload BACK of Insurance Card";
    }
    return dialogTitle;
  };

  markAllFormsCompleted = () => {
    this.setState({ showForms: false });
  };

  render() {
    const {
      loaded,
      classes,
      parentPendingSignatures,
      videoCallsLoading,
      insuranceRoadmap,
      notificationsLoading,
      ...otherProps
    } = this.props;
    const { consentFormOpen, uploading, phiFormDialogOpen, welcomeDialogOpen, showForms } =
      this.state;
    const { steps, currentStepIndex } = insuranceRoadmap;
    const loading = !loaded || videoCallsLoading || uploading || notificationsLoading;
    const displayForms = !loading && showForms;
    return (
      <>
        <div id="ClientCalendar">
          <div className={classes.dashboardContainer}>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
                <SubHeading>Loading</SubHeading>
              </div>
            ) : (
              <>
                <Dashboard
                  {...this.props}
                  {...this.state}
                  navigate={this.props.history.push}
                  onJoinVideoCall={this.onJoinVideoCall}
                  getPartofDayLabel={this.getPartofDayLabel}
                  onSelectCalendarDay={this.onSelectCalendarDay}
                  onCalendarNavigate={this.onCalendarNavigate}
                  onboarding={this.props.workflowStatus !== "FULL_CUSTOMER"}
                  onToggleRoadmapDialog={this.onToggleRoadmapDialog}
                  dismissUnreadMessageNotification={this.dismissUnreadMessageNotification}
                  onOpenUploadUserDocument={this.onOpenUploadUserDocument}
                  onToggleTreatmentPlan={this.onToggleTreatmentPlan}
                  onToggleConfirmTreatmentPlanApproval={this.onToggleConfirmTreatmentPlanApproval}
                  messageClinicianRequestingReschedule={this.messageClinicianRequestingReschedule}
                />

                {parentPendingSignatures &&
                  parentPendingSignatures.length > 0 &&
                  this.props.userPermissions?.sign_after_video_call && <ForceClientSignatures />}
                {this.props.eligibilityFields && <DocumentRequest />}

                {displayForms && (
                  <FormsComponent
                    clientId={this.props.clientId}
                    markAllFormsCompleted={this.markAllFormsCompleted}
                  />
                )}
                <TreatmentPlan
                  open={this.state.treatmentPlanOpen}
                  confirmTreatmentPlanOpen={this.state.confirmTreatmentPlanOpen}
                  onToggleTreatmentPlan={this.onToggleTreatmentPlan}
                  approveTreatmentPlan={this.props.approveTreatmentPlan}
                  treatmentPlanUrl={this.state.treatmentPlanUrl}
                  messageClinicianAboutTreatmentPlan={this.messageClinicianAboutTreatmentPlan}
                  onToggleConfirmTreatmentPlanApproval={this.onToggleConfirmTreatmentPlanApproval}
                  onSubmitTreatmentPlanApproval={this.onSubmitTreatmentPlanApproval}
                  {...this.props}
                />
                {steps[currentStepIndex] && (
                  <FullRoadmap
                    steps={steps}
                    currentStepIndex={currentStepIndex}
                    onToggleRoadmapDialog={this.onToggleRoadmapDialog}
                    open={this.state.fullRoadmapOpen}
                    classes={classes}
                  />
                )}
              </>
            )}
            <DropzoneDialog
              open={this.state.uploadDropzoneOpen}
              filesLimit={1}
              onSave={this.uploadUserDocument}
              acceptedFiles={[
                "image/*",
                ".pdf",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/rtf",
                "application/zip",
                "text/plain",
              ]}
              showPreviews={false}
              showPreviewsInDropzone={true}
              maxFileSize={15728640}
              onClose={this.onToggleUploadDropzone}
              dropzoneText={"Drag and drop or click here"}
              submitButtonText="Upload"
              dialogTitle={this.renderDropzoneDialogTitle()}
              dialogProps={{
                style: { zIndex: 40003 },
              }}
            />
            {this.props.phiReleaseReadAndAccepted && (
              <WelcomeMessage
                classes={classes}
                closeDialog={this.closeWelcomeDialog}
                welcomeDialogOpen={welcomeDialogOpen}
              />
            )}
            {phiFormDialogOpen && !consentFormOpen && (
              <PhiReleaseForm
                {...otherProps}
                {...this.state}
                savePhiReleaseSignature={this.savePhiReleaseSignature}
                startSignature={this.startSignature}
                phiReleaseFormSignatureRef={(ref) => (this.phiReleaseFormSignatureRef = ref)}
                physicians={this.props.eligibilityFields?.referring_physicians}
                formatPhoneNumber={this.formatPhoneNumber}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientDashboard));
