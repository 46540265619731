import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const SaveChangesDialog = (props) => {
  const { classes, open, saveChanges, closeDialog } = props;
  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "20%",
        },
      }}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography component="h2">Session</Typography>
        <Typography component="p">Do you want to save the changes you made?</Typography>
      </DialogTitle>

      <DialogActions className={classes.dialogButtons}>
        <Button variant="text" onClick={saveChanges}>
          Save
        </Button>
        <Button variant="text" color="default" onClick={closeDialog}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  dialogTitle: {
    marginBottom: 5,
    backgroundColor: "#3F4456",
    display: "flex",
    flexDirection: "column",
    color: "#f0f1f4",
    fontWeight: "400",
    "& h2": {
      fontSize: 15,
      lineHeight: "20px",
    },
    "& p": {
      fontSize: 13,
      lineHeight: "18px",
    },
  },
  radioButtonLabel: {
    fontSize: 13,
    color: "#3f4456",
    fontWeight: 500,
  },
  dialogButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    boxSizing: "border-box",
    margin: "10px 0px",
    "& button": {
      fontSize: 12,
    },
  },
});

export default withStyles(styles)(SaveChangesDialog);
