export default (theme) => ({
  pageContainer: {
    width: "100%",
    backgroundColor: "#fafafa",
    minHeight: "calc(100vh - 64px)",
    paddingTop: 25,
  },
  loadingContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "95%",
    },
  },
  pageTitle: {
    fontSize: 28,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  headerContainer: {
    width: "58%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      width: "60%",
      marginLeft: "7%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      flexDirection: "column",
      margin: "0 auto",
    },
  },
  dateContainer: {
    height: 50,
    width: 220,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
    borderRadius: 3,
    "& p": {
      color: "#545a72",
      fontSize: 15,
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      height: 45,
      width: 210,
      marginTop: 20,
    },
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 20,
    },
  },
  leftContainer: {
    width: "58%",
    [theme.breakpoints.down("xl")]: {
      width: "86%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "95%",
    },
  },
  rightContainer: {
    width: "37%",
    [theme.breakpoints.down("xl")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "86%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "95%",
    },
  },
  block: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
    borderRadius: 3,
    padding: "30px 50px",
    marginBottom: 30,
    [theme.breakpoints.down("xl")]: {
      padding: "15px 20px",
    },
    [theme.breakpoints.down("xl")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("xl")]: {
      padding: "20px",
    },
  },
  clinicianInfoHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 40,
    "& h4": {
      fontWeight: 500,
      letterSpacing: "-2%",
      lineHeight: "24px",
      marginBottom: 5,
    },
    "& p": {
      fontSize: 15,
      fontWeight: 500,
      color: "#2a2d39",
      lineHeight: "18px",
      letterSpacing: "5%",
    },
    "& .MuiButton-label": {
      letterSpacing: "-0.4px",
    },
  },
  gridRoot: {
    "& .MuiGrid-item": {
      "& p": {
        fontSize: 15,
        wordWrap: "break-word",
      },
      "& :first-child": {
        color: "#3f4456",
        fontWeight: 500,
      },
      "& :last-child": {
        color: "#15171d",
        fontWeight: 500,
      },
    },
    [theme.breakpoints.down("xl")]: {
      marginLeft: 0,
      "& .MuiGrid-item": {
        padding: 5,
      },
    },
  },
  documentsSectionTitle: {
    marginBottom: 25,
    fontWeight: 500,
  },
  document: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
    borderRadius: 3,
    padding: "10px 15px",
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button > span": {
      fontSize: 13,
    },
    [theme.breakpoints.down("xl")]: {
      padding: 10,
    },
  },
  documentButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  missingDocument: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  documentName: {
    fontSize: 15,
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
    display: "flex",
    alignItems: "center",
  },
  superBillsLoadingContainer: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
  },
  superBillTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  superBillDate: {
    fontSize: 17,
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    // marginLeft: 26,
  },
  superBillTherapyType: {
    fontSize: 16,
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
    display: "flex",
    alignItems: "center",
  },
  superBillAmountPaid: {
    fontSize: 14,
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    display: "flex",
    alignItems: "center",
  },
  passwordSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontWeight: 500,
      fontSize: 15,
      color: "#1517d",
    },
  },
  supportSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& h4": {
      fontWeight: 500,
      marginBottom: 10,
    },
    "& h5": {
      fontWeight: 500,
    },
    "& span": {
      color: "#531326",
    },
  },
  linkButton: {
    margin: "5px 10px",
    "& span": {
      color: "#8e42ca",
      size: 15,
    },
  },
  eCommConsentButtonAgree: {
    margin: "30px 0",
    minWidth: 80,
    "& span": {
      color: "#8e42ca",
      size: 16,
    },
  },
  eCommConsentButtonDisable: {
    margin: "30px 0",
    minWidth: 80,
    "& span": {
      color: "#DC2626",
      size: 16,
    },
  },
  editButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 20,
    margin: "20px 0px",
    "& button": {
      height: 40,
      borderRadius: 5,
    },
    "& button > span": {
      fontSize: 13,
    },
    [theme.breakpoints.down("xl")]: {
      justifyContent: "space-evenly",
    },
  },
  disabledEmail: {
    "& .MuiInputBase-input.Mui-disabled": {
      opacity: "0.5",
    },
  },
});
