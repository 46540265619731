import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import withStyles from "@mui/styles/withStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { format } from "date-fns";
import StarIcon from "@mui/icons-material/Star";

import { convertTokens } from "@date-fns/upgrade/v2";

const ClientPrimaryAccountSelection = (props) => {
  const {
    classes,
    open,
    closeDialog,
    availableAccounts,
    mapRelationshipToDisplay,
    onSelectPrimaryAccountHolder,
    primaryAccountHolderId,
    primaryAccountConfirmationOpen,
    onOpenPrimaryAccountConfirmation,
    onClosePrimaryAccountConfirmation,
    currentAccountHolder,
    newAccountInfo,
    importNewFamilyMember,
    importHealthieClientFamilyMemberLoading,
    importHealthieClientFamilyMemberSuccess,
    importHealthieClientFamilyMemberError,
    importHealthieClientFamilyMemberErrorMessage,
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      key="switch-clinician-dialog"
      PaperProps={{
        style: {
          backgroundColor: "#e5e5e5",
          borderRadius: 10,
          minHeight: 150,
          minWidth: "30%",
          maxWidth: "39%",
          padding: "15px",
        },
      }}
    >
      {primaryAccountConfirmationOpen ? (
        <>
          <div className={classes.dialogContainer}>
            <Typography component="h3" className={classes.title}>
              You're switching Primary Account Holders.
            </Typography>
            <div>
              <Typography component="p" className={classes.subtitle}>
                The previous accound holder won't have the same permissions that they used to and
                this account will be the one to receive emails and is the default account for
                scheduling purposes. Proceed with this switch?
              </Typography>
              <div className={classes.primaryAccountSelectionContainer}>
                <div className={classes.accountSelectionHeader}>
                  <Typography>Previous Primary</Typography>
                  <Typography>New Primary</Typography>
                </div>
                <div className={classes.selectionConfirmation}>
                  <div className={classes.nameContainer}>
                    <Typography>
                      {currentAccountHolder?.first_name} {currentAccountHolder?.last_name}
                    </Typography>
                    <div style={{ width: "16%" }}>
                      <div className={classes.arrow} />
                    </div>
                    <Typography>
                      {newAccountInfo?.first_name} {newAccountInfo?.last_name}
                    </Typography>
                  </div>
                  <div className={classes.relationshipContainer}>
                    <div>
                      <Typography component="h4">
                        {mapRelationshipToDisplay(currentAccountHolder.relationship)}
                      </Typography>
                      {currentAccountHolder.imported_date && (
                        <Typography className={classes.importedDateText}>
                          Imported:{" "}
                          {format(
                            new Date(currentAccountHolder.imported_date),
                            convertTokens("MM/DD/YYYY h:mm a"),
                          )}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Typography component="h4">
                        {mapRelationshipToDisplay(newAccountInfo.relationship)}
                      </Typography>
                      {newAccountInfo.imported_date && (
                        <Typography className={classes.importedDateText}>
                          Imported:{" "}
                          {format(
                            new Date(newAccountInfo.imported_date),
                            convertTokens("MM/DD/YYYY h:mm a"),
                          )}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  {importHealthieClientFamilyMemberError && (
                    <Typography className={classes.errorMessage}>
                      {importHealthieClientFamilyMemberErrorMessage ||
                        "An error has occurred. Please try again."}
                    </Typography>
                  )}
                  {importHealthieClientFamilyMemberSuccess && (
                    <Typography className={classes.successMessage}>
                      The new family member created successfully!
                    </Typography>
                  )}
                  {importHealthieClientFamilyMemberSuccess ||
                  importHealthieClientFamilyMemberError ? (
                    <Button variant="text" onClick={closeDialog}>
                      Close
                    </Button>
                  ) : (
                    <Button variant="text" onClick={onClosePrimaryAccountConfirmation}>
                      Go Back
                    </Button>
                  )}
                  <Button
                    onClick={importNewFamilyMember}
                    disabled={
                      importHealthieClientFamilyMemberLoading ||
                      importHealthieClientFamilyMemberSuccess ||
                      importHealthieClientFamilyMemberError
                    }
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.dialogContainer}>
            <Typography component="h3" className={classes.title}>
              Would you like to change the primary account holder?
            </Typography>
            <div>
              <Typography component="p" className={classes.subtitle}>
                All clients have a primary account holder. This account will be the one to receive
                emails and is the default account holder for scheduling purposes.
              </Typography>
              <Typography className={classes.warningText}>
                You must select a primary account holder in order to continue
              </Typography>

              <div className={classes.primaryAccountSelectionContainer}>
                <div className={classes.accountSelectionHeader}>
                  <Typography>Primary</Typography>
                  <Typography>Family Account</Typography>
                </div>
                <RadioGroup
                  value={primaryAccountHolderId}
                  onChange={(e) => onSelectPrimaryAccountHolder(e)}
                >
                  {availableAccounts.map((member, i) => {
                    return (
                      <React.Fragment key={member.id}>
                        <div className={classes.divider} />
                        <div key={member.id} className={classes.accountSelectionContainer}>
                          <FormControlLabel
                            classes={{
                              root: classes.accountSelectionFormControlLabelRoot,
                            }}
                            value={member.id}
                            control={
                              <Radio
                                color="default"
                                size="small"
                                classes={{
                                  root: classes.radioButton,
                                }}
                              />
                            }
                            label={
                              <>
                                <Typography className={classes.healthieClientName}>
                                  {member.first_name} {member.last_name}
                                </Typography>
                                <Typography className={classes.healthieCaregiverName}>
                                  {mapRelationshipToDisplay(member.relationship)}
                                </Typography>
                                {member.imported_date && (
                                  <Typography className={classes.importedDateText}>
                                    Imported:{" "}
                                    {format(
                                      new Date(member.imported_date),
                                      convertTokens("MM/DD/YYYY h:mm a"),
                                    )}
                                  </Typography>
                                )}
                                {member.primary_account && (
                                  <Typography className={classes.primaryAccountBadge}>
                                    <StarIcon style={{ fontSize: 14 }} />
                                    Primary currently
                                  </Typography>
                                )}
                              </>
                            }
                            labelPlacement="end"
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              {importHealthieClientFamilyMemberError && (
                <Typography className={classes.errorMessage}>
                  {importHealthieClientFamilyMemberErrorMessage ||
                    "An error has occurred. Please try again."}
                </Typography>
              )}
              {importHealthieClientFamilyMemberSuccess && (
                <Typography className={classes.successMessage}>
                  The new family member created successfully!
                </Typography>
              )}
              <Button variant="text" onClick={closeDialog}>
                {importHealthieClientFamilyMemberSuccess || importHealthieClientFamilyMemberError
                  ? "Close"
                  : " Cancel"}
              </Button>

              <Button
                onClick={onOpenPrimaryAccountConfirmation}
                disabled={
                  importHealthieClientFamilyMemberLoading ||
                  importHealthieClientFamilyMemberSuccess ||
                  importHealthieClientFamilyMemberError ||
                  !primaryAccountHolderId
                }
              >
                CONTINUE
              </Button>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px 10px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: 19,
    fontWeight: 500,
    color: "#15171d",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#3F4456",
    lineHeight: "18px",
    marginBottom: 15,
  },
  warningText: {
    fontSize: 12,
    lineHeight: "14px",
    color: " #92400E",
    fontWeight: 500,
  },
  importedDateText: {
    color: "#3F4456",
    fontSize: 12,
    lineHeight: "22px",
    fontWeight: 500,
    letterSpacing: "0.025em",
  },
  primaryAccountSelectionContainer: {
    backgroundColor: "#fafafa",
    border: "1px solid #e1e3e9",
    borderRadius: 3,
    margin: "20px 0px",
    padding: "20px 30px",
    [theme.breakpoints.down("xl")]: {
      padding: "15px",
    },
  },
  accountSelectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    marginBottom: 20,
    "& p": {
      width: "42%",
      color: "#15171d",
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "20px",
    },
    [theme.breakpoints.down("xl")]: {
      padding: 0,
    },
  },
  accountSelectionContainer: {
    margin: "20px 0px",
  },
  accountSelectionFormControlLabelRoot: {
    display: "flex",
    alignItems: "flex-start",
    marginLeft: 0,
    marginRight: 0,
    "& .MuiRadio-root": {
      marginTop: "-5px",
      marginRight: 75,
    },
    "& .MuiTypography-body1": {
      width: "100%",
    },
  },
  formLabel: {
    color: "#3F4456",
    marginBottom: 5,
    fontSize: 15,
  },
  healthieClientName: {
    color: "#2a2d39",
    fontSize: 17,
    fontWeight: 500,
    lineHeight: "20px",
  },
  healthieCaregiverName: {
    color: "#3F4456",
    fontSize: 15,
    fontWeight: 500,
  },
  buttonContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 15,
    "& button": {
      borderRadius: 5,
      fontSize: 13,
    },
  },
  divider: {
    height: "0.8px",
    width: "100%",
    backgroundColor: "#878DA5",
  },
  primaryAccountBadge: {
    color: "#065F46",
    lineHeight: "20px",
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: "0.025em",
    fontStyle: "italic",
    marginLeft: "-30px",
    "& svg": {
      color: "#552879",
      verticalAlign: "middle",
      marginRight: 14,
    },
  },
  selectionConfirmation: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    padding: "20px",
    borderTop: "0.5px solid #878DA5 ",
    [theme.breakpoints.down("xl")]: {
      padding: "10px 0px ",
    },
  },
  nameContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& p": {
      width: "42%",
      color: "#15171d",
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "25px",
    },
  },
  relationshipContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& div": {
      width: "42%",
      display: "flex",
      flexDirection: "column",
      "& h4": {
        color: "#3f4456",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "22px",
        letterSpacing: "-0.023m",
      },
    },
  },
  arrow: {
    position: "relative",
    backgroundColor: "#d3b3e1",
    width: "50px",
    height: "4px",
    borderRadius: "10px",
    transformOrigin: "right center",
    zIndex: 0,
    "&:before, &:after": {
      display: "block",
      content: '""',
      position: "absolute",
      left: "48px",
      backgroundColor: "#d3b3e1",
      width: "20px",
      height: "4px",
      transformOrigin: "2px center",
      borderRadius: "10px",
    },
    "&:before": {
      transform: "rotate(-140deg)",
    },
    "&:after": {
      transform: "rotate(140deg)",
    },
  },
  errorMessage: {
    fontSize: 14,
    color: "#DC2626",
    fontWeight: 500,
  },
  successMessage: {
    fontSize: 14,
    color: "#059669",
    fontWeight: 500,
  },
});

export default withStyles(styles)(ClientPrimaryAccountSelection);
