import { Auth } from "@aws-amplify/auth";

export function* checkCognitoAuth() {
  return yield new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((user) => {
        resolve({
          email: user.idToken.payload.email,
          groups: user.idToken.payload["cognito:groups"],
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function* refreshCognitoToken() {
  const cognitoUser = yield Auth.currentAuthenticatedUser();
  const currentSession = yield Auth.currentSession();
  yield cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    if (err) {
      return false;
    }
  });
  return true;
}
