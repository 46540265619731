import React from "react";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";

const ANReadItem = ({ title, text, type, xs, sm = 12, panelMode, divider }) => {
  return (
    <Stack width="100%">
      <Box
        sx={{
          flexBasis: { xs: "100%", sm: `${(sm / xs) * 100}%` },
          gap: 2,
        }}
        display={panelMode ? "flex" : ""}
        flexDirection={panelMode ? "row" : "column"}
      >
        {type === "avatar" ? (
          <Avatar src={text} />
        ) : (
          <>
            <Typography
              color={(theme) =>
                panelMode ? theme.palette.text.primary : theme.palette.text.secondary
              }
              variant={panelMode ? "body2" : "inputLabel"}
              sx={{ mb: 3, flex: "1 0 0", width: "100%", flexDirection: "column" }}
            >
              {title}
            </Typography>
            <Typography
              align={panelMode ? "right" : "left"}
              color={(theme) => (panelMode ? theme.palette.text.primary : "")}
              variant={panelMode ? "subtitle2" : "body2"}
            >
              {text || "-"}
            </Typography>
          </>
        )}
      </Box>
      {divider && (
        <Divider style={{ width: "100%", borderBottom: "1px solid #ababab", margin: "15px 0" }} />
      )}
    </Stack>
  );
};

export default ANReadItem;
