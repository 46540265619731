export default (theme) => ({
  container: {
    width: "100%",
    minHeight: "100vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  signinFormContainer: {
    width: 320,
    marginBottom: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  signinForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 64,
  },
  signinLogo: {
    height: "auto",
    width: 200,
  },
  passwordLabelContainer: {
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  signinError: {
    color: "#DC2626",
    fontWeight: 500,
  },
  forgotPasswordFormContainer: {
    width: 320,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  forgotPasswordForm: {
    width: "100%",
    marginTop: 64,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
  },
  forgotPasswordTitle: {
    color: "#000",
    fontWeight: 500,
    fontSize: 20,
    margin: "10px 0",
    textAlign: "center",
  },
  forgotPasswordText: {
    color: "#69718F",
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 20,
    lineHeight: 1.75,
  },
  forgotPasswordLink: {
    color: "#2A2D39",
    textDecoration: "underline",
  },
  resetPasswordFormContainer: {
    width: 320,
    marginBottom: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  resetPasswordLink: {
    textDecoration: "underline",
    color: "#DC2626",
    fontWeight: 500,
    cursor: "pointer",
  },
  title: {
    color: "#000",
    fontWeight: 500,
    fontSize: 24,
    margin: "10px 0",
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
  },
  accountText: {
    fontWeight: 500,
    color: "#7235A2",
    fontSize: 17,
    cursor: "pointer",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  label: {
    paddingLeft: 10,
  },
  formAgeInput: {
    width: 120,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  submit: {
    marginTop: theme.spacing(2),
    height: 50,
    textTransform: "uppercase",
    fontSize: 16,
    marginRight: 40,
    [theme.breakpoints.down("xl")]: {
      marginRight: 0,
      marginBottom: 20,
    },
  },
  footerContainer: {
    position: "absolute",
    bottom: 20,
    width: "60%",
    textAlign: "center",
  },
  footerText: {
    fontSize: 12,
    color: "#545a72",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listIcon: {
    width: 24,
    minWidth: 0,
    marginRight: 8,
  },
  valid: {
    color: "green",
  },
  notValid: {
    color: "red",
  },
  listText: {
    marginTop: 0,
    marginBottom: 0,
    color: "#2A2D39",
    fontSize: 14,
  },
  listTextValid: {
    marginTop: 0,
    marginBottom: 0,
    color: "#2E7D32",
    fontSize: 14,
  },
  callInfoContainer: {
    marginTop: 25,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  callInfoText: {
    textAlign: "center",
    fontSize: 18,
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  callInfoPhoneNumber: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: 22,
    [theme.breakpoints.down("xl")]: {
      fontSize: 18,
      marginBottom: 10,
    },
  },
  agreeToTermsPrivacyText: {
    color: "#2A2D39",
    fontSize: 15,
    "& a": {
      textDecoration: "none",
    },
  },
  checkboxContainer: {
    margin: "24px auto",
  },
  recoveryEmailButtonContainer: {
    width: "100%",
  },
  recoverPasswordButtonContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: -20,
    marginBottom: 15,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  },
  recoverPasswordButton: {
    height: 40,
  },
  contactFormContainer: {
    width: "100vw",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    // width: "90%",
    position: "relative",
    // marginBottom: "5%",
    [theme.breakpoints.down("xl")]: {
      margin: 0,
      paddingTop: 0,
    },
  },
  formTitleContainer: {
    display: "flex",
    margin: "50px 10%",
    alignItems: "baseline",
  },
  formTitle: {
    color: "#15171D",
    fontSize: 34,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 25,
    },
  },
  formLogo: {
    height: 38,
    width: 49,
    marginRight: 50,
    objectFit: "contain",
  },
  contactForm: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.4)",
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 0px",
  },
  formInfoText: {
    fontWeight: 500,
    color: "#2a2d39",
    fontSize: 19,
    lineHeight: 1.7,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  sectionTitleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px",
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 20,
    },
  },
  avatar: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #3F4456",
    color: "#3F4456",
  },
  sectionTitle: {
    fontSize: 22,
    color: " #15171D",
    marginLeft: 20,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
    },
  },
  profileInfoContainer: {
    width: "75%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      width: "95%",
      marginLeft: 24,
    },
  },
  formLabel: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
    color: "#2A2D39",
    lineHeight: 1.6,
  },
  formButtonsContainer: {
    width: "75%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginTop: 50,
    [theme.breakpoints.down("xl")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  formButtons: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  loading: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  extraBlockOne: {
    height: 110,
    [theme.breakpoints.down("xl")]: {
      height: 10,
    },
  },
  extraBlockTwo: {
    height: 107,
    [theme.breakpoints.down("xl")]: {
      height: 10,
    },
  },
  extraBlockThree: {
    height: 67,
    [theme.breakpoints.down("xl")]: {
      height: 10,
    },
  },
});
