import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "../Signin/styles";
import ResetPassword from "../ResetPassword/ResetPassword";
import {
  getSignedinState,
  getPasswordChange,
  getUserAttributes,
  isLoaded,
  getLogin,
  getResetPasswordSuccess,
  getResetPasswordError,
  getResetPasswordErrorMessage,
  getUser,
  isClinician,
  isCaregiver,
} from "selectors";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  passwordChange: getPasswordChange(state),
  userAttributes: getUserAttributes(state),
  loaded: isLoaded(state),
  login: getLogin(state),
  username: getLogin(state),
  resetPasswordSuccess: getResetPasswordSuccess(state),
  resetPasswordError: getResetPasswordError(state),
  resetPasswordErrorMessage: getResetPasswordErrorMessage(state),
  isClinician: isClinician(state),
  isClient: isCaregiver(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkAuthState: actions.checkAuthState,
      changeUserPassword: actions.changeUserPassword,
      getUser: actions.getUser,
      setUserAttributes: actions.setUserAttributes,
      clearResetPassword: actions.clearResetPassword,
      setPageDetails: actions.setPageDetails,
    },
    dispatch,
  );

function getInitialState(props) {
  return {
    username: props.username || "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
    verificationCode: "ignoreme",
    upperCase: false,
    lowerCase: false,
    includesNumber: false,
    properLength: false,
    success: false,
    showPassword: false,
    passwordError: false,
    passwordsMatchError: false,
  };
}

class ChangePasswordContainer extends Component {
  state = {
    ...getInitialState(this.props),
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Change Password?",
    });
    this.props.clearResetPassword();
    this.props.checkAuthState();
    if (this.props.login) {
      this.props.getUser();
    }
    if (this.props.loaded) {
      this.props.setUserAttributes({ username: this.props.username });
      this.setState(getInitialState(this.props));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded) {
      this.props.setUserAttributes({ username: this.props.username });
      this.setState(getInitialState(this.props));
    }
    if (!prevProps.resetPasswordSuccess && this.props.resetPasswordSuccess) {
      this.goToDashboard();
    }
  }

  goToSignin() {
    this.props.history.push("/");
  }

  goToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "password") {
      this.validatePassword(value);
    }
    this.setState({ [name]: val });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.changeUserPassword({
      ...this.state,
    });
  };

  validatePassword = (password) => {
    const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
    const lowerCaseRegex = new RegExp("^(?=.*[a-z])");
    const numberRegex = new RegExp("^(?=.*[0-9])");
    const lengthRegex = new RegExp("^(?=.{8,})");

    const upperCase = upperCaseRegex.test(password);
    const lowerCase = lowerCaseRegex.test(password);
    const includesNumber = numberRegex.test(password);
    const properLength = lengthRegex.test(password);
    this.setState({ upperCase, lowerCase, includesNumber, properLength });
  };

  toggleShowPasswords = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setPasswordError = (val) => {
    this.setState({ passwordError: val });
  };

  setPasswordsMatch = (val) => {
    this.setState({ passwordsMatchError: val });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <ResetPassword
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          change={true}
          handleMouseDownPassword={this.handleMouseDownPassword}
          toggleShowPasswords={this.toggleShowPasswords}
          setPasswordError={this.setPasswordError}
          setPasswordsMatch={this.setPasswordsMatch}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer),
);
