import React, { useState, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment-timezone";
import { TIMEZONES } from "../constants";

const OverrideClinicianTimePicker = (props) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const popperRef = useRef(null);

  const {
    classes,
    onChangeOverrideSchedule,
    overrideSchedule,
    overrideTime,
    selectClinicianOverrideTime,
    bcba,
    timezone,
  } = props;

  const handleAddHour = () => {
    let modifiedDate = moment(overrideTime);
    if (modifiedDate.hours() < 23) {
      modifiedDate.add(1, "hour");
    }
    selectClinicianOverrideTime(bcba, modifiedDate);
  };

  const handleSubtractHour = () => {
    let modifiedDate = moment(overrideTime);
    if (modifiedDate.hours() > 0) {
      modifiedDate.subtract(1, "hour");
    }
    selectClinicianOverrideTime(bcba, modifiedDate);
  };

  const handleAddMinute = () => {
    let modifiedDate = moment(overrideTime);
    if (modifiedDate.minutes() == 59 && modifiedDate.hours() == 23) {
      return;
    }
    modifiedDate.add(1, "minute");
    selectClinicianOverrideTime(bcba, modifiedDate);
  };

  const handleSubtractMinute = () => {
    let modifiedDate = moment(overrideTime);
    if (modifiedDate.minutes() == 0 && modifiedDate.hours == 0) {
      return;
    }
    modifiedDate.subtract(1, "minute");
    selectClinicianOverrideTime(bcba, modifiedDate);
  };

  const handleToggleMeridiem = (meridiem) => {
    let modifiedDate = moment(overrideTime);
    if (meridiem == "am") {
      modifiedDate.subtract(12, "hours");
    } else {
      modifiedDate.add(12, "hours");
    }
    selectClinicianOverrideTime(bcba, modifiedDate);
  };

  const onClickOverride = (e) => {
    setPopperOpen(e.target.checked);
    onChangeOverrideSchedule(e);
  };

  const handleClickAway = () => {
    onChangeOverrideSchedule({ target: { checked: false } });
    setPopperOpen(false);
  };

  const onClickChooseTime = () => {
    selectClinicianOverrideTime(bcba, overrideTime);
    setPopperOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControlLabel
        ref={popperRef}
        control={<Checkbox checked={overrideSchedule} onClick={onClickOverride} size="small" />}
        label="Override Schedule (manually choose time)"
      />
      {overrideSchedule && overrideTime && !popperOpen && (
        <div className={classes.overrideContainer}>
          <Typography component="h3">
            {moment(overrideTime).format("h:mm a")}
            {"  "} {TIMEZONES[timezone]} {"  "}
          </Typography>
          <Typography component="p" onClick={() => setPopperOpen(true)}>
            Edit
          </Typography>
        </div>
      )}

      <Popper
        open={popperOpen}
        placement="top"
        anchorEl={popperRef.current}
        className={classes.popper}
        disablePortal={true}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.popperContainer}>
            <div className={classes.popperInstructions}>
              <Typography component="h3">Manually choose a time for the session</Typography>
              <Typography component="p">Scroll or click to select the time</Typography>
            </div>
            <div className={classes.timePickerContainer}>
              <HourPicker
                handleAddHour={handleAddHour}
                handleSubtractHour={handleSubtractHour}
                {...props}
              />
              <MinutePicker
                handleAddMinute={handleAddMinute}
                handleSubtractMinute={handleSubtractMinute}
                {...props}
              />
              <AmPmPicker handleToggleMeridiem={handleToggleMeridiem} {...props} />
              <div className={classes.buttonContainer}>
                <Button onClick={onClickChooseTime} color="secondary" size="small">
                  Choose time
                </Button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const HourPicker = (props) => {
  const { classes, handleAddHour, handleSubtractHour, overrideTime, timezone } = props;
  const onWheel = (e) => {
    if (e.nativeEvent?.wheelDelta > 0) {
      handleAddHour();
    } else if (
      !(
        date.hours() == 0 ||
        new Date(moment.tz(overrideTime, timezone).subtract(1, "hour")) < new Date()
      )
    ) {
      handleSubtractHour();
    }
  };
  const date = moment.tz(overrideTime, timezone);
  return (
    <div className={classes.timePicker}>
      <IconButton
        color="inherit"
        size="small"
        aria-label="Increase hour"
        onClick={handleAddHour}
        disabled={date.hours() == 23}
        className={classes.iconButton}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
      <div className={classes.scrollTimeBlock} onWheel={onWheel}>
        <Typography component="h3">{date.format("h")}</Typography>
      </div>
      <IconButton
        color="inherit"
        size="small"
        aria-label="decrease hour"
        onClick={handleSubtractHour}
        disabled={
          date.hours() == 0 ||
          new Date(moment.tz(overrideTime, timezone).subtract(1, "hour")) < new Date()
        }
        className={classes.iconButton}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Typography component="p">HOURS</Typography>
    </div>
  );
};

const MinutePicker = (props) => {
  const { classes, handleAddMinute, handleSubtractMinute, overrideTime, timezone } = props;
  const onWheel = (e) => {
    if (e.nativeEvent?.wheelDelta > 0 && !(date.minutes() == 59 && date.hours() == 23)) {
      handleAddMinute();
    } else if (
      !(
        (date.minutes() == 0 && date.hours() == 0) ||
        new Date(moment.tz(overrideTime, timezone).subtract(1, "minute")) < new Date()
      )
    ) {
      handleSubtractMinute();
    }
  };
  const date = moment.tz(overrideTime, timezone);
  return (
    <div className={classes.timePicker}>
      <IconButton
        color="inherit"
        size="small"
        aria-label="Increase hour"
        onClick={handleAddMinute}
        disabled={date.minutes() == 59 && date.hours() == 23}
        className={classes.iconButton}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
      <div className={classes.scrollTimeBlock} onWheel={onWheel}>
        <Typography component="h3">{date.format("mm")}</Typography>
      </div>
      <IconButton
        color="inherit"
        size="small"
        aria-label="decrease hour"
        onClick={handleSubtractMinute}
        disabled={
          (date.minutes() == 0 && date.hours() == 0) ||
          new Date(moment.tz(overrideTime, timezone).subtract(1, "minute")) < new Date()
        }
        className={classes.iconButton}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Typography component="p">MINUTES</Typography>
    </div>
  );
};

const AmPmPicker = (props) => {
  const { classes, handleToggleMeridiem, overrideTime, timezone } = props;
  const meridiem = moment.tz(overrideTime, timezone).format("a");
  return (
    <div className={classes.meridiemContainer}>
      <div
        className={meridiem === "am" ? classes.selectedMeridiem : classes.unselectedMeridiem}
        onClick={
          meridiem === "am" ||
          new Date(moment.tz(overrideTime, timezone).subtract(12, "hours")) < new Date()
            ? null
            : () => handleToggleMeridiem("am")
        }
      >
        AM
      </div>
      <div
        className={meridiem === "pm" ? classes.selectedMeridiem : classes.unselectedMeridiem}
        onClick={meridiem === "pm" ? null : () => handleToggleMeridiem("pm")}
      >
        PM
      </div>
    </div>
  );
};

const styles = (theme) => ({
  overrideContainer: {
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
    "& p": {
      color: "#2a2d39",
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 10,
    },
    "& h3": {
      color: "#15171d",
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "24px",
      marginLeft: 55,
    },
    "& p": {
      fontSize: 14,
      marginLeft: 10,
      color: "#8342ca",
      cursor: "pointer",
    },
  },

  popper: {
    zIndex: 1000,
    height: 250,
    width: "100%",
    maxWidth: 550,
  },
  popperContainer: {
    width: "100%",
    height: "100%",
    border: "1px solid #C3C6D2",
    backgroundColor: "#F0F1F4",
    borderRadius: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  popperInstructions: {
    width: "100%",
    height: 80,
    backgroundColor: "#fafafa",
    border: "1px solid #C3C6D2",
    borderRadius: 7,
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingLeft: 15,
    alignItems: "flex-start",
    justifyContent: "center",
    color: "#2A2D39",
    "& h3": {
      fontWeight: 500,
      fontSize: 16,
    },
    "& p": {
      fontWeight: 500,
      fontSize: 14,
    },
  },
  timePickerContainer: {
    flex: 1,
    maxHeight: 170,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 15,
    paddingLeft: 40,
  },
  timePicker: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      color: "#2a2d39",
    },
    flex: 2,
  },
  scrollTimeBlock: {
    height: 70,
    width: 40,
    backgroundColor: "#fff",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h3": {
      fontSize: 19,
      fontWeight: 500,
      color: "#2a2d39",
    },
    "&:hover": {
      cursor: "ns-resize",
    },
  },
  meridiemContainer: {
    display: "flex",
    width: 110,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 26,
    flex: 3,
  },
  unselectedMeridiem: {
    color: "#a5aabc",
    fontSize: 19,
    fontWeight: 500,
    width: 50,
    height: 40,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 5px",
    "&:hover": {
      backgroundColor: "#E7E8EB",
      cursor: "pointer",
    },
  },
  selectedMeridiem: {
    color: "#2A2D39",
    fontSize: 19,
    fontWeight: 500,
    width: 50,
    height: 40,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 5px",
    "&:hover": {
      backgroundColor: "#E7E8EB",
      cursor: "pointer",
    },
  },
  buttonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 2,
    "& button": {
      backgroundColor: "#fff",
      height: 34,
    },
  },
});

export default withStyles(styles)(OverrideClinicianTimePicker);
