import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getUserList,
  createUser,
  getHealthieOrgMembers,
  inviteHealthieSupportUser,
  resendSupportUserHealthieInvite,
  resetSupportUserHealthiePassword,
  getUserDeactivationReasons,
} from "../api/users";
import { registerCognitoUser } from "../api/register";

function* getUsersAsync() {
  try {
    const userList = yield call(getUserList);
    yield put(actions.setUserList(userList.data, parseInt(userList.data[0].count)));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* createUserAsync({ payload }) {
  try {
    const cognitoId = yield call(registerCognitoUser, payload, true);
    const createUserParams = { ...payload, cognitoId };
    yield call(createUser, createUserParams);
    yield put(actions.createUserSuccess());
    yield put(actions.getUserList());
  } catch (e) {
    yield put(actions.createUserFail(e));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getHealthieOrgMembersAsync() {
  try {
    const members = yield call(getHealthieOrgMembers);
    yield put(actions.getHealthieOrgMembersSuccess(members.data));
  } catch (e) {
    yield put(actions.getHealthieOrgMembersFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* inviteHealthieSupportUserAsync({ payload }) {
  try {
    yield call(inviteHealthieSupportUser, payload);
    yield put(actions.inviteHealthieSupportUserSuccess());
  } catch (e) {
    if (e?.response?.data?.error) {
      yield put(actions.inviteHealthieSupportUserFail(e.response.data.error));
    } else {
      yield put(
        actions.inviteHealthieSupportUserFail(
          "An error has occurred while inviting this user. Please try again.",
        ),
      );
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* resendSupportUserHealthieInviteAsync({ payload }) {
  try {
    yield call(resendSupportUserHealthieInvite, payload);
    yield put(actions.resendSupportUserHealthieInviteSuccess());
  } catch (e) {
    yield put(actions.resendSupportUserHealthieInviteFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* resetSupportUserHealthiePasswordAsync({ payload }) {
  try {
    yield call(resetSupportUserHealthiePassword, payload);
    yield put(actions.resetSupportUserHealthiePasswordSuccess());
  } catch (e) {
    yield put(actions.resetSupportUserHealthiePasswordFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getUserDeactivationReasonsAsync() {
  try {
    const reasons = yield call(getUserDeactivationReasons);
    yield put(actions.getUserDeactivationReasonsSuccess(reasons.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_USER_LIST, getUsersAsync);
  yield takeEvery(actions.CREATE_USER, createUserAsync);
  yield takeEvery(actions.GET_HEALTHIE_ORG_MEMBERS, getHealthieOrgMembersAsync);
  yield takeEvery(actions.INVITE_HEALTHIE_SUPPORT_USER, inviteHealthieSupportUserAsync);
  yield takeEvery(
    actions.RESEND_SUPPORT_USER_HEALTHIE_INVITE,
    resendSupportUserHealthieInviteAsync,
  );
  yield takeEvery(
    actions.RESET_SUPPORT_USER_HEALTHIE_PASSWORD,
    resetSupportUserHealthiePasswordAsync,
  );
  yield takeEvery(actions.GET_USER_DEACTIVATION_REASONS, getUserDeactivationReasonsAsync);
}
