import React from "react";
import {
  Box,
  Badge,
  Avatar,
  Popover,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { Bell, Sidebar } from "@phosphor-icons/react";
import AvatarInitials from "./AvatarInitials";
import LogoIcon from "../../../assets/an_purple_white.png";
import Typography from "@mui/material/Typography";

export const Tools = ({
  handleBellClicked,
  unreadNotifications,
  userAvatarSrc,
  menuItems,
  userInitials,
  handleDrawerToggle,
}) => {
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClicked = (clickHandler) => {
    setOpen(false);
    clickHandler();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
        <Sidebar size={24} color={"#D2B3EA"} />
      </IconButton>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={LogoIcon} style={{ height: 29, width: "auto" }} />
      </div>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
        {handleBellClicked && (
          <IconButton sx={{ marginRight: 2 }} onClick={handleBellClicked}>
            <Badge badgeContent={unreadNotifications} color="primary" variant="dot">
              <Bell />
            </Badge>
          </IconButton>
        )}

        <IconButton onClick={handleClick} ref={anchorEl}>
          {userAvatarSrc || !userInitials ? (
            <Avatar src={userAvatarSrc} sx={{ width: 30, height: 30 }} />
          ) : (
            <AvatarInitials userInitials={userInitials} />
          )}
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClick}
          PaperProps={{
            style: { width: "150px", minWidth: "fit-content" },
          }}
        >
          <MenuList>
            {menuItems.map(
              (
                {
                  value,
                  label,
                  disabled = undefined,
                  icon = "",
                  divider = false,
                  typography = false,
                  clickHandler,
                },
                i,
              ) => (
                <MenuItem
                  key={i}
                  value={value}
                  onClick={() => handleMenuItemClicked(clickHandler)}
                  disabled={disabled}
                  divider={divider}
                >
                  {icon !== "" ? (
                    <ListItemIcon>
                      {React.createElement(icon, {
                        weight: "duotone",
                        color: "text.secondary",
                      })}
                    </ListItemIcon>
                  ) : null}
                  {typography ? (
                    <Typography variant="caption" color="text.secondary">{`${label}`}</Typography>
                  ) : (
                    label
                  )}
                </MenuItem>
              ),
            )}
          </MenuList>
        </Popover>
      </Box>
    </Box>
  );
};
