import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "../Signin/styles";
import ResetPassword from "../ResetPassword/ResetPassword";
import {
  getPasswordChange,
  getUserAttributes,
  getLoginInvitation,
  getInvitationError,
  getGroups,
} from "../../selectors";

const mapStateToProps = (state) => ({
  passwordChange: getPasswordChange(state),
  userAttributes: getUserAttributes(state),
  loginInvitation: getLoginInvitation(state),
  invitationError: getInvitationError(state),
  userGroups: getGroups(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword: actions.changePassword,
      getLoginInvitation: actions.getLoginInvitation,
      markInvitationUsed: actions.markInvitationUsed,
      setUserAttributes: actions.setUserAttributes,
      startSignin: actions.startSignin,
      setPageDetails: actions.setPageDetails,
      agreeToPolicies: actions.agreeToPolicies,
    },
    dispatch,
  );

function getInitialState(props) {
  return {
    username: props.userAttributes.email || "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
    verificationCode: "ignoreme",
    upperCase: false,
    lowerCase: false,
    includesNumber: false,
    properLength: false,
    invitationSignin: false,
    agreeToTermsAndPrivacy: false,
    showPassword: false,
    showConfirmPassword: false,
    passwordError: false,
    passwordsMatchError: false,
  };
}

class ForcePasswordContainer extends Component {
  state = {
    ...getInitialState(this.props),
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Reset your Password?",
    });

    if (!this.props.passwordChange && !this.props.match.params.guid) {
      this.props.history.push("/dashboard");
    }
    if (this.props.match.params.guid) {
      this.setState({ invitationSignin: true });
      this.props.getLoginInvitation(this.props.match.params.guid);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loginInvitation && this.props.loginInvitation) {
      this.props.setUserAttributes({
        username: this.props.loginInvitation.email,
      });
      this.props.startSignin({
        username: this.props.loginInvitation.email,
        password: this.props.loginInvitation.password,
      });
    }
    if (prevProps.passwordChange && !this.props.passwordChange) {
      this.props.markInvitationUsed(this.props.match.params.guid);
      if (this.props.loginInvitation.authority_name === "ROLE_PARENT") {
        this.props.agreeToPolicies({
          userId: this.props.loginInvitation.user_id,
        });
      }
      this.props.history.push("/dashboard");
    }
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;

    if (name === "password") {
      this.validatePassword(value);
    }

    this.setState({ [name]: val });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.changePassword({
      ...this.state,
    });
  };

  onSubmitInvitationLogin = (e) => {
    e.preventDefault();
    this.props.changePassword({
      ...this.state,
      currentPassword: this.props.loginInvitation.password,
      username: this.props.loginInvitation.email,
    });
  };

  validatePassword = (password) => {
    const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
    const lowerCaseRegex = new RegExp("^(?=.*[a-z])");
    const numberRegex = new RegExp("^(?=.*[0-9])");
    const lengthRegex = new RegExp("^(?=.{8,})");

    const upperCase = upperCaseRegex.test(password);
    const lowerCase = lowerCaseRegex.test(password);
    const includesNumber = numberRegex.test(password);
    const properLength = lengthRegex.test(password);

    this.setState({
      upperCase,
      lowerCase,
      includesNumber,
      properLength,
    });
  };
  toggleAgreeToTermsAndPrivacy = () => {
    this.setState({
      agreeToTermsAndPrivacy: !this.state.agreeToTermsAndPrivacy,
    });
  };

  toggleShowPasswords = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleShowConfirmPasswords = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setPasswordError = (val) => {
    this.setState({ passwordError: val });
  };

  setPasswordsMatch = (val) => {
    this.setState({ passwordsMatchError: val });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ResetPassword
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onSubmitInvitationLogin={this.onSubmitInvitationLogin}
          change={true}
          toggleAgreeToTermsAndPrivacy={this.toggleAgreeToTermsAndPrivacy}
          handleMouseDownPassword={this.handleMouseDownPassword}
          toggleShowPasswords={this.toggleShowPasswords}
          toggleShowConfirmPasswords={this.toggleShowConfirmPasswords}
          setPasswordError={this.setPasswordError}
          setPasswordsMatch={this.setPasswordsMatch}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ForcePasswordContainer),
);
