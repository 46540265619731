import React from "react";
import Modal from "elements/Modal";
import Typography from "@mui/material/Typography";

const AddToHealthieDialog = (props) => {
  const {
    addToHealthieOpen,
    healthieSaving,
    healthieSuccess,
    healthieError,
    submitAddToHealthie,
    onToggleDialog,
  } = props;

  return (
    <Modal
      open={addToHealthieOpen}
      onClose={onToggleDialog}
      title=" Invite Clinician to Healthie"
      titleCentered
      content={
        <div style={{ maxWidth: 500 }}>
          <Typography>
            This will email the clinician a link to set up their Healthie account and sync their
            calendar. It is recommended that you have a video call with the clinician to walk them
            through this process.
          </Typography>
        </div>
      }
      loading={healthieSaving}
      primaryActionText="Invite"
      primaryActionOnClick={submitAddToHealthie}
      primaryActionDisabled={healthieSaving || healthieSuccess}
      secondaryActionText={healthieSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onToggleDialog}
      secondaryActionDisabled={healthieSaving}
      success={healthieSuccess}
      successMessage="  Healthie Invitation sent successfully."
      error={healthieError}
      errrorMessage="An error has occurred. Please try again."
    />
  );
};

export default AddToHealthieDialog;
