import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { GLOBAL_STATES, TIMEZONES } from "../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import OverrideClinicianTimePicker from "elements/OverrideClinicianTimePicker";
import { formatToTimeZone } from "date-fns-timezone";
import { format, parseISO } from "date-fns";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import { convertTokens } from "@date-fns/upgrade/v2";
import ANDatePicker from "elements/ANDatePicker";
import { checkFeatureAccess } from "api/featureAccess";
import { TimeSlots } from "./TimeSlots";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";
import { getTimeZoneOptions } from "utils/getTimeZoneOptions";

const Schedule = (props) => {
  const {
    classes,
    clinicians,
    date,
    selectedClinician,
    selectedClinicianId,
    timezone,
    callType,
    callDuration,
    timeSelected,
    onChange,
    onChangeDate,
    onChangeClinician,
    loadingAvailability,
    availability,
    isCaregiver,
    isClinician,
    clinicianTime,
    selectClinicianTime,
    demoCall,
    rescheduleCall,
    allowOverride,
    overrideSchedule,
    onChangeOverrideSchedule,
    overrideTime,
    selectClinicianOverrideTime,
    isRepeating,
    repeatType,
    onChangeRepeatType,
    days,
    repeatDays,
    onToggleDaySelect,
    repeatEndType,
    repeatUntilDate,
    repeatNumberOfWeeks,
    callInstructionsAvailable,
    callInstructions,
    onToggleCallInstuctionsAvailable,
    scheduleCallLoading,
    customerDetails,
    clinicianHealthieProviderId,
    clinicianQuickAction,
    onScheduleVideoCall,
    selectScheduledClinician,
    onRescheduleVideoCall,
    rescheduleAllInstances,
    onChangeNumberOfWeeks,
    offPlatform,
    offPlatformType,
    onChangeRadio,
    isTherapeuticConsult,
    isDirty,
  } = props;
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  let clientName = customerDetails?.details?.name
    ? capitalizeFirstLetter(customerDetails.details.name)
    : "";
  let clientLastName = customerDetails?.details?.child_last_name
    ? capitalizeFirstLetter(customerDetails.details.child_last_name)
    : "";
  let clientCity = customerDetails?.details?.location?.city
    ? capitalizeFirstLetter(customerDetails.details.location?.city)
    : "";
  let clientState = customerDetails?.details?.location?.state
    ? GLOBAL_STATES[customerDetails.details.location?.state]
    : "";
  let clientTimezone = customerDetails?.details?.timezone
    ? TIMEZONES[customerDetails.details.timezone]
    : "";

  let incompleteInfo =
    !timezone || !date || !callDuration || !callType || !selectedClinician ? true : false;
  let buttonDisabled =
    (!clinicianTime && !overrideTime) ||
    scheduleCallLoading ||
    (isRepeating && !repeatEndType) ||
    !selectedClinicianId;
  let rescheduleButtonDisabled = !isDirty || scheduleCallLoading;

  const [hasAccessToOnSched, setHasAccessToOnSched] = useState(false);

  useEffect(() => {
    if (selectedClinicianId) {
      checkFeatureAccess(selectedClinicianId, "onsched_scheduling")
        .then((response) => {
          setHasAccessToOnSched(response);
        })
        .catch((error) => {
          console.error("Error fetching feature access:", error);
        });
    }
  }, [selectedClinicianId]);

  return (
    <div className={classes.scheduleContainer}>
      <div className={classes.scheduleHeader}>
        {demoCall ? (
          <Typography className={classes.title}>Schedule Demo Session</Typography>
        ) : (
          <Typography className={classes.title}>
            {rescheduleCall ? "Reschedule a Session for" : "Schedule a Session for"} {clientName}{" "}
            {clientLastName}
          </Typography>
        )}
        {clientName && clientCity && clientState && clientTimezone && !demoCall && (
          <Typography className={classes.subtitle}>
            {clientName} lives in {clientCity}, {clientState}.{" "}
            {formatTimeZoneToAbbreviation(clientTimezone)} Time Zone
          </Typography>
        )}
      </div>
      <div className={classes.selectionsContainer}>
        <div className={classes.topSelections}>
          <div className={classes.dateSelectionContainer}>
            <FormLabel component="legend">
              {format(new Date(date), convertTokens("dddd"))}
            </FormLabel>
            <ANDatePicker
              value={new Date(date)}
              onChange={(date) => onChangeDate("date", date)}
              format="PP" //"MMMM d, yyyy"
              minDate={parseISO(
                formatToTimeZone(new Date(), "YYYY-MM-DD HH:mm:ss.SSS", {
                  timeZone: customerDetails?.details?.timezone || "America/New_York",
                }),
              )}
              fullWidth
              // disabled={rescheduleAllInstances}
            />
          </div>
          {selectScheduledClinician && (
            <div className={classes.clinicianSelectionContainer}>
              <FormLabel component="legend">Clinician</FormLabel>
              <TextField
                select
                onChange={(e) => onChangeClinician(e)}
                value={selectedClinicianId}
                disabled={rescheduleAllInstances}
                fullWidth
              >
                <MenuItem disabled value={""}>
                  Select clinician
                </MenuItem>
                {clinicians.map((clinician) => {
                  return (
                    <MenuItem key={clinician.clinician_user_id} value={clinician.clinician_user_id}>
                      {clinician.first_name} {clinician.last_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          )}
        </div>
        <div className={classes.bottomSelections}>
          <div className={classes.timezoneSelectionContainer}>
            <FormLabel component="legend">Time Zone</FormLabel>
            <TextField
              select
              id="timezone"
              placeholder="Time Zone"
              onChange={onChange("timezone")}
              disabled={rescheduleCall && !rescheduleAllInstances}
              value={timezone}
              size="small"
              autoComplete="off"
              fullWidth
            >
              <MenuItem disabled value={""}>
                Select Time Zone
              </MenuItem>
              {getTimeZoneOptions().map(({ label, value }, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className={classes.sessionTypeSelectionContainer}>
            <FormLabel component="legend">Session Type</FormLabel>
            <TextField select onChange={onChange("callType")} value={callType} fullWidth>
              <MenuItem disabled value={""}>
                Select type
              </MenuItem>
              <MenuItem value={"ORIENTATION"}>Orientation</MenuItem>
              <MenuItem value={"ASSESSMENT"}>Assessment</MenuItem>
              <MenuItem value={"DIRECT_THERAPY"}>Direct Therapy</MenuItem>
              <MenuItem value={"CAREGIVER_TRAINING"}>Caregiver Training</MenuItem>
            </TextField>
          </div>

          <div className={classes.sessionDurationSelectionContainer}>
            <FormLabel component="legend">Duration</FormLabel>
            <TextField select onChange={onChange("callDuration")} value={callDuration} fullWidth>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={45}>45 Minutes</MenuItem>
              <MenuItem value={60}>60 Minutes</MenuItem>
              <MenuItem value={75}>75 Minutes</MenuItem>
              <MenuItem value={90}>90 Minutes</MenuItem>
              <MenuItem value={120}>120 Minutes</MenuItem>
            </TextField>
          </div>
        </div>
        <div className={classes.bottomSelections}>
          <div className={classes.locationSelectionContainer}>
            <FormLabel component="legend">Location</FormLabel>
            <div className={classes.locationRadioGroup}>
              <RadioGroup
                name="offPlatform"
                value={offPlatform}
                onChange={onChangeRadio("offPlatform")}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography component="p" style={{ fontSize: 16 }}>
                      On-Platform Call
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography component="p" style={{ fontSize: 16 }}>
                      {isTherapeuticConsult ? "Off-Platform Call" : "Doxy Call"}
                    </Typography>
                  }
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        {offPlatform && isTherapeuticConsult && (
          <div className={classes.bottomSelections}>
            <div style={{ width: "100%" }}>
              <FormLabel component="legend">What kind of call is this?</FormLabel>
              <div className={classes.locationRadioGroup} style={{ border: "none" }}>
                <RadioGroup
                  name="offPlatformType"
                  value={offPlatformType}
                  onChange={onChange("offPlatformType")}
                >
                  <FormControlLabel
                    value={"video"}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography component="p" style={{ fontSize: 16 }}>
                        Video Call
                        <span style={{ margin: "0 10px" }}>-</span>
                        <span style={{ fontSize: 12 }}>Doxy Platform</span>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"phone"}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography component="p" style={{ fontSize: 16 }}>
                        Phone Call
                        <span style={{ margin: "0 10px" }}>-</span>
                        <span style={{ fontSize: 12 }}>
                          This option is only allowed to be used for{" "}
                          <span style={{ fontSize: 12, fontWeight: 500 }}>
                            therapeutic consultation
                          </span>
                        </span>
                      </Typography>
                    }
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={classes.availabilityContainer}>
        {hasAccessToOnSched ? (
          <TimeSlots
            healthieAvailability={availability}
            duration={callDuration}
            callType={callType}
            incompleteInfo={incompleteInfo}
            selectClinicianTime={selectClinicianTime}
            selectedClinician={selectedClinician}
            clinicianTime={clinicianTime}
            overrideSchedule={overrideSchedule}
            timezone={timezone}
            classes={classes}
            date={date}
            isCaregiver={isCaregiver}
            clinicianId={props.user.clinician_id}
            allowOverride={allowOverride}
            overrideTime={overrideTime}
            onChangeOverrideSchedule={onChangeOverrideSchedule}
            selectClinicianOverrideTime={selectClinicianOverrideTime}
          />
        ) : incompleteInfo ? (
          <Typography className={classes.infoText}>
            Complete all fields to show available times.{" "}
          </Typography>
        ) : loadingAvailability ? (
          <div className={classes.clinicianLoading}>
            <CircularProgress />
            <Typography component="p">Loading</Typography>
          </div>
        ) : (
          <>
            <Typography className={classes.availabilitySubHeader}>
              Available Times for{" "}
              <span>
                {selectedClinician?.first_name} {selectedClinician?.last_name}
              </span>{" "}
              on
              <span> {format(new Date(date), convertTokens("MMMM Do"))}</span>.
            </Typography>
            <div className={classes.timeOptionsContainer}>
              {availability.length > 0 ? (
                availability.map((time, index) => {
                  const startDate = time.start.split(" ");
                  // Split the date string into its components
                  const [datePart, timePart, timezonePart] = startDate;
                  // construct the date into ISO format;
                  const timestamp = `${datePart}T${timePart}.000${timezonePart.substr(0, 3)}:00`;
                  return (
                    <div
                      key={index}
                      onClick={() => selectClinicianTime(selectedClinician, time)}
                      className={
                        clinicianTime &&
                        clinicianTime.bcbaEmail === selectedClinician.email &&
                        `${startDate[0]}T${startDate[1]}${startDate[2]}` ===
                          clinicianTime.startDate &&
                        !overrideSchedule
                          ? classes.timeOptionSelected
                          : classes.timeOption
                      }
                    >
                      <Typography component="p">
                        {formatToTimeZone(new Date(timestamp), "h:mm a", {
                          timeZone: timezone,
                        })}
                      </Typography>
                    </div>
                  );
                })
              ) : (
                <Typography component="p" className={classes.notAvailableText}>
                  {isCaregiver ? (
                    <strong>This Clinician has no available times this day</strong>
                  ) : (
                    <strong>No available times on this day</strong>
                  )}
                </Typography>
              )}
              {allowOverride && (
                <OverrideClinicianTimePicker
                  onChangeOverrideSchedule={onChangeOverrideSchedule}
                  overrideSchedule={overrideSchedule}
                  overrideTime={overrideTime}
                  bcba={selectedClinician}
                  selectClinicianOverrideTime={selectClinicianOverrideTime}
                  timezone={timezone}
                />
              )}
            </div>

            <Typography className={classes.noteText}>
              NOTE: All times are being displayed in {TIMEZONES[timezone]}
            </Typography>
          </>
        )}
      </div>
      <div className={classes.repeatingEventCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRepeating}
              onClick={onChange("isRepeating")}
              size="small"
              disabled={
                (!timeSelected && !overrideSchedule) ||
                (overrideSchedule && !overrideTime) ||
                rescheduleCall
              }
            />
          }
          label="Repeating Event"
        />
      </div>
      {isRepeating && (
        <div
          className={classes.availabilityContainer}
          style={
            rescheduleCall && !rescheduleAllInstances
              ? { opacity: "0.5", pointerEvents: "none" }
              : null
          }
        >
          <Typography className={classes.availabilitySubHeader}>
            Specify how you would like your session to repeat.
          </Typography>
          <TextField
            select
            onChange={(e) => onChangeRepeatType(e)}
            value={repeatType}
            className={classes.repeatSelect}
          >
            <MenuItem disabled value={""}>
              Select type
            </MenuItem>
            <MenuItem value={"daily"}>Daily</MenuItem>
            <MenuItem value={"weekday"}>Weekdays</MenuItem>
            <MenuItem value={"weekend"}>Weekends</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </TextField>
          <div className={classes.dayOptionsContainer}>
            {days.map((day, index) => {
              return (
                <div
                  key={day.order}
                  onClick={() => onToggleDaySelect(day.order)}
                  className={
                    repeatDays.includes(day.order) ? classes.timeOptionSelected : classes.timeOption
                  }
                >
                  <Typography component="p">{day.name}</Typography>
                </div>
              );
            })}
          </div>
          {repeatType && repeatDays.length > 0 && (
            <div className={classes.repeatEndTypeContainer}>
              <TextField
                select
                onChange={onChange("repeatEndType")}
                value={repeatEndType}
                className={classes.repeatSelect}
              >
                <MenuItem disabled value={""}>
                  Select end type
                </MenuItem>
                <MenuItem value={"until_date"}>Until a date</MenuItem>
                <MenuItem value={"number_of_weeks"}>For a number of weeks</MenuItem>
              </TextField>
              {!repeatEndType ? (
                <Typography className={classes.infoText}>Please select repeat end type</Typography>
              ) : (
                repeatEndType == "until_date" && (
                  <ANDatePicker
                    value={new Date(repeatUntilDate)}
                    onChange={(date) => onChangeDate("repeatUntilDate", date)}
                    maxDate={new Date(new Date().setDate(new Date().getDate() + 26 * 7))}
                    className={classes.repeatEndDatePicker}
                    disablePast
                  />
                )
              )}
              {repeatEndType == "number_of_weeks" && (
                <div className={classes.repeatWeeksContainer}>
                  <span>Repeat </span>
                  <TextField
                    variant="standard"
                    value={repeatNumberOfWeeks}
                    onChange={onChangeNumberOfWeeks}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 26 },
                    }}
                    classes={{ root: classes.repeatNumberOfWeeksTextfield }}
                  />{" "}
                  <span>{repeatNumberOfWeeks == "1" ? "time" : "times"}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className={classes.instructionsContainer}>
        {!callInstructionsAvailable ? (
          <Button
            variant="text"
            startIcon={<AddCircleRoundedIcon />}
            onClick={onToggleCallInstuctionsAvailable}
          >
            Add Instructions
          </Button>
        ) : (
          <>
            <Typography>
              Include Instructions for {clientName} {clientLastName} below
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline={true}
              rows={3}
              value={callInstructions}
              placeholder="What instructions would you like to include? Type in this space."
              onChange={onChange("callInstructions")}
              classes={{ root: classes.instructionsTextfield }}
            />
          </>
        )}
      </div>
      <div className={classes.buttonContainer}>
        {rescheduleCall ? (
          <Button onClick={onRescheduleVideoCall} disabled={rescheduleButtonDisabled}>
            Reschedule
          </Button>
        ) : (
          <Button onClick={onScheduleVideoCall} disabled={buttonDisabled}>
            Schedule
          </Button>
        )}
      </div>
    </div>
  );
};

export default Schedule;

const StyledInput = withStyles({
  root: {
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 16,
      color: "#15171d",
      fontWeight: 500,
      letterSpacing: "-2%",
      "&:hover fieldset": {
        border: "1px solid #A5AABC",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #A5AABC",
      },
    },
    "& .Mui-disabled": {
      opacity: "0.5",
      pointerEvents: "none",
    },
  },
})(TextField);

export const CustomSelect = React.forwardRef((props, ref) => {
  return <StyledInput select {...props} variant="outlined" size="small" />;
});
