import { Box, IconButton, Slide, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { forwardRef } from "react";
import ANFormView from "./ANFormView";
import { SealCheck, X } from "@phosphor-icons/react";
import AnFormEdit from "./ANFormEdit";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

const AnDetailsPanel = forwardRef(
  (
    {
      classes,
      open,
      setOpen,
      editMode,
      titlePanel,
      data = [],
      config = [],
      resetData,
      inputsForm,
      labelSaveButton,
      isLoading,
      onSave,
      onCancel,
      isDirty,
      saveTitle,
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Slide
        ref={ref}
        direction="left"
        in={open}
        sx={{
          width: { xs: "100%", md: "75%", lg: "50%" },
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
        mountOnEnter
        unmountOnExit
      >
        <Stack
          sx={{ borderLeft: `1px solid ${theme.palette.divider}`, overflowY: "auto" }}
          gap={theme.spacing(3)}
          style={{
            padding: theme.spacing(7),
          }}
          width="512px"
          minWidth="512px"
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              width="100%"
              style={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(3),
                flex: "1 0 0",
              }}
            >
              {titlePanel && (
                <>
                  <SealCheck
                    fontSize={"20px"}
                    weight="duotone"
                    color={theme.palette.primary.main}
                  ></SealCheck>
                  <Typography variant="subtitle1">{titlePanel}</Typography>
                </>
              )}
            </Box>
            {!editMode && (
              <IconButton
                fontSize="small"
                onClick={() => {
                  setOpen(!open);
                  resetData();
                }}
              >
                <X />
              </IconButton>
            )}
          </Box>
          {editMode ? (
            <AnFormEdit inputsForm={inputsForm} classes={classes} />
          ) : (
            <ANFormView data={data} config={config} panelMode />
          )}
          {editMode && onSave && (
            <LoadingButton
              disabled={isDirty}
              onClick={onSave}
              className={classes.button}
              color="primary"
              loading={isLoading}
            >
              {saveTitle}
            </LoadingButton>
          )}
          {editMode && onCancel && (
            <Button
              variant="text"
              color={"primary"}
              size="medium"
              onClick={onCancel}
              style={{ minWidth: 0 }}
            >
              Cancel
            </Button>
          )}
        </Stack>
      </Slide>
    );
  }
);

export default AnDetailsPanel;
