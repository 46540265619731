import { TramRounded } from "@mui/icons-material";
import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  users: [],
  page: 0,
  rowsPerPage: 25,
  count: 0,
  sort: "",
  sortdirection: "asc",
  userLoaded: false,
  usersLoading: false,
  saving: false,
  saveError: false,
  createUserLoading: false,
  createUserSuccess: false,
  createUserError: false,
  createUserErrorMessage: "",
  user: {},
  healthieOrgMembers: [],
  healthieOrgMembersLoading: false,
  healthieOrgMembersPage: 0,
  healthieOrgMembersOffset: 0,
  healthieOrgMembersCount: 0,
  inviteHealthieUserLoading: false,
  inviteHealthieUserSuccess: false,
  inviteHealthieUserErrorMessage: null,
  deactivationReasons: [],
};

const reducers = {
  [actions.GET_USER_LIST](state) {
    return {
      ...state,
      usersLoading: true,
    };
  },
  [actions.SET_USER_LIST](state, { payload }) {
    return {
      ...state,
      users: payload.users,
      count: payload.count,
      usersLoading: false,
      saving: false,
    };
  },
  [actions.SET_USERS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_USERS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_USERS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.CREATE_USER](state, { payload }) {
    return {
      ...state,
      saving: true,
      createUserLoading: true,
      createUserError: false,
      createUserErrorMessage: "",
    };
  },
  [actions.CREATE_USER_SUCCESS](state) {
    return {
      ...state,
      createUserLoading: false,
      createUserSuccess: true,
    };
  },
  [actions.CREATE_USER_FAIL](state, { payload }) {
    return {
      ...state,
      createUserLoading: false,
      createUserError: true,
      createUserErrorMessage: payload,
    };
  },
  [actions.RESET_CREATE_USER](state) {
    return {
      ...state,
      createUserSuccess: false,
      createUserError: false,
    };
  },
  [actions.GET_USER_DETAILS](state) {
    return {
      ...state,
      userLoaded: false,
    };
  },
  [actions.SET_USER_DETAILS](state, { payload }) {
    return {
      ...state,
      userLoaded: true,
      user: payload.user,
      saving: false,
    };
  },
  [actions.SET_USER_SAVE_ERROR](state) {
    return {
      ...state,
      saving: false,
      saveError: true,
    };
  },
  [actions.CLEAR_USER_SAVE_ERROR](state) {
    return {
      ...state,
      saveError: false,
    };
  },
  [actions.GET_HEALTHIE_ORG_MEMBERS](state) {
    return {
      ...state,
      healthieOrgMembersLoading: true,
    };
  },
  [actions.GET_HEALTHIE_ORG_MEMBERS_SUCCESS](state, { payload }) {
    return {
      ...state,
      healthieOrgMembers: payload.members,
      healthieOrgMembersCount: payload.count,
      healthieOrgMembersLoading: false,
    };
  },
  [actions.GET_HEALTHIE_ORG_MEMBERS_FAIL](state) {
    return {
      ...state,
      healthieOrgMembersLoading: false,
    };
  },
  [actions.SET_HEALTHIE_ORG_MEMBERS_PAGE](state, { payload }) {
    return {
      ...state,
      healthieOrgMembersPage: payload,
      healthieOrgMembersOffset: payload * 10,
    };
  },
  [actions.INVITE_HEALTHIE_SUPPORT_USER](state) {
    return {
      ...state,
      inviteHealthieUserLoading: true,
      inviteHealthieUserSuccess: false,
    };
  },
  [actions.INVITE_HEALTHIE_SUPPORT_USER_SUCCESS](state) {
    return {
      ...state,
      inviteHealthieUserLoading: false,
      inviteHealthieUserSuccess: true,
    };
  },
  [actions.INVITE_HEALTHIE_SUPPORT_USER_FAIL](state, { payload }) {
    return {
      ...state,
      inviteHealthieUserLoading: false,
      inviteHealthieUserErrorMessage: payload,
    };
  },
  [actions.RESEND_SUPPORT_USER_HEALTHIE_INVITE](state) {
    return {
      ...state,
      inviteHealthieUserLoading: true,
      inviteHealthieUserSuccess: false,
    };
  },
  [actions.RESEND_SUPPORT_USER_HEALTHIE_INVITE_SUCCESS](state) {
    return {
      ...state,
      inviteHealthieUserLoading: false,
      inviteHealthieUserSuccess: true,
    };
  },
  [actions.RESEND_SUPPORT_USER_HEALTHIE_INVITE_FAIL](state) {
    return {
      ...state,
      inviteHealthieUserLoading: false,
    };
  },
  [actions.RESET_SUPPORT_USER_HEALTHIE_PASSWORD](state) {
    return {
      ...state,
      inviteHealthieUserLoading: true,
      inviteHealthieUserSuccess: false,
    };
  },
  [actions.RESET_SUPPORT_USER_HEALTHIE_PASSWORD_SUCCESS](state) {
    return {
      ...state,
      inviteHealthieUserSuccess: true,
      inviteHealthieUserLoading: false,
    };
  },
  [actions.RESET_SUPPORT_USER_HEALTHIE_PASSWORD_FAIL](state) {
    return {
      ...state,
      inviteHealthieUserLoading: false,
    };
  },
  [actions.RESET_HEALTHIE_SUCCESS_FLAG](state) {
    return {
      ...state,
      inviteHealthieUserSuccess: false,
    };
  },
  [actions.GET_USER_DEACTIVATION_REASONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      deactivationReasons: payload,
    };
  },
};

export default createReducer(initialState, reducers);
