import React, { Component } from "react";
import { CustomInput } from "elements/DEPRECATED_elements/CustomInput/v1";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { COVERAGE_STATES, STATES } from "../../constants";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";

function getInitialState() {
  return {
    insuranceId: "",
    name: "",
    isMedicaid: null,
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    payerId: "",
    coverageState: "",
    locationModifier: null,
    providerModifier: "",
    telehealthModifier: null,
    isDirty: false,
    insuranceIdError: false,
    nameError: false,
    isMedicaidError: false,
    locationModifierError: false,
    providerModifierError: false,
    telehealthModifierError: false,
    address1Error: false,
    cityError: false,
    stateError: false,
    zipError: false,
    phoneError: false,
    payerIdError: false,
    coverageStateError: false,
    successToastOpen: false,
    error: false,
  };
}

class CreatePlanDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...getInitialState() };
  }

  componentDidMount() {
    this.props.getInsuranceProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.createPlanLoading && !this.props.createPlanLoading) {
      if (this.props.createPlanSuccess) {
        this.onClose();
        this.setState({ successToastOpen: true }, () => {
          setTimeout(() => {
            this.setState({ successToastOpen: false });
          }, 4000);
        });
      } else {
        this.setState({ error: true });
      }
    }
  }

  onChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: this.stringToBoolean(value),
      [`${name}Error`]: false,
    });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onNumberChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  onChangePhone = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    this.setState({
      [name]: numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3",
      ),
    });
  };

  goBack = () => {
    this.props.history.push("/insurance");
  };

  onClickSave = () => {
    this.setState({ error: false });
    const {
      insuranceId,
      name,
      isMedicaid,
      address1,
      address2,
      city,
      state,
      zip,
      payerId,
      coverageState,
      locationModifier,
      telehealthModifier,
      providerModifier,
      phone,
    } = this.state;
    this.setState(
      {
        insuranceIdError: !insuranceId,
        nameError: !name,
        isMedicaidError: isMedicaid === null,
        locationModifierError: !locationModifier,
        providerModifierError: !providerModifier,
        telehealthModifierError: !telehealthModifier,
        address1Error: !address1,
        cityError: !city,
        stateError: !state,
        zipError: !zip || zip.trim().length != 5,
        phoneError: !phone || phone.trim().match(/\d/g).length != 10,
        payerIdError: !payerId,
        coverageStateError: !coverageState,
      },
      () => {
        if (
          this.state.insuranceIdError ||
          this.state.nameError ||
          this.state.isMedicaidError ||
          this.state.locationModifierError ||
          this.state.providerModifierError ||
          this.state.telehealthModifierError ||
          this.state.address1Error ||
          this.state.cityError ||
          this.state.stateError ||
          this.state.zipError ||
          this.state.phoneError ||
          this.state.payerIdError ||
          this.state.coverageStateError
        ) {
          return;
        } else {
          this.props.onCreateNewInsurancePlan({
            insuranceId,
            name,
            isMedicaid,
            address1,
            address2,
            city,
            state,
            zip,
            locationModifier,
            telehealthModifier,
            providerModifier,
            phone: phone?.replace(/[^0-9]/g, ""),
            payerId,
            coverageState,
          });
        }
      },
    );
  };

  onClose = () => {
    this.setState({ ...getInitialState() });
    this.props.onToggleCreatePlanDialog();
  };

  render() {
    const {
      createPlanLoading,
      classes,
      insurancePlan,
      providers,
      onToggleCreatePlanDialog,
      createPlanDialogOpen,
    } = this.props;
    const {
      insuranceId,
      name,
      isMedicaid,
      address1,
      address2,
      city,
      state,
      zip,
      locationModifier,
      telehealthModifier,
      providerModifier,
      phone,
      payerId,
      coverageState,
      nameError,
      insuranceIdError,
      isMedicaidError,
      locationModifierError,
      providerModifierError,
      telehealthModifierError,
      address1Error,
      cityError,
      stateError,
      zipError,
      phoneError,
      payerIdError,
      coverageStateError,
      successToastOpen,
      error,
    } = this.state;

    return (
      <>
        <Dialog
          open={createPlanDialogOpen}
          disableEscapeKeyDown={true}
          PaperProps={{
            style: {
              maxWidth: "95%",
              width: 700,
            },
          }}
        >
          <div className={classes.dialogTitleContainer}>
            <Typography component="h2" className={classes.dialogTitle}>
              Add a New Insurance Plan
            </Typography>
          </div>
          <div className={classes.errorMessageContianer}>
            {error && (
              <Typography component="p">
                Something went wrong on our end. Please try again.
              </Typography>
            )}
          </div>
          <DialogContent>
            <div className={classes.planDetailsContainer}>
              <Grid container justifyContent="center" alignItems="flex-start" spacing={6}>
                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Insurance Company*
                    </FormLabel>
                    <TextField
                      id="insuranceId"
                      name="insuranceId"
                      autoComplete="off"
                      onChange={this.onChange("insuranceId")}
                      value={insuranceId}
                      select
                      SelectProps={{
                        required: true,
                      }}
                      fullWidth
                      onBlur={() =>
                        insuranceIdError && insuranceId
                          ? this.setState({ insuranceIdError: false })
                          : null
                      }
                      helperText={insuranceIdError ? "Please select an insurer" : null}
                    >
                      <MenuItem value="">Select insurance company</MenuItem>
                      {providers?.map((provider) => {
                        return (
                          <MenuItem key={provider.id} value={provider.id}>
                            {provider.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Name of Plan*
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      autoComplete="off"
                      onChange={this.onChange("name")}
                      value={name}
                      fullWidth
                      placeholder="Enter name of plan"
                      onBlur={() =>
                        nameError && name ? this.setState({ nameError: false }) : null
                      }
                      helperText={nameError ? "Please enter a plan name" : null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Medicaid Plan?*
                    </FormLabel>
                    <RadioGroup
                      aria-label="isMedicaid"
                      name="isMedicaid"
                      value={isMedicaid}
                      onChange={this.onRadioButtonChange("isMedicaid")}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="Yes"
                        size="small"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="No"
                        size="small"
                      />
                    </RadioGroup>
                    {isMedicaidError && (
                      <Typography component="p" className={classes.helperText}>
                        Please select an option
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Location Modifier*
                    </FormLabel>
                    <RadioGroup
                      aria-label="locationModifier"
                      name="locationModifier"
                      value={locationModifier}
                      onChange={this.onRadioButtonChange("locationModifier")}
                      row
                      style={{ marginBottom: 5 }}
                    >
                      <FormControlLabel
                        value={"2"}
                        control={<Radio color="primary" />}
                        label="2"
                        size="small"
                      />
                      <FormControlLabel
                        value={"11"}
                        control={<Radio color="primary" />}
                        label="11"
                        size="small"
                      />
                      <FormControlLabel
                        value={"12"}
                        control={<Radio color="primary" />}
                        label="12"
                        size="small"
                      />
                    </RadioGroup>
                    {locationModifierError && (
                      <Typography component="p" className={classes.helperText}>
                        Please select an option
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Provider Modifier*
                    </FormLabel>

                    <TextField
                      id="providerModifier"
                      name="providerModifier"
                      autoComplete="off"
                      onChange={this.onChange("providerModifier")}
                      value={providerModifier}
                      select
                      SelectProps={{
                        required: true,
                      }}
                      fullWidth
                      style={{ marginBottom: 10 }}
                      onBlur={() =>
                        providerModifierError && providerModifier
                          ? this.setState({
                              providerModifierError: false,
                            })
                          : null
                      }
                      helperText={providerModifierError ? "Please select a modifier" : null}
                    >
                      <MenuItem disabled value="">
                        Select provider modifier
                      </MenuItem>
                      <MenuItem value="HO">HO</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Telehealth Modifier*
                    </FormLabel>
                    <RadioGroup
                      aria-label="telehealthModifier"
                      name="telehealthModifier"
                      value={telehealthModifier}
                      onChange={this.onRadioButtonChange("telehealthModifier")}
                      row
                      style={{ marginBottom: 5 }}
                    >
                      <FormControlLabel
                        value={"GT"}
                        control={<Radio color="primary" />}
                        label="GT"
                        size="small"
                      />
                      <FormControlLabel
                        value={"95"}
                        control={<Radio color="primary" />}
                        label="95"
                        size="small"
                      />
                    </RadioGroup>
                    {telehealthModifierError && (
                      <Typography component="p" className={classes.helperText}>
                        Please select an option
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Payer ID*
                    </FormLabel>
                    <TextField
                      id="payerId"
                      name="payerId"
                      autoComplete="off"
                      onChange={this.onChange("payerId")}
                      value={payerId}
                      fullWidth
                      placeholder="Enter a payer id"
                      onBlur={() =>
                        payerIdError && payerId ? this.setState({ payerIdError: false }) : null
                      }
                      helperText={payerIdError ? "Please enter a payer Id" : null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Coverage State*
                    </FormLabel>
                    <TextField
                      id="coverageState"
                      name="coverageState"
                      autoComplete="off"
                      onChange={this.onChange("coverageState")}
                      value={coverageState}
                      select
                      SelectProps={{
                        required: true,
                      }}
                      fullWidth
                      style={{ marginBottom: 10 }}
                      onBlur={() =>
                        coverageStateError && coverageState != ""
                          ? this.setState({
                              coverageStateError: false,
                            })
                          : null
                      }
                      helperText={coverageStateError ? "Select Coverage State" : null}
                    >
                      <MenuItem disabled value="">
                        Select coverage state
                      </MenuItem>
                      {Object.keys(COVERAGE_STATES).map((key) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {COVERAGE_STATES[key]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl margin="none" fullWidth>
                      <FormLabel component="legend" className={classes.formLabel}>
                        Plan Address
                      </FormLabel>
                    </FormControl>
                    <TextField
                      id="address1"
                      name="address1"
                      autoComplete="off"
                      onChange={this.onChange("address1")}
                      value={address1}
                      fullWidth
                      placeholder="Address line 1*"
                      onBlur={() =>
                        address1Error && address1 ? this.setState({ address1Error: false }) : null
                      }
                      helperText={address1Error ? "Please enter an address" : null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address2"
                      name="address2"
                      autoComplete="off"
                      onChange={this.onChange("address2")}
                      value={address2}
                      fullWidth
                      placeholder="Address line 2"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={this.onChange("city")}
                      value={city}
                      fullWidth
                      placeholder="City*"
                      onBlur={() =>
                        cityError && city ? this.setState({ cityError: false }) : null
                      }
                      helperText={cityError ? "Please enter a city" : null}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="state"
                      name="state"
                      autoComplete="off"
                      onChange={this.onChange("state")}
                      value={state}
                      fullWidth
                      select
                      onBlur={() =>
                        stateError && state ? this.setState({ stateError: false }) : null
                      }
                      helperText={stateError ? "Please select a state" : null}
                    >
                      <MenuItem disabled value={""}>
                        State*
                      </MenuItem>
                      {Object.keys(STATES).map((key) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {STATES[key]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="zip"
                      name="zip"
                      autoComplete="off"
                      onChange={this.onNumberChange("zip")}
                      inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                      value={zip}
                      fullWidth
                      placeholder="ZIP Code*"
                      onBlur={() =>
                        zip && zip.trim().length === 5
                          ? this.setState({ zipError: false })
                          : this.setState({ zipError: true })
                      }
                      helperText={zipError ? "Please enter a valid ZIP Code" : null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      name="phone"
                      autoComplete="off"
                      onChange={this.onChangePhone("phone")}
                      value={phone}
                      fullWidth
                      placeholder="Phone Number*"
                      onBlur={() =>
                        phone && phone.trim().match(/\d/g).length === 10
                          ? this.setState({ phoneError: false })
                          : this.setState({ phoneError: true })
                      }
                      helperText={phoneError ? "Please enter a valid phone number" : null}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ margin: "0px 15px 15px 0" }}>
            <Button onClick={this.onClose} variant="outlined" disabled={createPlanLoading}>
              Cancel
            </Button>
            <LoadingButton onClick={this.onClickSave} loading={createPlanLoading}>
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {successToastOpen && (
          <div className={classes.snackbarContainer}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={successToastOpen}
              classes={{ anchorOriginTopCenter: classes.snackbar }}
              onClose={() => this.setState({ successToastOpen: false })}
            >
              <Alert elevation={6} variant="outlined" severity="success">
                <Typography
                  component="p"
                  style={{ fontSize: 16, fontWeight: 500, color: "#047857" }}
                >
                  New insurance plan has been added
                </Typography>
              </Alert>
            </Snackbar>
          </div>
        )}
      </>
    );
  }
}

export default CreatePlanDialog;
