export default (theme) => ({
  closeIcon: {
    position: "absolute",
    top: 20,
    left: 20,
    [theme.breakpoints.down("xl")]: {
      top: 10,
      left: 10,
    },
    [theme.breakpoints.down("xl")]: {
      top: 5,
      left: 5,
    },
  },
  scheduleContainer: {
    margin: "30px 140px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    [theme.breakpoints.down("xl")]: {
      margin: "30px 75px",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "20px 10px",
    },
  },
  scheduleHeader: {
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down("xl")]: {
      marginTop: 30,
    },
  },
  title: {
    color: "#1c0d28",
    fontSize: 20,
    lineHeight: "20px",
    letterSpacing: "-2%",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
    },
  },
  subtitle: {
    color: "#391a51",
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 500,
    marginTop: 10,
    wordSpacing: "2px",
  },
  selectionsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  topSelections: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "15px 0",
    "& legend": {
      marginBottom: 10,
      color: "#3f4456",
      fontSize: 15,
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      padding: 0,
      "& legend": {
        marginTop: 15,
      },
    },
  },
  dateSelectionContainer: {
    width: "30%",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
    },
  },
  clinicianSelectionContainer: {
    width: "25%",
    marginLeft: "18%",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
      marginLeft: 0,
    },
  },
  bottomSelections: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 0",
    "& legend": {
      marginBottom: 10,
      color: "#3f4456",
      fontSize: 15,
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      padding: 0,
      "& legend": {
        marginTop: 15,
      },
    },
  },
  timezoneSelectionContainer: {
    width: "40%",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
    },
  },
  locationSelectionContainer: {
    width: "45%",
    [theme.breakpoints.down("xl")]: {
      width: "80%",
    },
  },
  locationRadioGroup: {
    width: "100%",
    padding: "10px 0 10px 20px",
    border: "1px solid #C3C6D2",
    borderRadius: 7,
  },
  sessionTypeSelectionContainer: {
    width: "25%",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
    },
  },
  sessionDurationSelectionContainer: {
    width: "19%",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
    },
  },
  availabilityContainer: {
    alignSelf: "center",
    width: "85%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 15px",
    backgroundColor: "#fafafa",
    border: "0.5px solid #C3C6D2",
    borderRadius: 7,
    margin: "20px 0px",
  },
  infoText: {
    fontSize: 15,
  },
  availabilitySubHeader: {
    fontSize: 15,
    color: "#2a2d39",
    fontWeight: 500,
    letterSpacing: "2%",
    alignSelf: "start",
    marginLeft: 20,
    "& span": {
      fontWeight: 500,
    },
  },
  timeOptionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      justifyContent: "space-evenly",
      width: "90%",
      padding: 0,
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  timeOption: {
    width: "18%",
    height: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: "#FFF",
    border: "0.5px solid #A5AABC",
    boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
    margin: 10,
    cursor: "pointer",
    "& p": {
      fontSize: 15,
      lineHeight: "26px",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("xl")]: {
      width: "30%",
    },
  },
  timeOptionSelected: {
    width: "18%",
    height: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: " #A568D5",
    border: "1px solid #8E42CA",
    boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
    margin: 10,
    cursor: "pointer",
    "& p": {
      fontSize: 15,
      lineHeight: "26px",
      color: "#fff",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("xl")]: {
      width: "30%",
    },
  },
  noteText: {
    color: "#78350F",
    fontSize: 14,
    fontWeight: 500,
    alignSelf: "start",
    marginLeft: 20,
  },
  repeatingEventCheckbox: {
    width: "100%",
    alignSelf: "center",
    [theme.breakpoints.down("xl")]: {
      width: "85%",
    },
  },
  repeatingEventLabel: {
    color: "2A2D39",
    fontWeight: 500,
    fontSize: 15,
    marginLeft: 15,
  },
  buttonContainer: {
    width: "85%",
    margin: "0px auto",
    marginTop: 30,
    textAlign: "right",
    "& button": {
      textTransform: "uppercase",
    },
  },
  timePickerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
    width: "100%",
    "& p": {
      fontWeight: 500,
    },
  },
  timepanel: {
    zIndex: 3001,
  },
  repeatSelect: {
    alignSelf: "start",
    margin: "10px",
    marginLeft: 50,
    [theme.breakpoints.down("xl")]: {
      width: "70%",
      alignSelf: "center",
      marginLeft: 0,
    },
    [theme.breakpoints.down("xl")]: {
      width: "85%",
    },
  },
  dayOptionsContainer: {
    width: "85%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 15,
    padding: 10,
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      marginTop: 0,
    },
  },
  repeatEndTypeContainer: {
    width: "100%",
    alignSelf: "start",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 50,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: 0,
    },
  },
  repeatEndDatePicker: {
    width: "40%",
    [theme.breakpoints.down("xl")]: {
      margin: 10,
      marginLeft: "-3px",
      width: "70%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "85%",
    },
  },
  repeatWeeksContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  repeatNumberOfWeeksTextfield: {
    width: "max-content",
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  instructionsContainer: {
    marginTop: 10,
    width: "85%",
    alignSelf: "center",
    "& p": {
      margin: "10px 0px",
      color: "#3F4456",
      fontSize: 15,
      lineHeight: "20px",
      fontWeight: 500,
    },
  },
  instructionsTextfield: {
    backgroundColor: "#fafafa",
    "& fieldset": {
      borderRadius: 7,
      border: "0.5px solid #C3C6D2",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      color: "#69718F",
      fontWeight: 500,
      letterSpacing: "-2%",
      "&:hover fieldset": {
        border: "1px solid #C3C6D2",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C3C6D2",
      },
    },
  },
  confirmationHeaderContainer: {
    backgroundColor: "#e1e3e9",
    padding: "15px 10%",
    "& h5": {
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "22px",
      color: "#065f46",
    },
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "18px",
      color: "#3f4456",
    },
  },
  confirmationInfoContainer: {
    width: "80%",
    margin: "20px auto",
    display: "flex",
    justifyContent: "flex-start",
    gap: 30,
    "& div": {
      width: "33%",
      "& :first-child": {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: "18px",
        color: "#3f4456",
      },
      "& :nth-child(2)": {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: "18px",
        color: "#2a2d39",
      },
    },
  },
  confirmationDateInfoContainer: {
    width: "80%",
    margin: "10px auto",
    "& div": {
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      "& :first-child": {
        fontSize: 18,
        marginRight: 10,
        color: "#3f4456",
      },
      "& :nth-child(2)": {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: "20px",
        color: "#2a2d39",
      },
    },
  },
  confirmationButtonsContainer: {
    width: "90%",
    textAlign: "right",
    margin: "0px auto 20px",
  },
  clinicianLoading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
