import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";

import {
  getCreatePlanLoading,
  getCreatePlanSuccess,
  getCreateProviderLoading,
  getCreateProviderSuccess,
  getInsuranceListProviderId,
  getInsuranceListState,
  getInsuranceLoading,
  getInsurancePlans,
  getInsurancePlansCount,
  getInsurancePlansPage,
  getInsurancePlansRowsPerPage,
  getInsurancePlansSort,
  getInsurancePlansSortDirection,
  getInsuranceProvidersList,
  getInsuranceSuccess,
} from "selectors";
import InsuranceUtilityTable from "./InsuranceUtilityTable";
import { CustomInput } from "elements/DEPRECATED_elements/CustomInput/v1";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { COVERAGE_STATES, GLOBAL_STATES } from "../../constants";
import CreatePlanDialog from "./CreatePlanDialog";
import CreateProviderDialog from "./CreateProviderDialog";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const mapStateToProps = (state) => ({
  insurancePlans: getInsurancePlans(state),
  count: getInsurancePlansCount(state),
  page: getInsurancePlansPage(state),
  rowsPerPage: getInsurancePlansRowsPerPage(state),
  sort: getInsurancePlansSort(state),
  sortDirection: getInsurancePlansSortDirection(state),
  loading: getInsuranceLoading(state),
  success: getInsuranceSuccess(state),
  insuranceListState: getInsuranceListState(state),
  insuranceListProviderId: getInsuranceListProviderId(state),
  providers: getInsuranceProvidersList(state),
  createPlanLoading: getCreatePlanLoading(state),
  createPlanSuccess: getCreatePlanSuccess(state),
  createProviderLoading: getCreateProviderLoading(state),
  createProviderSuccess: getCreateProviderSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInsurancePlans: actions.getInsurancePlans,
      getInsuranceProviders: actions.getInsuranceProvidersList,
      createInsurancePlan: actions.createInsurancePlan,
      updateInsurancePlan: actions.updateInsurancePlan,
      createInsuranceProvider: actions.createInsuranceProvider,
      updateInsuranceProvider: actions.updateInsuranceProvider,
      setInsuranceListState: actions.setInsuranceListState,
      setInsuranceListProviderId: actions.setInsuranceListProviderId,
      clearInsuranceListFilters: actions.clearInsuranceListFilters,
      setPage: actions.setInsurancePlansPage,
      setRowsPerPage: actions.setInsurancePlansRowsPerPage,
      setSort: actions.setInsurancePlansSort,
    },
    dispatch,
  );

class InsuranceUtility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createProviderDialogOpen: false,
      createPlanDialogOpen: false,
      states: [],
    };
  }

  componentDidMount() {
    this.props.getInsurancePlans();
    this.props.getInsuranceProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.sort !== this.props.sort ||
      prevProps.page !== this.props.page ||
      prevProps.sortDirection !== this.props.sortDirection ||
      prevProps.rowsPerPage !== this.props.rowsPerPage ||
      prevProps.insuranceListState !== this.props.insuranceListState ||
      prevProps.insuranceListProviderId !== this.props.insuranceListProviderId
    ) {
      this.props.getInsurancePlans();
    }
    if (
      prevProps.insurancePlans?.length == 0 &&
      this.props.insurancePlans?.length > 0 &&
      this.state.states.length == 0
    ) {
      let allStates = this.props.insurancePlans[0]?.all_states?.split(',') ?? [];
      allStates.unshift("NATIONAL");

      const states = allStates
        ?.map((plan) => {
          return plan.trim();
        })
        ?.filter((x, i, a) => a.indexOf(x) == i);
      this.setState({ states });
    }
  }

  componentWillUnmount() {
    this.props.clearInsuranceListFilters();
  }

  handleSortClick = (name) => {
    this.props.setSort({
      sort: name,
    });
  };

  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setRowsPerPage(parseInt(event.target.value));
    this.props.setPage(0);
  };

  onChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  onToggleCreatePlanDialog = () => {
    this.setState({ createPlanDialogOpen: !this.state.createPlanDialogOpen });
  };

  onToggleCreateProviderDialog = () => {
    this.setState({
      createProviderDialogOpen: !this.state.createProviderDialogOpen,
    });
  };

  onCreateNewInsurancePlan = (plan) => {
    this.props.createInsurancePlan(plan);
    this.addCoverageStateIfMissing(plan);
  };

  addCoverageStateIfMissing(plan) {
    // Check if the coverageState from the plan is not included in the states array
    if (!this.state.states.includes(plan.coverageState)) {
      // Add coverageState to the states array
      this.setState({
        states: [...this.state.states, plan.coverageState].sort()
      });      
    }
  }

  onCreateNewInsuranceProvider = (provider) => {
    this.props.createInsuranceProvider(provider);
  };

  render() {
    const {
      loading,
      classes,
      providers,
      insuranceListState,
      insuranceListProviderId,
      insurancePlans,
    } = this.props;

    const { states, createPlanDialogOpen } = this.state;

    return (
      <>
        <div className={classes.header}>
          <div className={classes.headerButtonContainer}>
            <TextField
              id="insuranceListState"
              name="insuranceListState"
              autoComplete="no"
              onChange={(e) => this.props.setInsuranceListState(e.target.value)}
              value={insuranceListState}
              label="State"
              select
              style={{ marginRight: 10, width: 256 }}
              SelectProps={{
                required: true,
              }}
            >
              <MenuItem value="">All</MenuItem>
              {states?.map((state, i) => {
                return (
                  <MenuItem key={i} value={state}>
                    {GLOBAL_STATES[state]}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              id="insuranceListProviderId"
              name="insuranceListProviderId"
              autoComplete="off"
              onChange={(e) => this.props.setInsuranceListProviderId(e.target.value)}
              value={insuranceListProviderId}
              label="Insurance Name"
              select
              style={{ width: 256 }}
              SelectProps={{
                required: true,
              }}
            >
              <MenuItem value="">All</MenuItem>
              {providers?.map((provider) => {
                return (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className={classes.headerButtonContainer}>
            <Button
              onClick={this.onToggleCreateProviderDialog}
              color="secondary"
              style={{ marginRight: 10 }}
              startIcon={<AddIcon />}
            >
              Insurance
            </Button>
            <Button
              onClick={this.onToggleCreatePlanDialog}
              color="secondary"
              startIcon={<AddIcon />}
            >
              Plan
            </Button>
          </div>
        </div>
        <InsuranceUtilityTable
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSortClick={this.handleSortClick}
          {...this.props}
          {...this.state}
        />
        <CreatePlanDialog
          {...this.state}
          {...this.props}
          onToggleCreatePlanDialog={this.onToggleCreatePlanDialog}
          onCreateNewInsurancePlan={this.onCreateNewInsurancePlan}
        />
        <CreateProviderDialog
          {...this.state}
          {...this.props}
          onToggleCreateProviderDialog={this.onToggleCreateProviderDialog}
          onCreateNewInsuranceProvider={this.onCreateNewInsuranceProvider}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsuranceUtility));
