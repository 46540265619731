import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import { format, addMinutes } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Button from "@mui/material/Button";
import ConfirmStartCallDialog from "../components/ClinicianDashboard/ConfirmStartCallDialog";
import ManageCallDialog from "../components/ClinicianDashboard/ManageCallDialog";
import PatientTherapyProgress from "./PatientTherapyProgress";
import SectionContainer from "./SectionContainer";
import {
  getUser,
  getUserId,
  getUserClinicianId,
  getNextClinicianCallSuccess,
  getNextClinicianCallLoading,
  getNextClinicianCall,
  getUserPermissionsList,
} from "../selectors";
import actions from "../actions";
import { v4 as uuidv4 } from "uuid";
import { capitalizeBillingTypeFirstLetter } from "../utils/capitalizeBillingTypeFirstLetter";
import SessionScheduler from "../components/SessionScheduler";
import CancelSessionDialog from "../components/WeeklyCalendarSessions/CancelSessionDialog";
import EditSelectionDialog from "../components/WeeklyCalendarSessions/EditSelectionDialog";
import momentTZ from "moment-timezone";

import { convertTokens } from "@date-fns/upgrade/v2";
import { getCancellationReasonLabel, getKeyCancellationReason } from "../constants/cancelReasons";

const mapStateToProps = (state) => ({
  clinicianUserId: getUserId(state),
  clinician: getUser(state),
  nextCall: getNextClinicianCall(state),
  nextCallSuccess: getNextClinicianCallSuccess(state),
  nextCallLoading: getNextClinicianCallLoading(state),
  userPermissions: getUserPermissionsList(state),
  clinicianId: getUserClinicianId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNextClinicianCall: actions.getNextClinicianCall,
      setOneTimeVideoInfo: actions.setOneTimeVideoInfo,
      cancelVideoCall: actions.cancelVideoCall,
      getClinicianCallsForWeek: actions.getClinicianCallsWeek,
      setVideoCallsType: actions.setVideoCallsType,
      getClinicianUpcomingCalls: actions.getClinicianUpcomingCalls,
      getClinicianClientStatus: actions.getClinicianClientStatus,
    },
    dispatch
  );

class NextSessionBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startCallOpen: false,
      manageCallOpen: false,
      rescheduleCallOpen: false,
      editSelectionOpen: false,
      editAllInstances: false,
      cancelReasonText: "",
      responsibleForCancellation: "",
      cancelSessionOpen: false,
      cancelSelectionOpen: false,
      cancelAllInstances: false,
    };
  }

  componentDidMount() {
    if (this.props.clinicianUserId) {
      this.props.getNextClinicianCall(this.props.clinicianUserId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.clinicianUserId && this.props.clinicianUserId) {
      this.props.getNextClinicianCall(this.props.clinicianUserId);
    }
  }

  onOpenStartCallDialog = () => {
    this.setState({ startCallOpen: true });
  };

  onCloseStartCallDialog = () => {
    this.setState({ startCallOpen: false });
  };

  startVideoCall = () => {
    const { nextCall } = this.props;
    if (nextCall?.off_platform) {
      window.location.replace(`${process.env.DOXY_URL}`);
      return;
    }
    let roomId = uuidv4();
    if (nextCall.room_id) {
      roomId = nextCall.room_id;
    }
    const details = {
      videoInfo: nextCall,
      roomId,
      videoKey: nextCall.video_key,
    };
    this.props.setOneTimeVideoInfo(details);
    this.props.history.push(`/video/${details.videoKey}`);
  };

  onOpenManageCallDialog = () => {
    this.setState({ manageCallOpen: true });
  };

  onCloseManageCallDialog = () => {
    this.setState({ manageCallOpen: false });
  };

  onClickRescheduleSession = (recurringEvent) => {
    if (recurringEvent) {
      this.setState({ editSelectionOpen: true, manageCallOpen: false });
    } else {
      this.setState({ rescheduleCallOpen: true, manageCallOpen: false });
    }
  };

  onCloseRescheduleCallDialog = () => {
    this.setState({
      manageCallOpen: false,
      rescheduleCallOpen: false,
      editAllInstances: false,
    });
    this.props.getNextClinicianCall(this.props.clinicianUserId);
    this.props.getClinicianCallsForWeek(this.props.clinicianUserId);
    this.props.setVideoCallsType("future");
    this.props.getClinicianUpcomingCalls();
    if (this.props.getCalendarUpdate) {
      this.props.getCalendarUpdate();
    }
  };

  onToggleEditSelection = () => {
    this.setState(
      {
        editSelectionOpen: !this.state.editSelectionOpen,
      },
      () => {
        if (!this.state.editSelectionOpen) {
          this.setState({
            editAllInstances: false,
          });
        }
      }
    );
  };

  onChangeEditSelection = (e) => {
    const { value } = e.target;
    this.setState({ editAllInstances: this.stringToBoolean(value) });
  };

  onContinueEditSelection = () => {
    this.setState({ editSelectionOpen: false, rescheduleCallOpen: true });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onClickCancelSession = () => {
    this.setState({ cancelSessionOpen: true, manageCallOpen: false });
  };

  onToggleCancelSession = () => {
    this.setState(
      {
        cancelSessionOpen: !this.state.cancelSessionOpen,
      },
      () => {
        if (!this.state.cancelSessionOpen) {
          this.setState({
            cancelReasonText: "",
            cancelAllInstances: false,
          });
          this.props.getClinicianClientStatus(this.props.clinicianId);
        }
      }
    );
  };

  onToggleCancelSelection = () => {
    this.setState({
      cancelSelectionOpen: !this.state.cancelSelectionOpen,
    });
  };

  onChangeCancelSelection = (e) => {
    const { value } = e.target;
    this.setState({ cancelAllInstances: this.stringToBoolean(value) });
  };

  onContinueCancelSelection = () => {
    this.onToggleCancelSelection();
    this.onToggleCancelSession();
  };

  onChangeCancelReason = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  submitCancelSession = () => {
    const { cancelReasonText, cancelAllInstances, responsibleForCancellation } = this.state;
    const { nextCall } = this.props;
    this.props.cancelVideoCall({
      message: cancelReasonText,
      responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
      id: nextCall.id,
      clinicianUserId: nextCall.clinician_user_id,
      cancelAllInstances,
    });
    this.onToggleCancelSession();
  };

  onChangeCancelReasonText = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    this.setState({
      responsibleForCancellation: e.target.value,
      cancelReasonText,
    });
  };

  formatCallDuration = (duration) => {
    let hours = parseInt(duration / 60);
    let minutes = duration % 60;
    if (hours === 0) {
      return `${minutes} minutes`;
    } else if (hours === 1) {
      return `${hours} hour ${minutes > 0 ? minutes + " minutes" : ""}`;
    } else {
      return `${hours} hours ${minutes > 0 ? minutes + " minutes" : ""}`;
    }
  };

  render() {
    const {
      classes,
      showClientProgress,
      nextCall,
      nextCallLoading,
      nextCallSuccess,
      clinicianUserId,
      loading,
      history,
      userPermissions,
      minHeight,
    } = this.props;

    const callIsExpired = momentTZ(nextCall?.scheduled_date)
      .add(nextCall?.call_duration, "minutes")
      .isBefore(momentTZ());
    const isPrimaryClinician = nextCall?.clinician_user_id === clinicianUserId;
    const canModifyEvent = userPermissions?.select_scheduled_clinician;
    return (
      <SectionContainer
        minHeight={minHeight ? minHeight : null}
        halfWidth={showClientProgress ? false : true}
      >
        <div
          className={classes.sessionInfoContainer}
          style={{ width: showClientProgress ? "42%" : "100%" }}
        >
          <Typography component="p" className={classes.titleText}>
            Next Session
          </Typography>
          {nextCallLoading || !clinicianUserId ? (
            <div className={classes.loading}>
              <CircularProgress style={{ color: "#885FD5" }} size={30} />
            </div>
          ) : (
            nextCall && (
              <div className={classes.callDetails}>
                <Typography component="p" className={classes.sessionDate}>
                  {format(new Date(nextCall.scheduled_date), convertTokens("dddd, D MMM"))}
                  {nextCall?.off_platform
                    ? nextCall.off_platform_type === "video"
                      ? " (Doxy)"
                      : " (Phone Call)"
                    : ""}
                </Typography>
                {userPermissions?.assignable_as_secondary_clinician && (
                  <Typography component="p" className={classes.roleText}>{`Role: ${
                    nextCall.clinician_user_id === clinicianUserId
                      ? "Primary Clinician"
                      : "Supervising Clinician"
                  }`}</Typography>
                )}
                <div className={classes.sessionTimeContainer}>
                  <ScheduleIcon style={{ color: "#8f8f8f", fontSize: 14, marginRight: 5 }} />
                  <Typography component="p" className={classes.callDuration}>
                    {nextCall.call_duration
                      ? `${format(
                          new Date(nextCall.scheduled_date),
                          convertTokens("h:mm aa")
                        )} - ${format(
                          new Date(
                            addMinutes(new Date(nextCall.scheduled_date), nextCall.call_duration)
                          ),
                          convertTokens("h:mm aa")
                        )} -- ${this.formatCallDuration(nextCall.call_duration)}`
                      : format(new Date(nextCall.scheduled_date), convertTokens("h:mm aa"))}
                  </Typography>

                  {showClientProgress &&
                    (userPermissions?.reschedule_call || userPermissions?.cancel_call) &&
                    !nextCall?.start_date &&
                    (isPrimaryClinician || canModifyEvent) &&
                    !callIsExpired && (
                      <Button
                        className={classes.manageCallButton}
                        onClick={this.onOpenManageCallDialog}
                        color="secondary"
                      >
                        Manage Session
                      </Button>
                    )}
                </div>
                {!showClientProgress && (
                  <div className={classes.callTypeContainer}>
                    <Typography component="p" className={classes.callType}>
                      {`Call Type: ${nextCall.off_platform ? "OFF-PLATFORM " : ""}${
                        nextCall.billing_type
                          ? capitalizeBillingTypeFirstLetter(nextCall.billing_type)
                          : ""
                      }`}
                    </Typography>
                    {userPermissions?.view_my_clients && (
                      <Button
                        className={classes.clientDetailsButton}
                        onClick={() => history.push(`/clients/${nextCall.client_id}/dashboard`)}
                        variant="text"
                      >
                        Client Details
                      </Button>
                    )}
                  </div>
                )}
                <div className={classes.participantsContainer}>
                  <div className={classes.callParticipants}>
                    <Typography component="p" className={classes.clientName}>
                      {`Client: ${nextCall.child_name} `}
                      {`${
                        nextCall.child_last_name
                          ? nextCall.child_last_name
                          : nextCall.child_name.split(" ").length === 1
                          ? nextCall.last_name
                          : ""
                      }`}
                    </Typography>
                    {!showClientProgress && (
                      <Typography component="p" className={classes.guardianName}>
                        {`Guardian's Name: ${nextCall.parent_name} ${nextCall.last_name}`}
                      </Typography>
                    )}
                  </div>
                  {!showClientProgress &&
                    (userPermissions?.reschedule_call || userPermissions?.cancel_call) &&
                    (isPrimaryClinician || canModifyEvent) &&
                    !callIsExpired && (
                      <Button
                        className={classes.manageCallButton}
                        onClick={this.onOpenManageCallDialog}
                        color="secondary"
                      >
                        Manage Session
                      </Button>
                    )}
                </div>
                {showClientProgress && (
                  <div className={classes.callTypeContainer}>
                    <Typography component="p" className={classes.callType}>
                      {`Call Type: ${nextCall.off_platform ? "OFF-PLATFORM " : ""}${
                        nextCall.billing_type
                          ? capitalizeBillingTypeFirstLetter(nextCall.billing_type)
                          : ""
                      }`}
                    </Typography>
                    {userPermissions?.view_my_clients && (
                      <Button
                        className={classes.clientDetailsButton}
                        onClick={() => history.push(`/clients/${nextCall.client_id}/dashboard`)}
                        variant="text"
                        color="secondary"
                      >
                        Client Details
                      </Button>
                    )}
                  </div>
                )}
                <div className={classes.divider} />
                <div className={classes.joinCallContainer}>
                  {userPermissions?.start_video_call && nextCall?.off_platform_type !== "phone" && (
                    <Button className={classes.joinCallButton} onClick={this.onOpenStartCallDialog}>
                      Join{nextCall?.off_platform ? " Doxy" : ""} Call
                    </Button>
                  )}
                </div>
              </div>
            )
          )}
          {!nextCallLoading && !nextCall && nextCallSuccess && (
            <div className={classes.callDetails}>
              <Typography
                component="p"
                className={classes.sessionDate}
                style={{ textAlign: "center", width: "100%" }}
              >
                No Calls Scheduled
              </Typography>
              <div className={classes.divider} />
              <div className={classes.joinCallContainer}>
                {userPermissions?.view_my_clients && (
                  <Button
                    className={classes.joinCallButton}
                    onClick={() => history.push("/my-clients")}
                  >
                    View Client List
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        {this.props.nextCall && (
          <>
            {showClientProgress && (
              <div className={classes.patientProgress}>
                <PatientTherapyProgress />
              </div>
            )}
            <ConfirmStartCallDialog
              onCloseStartCallDialog={this.onCloseStartCallDialog}
              startVideoCall={this.startVideoCall}
              startCallOpen={this.state.startCallOpen}
              callDetails={this.props.nextCall}
            />
            <ManageCallDialog
              onCloseManageCallDialog={this.onCloseManageCallDialog}
              onClickRescheduleSession={this.onClickRescheduleSession}
              videoCall={this.props.nextCall}
              onClickCancelSession={this.onClickCancelSession}
              {...this.state}
              {...this.props}
            />
            <EditSelectionDialog
              sessionDetails={nextCall}
              open={this.state.editSelectionOpen}
              editAllInstances={this.state.editAllInstances}
              closeDialog={this.onToggleEditSelection}
              onChangeEditSelection={this.onChangeEditSelection}
              onContinueEditSelection={this.onContinueEditSelection}
            />
            {this.state.rescheduleCallOpen && (
              <SessionScheduler
                open={this.state.rescheduleCallOpen}
                closeDialog={this.onCloseRescheduleCallDialog}
                isClinician={true}
                rescheduleCall={true}
                rescheduleDetails={nextCall}
                allowOverride={userPermissions?.override_clinician_schedule}
                rescheduleAllInstances={this.state.editAllInstances}
                demoCall={nextCall?.is_test}
              />
            )}
            <CancelSessionDialog
              sessionDetails={nextCall}
              open={this.state.cancelSessionOpen}
              cancelReasonText={this.state.cancelReasonText}
              responsibleForCancellation={this.state.responsibleForCancellation}
              closeDialog={this.onToggleCancelSession}
              onChangeCancelReason={this.onChangeCancelReason}
              submitCancelSession={this.submitCancelSession}
              onChangeResponsibleForCancellation={this.onChangeResponsibleForCancellation}
              cancelAllInstances={this.state.cancelAllInstances}
              onChangeCancelSelection={this.onChangeCancelSelection}
            />
          </>
        )}
      </SectionContainer>
    );
  }
}

const styles = (theme) => ({
  titleText: {
    fontSize: 18,
    color: "#595959",
    fontWeight: 500,
    marginBottom: 10,
  },
  sessionInfoContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  callDetails: {
    // position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  sessionDate: {
    fontSize: 20,
    color: "#444444",
    fontWeight: 500,
  },
  sessionTimeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  roleText: {
    fontSize: 14,
    color: "#737373",
    margin: "3px 0",
    fontWeight: 500,
  },
  callDuration: {
    fontSize: 13,
    color: "#878787",
    fontWeight: 500,
  },
  manageCallButton: {
    marginLeft: "auto",
    "& span": {
      fontSize: 13,
    },
  },
  participantsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  callParticipants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  clientName: {
    lineHeight: "18px",
    fontSize: 18,
    color: "#595959",
    fontWeight: 500,
  },
  guardianName: {
    fontSize: 12,
    color: "#878787",
    marginTop: 3,
  },
  callTypeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  callType: {
    fontSize: 14,
    color: "#878787",
  },
  clientDetailsButton: {
    "& span": {
      fontSize: 13,
      color: "#878787",
    },
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid #ababab",
    margin: "15px 0",
  },
  joinCallContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  joinCallButton: {
    width: "60%",
  },
  loading: {
    display: "flex",
    width: "100%",
    height: 170,
    justifyContent: "center",
    alignItems: "center",
  },
  patientProgress: {
    width: "54%",
    height: "100%",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NextSessionBlock));
