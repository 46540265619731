import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { Tools } from "./HeaderSections/ToolsMobile";
import { BreadBox, PageTitle } from "./HeaderSections";
import { useSelector } from "react-redux";
import Divider from "elements/Divider";

/**
 *
 * @param {{
 *  handleSearchChange: Function,
 *  handleMenuClicked: Function,
 *  handleAvatarClicked: Function,
 *  handleBellClicked: Function,
 *  pageTitle: String,
 *  userAvatarSrc: String,
 *  menuExpanded: Boolean,
 *  unreadNotifications: Number,
 *  menuItems: Array<{ value: String, label: String, clickHandler: Function}>,
 *  breadcrumbs: Array,
 *  contextButtons Any
 * }} props
 * @returns
 */
export const HeaderMobile = (props) => {
  const {
    // handlers
    handleMenuClicked,
    handleBellClicked,
    handleDrawerToggle,
    // values
    menuExpanded,
    userAvatarSrc,
    unreadNotifications,
    menuItems,
    contextButtons,
    userInitials,
  } = props;
  const pageTitle = useSelector((state) => state.system.pageName);
  const hideDivider = useSelector((state) => state.system?.hideDivider ?? false);
  const altTitle = useSelector((state) => state.system?.altTitle);
  const customContextButtons = useSelector((state) => state.system?.customContextButtons);
  const onEditButton = useSelector((state) => state.system?.onEditButton ?? false);
  const editButtonAction = useSelector((state) => state.system?.editButtonAction);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: {
            xs: "flex",
            lg: "none",
            backgroundColor: "#552879",
            color: "#D2B3EA",
          },
        }}
      >
        <Toolbar
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 64,
          }}
        >
          <Tools
            handleBellClicked={handleBellClicked}
            unreadNotifications={unreadNotifications}
            userAvatarSrc={userAvatarSrc}
            menuExpanded={menuExpanded}
            menuItems={menuItems}
            handleMenuClicked={handleMenuClicked}
            userInitials={userInitials}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "block", width: "100%", padding: "80px 16px 0" }}>
        <PageTitle
          pageTitle={altTitle ?? pageTitle}
          contextButtons={customContextButtons ?? contextButtons}
          hideDivider={hideDivider}
          onEditButton={onEditButton}
          editButtonAction={editButtonAction}
        />
        {!hideDivider && <Divider />}
      </Box>
    </>
  );
};
