import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CaretDown, PencilSimpleLine, DownloadSimple, Play } from "@phosphor-icons/react";
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useVideoPlayer } from "components/VideoPlayer/useVideoPlayer";
import VideoPlayer from "components/VideoPlayer";
import { format } from "date-fns";

const ViewActions = (props) => {
  const {
    needsEdits,
    noteCompleted,
    userPermissions,
    approved,
    onToggleRequestChangesDialog,
    approveNote,
    approveTimeLoading,
    viewOnly,
    userId,
    clinicianUserId,
    locked,
    setViewOnly,
    associatedSessionNotes,
    totalCallTime,
    downloadViewOnly,
    downloadAllSessionNotes,
    onToggleExitAdhocDialog,
    videoId,
    serviceType,
    clientName,
    startDate,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const modalControl = useVideoPlayer();

  const timeCoded = associatedSessionNotes.reduce((a, c) => {
    return c.actual_time ? a + parseInt(c.actual_time) : a;
  }, 0);

  const showEdit =
    (associatedSessionNotes.some((note) => !note.locked) || timeCoded !== totalCallTime) &&
    userId === clinicianUserId &&
    !downloadViewOnly;

  const handleWatchVideoCall = () => {
    modalControl.openModal();
    handleClose();
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formattedDate = startDate ? format(new Date(startDate), "MMMM d, yyyy") : "";

  return (
    <>
      {showEdit && (
        <Button
          color="secondary"
          onClick={() => setViewOnly(false)}
          startIcon={<PencilSimpleLine size={20} color="rgba(0, 0, 0, 0.87)" />}
        >
          Edit
        </Button>
      )}
      {userPermissions?.approve_off_platform_billing &&
        noteCompleted &&
        !needsEdits &&
        !approved &&
        !downloadViewOnly && (
          <>
            <Button
              color="secondary"
              style={{ marginRight: 10 }}
              onClick={onToggleRequestChangesDialog}
              startIcon={<PencilSimpleLine size={20} color="rgba(0, 0, 0, 0.87)" />}
            >
              Suggest Edits
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={approveNote}
              loading={approveTimeLoading}
            >
              Approve Service
            </LoadingButton>
          </>
        )}
      {(downloadViewOnly || (noteCompleted && approved && !showEdit)) &&
        userPermissions?.download_session_notes && (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
              <Button
                sx={{ marginRight: "8px" }}
                variant="contained"
                color="secondary"
                endIcon={<CaretDown />}
                onClick={handleMoreClick}
              >
                More
              </Button>
              <Button variant="contained" onClick={onToggleExitAdhocDialog}>
                Close
              </Button>
            </div>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={downloadAllSessionNotes}>
                <ListItemIcon>
                  <DownloadSimple />
                </ListItemIcon>
                <ListItemText primary="Download Notes" />
              </MenuItem>
              <MenuItem
                onClick={handleWatchVideoCall}
                disabled={!userPermissions?.view_client_video_session}
              >
                <ListItemIcon>
                  <Play />
                </ListItemIcon>
                <ListItemText primary="Watch Video Call" />
              </MenuItem>
            </Menu>
          </>
        )}
      <VideoPlayer
        videoPlayerTitle={`${serviceType?.description} with ${clientName} - ${formattedDate}`}
        videoId={videoId}
        asModal={true}
        modalControl={modalControl}
      />
    </>
  );
};

export default ViewActions;
