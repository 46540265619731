import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EventTracking from "../../utils/eventtracking";
import actions from "../../actions";
import styles from "../Signin/styles";
import withStyles from "@mui/styles/withStyles";

import {
  getSignedinState,
  getResetPasswordSuccess,
  getResetPasswordError,
  getResetPasswordErrorMessage,
} from "../../selectors";

import ResetPassword from "./ResetPassword";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  resetPasswordSuccess: getResetPasswordSuccess(state),
  resetPasswordError: getResetPasswordError(state),
  resetPasswordErrorMessage: getResetPasswordErrorMessage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword: actions.resetPassword,
      clearResetPassword: actions.clearResetPassword,
      checkAuthState: actions.checkAuthState,
      setPageDetails: actions.setPageDetails,
      startSignin: actions.startSignin,
    },
    dispatch,
  );

function getInitialState() {
  return {
    username: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
    upperCase: false,
    lowerCase: false,
    includesNumber: false,
    properLength: false,
    showPassword: false,
    showConfirmPassword: false,
    passwordError: false,
    passwordsMatchError: false,
  };
}

class ResetPasswordContainer extends Component {
  state = {
    ...getInitialState(),
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Reset your password?",
    });
    if (this.props.signedInState) {
      this.goToChat();
      return;
    }
    let username = "";
    let verificationCode = "";

    const params = new URLSearchParams(this.props.location.search);
    if (params.get("email")) {
      username = params.get("email").replace(/ /g, "+");
    }
    if (params.get("code")) {
      verificationCode = params.get("code");
    }
    this.setState({
      username,
      verificationCode,
    });

    this.tracking = new EventTracking();
    this.tracking.pageView();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.signedInState && this.props.signedInState) {
      this.goToDashboard();
    }
    if (!prevProps.resetPasswordSuccess && this.props.resetPasswordSuccess) {
      this.props.startSignin({
        username: this.state.username,
        password: this.state.password,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearResetPassword();
  }

  goToChat() {
    this.props.history.push("/chat");
  }

  goToDashboard() {
    this.props.history.push("/dashboard");
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    if (name === "password") {
      this.validatePassword(value);
    }

    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.resetPassword({
      ...this.state,
    });
  };

  validatePassword = (password) => {
    const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
    const lowerCaseRegex = new RegExp("^(?=.*[a-z])");
    const numberRegex = new RegExp("^(?=.*[0-9])");
    const lengthRegex = new RegExp("^(?=.{8,})");

    const upperCase = upperCaseRegex.test(password);
    const lowerCase = lowerCaseRegex.test(password);
    const includesNumber = numberRegex.test(password);
    const properLength = lengthRegex.test(password);
    this.setState({
      upperCase,
      lowerCase,
      includesNumber,
      properLength,
    });
  };

  goToSignin = () => {
    this.props.history.push("/");
  };

  toggleShowPasswords = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleShowConfirmPasswords = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setPasswordError = (val) => {
    this.setState({ passwordError: val });
  };

  setPasswordsMatch = (val) => {
    this.setState({ passwordsMatchError: val });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ResetPassword
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          handleMouseDownPassword={this.handleMouseDownPassword}
          toggleShowPasswords={this.toggleShowPasswords}
          toggleShowConfirmPasswords={this.toggleShowConfirmPasswords}
          setPasswordError={this.setPasswordError}
          setPasswordsMatch={this.setPasswordsMatch}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer),
);
