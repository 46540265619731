const ACTIONS = {
  GET_PARENT_INFO: "GET_PARENT_INFO",
  GET_PARENT_INFO_SUCCESS: "GET_PARENT_INFO_SUCCESS",
  SHOW_WELCOME: "SHOW_WELCOME",
  HIDE_WELCOME: "HIDE_WELCOME",
  SHOW_PAYMENT: "SHOW_PAYMENT",
  HIDE_PAYMENT: "HIDE_PAYMENT",
  SHOW_REFERRAL_BANNER: "SHOW_REFERRAL_BANNER",
  HIDE_REFERRAL_BANNER: "HIDE_REFERRAL_BANNER",
  GET_USER_CLINICIAN: "GET_USER_CLINICIAN",
  GET_USER_CLINICIAN_SUCCESS: "GET_USER_CLINICIAN_SUCCESS",
  GET_CLINICIAN_BIO: "GET_CLINICIAN_BIO",
  GET_CLINICIAN_BIO_SUCCESS: "GET_CLINICIAN_BIO_SUCCESS",
  GET_CLINICIAN_SPECIALTIES: "GET_CLINICIAN_SPECIALTIES",
  GET_CLINICIAN_SPECIALTIES_SUCCESS: "GET_CLINICIAN_SPECIALTIES_SUCCESS",
  CLINICIAN_SELECTED: "CLINICIAN_SELECTED",
  CLINICIAN_REFERRED: "CLINICIAN_REFERRED",
  CLEAR_CLINICIAN: "CLEAR_CLINICIAN",
  CLEAR_DISPLAY_CLINICIAN: "CLEAR_DISPLAY_CLINICIAN",
  CLEAR_QUESTIONNAIRE_ANSWERS: "CLEAR_QUESTIONNAIRE_ANSWERS",
  SAVE_PROFILE: "SAVE_PROFILE",
  GET_DASHBOARD: "GET_DASHBOARD",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  CLEAR_DASHBOARD: "CLEAR_DASHBOARD",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_FAIL: "CANCEL_SUBSCRIPTION_FAIL",
  GET_CANCEL_REASONS: "GET_CANCEL_REASONS",
  GET_CANCEL_REASONS_SUCCESS: "GET_CANCEL_REASONS_SUCCESS",
  AGREE_TO_POLICIES: "AGREE_TO_POLICIES",
  AGREE_TO_POLICIES_SUCCESS: "AGREE_TO_POLICIES_SUCCESS",
  AGREE_TO_PHI_RELEASE: "AGREE_TO_PHI_RELEASE",
  SHOW_ABA_INTEREST_DIALOG: "SHOW_ABA_INTEREST_DIALOG",
  HIDE_ABA_INTEREST_DIALOG: "HIDE_ABA_INTEREST_DIALOG",
  GET_ACTIVE_TREATMENT_PLAN: "GET_ACTIVE_TREATMENT_PLAN",
  GET_ACTIVE_TREATMENT_PLAN_SUCCESS: "GET_ACTIVE_TREATMENT_PLAN_SUCCESS",
  APPROVE_TREATMENT_PLAN: "APPROVE_TREATMENT_PLAN",
  APPROVE_TREATMENT_PLAN_SUCCESS: "APPROVE_TREATMENT_PLAN_SUCCESS",
  GET_INSURANCE_DASHBOARD: "GET_INSURANCE_DASHBOARD",
  GET_INSURANCE_DASHBOARD_SUCCESS: "GET_INSURANCE_DASHBOARD_SUCCESS",
  UPDATE_FULL_CUSTOMER: "UPDATE_FULL_CUSTOMER",
  UPDATE_FULL_CUSTOMER_SUCCESS: "UPDATE_FULL_CUSTOMER_SUCCESS",
  UPDATE_FULL_CUSTOMER_FAIL: "UPDATE_FULL_CUSTOMER_FAIL",

  UPLOAD_USER_DOCUMENT: "UPLOAD_USER_DOCUMENT",
  UPLOAD_USER_DOCUMENT_SUCCESS: "UPLOAD_USER_DOCUMENT_SUCCESS",
  UPLOAD_USER_DOCUMENT_FAIL: "UPLOAD_USER_DOCUMENT_FAIL",

  GET_INSURANCE_ROADMAP: "GET_INSURANCE_ROADMAP",
  GET_INSURANCE_ROADMAP_SUCCESS: "GET_INSURANCE_ROADMAP_SUCCESS",
  SEND_DOCUMENT_REQUEST_SIGNATURE: "SEND_DOCUMENT_REQUEST_SIGNATURE",
  SEND_DOCUMENT_REQUEST_SIGNATURE_SUCCESS: "SEND_DOCUMENT_REQUEST_SIGNATURE_SUCCESS",
  SEND_DOCUMENT_REQUEST_SIGNATURE_FAIL: "SEND_DOCUMENT_REQUEST_SIGNATURE_FAIL",
  SEND_REJECTION_ELIGIBILITY_INFO: "SEND_REJECTION_ELIGIBILITY_INFO",
  SEND_REJECTION_ELIGIBILITY_INFO_SUCCESS: "SEND_REJECTION_ELIGIBILITY_INFO_SUCCESS",
  LOG_AWS_UPLOAD_ERROR: "LOG_AWS_UPLOAD_ERROR",
  GET_ELIGIBILITY_FIELDS: "GET_ELIGIBILITY_FIELDS",
  GET_ELIGIBILITY_FIELDS_SUCCESS: "GET_ELIGIBILITY_FIELDS_SUCCESS",
  GET_UNSIGNED_DOCUMENT_REQUESTS: "GET_UNSIGNED_DOCUMENT_REQUESTS",
  GET_UNSIGNED_DOCUMENT_REQUESTS_SUCCESS: "GET_UNSIGNED_DOCUMENT_REQUESTS_SUCCESS",
  SIGN_DOCUMENT_REQUEST: "SIGN_DOCUMENT_REQUEST",
  SIGN_DOCUMENT_REQUEST_SUCCESS: "SIGN_DOCUMENT_REQUEST_SUCCESS",
  SIGN_DOCUMENT_REQUEST_FAIL: "SIGN_DOCUMENT_REQUEST_FAIL",

  CHECK_RECAPTCHA_VERIFICATION: " CHECK_RECAPTCHA_VERIFICATION",
  CHECK_RECAPTCHA_VERIFICATION_SUCCESS: " CHECK_RECAPTCHA_VERIFICATION_SUCCESS",
  CHECK_RECAPTCHA_VERIFICATION_FAIL: " CHECK_RECAPTCHA_VERIFICATION_FAIL",

  GET_CUSTOMER_SIGNED_FORM: "GET_CUSTOMER_SIGNED_FORM",
  GET_CUSTOMER_SIGNED_FORM_SUCCESS: "GET_CUSTOMER_SIGNED_FORM_SUCCESS",

  SAVE_SIGNED_CUSTOMER_FORM: "SAVE_SIGNED_CUSTOMER_FORM",
  SAVE_SIGNED_CUSTOMER_FORM_SUCCESS: "SAVE_SIGNED_CUSTOMER_FORM_SUCCESS",
  SAVE_SIGNED_CUSTOMER_FORM_FAIL: "SAVE_SIGNED_CUSTOMER_FORM_FAIL",

  CLEAR_CUSTOMER_FORM: "CLEAR_CUSTOMER_FORM",

  UPDATE_CLIENT_INFO: "UPDATE_CLIENT_INFO",
  UPDATE_CLIENT_INFO_SUCCESS: "UPDATE_CLIENT_INFO_SUCCESS",
  UPDATE_CLIENT_INFO_FAIL: "UPDATE_CLIENT_INFO_FAIL",
  UPDATE_CLIENT_INFO_RESET_STATE: "UPDATE_CLIENT_INFO_RESET_STATE",

  UPDATE_CAREGIVER_INFO: "UPDATE_CAREGIVER_INFO",
  UPDATE_CAREGIVER_INFO_SUCCESS: "UPDATE_CAREGIVER_INFO_SUCCESS",
  UPDATE_CAREGIVER_INFO_FAIL: "UPDATE_CAREGIVER_INFO_FAIL",
  GET_CUSTOMER_CALLS_BY_MONTH: "GET_CUSTOMER_CALLS_BY_MONTH",
  GET_CUSTOMER_CALLS_BY_MONTH_SUCCESS: "GET_CUSTOMER_CALLS_BY_MONTH_SUCCESS",
  GET_CUSTOMER_CALLS_BY_MONTH_FAIL: "GET_CUSTOMER_CALLS_BY_MONTH_FAIL",

  GET_CUSTOMER_DASHBOARD_NOTIFICATIONS: "GET_CUSTOMER_DASHBOARD_NOTIFICATIONS",
  GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_SUCCESS: "GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_SUCCESS",
  GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_FAIL: "GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_FAIL",
  MARK_CUSTOMER_NOTIFICATION_AS_READ: "MARK_CUSTOMER_NOTIFICATION_AS_READ",

  REQUEST_TREATMENT_PLAN_REVIEW: "REQUEST_TREATMENT_PLAN_REVIEW",
  REQUEST_VIDEO_CALL_RESCHEDULE: "REQUEST_VIDEO_CALL_RESCHEDULE",

  GET_CLIENT_STATIC_PAGE: "GET_CLIENT_STATIC_PAGE",
  GET_CLIENT_STATIC_PAGE_SUCCESS: "GET_CLIENT_STATIC_PAGE_SUCCESS",
  GET_CLIENT_STATIC_PAGE_FAIL: "GET_CLIENT_STATIC_PAGE_FAIL",

  SET_STATIC_PAGE_STATE: "SET_STATIC_PAGE_STATE",
  SET_STATIC_PAGE_STATE_SUCCESS: "SET_STATIC_PAGE_STATE_SUCCESS",

  SET_PARENT_PENDING_SIGNATURES: "SET_PARENT_PENDING_SIGNATURES",
};

const ACTION_CREATORS = {
  getParentInfo() {
    return {
      type: ACTIONS.GET_PARENT_INFO,
    };
  },
  getParentInfoSuccess(user) {
    return {
      type: ACTIONS.GET_PARENT_INFO_SUCCESS,
      payload: {
        user,
      },
    };
  },
  setParentPendingSignatures(payload) {
    return {
      type: ACTIONS.SET_PARENT_PENDING_SIGNATURES,
      payload,
    };
  },
  showWelcome() {
    return { type: ACTIONS.SHOW_WELCOME };
  },
  hideWelcome() {
    return { type: ACTIONS.HIDE_WELCOME };
  },
  showPayment() {
    return { type: ACTIONS.SHOW_PAYMENT };
  },
  hidePayment() {
    return { type: ACTIONS.HIDE_PAYMENT };
  },
  showReferralBanner() {
    return { type: ACTIONS.SHOW_REFERRAL_BANNER };
  },
  hideReferralBanner() {
    return { type: ACTIONS.HIDE_REFERRAL_BANNER };
  },
  getUserClinician(clientId) {
    return { type: ACTIONS.GET_USER_CLINICIAN, payload: clientId };
  },
  getUserClinicianSuccess(clinician) {
    return {
      type: ACTIONS.GET_USER_CLINICIAN_SUCCESS,
      payload: {
        clinician,
      },
    };
  },
  getClinicianBio(clinicianUserId) {
    return {
      type: ACTIONS.GET_CLINICIAN_BIO,
      payload: {
        clinicianUserId,
      },
    };
  },
  getClinicianBioSuccess(clinician, specialties) {
    return {
      type: ACTIONS.GET_CLINICIAN_BIO_SUCCESS,
      payload: {
        clinician,
        specialties,
      },
    };
  },
  getClinicianSpecialties(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_SPECIALTIES,
      payload: {
        clinicianId,
      },
    };
  },
  getClinicianSpecialtiesSuccess(specialties) {
    return {
      type: ACTIONS.GET_CLINICIAN_SPECIALTIES_SUCCESS,
      payload: {
        specialties,
      },
    };
  },
  selectClinician() {
    return {
      type: ACTIONS.CLINICIAN_SELECTED,
    };
  },
  referredClinician() {
    return {
      type: ACTIONS.CLINICIAN_REFERRED,
    };
  },
  clearClinician() {
    return { type: ACTIONS.CLEAR_CLINICIAN };
  },
  clearDisplayClinician() {
    return { type: ACTIONS.CLEAR_DISPLAY_CLINICIAN };
  },
  clearQuestionnaireAnswers() {
    return { type: ACTIONS.CLEAR_QUESTIONNAIRE_ANSWERS };
  },
  getDashboard() {
    return {
      type: ACTIONS.GET_DASHBOARD,
    };
  },
  getDashboardSuccess(payload) {
    return {
      type: ACTIONS.GET_DASHBOARD_SUCCESS,
      payload,
    };
  },
  clearDashboard() {
    return {
      type: ACTIONS.CLEAR_DASHBOARD,
    };
  },
  cancelSubscription(reason, recommend) {
    return {
      type: ACTIONS.CANCEL_SUBSCRIPTION,
      payload: { reason, recommend },
    };
  },
  cancelSubscriptionSuccess() {
    return {
      type: ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS,
    };
  },
  cancelSubscriptionFail(error) {
    return {
      type: ACTIONS.CANCEL_SUBSCRIPTION_FAIL,
      payload: error,
    };
  },
  getCancelReasons() {
    return {
      type: ACTIONS.GET_CANCEL_REASONS,
    };
  },
  getCancelReasonsSuccess(payload) {
    return {
      type: ACTIONS.GET_CANCEL_REASONS_SUCCESS,
      payload,
    };
  },
  agreeToPolicies(info) {
    return {
      type: ACTIONS.AGREE_TO_POLICIES,
      payload: info,
    };
  },
  agreeToPoliciesSuccess() {
    return {
      type: ACTIONS.AGREE_TO_POLICIES_SUCCESS,
    };
  },
  agreeToPhiRelease() {
    return {
      type: ACTIONS.AGREE_TO_PHI_RELEASE,
    };
  },
  showABAInterestDialog() {
    return {
      type: ACTIONS.SHOW_ABA_INTEREST_DIALOG,
    };
  },
  hideABAInterestDialog() {
    return {
      type: ACTIONS.HIDE_ABA_INTEREST_DIALOG,
    };
  },
  getActiveTreatmentPlan() {
    return {
      type: ACTIONS.GET_ACTIVE_TREATMENT_PLAN,
    };
  },
  getActiveTreatmentPlanSuccess(plan) {
    return {
      type: ACTIONS.GET_ACTIVE_TREATMENT_PLAN_SUCCESS,
      payload: plan,
    };
  },
  approveTreatmentPlan(plan) {
    return {
      type: ACTIONS.APPROVE_TREATMENT_PLAN,
      payload: plan,
    };
  },
  approveTreatmentPlanSuccess() {
    return {
      type: ACTIONS.APPROVE_TREATMENT_PLAN_SUCCESS,
    };
  },
  getInsuranceDashboard() {
    return {
      type: ACTIONS.GET_INSURANCE_DASHBOARD,
    };
  },
  getInsuranceDashboardSuccess(dashboard) {
    return {
      type: ACTIONS.GET_INSURANCE_DASHBOARD_SUCCESS,
      payload: dashboard,
    };
  },
  updateToFullCustomer() {
    return {
      type: ACTIONS.UPDATE_FULL_CUSTOMER,
    };
  },
  updateToFullCustomerSuccess() {
    return {
      type: ACTIONS.UPDATE_FULL_CUSTOMER_SUCCESS,
    };
  },
  updateToFullCustomerFail() {
    return {
      type: ACTIONS.UPDATE_FULL_CUSTOMER_FAIL,
    };
  },
  uploadUserDocuments(documentObj) {
    return {
      type: ACTIONS.UPLOAD_USER_DOCUMENT,
      payload: documentObj,
    };
  },
  uploadUserDocumentsSuccess() {
    return {
      type: ACTIONS.UPLOAD_USER_DOCUMENT_SUCCESS,
    };
  },
  uploadUserDocumentsFail() {
    return {
      type: ACTIONS.UPLOAD_USER_DOCUMENT_FAIL,
    };
  },
  getInsuranceRoadmap() {
    return {
      type: ACTIONS.GET_INSURANCE_ROADMAP,
    };
  },
  getInsuranceRoadmapSuccess(roadmap) {
    return {
      type: ACTIONS.GET_INSURANCE_ROADMAP_SUCCESS,
      payload: roadmap,
    };
  },
  sendDocumentRequestSignature(sigObject) {
    return {
      type: ACTIONS.SEND_DOCUMENT_REQUEST_SIGNATURE,
      payload: sigObject,
    };
  },
  sendDocumentRequestSignatureSuccess() {
    return {
      type: ACTIONS.SEND_DOCUMENT_REQUEST_SIGNATURE_SUCCESS,
    };
  },
  sendDocumentRequestSignatureFail() {
    return {
      type: ACTIONS.SEND_DOCUMENT_REQUEST_SIGNATURE_FAIL,
    };
  },
  sendRejectionEligibilityInfo(info) {
    return {
      type: ACTIONS.SEND_REJECTION_ELIGIBILITY_INFO,
      payload: info,
    };
  },
  sendRejectionEligibilityInfoSuccess() {
    return {
      type: ACTIONS.SEND_REJECTION_ELIGIBILITY_INFO_SUCCESS,
    };
  },
  logAWSUploadError(error) {
    return {
      type: ACTIONS.LOG_AWS_UPLOAD_ERROR,
      payload: error,
    };
  },
  getEligibilityFields() {
    return {
      type: ACTIONS.GET_ELIGIBILITY_FIELDS,
    };
  },
  getEligibilityFieldsSuccess(fields) {
    return {
      type: ACTIONS.GET_ELIGIBILITY_FIELDS_SUCCESS,
      payload: fields,
    };
  },
  getUnsignedDocumentRequests() {
    return {
      type: ACTIONS.GET_UNSIGNED_DOCUMENT_REQUESTS,
    };
  },
  getUnsignedDocumentRequestsSuccess(payload) {
    return {
      type: ACTIONS.GET_UNSIGNED_DOCUMENT_REQUESTS_SUCCESS,
      payload,
    };
  },
  signDocumentRequest(payload) {
    return {
      type: ACTIONS.SIGN_DOCUMENT_REQUEST,
      payload,
    };
  },
  signDocumentRequestSuccess() {
    return {
      type: ACTIONS.SIGN_DOCUMENT_REQUEST_SUCCESS,
    };
  },
  signDocumentRequestFail() {
    return {
      type: ACTIONS.SIGN_DOCUMENT_REQUEST_FAIL,
    };
  },
  checkRecaptchaVerification(payload) {
    return {
      type: ACTIONS.CHECK_RECAPTCHA_VERIFICATION,
      payload,
    };
  },
  checkRecaptchaVerificationSuccess(payload) {
    return {
      type: ACTIONS.CHECK_RECAPTCHA_VERIFICATION_SUCCESS,
      payload,
    };
  },
  checkRecaptchaVerificationFail(error) {
    return {
      type: ACTIONS.CHECK_RECAPTCHA_VERIFICATION_FAIL,
      payload: error,
    };
  },
  getCustomerSignedForm(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_SIGNED_FORM,
      payload,
    };
  },
  getCustomerSignedFormSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_SIGNED_FORM_SUCCESS,
      payload,
    };
  },
  saveSignedCustomerForm(payload) {
    return {
      type: ACTIONS.SAVE_SIGNED_CUSTOMER_FORM,
      payload,
    };
  },
  saveSignedCustomerFormSuccess() {
    return {
      type: ACTIONS.SAVE_SIGNED_CUSTOMER_FORM_SUCCESS,
    };
  },
  saveSignedCustomerFormFail() {
    return {
      type: ACTIONS.SAVE_SIGNED_CUSTOMER_FORM_FAIL,
    };
  },
  clearCustomerForm() {
    return {
      type: ACTIONS.CLEAR_CUSTOMER_FORM,
    };
  },
  updateClientInfo(payload) {
    return {
      type: ACTIONS.UPDATE_CLIENT_INFO,
      payload,
    };
  },
  updateClientInfoSuccess() {
    return {
      type: ACTIONS.UPDATE_CLIENT_INFO_SUCCESS,
    };
  },
  updateClientInfoFail() {
    return {
      type: ACTIONS.UPDATE_CLIENT_INFO_FAIL,
    };
  },
  resetClientInfoState() {
    return {
      type: ACTIONS.UPDATE_CLIENT_INFO_RESET_STATE,
    };
  },
  updateCaregiverInfo(payload) {
    return {
      type: ACTIONS.UPDATE_CAREGIVER_INFO,
      payload,
    };
  },
  updateCaregiverInfoSuccess() {
    return {
      type: ACTIONS.UPDATE_CAREGIVER_INFO_SUCCESS,
    };
  },
  updateCaregiverInfoFail() {
    return {
      type: ACTIONS.UPDATE_CAREGIVER_INFO_FAIL,
    };
  },
  getCustomerCallsByMonth(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_CALLS_BY_MONTH,
      payload,
    };
  },
  getCustomerCallsByMonthSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_CALLS_BY_MONTH_SUCCESS,
      payload,
    };
  },
  getCustomerCallsByMonthFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_CALLS_BY_MONTH_FAIL,
    };
  },
  getCustomerDashboardNotifications() {
    return {
      type: ACTIONS.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS,
    };
  },
  getCustomerDashboardNotificationsSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_SUCCESS,
      payload,
    };
  },
  getCustomerDashboardNotificationsFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_FAIL,
    };
  },
  markCustomerNotificationAsRead(payload) {
    return {
      type: ACTIONS.MARK_CUSTOMER_NOTIFICATION_AS_READ,
      payload,
    };
  },
  requestTreatmentPlanReview() {
    return {
      type: ACTIONS.REQUEST_TREATMENT_PLAN_REVIEW,
    };
  },
  requestVideoCallReschedule(payload) {
    return {
      type: ACTIONS.REQUEST_VIDEO_CALL_RESCHEDULE,
      payload,
    };
  },
  getClientStaticPage(payload) {
    return {
      type: ACTIONS.GET_CLIENT_STATIC_PAGE,
      payload,
    };
  },
  getClientStaticPageSuccess(payload) {
    return {
      type: ACTIONS.GET_CLIENT_STATIC_PAGE_SUCCESS,
      payload,
    };
  },
  getClientStaticPageFail(payload) {
    return {
      type: ACTIONS.GET_CLIENT_STATIC_PAGE_FAIL,
      payload,
    };
  },
  setStaticPageState(payload) {
    return {
      type: ACTIONS.SET_STATIC_PAGE_STATE,
      payload,
    };
  },
  setStaticPageStateSuccess(payload) {
    return {
      type: ACTIONS.SET_STATIC_PAGE_STATE_SUCCESS,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
