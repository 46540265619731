export default (theme) => ({
  mainBody: {
    width: "100%",
    marginTop: theme.spacing(8),
    padding: 25,
  },
  clinicianViewOnlyContainer: {
    marginTop: theme.spacing(-8),
  },
});
