import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getInsuranceCodes,
  createApprovedUnits,
  updateApprovedUnits,
  getApprovedUnits,
  getInsuranceProviders,
  getInsuranceProvidersNotAccepted,
  getAuthorizationCodes,
  createAuthorizationCode,
  updateAuthorizationCode,
  getUserInsurance,
  updateUserInsurance,
  createUserInsurance,
  disableUserInsurance,
  getBillableTime,
  createOffPlatformWork,
  getOffPlatformWork,
  approveOffPlatformWork,
  denyOffPlatformWork,
  updateBillableTimeInsuranceCode,
  getApprovedUnitsByAuthId,
  getInsuranceCodesByUserCurrentAuth,
  getAvailableInsuranceCodes,
  getSuperBillsByClient,
  getReferringPhysicians,
  createReferringPhysician,
  getInsuranceProvidersList,
  createInsuranceProvider,
  updateInsuranceProvider,
  getInsurancePlans,
  createInsurancePlan,
  updateInsurancePlan,
  getInsurancePlan,
  getInsuranceProvider,
} from "../api/insurance";

function* getInsuranceProvidersAsync({ payload }) {
  try {
    const providers = yield call(getInsuranceProviders, payload);
    yield put(actions.getInsuranceProvidersSuccess(providers.data));
    if (payload) {
      const providersNotAccepted = yield call(getInsuranceProvidersNotAccepted, payload);
      yield put(actions.getInsuranceProvidersNotAcceptedSuccess(providersNotAccepted.data));
    }
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceCodesAsync() {
  try {
    const codes = yield call(getInsuranceCodes);
    yield put(actions.getInsuranceCodesSuccess(codes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getApprovedUnitsAsync({ payload }) {
  try {
    const units = yield call(getApprovedUnits, payload);
    yield put(actions.getApprovedUnitsSuccess(units.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createApprovedUnitsAsync({ payload }) {
  try {
    yield call(createApprovedUnits, payload);
    yield put(actions.getApprovedUnits(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateApprovedUnitsAsync({ payload }) {
  try {
    yield call(updateApprovedUnits, payload);
    yield put(actions.getApprovedUnits(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAuthorizationCodesAsync({ payload }) {
  try {
    const codes = yield call(getAuthorizationCodes, payload);
    let authCodes = [...codes.data].map((code) => {
      code.approvedUnitsLoading = true;
      return code;
    });
    yield put(actions.getAuthorizationCodesSuccess(codes.data));
    for (let i = 0; i < authCodes.length; i++) {
      let approvedUnits = yield call(getApprovedUnitsByAuthId, {
        clientId: payload,
        authId: authCodes[i].id,
      });
      authCodes[i].approvedUnits = approvedUnits.data;
      authCodes[i].approvedUnitsLoading = false;
    }
    yield put(actions.getAuthorizationCodesSuccess(authCodes));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createAuthorizationCodeAsync({ payload }) {
  try {
    const result = yield call(createAuthorizationCode, payload);
    const authCode = result.data;
    if (payload.approvedUnits) {
      for (let i = 0; i < payload.approvedUnits.length; i++) {
        let unit = payload.approvedUnits[i];
        unit.insuranceAuthorizationsId = authCode.authorization_code_id;
        unit.clientId = payload.clientId;
        yield put(actions.createApprovedUnits(unit));
      }
    }
    yield put(actions.getAuthorizationCodes(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateAuthorizationCodeAsync({ payload }) {
  try {
    yield call(updateAuthorizationCode, payload);
    if (payload.approvedUnits) {
      for (let i = 0; i < payload.approvedUnits.length; i++) {
        let unit = payload.approvedUnits[i];
        yield put(actions.updateApprovedUnits(unit));
      }
    }
    yield put(actions.getAuthorizationCodes(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceCodesByUserAsync({ payload }) {
  try {
    const codes = yield call(getInsuranceCodesByUserCurrentAuth, payload);
    yield put(actions.getInsuranceCodesByUserSuccess(codes.data));
  } catch (e) {
    yield put(actions.getInsuranceCodesByUserFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAllAvailableCodesAsync({ payload }) {
  try {
    const codes = yield call(getAvailableInsuranceCodes, payload);
    yield put(actions.getAllAvailableInsuranceCodesSuccess(codes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getUserInsuranceAsync({ payload }) {
  try {
    const insurances = yield call(getUserInsurance, payload);
    yield put(actions.getUserInsuranceSuccess(insurances.data));
  } catch (e) {
    yield put(actions.getUserInsuranceFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateUserInsuranceAsync({ payload }) {
  try {
    yield call(updateUserInsurance, payload);
    yield put(actions.getUserInsurance(payload.clientId));
    yield put(actions.getInsuranceCodesByUser(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createUserInsuranceAsync({ payload }) {
  try {
    yield call(createUserInsurance, payload);
    yield put(actions.getUserInsurance(payload.clientId));
    yield put(actions.getInsuranceCodesByUser(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* disableUserInsuranceAsync({ payload }) {
  try {
    yield call(disableUserInsurance, payload);
    yield put(actions.getUserInsurance(payload.clientId));
    yield put(actions.getInsuranceCodesByUser(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getBillableTimeAsync({ payload }) {
  try {
    const billableTime = yield call(getBillableTime, payload);
    yield put(actions.getBillableTimeSuccess(billableTime.data));
  } catch (e) {
    yield put(actions.getBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createOffPlatformWorkAsync({ payload }) {
  try {
    const result = yield call(createOffPlatformWork, payload);
    const note = {
      billableTimeId: result.data[0].id,
      ...payload,
    };
    yield put(actions.saveSessionNote(note));
    yield put(actions.createOffPlatformWorkSuccess());
  } catch (e) {
    yield put(actions.createOffPlatformWorkFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getOffPlatformWorkAsync() {
  try {
    const offPlatform = yield call(getOffPlatformWork);
    yield put(actions.getOffPlatformWorkSuccess(offPlatform.data));
  } catch (e) {
    yield put(actions.getOffPlatformWorkFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* approveOffPlatformWorkAsync({ payload }) {
  try {
    yield call(approveOffPlatformWork, payload);
    yield put(actions.getOffPlatformWork());
  } catch (e) {
    yield put(actions.getOffPlatformWorkFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* denyOffPlatformWorkAsync({ payload }) {
  try {
    yield call(denyOffPlatformWork, payload);
    yield put(actions.getOffPlatformWork());
  } catch (e) {
    yield put(actions.getOffPlatformWorkFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateBillableTimeInsuranceCodeAsync({ payload }) {
  try {
    yield call(updateBillableTimeInsuranceCode, payload);
    yield put(actions.updateBillableTimeInsuranceCodeSuccess());
  } catch (e) {
    yield put(actions.updateBillableTimeInsuranceCodeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSuperBillsByClientAsync({ payload }) {
  try {
    const superBills = yield call(getSuperBillsByClient, payload);
    yield put(actions.getSuperBillsByClientSuccess(superBills.data));
  } catch (e) {
    yield put(actions.getSuperBillsByClientFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getReferringPhysiciansAsync() {
  try {
    const physicians = yield call(getReferringPhysicians);
    yield put(actions.getReferringPhysiciansSuccess(physicians.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createReferringPhysicianAsync({ payload }) {
  try {
    const physician = yield call(createReferringPhysician, payload);
    yield put(actions.getReferringPhysicians());
    yield put(actions.createReferringPhysicianSuccess(physician.data));
  } catch (e) {
    yield put(actions.createReferringPhysicianFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceProvidersListAsync() {
  try {
    const providers = yield call(getInsuranceProvidersList);
    yield put(actions.getInsuranceProvidersListSuccess(providers.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createInsuranceProviderAsync({ payload }) {
  try {
    yield call(createInsuranceProvider, payload);
    yield put(actions.createInsuranceProviderSuccess());
    yield put(actions.getInsuranceProvidersList());
  } catch (e) {
    yield put(actions.createInsuranceProviderFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateInsuranceProviderAsync({ payload }) {
  try {
    yield call(updateInsuranceProvider, payload);
    yield put(actions.updateInsuranceProviderSuccess());
    yield put(actions.getInsuranceProvidersList());
  } catch (e) {
    yield put(actions.updateInsuranceProviderFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsurancePlansAsync() {
  try {
    const result = yield call(getInsurancePlans);
    const plans = {
      plans: result.data,
      count: result.data[0]?.count,
    };
    yield put(actions.getInsurancePlansSuccess(plans));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createInsurancePlanAsync({ payload }) {
  try {
    yield call(createInsurancePlan, payload);
    yield put(actions.createInsurancePlanSuccess());
    const result = yield call(getInsurancePlans);
    const plans = {
      plans: result.data,
      count: result.data[0]?.count,
    };
    yield put(actions.getInsurancePlansSuccess(plans));
  } catch (e) {
    yield put(actions.createInsurancePlanFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateInsurancePlanAsync({ payload }) {
  try {
    yield call(updateInsurancePlan, payload);
    yield put(actions.updateInsurancePlanSuccess());
  } catch (e) {
    yield put(actions.updateInsurancePlanFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsurancePlanAsync({ payload }) {
  try {
    const plan = yield call(getInsurancePlan, payload);
    yield put(actions.getInsurancePlanSuccess(plan.data));
  } catch (e) {
    yield put(actions.getInsurancePlanFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getInsuranceProviderAsync({ payload }) {
  try {
    const provider = yield call(getInsuranceProvider, payload);
    yield put(actions.getInsuranceProviderSuccess(provider.data));
  } catch (e) {
    yield put(actions.getInsuranceProviderFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* insuranceSaga() {
  yield takeEvery(actions.GET_INSURANCE_PROVIDERS, getInsuranceProvidersAsync);
  yield takeEvery(actions.GET_INSURANCE_CODES, getInsuranceCodesAsync);
  yield takeEvery(actions.CREATE_APPROVED_UNITS, createApprovedUnitsAsync);
  yield takeEvery(actions.UPDATE_APPROVED_UNITS, updateApprovedUnitsAsync);
  yield takeEvery(actions.GET_APPROVED_UNITS, getApprovedUnitsAsync);
  yield takeEvery(actions.GET_AUTHORIZATION_CODES, getAuthorizationCodesAsync);
  yield takeEvery(actions.CREATE_AUTHORIZATION_CODE, createAuthorizationCodeAsync);
  yield takeEvery(actions.UPDATE_AUTHORIZATION_CODE, updateAuthorizationCodeAsync);
  yield takeEvery(actions.GET_INSURANCE_CODES_BY_USER, getInsuranceCodesByUserAsync);
  yield takeEvery(actions.GET_USER_INSURANCE, getUserInsuranceAsync);
  yield takeEvery(actions.UPDATE_USER_INSURANCE, updateUserInsuranceAsync);
  yield takeEvery(actions.CREATE_USER_INSURANCE, createUserInsuranceAsync);
  yield takeEvery(actions.DISABLE_USER_INSURANCE, disableUserInsuranceAsync);
  yield takeEvery(actions.GET_BILLABLE_TIME, getBillableTimeAsync);
  yield takeEvery(actions.CREATE_OFF_PLATFORM_WORK, createOffPlatformWorkAsync);
  yield takeEvery(actions.GET_OFF_PLATFORM_WORK, getOffPlatformWorkAsync);
  yield takeEvery(actions.APPROVE_OFF_PLATFORM_WORK, approveOffPlatformWorkAsync);
  yield takeEvery(actions.DENY_OFF_PLATFORM_WORK, denyOffPlatformWorkAsync);
  yield takeEvery(
    actions.UPDATE_BILLABLE_TIME_INSURANCE_CODE,
    updateBillableTimeInsuranceCodeAsync,
  );
  yield takeEvery(actions.GET_ALL_AVAILABLE_CODES, getAllAvailableCodesAsync);
  yield takeEvery(actions.GET_SUPERBILLS_BY_CLIENT, getSuperBillsByClientAsync);
  yield takeEvery(actions.GET_REFERRING_PHYSICIANS, getReferringPhysiciansAsync);
  yield takeEvery(actions.CREATE_REFERRING_PHYSICIAN, createReferringPhysicianAsync);
  yield takeEvery(actions.GET_INSURANCE_PROVIDERS_LIST, getInsuranceProvidersListAsync);
  yield takeEvery(actions.CREATE_INSURANCE_PROVIDER, createInsuranceProviderAsync);
  yield takeEvery(actions.UPDATE_INSURANCE_PROVIDER, updateInsuranceProviderAsync);
  yield takeEvery(actions.GET_INSURANCE_PLANS, getInsurancePlansAsync);
  yield takeEvery(actions.CREATE_INSURANCE_PLAN, createInsurancePlanAsync);
  yield takeEvery(actions.UPDATE_INSURANCE_PLAN, updateInsurancePlanAsync);
  yield takeEvery(actions.GET_INSURANCE_PLAN, getInsurancePlanAsync);
  yield takeEvery(actions.GET_INSURANCE_PROVIDER, getInsuranceProviderAsync);
}
