import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const EditFamilyMember = (props) => {
  const {
    classes,
    formatPhoneNumber,
    familyPhoneError,
    setError,
    contact,
    onChangeFamilyContact,
    userPermissions,
  } = props;

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sm={12}
        spacing={5}
        classes={{ root: classes.gridRoot }}
        style={{ paddingBottom: "24px" }}
      >
        <Grid item xs={12}>
          <TextField
            label="Relationship to Client"
            select
            id={`${contact.id}-familyRelationship`}
            name="relationship"
            onChange={onChangeFamilyContact(`${contact.id}`)}
            value={contact.relationship}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            <MenuItem disabled value={""} />
            <MenuItem value="caregiver">Caregiver</MenuItem>
            <MenuItem value="child">Child</MenuItem>
            <MenuItem value="dependent">Dependent</MenuItem>
            <MenuItem value="family_member">Family Member</MenuItem>
            <MenuItem value="legal_guardian">Legal Guardian</MenuItem>
            <MenuItem value="is_parent">Parent</MenuItem>
            <MenuItem value="spouse">Spouse</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            id={`${contact.id}-guardianFirstName`}
            name="first_name"
            onChange={onChangeFamilyContact(`${contact.id}`)}
            value={contact.first_name}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            id={`${contact.id}-familyLasttName`}
            name="last_name"
            onChange={onChangeFamilyContact(`${contact.id}`)}
            value={contact.last_name}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            id={`${contact.id}-email`}
            name="email"
            onChange={onChangeFamilyContact(`${contact.id}`)}
            value={contact.email}
            variant="outlined"
            size="small"
            disabled={!userPermissions?.update_client_email_address}
            fullWidth
            helperText={
              !userPermissions?.update_client_email_address
                ? `Can't edit. Reach out to support if a change is needed.`
                : ``
            }
            className={classes.disabledEmail}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            id={`${contact.id}-phone_number`}
            name="phone_number"
            onChange={onChangeFamilyContact(`${contact.id}`)}
            value={formatPhoneNumber(contact.phone_number)}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 10 }}
            onFocus={() => setError("familyPhoneError", false)}
            onBlur={() =>
              contact.phone_number.trim().length === 10 || !contact.phone_number
                ? setError("familyPhoneError", false)
                : setError("familyPhoneError", true)
            }
            helperText={familyPhoneError ? "Invalid Phone Number" : null}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={onChangeFamilyContact(`${contact.id}`)}
                id={`${contact.id}-legal_guardian`}
                name="legal_guardian"
                checked={contact.legal_guardian ?? false}
              />
            }
            label="Legal Guardian"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default withStyles(styles)(EditFamilyMember);
