import axios from "axios";
import cookie from "react-cookies";
import { detect } from "detect-browser";
import { Auth } from "@aws-amplify/auth";
import { POSTLogger } from "amazon-chime-sdk-js";

class SecureRequest {
  constructor() {
    const browser = detect();
    const user_id = cookie.load("userId");
    let system_version = null;
    let device_type = null;
    let browser_type = null;
    if (browser) {
      system_version = browser.version;
      browser_type = browser.name;
      device_type = browser.os;
    }
    this.request = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        user_id,
        system_version,
        browser_type,
        device_type,
      },
      baseURL: process.env.BASE_URL,
    });
    this.request.interceptors.request.use((config) => {
      return Auth.currentSession()
        .then((data) => {
          config.headers.accessToken = data.accessToken.jwtToken;
          return Promise.resolve(config);
        })
        .catch(() => {
          return Promise.resolve(config);
        });
    });
  }

  getRequest() {
    return this.request;
  }
}

export default SecureRequest;

// helpers

/** @type {(url: string, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiGet(...args) {
  return new SecureRequest().getRequest().get(...args);
}

/** @type {(url: string, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiDelete(...args) {
  return new SecureRequest().getRequest().delete(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPut(...args) {
  return new SecureRequest().getRequest().put(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPost(...args) {
  return new SecureRequest().getRequest().post(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPatch(...args) {
  return new SecureRequest().getRequest().patch(...args);
}

/**
 * TODO how to handle this if token is refreshed?
 * @param {number} videoCallId
 * @param {import('amazon-chime-sdk-js').POSTLoggerOptions} loggerOptions
 */
export async function createVideoCallPostLogger(videoCallId, loggerOptions = {}) {
  if (!videoCallId) throw new Error("videoCallId must be valid");
  const secureRequest = new SecureRequest();
  const headers = secureRequest.request.defaults.headers;
  const data = await Auth.currentSession();
  const accessToken = data.getAccessToken();
  headers.accessToken = accessToken.getJwtToken();

  return new POSTLogger({
    url: `${process.env.BASE_URL}/v2/video/${videoCallId}/log`,
    intervalMs: 20000,
    ...loggerOptions,
    headers: {
      ...headers,
      ...(loggerOptions.headers || {}),
    },
  });
}
