import {
  getChatsPage,
  getChatsPerPage,
  getChatsSort,
  getChatsSortDirection,
  getChatsFilter,
} from "../selectors";
import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";

export function* getChatList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getChatsPage);
  const size = yield select(getChatsPerPage);
  const sort = yield select(getChatsSort);
  const direction = yield select(getChatsSortDirection);
  const filter = yield select(getChatsFilter);
  const params = {
    page,
    size,
    sort,
    direction,
    filter,
  };

  return yield Request.get(`/chats`, { params });
}

export function* getChatConversation(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/chats/${id}`);
}

export function* getChatMessages(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/chats/${id}/messages`);
}

export function* getAllChatTags() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/chats/tags`);
}

export function* getChatMessageTags(messageId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/chats/${messageId}/tags`);
}

export function* tagChatMessage(messageId, tagId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/chats/${messageId}/tag`, {
    tagId,
  });
}

export function* deleteMessageTag(messageTagId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.delete(`/chats/${messageTagId}/delete`);
}
