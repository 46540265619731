import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "actions";
import {
  getCustomerSearchResults,
  getCustomerSearchLoading,
  isSuperAdmin,
  getUserPermissionsList,
} from "selectors";
import {
  MagnifyingGlass,
  UserCircle,
  UsersThree,
  UserRectangle,
  IdentificationBadge,
  XSquare,
} from "@phosphor-icons/react";
import { load, save } from "react-cookies";
import { CustomPopper, SearchOptionsList } from "./SearchElements";
import { CHILD, PARENT, CLINICIAN, CLIENT_ID, CLINICIAN_ID, ID } from "./UserTypesConsts";
import { styles } from "./styles";

const FILTER_STATE_KEY = "ClientSearch:filterState";

const mapStateToProps = (state) => ({
  searchResults: getCustomerSearchResults(state),
  searchLoading: getCustomerSearchLoading(state),
  isSuperAdmin: isSuperAdmin(state),
  userPermissions: getUserPermissionsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCustomerSearch: actions.clearCustomerSearch,
      getCustomerSearch: actions.getSingleCustomerSearch,
    },
    dispatch
  );

const initialState = () => {
  const cookieState = load(FILTER_STATE_KEY) || {};
  return {
    parents: true,
    clients: true,
    clinicians: true,
    customerCare: false,
    deactivated: false,
    allUsers: false,
    ...cookieState,
    search: "",
    focusOn: false,
    rerenderKey: new Date().toISOString(),
  };
};

const TypeUserIcon = ({ type }) => {
  if (type === CHILD) {
    return <UserCircle weight="duotone" />;
  }

  if (type === PARENT) {
    return <UsersThree weight="duotone" />;
  }

  if (type === CLINICIAN) {
    return <IdentificationBadge weight="duotone" />;
  }

  return <UserRectangle weight="duotone" />;
};

class ClientSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState(),
    };
  }

  componentDidMount() {
    if (this.props.userPermissions) {
      this.mapPermissionsToFilterState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { parents, clients, clinicians, deactivated, allUsers } = this.state;
    const { userPermissions } = this.props;

    let search = this.state.search.toLowerCase().trim();

    if (
      (prevState.search.length !== this.state.search.length ||
        prevState.parents !== this.state.parents ||
        prevState.clients !== this.state.clients ||
        prevState.clinicians !== this.state.clinicians ||
        prevState.deactivated !== this.state.deactivated ||
        prevState.allUsers !== this.state.allUsers) &&
      search.length >= 3
    ) {
      if (parents || clients || clinicians || allUsers) {
        this.props.getCustomerSearch({
          search,
          parents,
          clients,
          clinicians,
          deactivated,
          allUsers,
        });
      } else {
        this.props.clearCustomerSearch();
        // Search without filters activated
        this.props.getCustomerSearch({
          search,
          parents: userPermissions.view_client_list_by_workflow_state,
          clients: userPermissions.view_client_list_by_workflow_state,
          clinicians: userPermissions.view_clinician_list,
          deactivated,
          allUsers,
        });
      }
    }

    if (prevState.search.length !== this.state.search.length && this.state.search.length === 0) {
      this.props.clearCustomerSearch();
    }

    if (!prevProps.userPermissions && this.props.userPermissions) {
      this.mapPermissionsToFilterState();
    }

    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    save(FILTER_STATE_KEY, this.state, { path: "/", expires });
  }

  mapPermissionsToFilterState = () => {
    const { userPermissions } = this.props;
    let filterState = { ...this.state };

    if (!userPermissions.view_client_list_by_workflow_state) {
      filterState = {
        ...filterState,
        parents: false,
        clients: false,
      };
    }

    if (!userPermissions.view_clinician_list) {
      filterState = {
        ...filterState,
        clinicians: false,
      };
    }

    this.setState({ ...filterState });
  };

  onChange = (e, v) => {
    this.setState({ search: v });
  };

  onChangeFilter = (name, val) => {
    if (name === "allUsers") {
      this.setState({
        parents: false,
        clients: false,
        clinicians: false,
        allUsers: val,
      });
    } else {
      if (name !== "deactivated") {
        this.setState({ allUsers: false });
      }
      this.setState({ [name]: val });
    }
  };

  getUserType = (option) => {
    const { search } = this.state;
    let searchQuery = search.trim().toLowerCase();
    let type;

    if (!isNaN(parseInt(search, 10))) {
      if (option.client_id?.includes(searchQuery)) {
        type = CLIENT_ID;
      } else if (option.clinician_id?.includes(searchQuery)) {
        type = CLINICIAN_ID;
      } else {
        type = ID;
      }
    } else {
      if (option.child_name && option.child_name.toLowerCase()?.includes(searchQuery)) {
        type = CHILD;
      } else if (
        option.user_name &&
        option.user_name.toLowerCase()?.includes(searchQuery) &&
        option.customer_id
      ) {
        type = PARENT;
      } else if (option.authority) {
        type = this.renderUserRole(option.authority);
      }
    }

    return type;
  };

  renderUserRole = (role) => {
    if (role?.includes("ROLE_SUPER_ADMIN" || "ROLE_ADMIN")) {
      return "admin";
    } else if (role?.includes("ROLE_CLINICAL_DIRECTOR")) {
      return "director";
    } else if (role?.includes("ROLE_CLINICAL_SUPERVISOR")) {
      return "supervisor";
    } else if (role?.includes("ROLE_CUSTOMER_CARE")) {
      return "customercare";
    } else if (role?.includes("ROLE_CLINICIAN_VIEW_ONLY")) {
      return "clinicianviewonly";
    } else if (role?.includes("ROLE_CLINICIAN")) {
      return "clinician";
    } else if (role?.includes("ROLE_PARENT") && (this.state.parents || this.state.allUsers)) {
      return "parent";
    } else if (role?.includes("ROLE_PARENT") && this.state.clients) {
      return "child";
    } else if (role?.includes("ROLE_FAMILY")) {
      return "familymember";
    } else {
      return "user";
    }
  };

  renderOptionLabel = (option) => {
    let type = this.getUserType(option);

    switch (type) {
      case "admin":
        return {
          role: "Admin",
          username: option.user_name,
        };
      case "director":
        return {
          role: "Director",
          username: option.user_name,
        };
      case "supervisor":
        return {
          role: "Supervisor",
          username: option.user_name,
        };
      case "customercare":
        return {
          role: "Customer Care",
          username: option.user_name,
        };
      case "clinicianviewonly":
        return {
          role: "Clinician View Only",
          username: option.user_name,
        };
      case "clinician":
        return {
          role: "Clinician",
          username: option.user_name,
        };
      case "familymember":
        return {
          role: "Family Member",
          username: option.user_name,
        };
      case "child":
        return {
          role: "Child",
          username: option.child_name,
        };
      case "parent":
        return {
          role: "Parent",
          username: option.user_name,
        };
      case "user":
        return {
          role: "User",
          username: option.user_name,
        };
      case "clientId":
        return {
          role: "Client",
          username: option.child_name || option.user_name,
        };
      case "clinicianId":
        return {
          role: "Clinician",
          username: option.user_name,
        };
      case "id":
        return {
          role: "User",
          username: option.user_name,
        };
      default:
        return null;
    }
  };

  onSelectOption = async (event, value) => {
    if (this.props.searchResults.length > 0) {
      const allUsers = this.state.allUsers;
      this.setState({
        ...initialState(),
        rerenderKey: new Date().toISOString(),
      });
      await this.props.clearCustomerSearch();
      this.props.onSelectSearchOption(event, value, allUsers);
    }
  };

  toggleUserSearch = () => {
    this.setState({ allUsers: !this.state.allUsers });
  };

  setFocus = () => {
    if (!this.state.focusOn) {
      this.setState({ focusOn: true });
    }
  };

  render() {
    const { classes, searchResults, searchLoading } = this.props;
    const { search, focusOn, rerenderKey, allUsers } = this.state;

    return (
      <>
        <div className={classes.searchBarContainer}>
          <div className={classes.searchBar} onClick={this.setFocus}>
            <Autocomplete
              key={rerenderKey}
              name="CustomerSearch"
              id="customerSearchAutocomplete"
              onInputChange={(e, v) => {
                this.onChange(e, v);
              }}
              inputValue={search}
              options={searchResults}
              freeSolo
              filterOptions={(x) => x}
              getOptionLabel={(option) => this.renderOptionLabel(option)?.username}
              renderOption={(props, option) => {
                const label = this.renderOptionLabel(option);
                const typeUser = this.getUserType(option);

                return (
                  <section {...props} key={props.id}>
                    {!option.is_active ? (
                      <XSquare weight="duotone" />
                    ) : (
                      <TypeUserIcon type={typeUser} />
                    )}
                    <span className={classes.label}>{label.username}</span>
                    {allUsers && (
                      <Typography variant="caption" component="small">
                        {label.role}
                      </Typography>
                    )}
                  </section>
                );
              }}
              PaperComponent={({ children }) => (
                <Paper
                  elevation={8}
                  classes={{ root: classes.paper }}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <SearchOptionsList
                    onChangeFilter={this.onChangeFilter}
                    {...this.state}
                    {...this.props}
                    children={children}
                  />
                </Paper>
              )}
              fullWidth
              onChange={(event, value) => this.onSelectOption(event, value)}
              autoHighlight={true}
              disablePortal
              loading={searchLoading}
              clearOnBlur
              blurOnSelect
              openOnFocus={true}
              classes={{
                popperDisablePortal: classes.popperDisablePortal,
                root: classes.searchBarInput,
                loading: classes.label,
              }}
              PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  onBlur={() => {
                    this.setState({ ...initialState() });
                    this.props.clearCustomerSearch();
                  }}
                  placeholder={"Search"}
                  inputRef={(input) => {
                    if (input != null && focusOn) {
                      input.focus();
                    }
                  }}
                  sx={{ height: 38 }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <MagnifyingGlass size={20} color="rgba(0, 0, 0, 0.56)" />,
                  }}
                />
              )}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientSearch));
