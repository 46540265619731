import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SubHeading from "./SubHeading";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

const SectionBox = (props) => {
  const { classes, loading, columnDirection, title, description, ...rest } = props;
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center">
      <Box width="100%" maxWidth="1200px" padding={theme.spacing(5)} {...rest}>
        <Box
          display={"flex"}
          sx={{
            flexDirection: "row",
            "@media (max-width: 1200px)": { flexDirection: "column" },
            marginBottom: theme.spacing(11),
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              width: "75%",
              mb: 7,
              "@media (max-width: 1200px)": { flexDirection: "column", width: "100%" },
            }}
          >
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={columnDirection ? "column" : "row"}
            width={"100%"}
            sx={{ "@media (min-width: 1200px)": { pl: "24px" } }}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress size={30} color="primary" style={{ margin: 10 }} />
                <SubHeading>Loading</SubHeading>
              </div>
            ) : (
              <React.Fragment>{props.children}</React.Fragment>
            )}
          </Box>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};
export default SectionBox;
