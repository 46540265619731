import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../../styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { DIAGNOSTIC_CODES, ETHNICITIES, LANGUAGES, STATES, TIMEZONES } from "../../../../constants";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import TimezonePicker from "elements/TimezonePicker";
import SectionBox from "elements/SectionBox";

import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import customerInfoConfig from "./CustomerInfo";
import ANFormView from "../../../../elements/Forms/ANFormView";

const PersonalInformation = (props) => {
  const {
    classes,
    customerDetails,
    editMode,
    firstName,
    lastName,
    dob,
    gender,
    pronouns,
    address1,
    address2,
    city,
    state,
    zipCode,
    clientTimezone,
    zipError,
    onChange,
    onChangeDate,
    onChangeNumber,
    setError,
    clientDiagnosis,
    preferredFirstName,
    identifiedGender,
    transgender,
    ethnicity,
    primaryLanguage,
    needsInterpreter,
    medicalConditions,
    livesWith,
    onChangeArrayToString,
    preAuthRequired,
    requiresTablet,
    isCaregiver,
  } = props;
  let customerInfo = customerDetails?.details;

  const [ethnicityList, setEthnicityList] = React.useState([]);

  useEffect(() => {
    if (ethnicity) {
      setEthnicityList(ethnicity.split(",").map((item) => item.trim()));
    }
  }, [ethnicity]);

  const handleChange = (name) => (event) => {
    const {
      target: { value },
    } = event;
    setEthnicityList(
      typeof value === "string" ? value.split(",").map((item) => item.trim()) : value
    );
    onChangeArrayToString(name, value);
  };

  return (
    <>
      <SectionBox
        title="Personal Information"
        description={`${isCaregiver ? "Name, address, and personal details" : "Manage client details such as name, address, and demographics"}`}
        columnDirection
      >
        {editMode ? (
          <>
            <Grid container spacing={7}>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="firstName"
                  label="First Name"
                  onChange={onChange("firstName")}
                  value={firstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="preferredFirstName"
                  label="Preferred First Name"
                  onChange={onChange("preferredFirstName")}
                  value={preferredFirstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="lastName"
                  label="Last Name"
                  onChange={onChange("lastName")}
                  value={lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <ANDatePicker
                  label="Date of Birth"
                  value={new Date(dob)}
                  onChange={(date) => onChangeDate("dob", date)}
                  format="PP" //"MMM d, yyyy"
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="gender"
                  label="Sex Assigned at Birth"
                  select
                  onChange={onChange("gender")}
                  value={gender}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="non_binary">Non-binary</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="identifiedGender"
                  label="Identified Gender "
                  select
                  onChange={onChange("identifiedGender")}
                  value={identifiedGender}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="non_binary">Non-binary</MenuItem>
                  <MenuItem value="gender_gliud">Gender Fliud</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="transgender"
                  label="Transgender"
                  select
                  onChange={onChange("transgender")}
                  value={transgender ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="pronouns"
                  select
                  label="Pronouns"
                  onChange={onChange("pronouns")}
                  value={pronouns}
                  fullWidth
                >
                  <MenuItem value="" disabled />
                  <MenuItem value="she/her">She/Her</MenuItem>
                  <MenuItem value="he/him">He/Him</MenuItem>
                  <MenuItem value="they/them">They/Them</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="daignosis"
                  select
                  label="Diagnosis"
                  name="diagnosis"
                  value={clientDiagnosis}
                  onChange={onChange("clientDiagnosis")}
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Diagnosis
                  </MenuItem>
                  {DIAGNOSTIC_CODES.map((code, index) => {
                    return (
                      <MenuItem key={index} value={code}>
                        {code}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="address1"
                  label="Address"
                  onChange={onChange("address1")}
                  value={address1}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="address2"
                  label="Address 2"
                  onChange={onChange("address2")}
                  value={address2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="state"
                  select
                  label="State"
                  onChange={onChange("state")}
                  value={state}
                  fullWidth
                  autoComplete="off"
                >
                  <MenuItem disabled value={""}>
                    Select a state
                  </MenuItem>
                  {Object.keys(STATES).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {STATES[key]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="city"
                  label="City"
                  onChange={onChange("city")}
                  value={city}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="zipCode"
                  label="ZIP Code"
                  onChange={onChangeNumber("zipCode")}
                  value={zipCode}
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  onFocus={() => setError("zipError", false)}
                  onBlur={() =>
                    zipCode.trim().length === 5 || !zipCode
                      ? setError("zipError", false)
                      : setError("zipError", true)
                  }
                  helperText={zipError ? "Invalid ZIP Code" : null}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TimezonePicker
                  timezone={clientTimezone}
                  onTimezoneChange={onChange("clientTimezone")}
                  classes={classes}
                  outlined={true}
                  fullWidth
                  // InputLabelProps={{ shrink: true }}
                  label="Time Zone"
                />
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="livesWith"
                  label="Client Lives With"
                  onChange={onChange("livesWith")}
                  value={livesWith}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <FormControl className="select" sx={{ width: "100%" }}>
                  <InputLabel>Race & Ethnicity</InputLabel>
                  <Select
                    id="ethnicity"
                    value={ethnicityList}
                    onChange={handleChange("ethnicity")}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => <Typography>{selected.join(", ")}</Typography>}
                    multiple
                    displayEmpty
                  >
                    {ETHNICITIES.map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={ethnicityList.indexOf(key) > -1} />
                          <ListItemText
                            primary={key}
                            primaryTypographyProps={{ variant: "body2" }}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="primaryLanguage"
                  name="primaryLanguage"
                  label="Primary Language"
                  value={primaryLanguage}
                  onChange={onChange("primaryLanguage")}
                  fullWidth
                  select
                >
                  <MenuItem disabled value={""} />
                  {LANGUAGES.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="needsInterpreter"
                  select
                  label="Interpreter Required"
                  onChange={onChange("needsInterpreter")}
                  value={needsInterpreter ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="medicalConditions"
                  label="Medical Conditions"
                  onChange={onChange("medicalConditions")}
                  value={medicalConditions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  id="preAuthRequired"
                  label="Pre Authorization Required"
                  select
                  onChange={onChange("preAuthRequired")}
                  value={preAuthRequired ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={onChange("requiresTablet")}
                      id={"requiresTablet"}
                      name="legal_guardian"
                      checked={requiresTablet ?? false}
                    />
                  }
                  label="Requires Tablet"
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <ANFormView data={customerInfo} config={customerInfoConfig} />
        )}
      </SectionBox>
    </>
  );
};

export default withStyles(styles)(PersonalInformation);
