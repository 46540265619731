import React from "react";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import { TableCell, TableRow, Typography, Tooltip, Link } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { COVERAGE_STATES } from "../../constants";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 13,
  },
}))(Tooltip);

const InsuranceUtilityTable = (props) => {
  const {
    insurancePlans,
    page,
    rowsPerPage,
    count,
    sort,
    sortDirection,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
  } = props;
  const rows = insurancePlans;
  return (
    <CustomTable
      count={parseInt(count)}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Insurance Plans Found"
      headerCells={[
        <CustomHeaderCell
          label="Insurance Name"
          sortable={true}
          sortDirection={sort === "i.name" ? sortDirection : false}
          active={sort === "i.name"}
          onClick={() => handleSortClick("i.name")}
        />,
        <CustomHeaderCell
          label="Insurance Plan"
          sortable={true}
          sortDirection={sort === "p.name" ? sortDirection : false}
          active={sort === "p.name"}
          onClick={() => handleSortClick("p.name")}
        />,
        <CustomHeaderCell label="Plan Address" />,
        <CustomHeaderCell label="Medicaid Plan" />,
        <CustomHeaderCell label="Location Modifier" />,
        <CustomHeaderCell label="Provider Modifier" />,
        <CustomHeaderCell label="Telehealth Modifier" />,
        <CustomHeaderCell label="Coverage State" />,
      ]}
    >
      {rows.length > 0 &&
        rows.map((row) => {
          const address = [
            `${row.address_1?.trim()}${row.address_2 ? " " + row.address_2?.trim() : ""}`,
            `${row.city?.trim()}, ${row.state} ${row.zip}`,
          ];
          const payerId = row.payer_id ? `[${row.payer_id?.trim()}]` : "";
          return (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                <Link onClick={() => props.history.push(`libraries/insurance/${row.insurance_id}`)}>
                  {row.insurance_name}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link onClick={() => props.history.push(`libraries/insurance/plan/${row.id}`)}>
                  {row.name} {payerId}
                </Link>
              </TableCell>
              <TableCell align="left">
                <CustomTooltip
                  title={<div style={{ whiteSpace: "pre-line" }}>{address.join("\n")}</div>}
                  placement="top"
                  arrow
                >
                  <span
                    style={{
                      width: 250,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "block",
                    }}
                  >
                    {address.join(" ")}
                  </span>
                </CustomTooltip>
              </TableCell>
              <TableCell align="left">{row.is_medicaid ? "Yes" : "No"}</TableCell>
              <TableCell align="left">{row.location_modifier}</TableCell>
              <TableCell align="left">{row.provider_modifier}</TableCell>
              <TableCell align="left">{row.telehealth_modifier}</TableCell>
              <TableCell align="left">
                {COVERAGE_STATES[row.coverage_state] ?? "National"}
              </TableCell>
            </TableRow>
          );
        })}
    </CustomTable>
  );
};

export default InsuranceUtilityTable;
