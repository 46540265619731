import React from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import actions from "actions";
import { VideoChat } from "components/VideoChat/VideoChat";
import { mapStateToProps } from "./State/mapStateToProps";
import { mapDispatchToProps } from "./State/mapDispatchToProps";
import DebugOverlay from "components/DebugOverlay";

import MeetingSession from "video/meeting/MeetingSession";
import MeetingRoster, { UserPresenceLevel } from "video/meeting/MeetingRoster";
import MessagingSession from "video/messaging/MessagingSession";
import { log } from "video/debug";
import { endMeeting } from "api/video";
import { useJamMetadata } from "hooks/useJamMetadata";

import styles from "./styles";

const { IN_CALL, IN_LOBBY, ABSENT, JOINING, RECONNECTED, RECONNECTING, SCREEN_SHARING } =
  UserPresenceLevel;

const VideoInputQualityNetworkLevelMap = {
  "144p": 0.5, // 0 bars
  "222p": 1, // 1 bars
  "360p": 2, // 2 bars
  "540p": 3, // 3 bars (3/4 both show 3 bars)
  "720p": 5, // 4 bars
  "1080p": 6, // not used currently
  "2160p": 7, // not used currently
};

const [jamMetadata, addJamMetadata] = useJamMetadata();

export class VideoChatV2 extends VideoChat {
  get audioVideoController() {
    return this.meetingSession?.audioVideoController;
  }

  get contentShareController() {
    return this.meetingSession?.contentShareController;
  }

  /** @type {MessagingSession} */
  messagingSession;

  /** @type {MeetingRoster} */
  meetingRoster;

  startedVideoCall = false;

  __connectionAttempts = 0;

  __rejoining = false;

  static initialState = (additionalState = {}) => ({
    allowedToJoinCall: true,

    // video streams' states
    remoteVideoDisabled: true,
    remoteClinicianVideoDisabled: true,
    secondaryVideoDisabled: true,

    // joined flags (have pressed 'JOIN' button - persists upon reconnect UNTIL clinician press END CALL button)
    clientJoined: false,
    clinicianJoined: false,
    hasJoinedRoom: false,

    // connection status (resets upon reconnect)
    clientConnected: false,
    primaryClinicianConnected: false,
    secondaryClinicianConnected: false,
    secondaryClinicianData: null,

    // screen sharing
    clinicianScreenSharing: false,
    parentScreenSharing: false,

    ...additionalState,
  });

  constructor(props) {
    super(props, VideoChatV2.initialState());

    window.vc2 = () => this;

    this.state = {
      ...this.state,
      ...VideoChatV2.initialState(),

      /** @type {VideoQuality | "AUTO"} */
      desiredVideoQuality: "AUTO",

      /** @type {VideoQuality} */
      currentVideoQuality: "540p",

      networkDebugEnabled: false,
      networkDebugInfo: {
        videoDownstreamPacketLossPercent: 0,
        videoUpstreamPacketLossPercent: 0,
        availableIncomingBitrate: 0, // bps
        availableOutgoingBitrate: 0, // bps
      },
    };

    // overloads for irrelevant parent calls
    this.captureScreen = this.enableScreenSharing.bind(this);
    this.endScreenCapture = this.disableScreenSharing.bind(this);
    this.attachParticipantTracks = () => {};
    this.detachParticipantTracks = () => {};
    this.disconnectVideo = async () => {
      this.state.activeRoom?.disconnect();
    };

    this.leaveRoom = async () => {
      if (this.state.activity.active) {
        this.endActivity();
      }
      if (this.state.roomLayout !== "standard") {
        this.updateRoomLayout("standard");
      }
      this.emit("end-call", { videoId: this.props.videoId });

      // send final channel state as a persistent messsage AND set it as the channel's metadata
      await Promise.all([
        this.messagingSession
          ?.sendPersistentControlMessage(
            JSON.stringify({
              state: "ENDED",
              updated: Date.now(),
              userPresenceMap: this.meetingRoster.userPresenceMap,
            }),
            "MEETING_STATE_UPDATE"
          )
          .catch((e) => {
            this.props.logError({
              errorType: "CHIME_END_ERROR",
              errorMessage: `failed to send final state to channel  - ${e.message || JSON.stringify(e)}`,
            });
          }),
        endMeeting(this.props.videoId).catch((e) => {
          this.props.logError({
            errorType: "CHIME_END_ERROR",
            errorMessage: `failed to send final state to api - ${e.message || JSON.stringify(e)}`,
          });
        }),
      ]);

      this.disconnectFromMeeting();

      this.setState({ endCallTriggered: true, cameraDisabled: true, muted: true });

      this.checkEndCall = setTimeout(() => {
        this.props.getVideoCallInfo(this.props.videoKey);
        this.setState({
          hasJoinedRoom: false,
          localMediaAvailable: false,
          endCallDialogOpen: false,
          backPressDialogOpen: false,
          confirmedGoBack: true,
          animation: {},
          activity: {},
        });
      }, 2000);
    };

    // get the number of 'bars' to show based on videoInputQuality
    // TODO this only makes sense with 'AUTO' since it's changing videoInputQuality based on network quality
    this.resolveLocalParticipantNetworkLevel = () => {
      return (
        VideoInputQualityNetworkLevelMap[
          this.audioVideoController?.videoInputController?.videoInputQuality || ""
        ] || 0
      );
    };

    this.componentDidMount = async () => {
      if (this.props.userId && this.props.videoId) {
        this.fetchMessagingConfig(this.props.videoId, this.props.userId);
      }

      await super.componentDidMount();

      // there are some conditions in the parent which only work when the Component is mounted before the respective network calls complete and the store is updated
      if (
        this.props.startDate &&
        this.props.endDate &&
        this.props.userPermissions?.sign_after_video_call &&
        !this.props.isCaregiver
      ) {
        this.props.history.push("/billing");

        // if (this.props.oneTimeVideoInfo?.billing_type === "ORIENTATION") {
        //   this.props.history.push("/dashboard");
        // } else {
        //   this.emit("check-billing-completion", {
        //     videoId: this.props.videoId,
        //     userId: this.props.userId,
        //   });
        // }
      }
    };

    this.componentDidUpdate = async (prevProps, prevState) => {
      // this check extends the one in the parent, which is only checking for userId
      if (!(prevProps.userId && prevProps.videoId) && this.props.userId && this.props.videoId) {
        this.fetchMessagingConfig();
      }

      // fix for race condition in parent
      if (
        !prevState.clientJoined &&
        this.state.clientJoined &&
        this.props.videoId &&
        !(this.props.isCaregiver || this.props.endDate)
      ) {
        this.setJoinRoomReady();
      }

      // pressed JOIN CALL button
      if (!prevState.readyToJoinCall && this.state.readyToJoinCall) {
        this.meetingRoster
          ?.setCurrentPresence(UserPresenceLevel.JOINING)
          .then(() => this.rebuildState());
      }

      // flush the socket queue once it's newly connected
      if (this.state.socketConnected && !prevState.socketConnected) {
        while (this.__socketQueue.length) {
          this.__socketQueue.shift().call(this, this.socket);
        }
      }

      // desired video quality setting was changed
      if (this.state.desiredVideoQuality !== prevState.desiredVideoQuality) {
        this.audioVideoController.videoInputController.setDesiredVideoInputQuality(
          this.state.desiredVideoQuality
        );
      }

      // as soon as we get the Messaging config back lets initialize it
      if (this.props.ChannelArn && this.props.ChannelArn !== prevProps.ChannelArn) {
        this.onMessagingConfigReceived();
      }

      // as soon as we get the Meeting config back lets initialize it
      if (
        this.props.Meeting?.MeetingId &&
        this.props.Meeting.MeetingId !== prevProps.Meeting?.MeetingId
      ) {
        this.onMeetingConfigReceived();
      }

      // Camera Input device changed
      if (this.state.currentCameraInput !== prevState.currentCameraInput) {
        this.audioVideoController?.setVideoInput(this.state.currentCameraInput);
        this.audioVideoController?.restartVideoInput();
      }

      // Audio Input device changed
      if (this.state.currentAudioInput !== prevState.currentAudioInput) {
        this.audioVideoController?.setAudioInput(this.state.currentAudioInput);
      }

      // Audio Output device changed
      if (this.state.currentAudioOutput !== prevState.currentAudioOutput && this.localMedia) {
        this.audioVideoController?.setAudioOutput(this.state.currentAudioOutput, this.localMedia);
      }

      return super.componentDidUpdate(prevProps, prevState);
    }; // </ComponentDidUpdate>

    this.cleanupComponent = async () => {
      if (this.state.clinicianScreenSharing || this.state.parentScreenSharing) {
        this.setScreenShareOff();
      }
      if (this.state.previewTracks) {
        this.state.previewTracks.forEach((track) => {
          track.stop();
        });
      }
      if (this.state.localVideoTrack) {
        this.state.localVideoTrack.stop();
      }
      return new Promise((res) =>
        this.rebuildState(
          {
            activeRoom: null,
            hasJoinedRoom: false,
            localMediaAvailable: false,
            localParticipantNetworkLevel: null,
            localParticipantNetworkLevelReceived: false,
          },
          res
        )
      );
    };

    const { updateRoomLayout } = this;
    this.updateRoomLayout = (layout) => {
      updateRoomLayout(layout);
      process.nextTick(() => this.rebuildState());
    };

    // Device change handlers
    this.handleUserCameraChange = (e) => {
      this.setCurrentInput("currentCameraInput", e.target.value);
    };
    this.handleUserMicrophoneChange = (e) => {
      this.setCurrentInput("currentAudioInput", e.target.value);
    };
    this.handleUserSpeakerChange = (e) => {
      this.setCurrentInput("currentAudioOutput", e.target.value);
    };

    this.disableAudio = () => {
      this.audioVideoController?.audioInputController?.stop();
    };
    this.enableAudio = () => {
      this.audioVideoController?.audioInputController?.start();
    };

    this.disableVideo = () => {
      this.audioVideoController?.videoInputController?.stop();
    };
    this.enableVideo = () => {
      this.audioVideoController?.videoInputController?.start();
    };

    // Device 1/0 handlers
    this.toggleAudio = () => {
      if (this.state.muted) {
        this.enableAudio();
      } else {
        this.disableAudio();
      }

      this.toggleAudioView();
    };

    this.toggleVideo = () => {
      if (this.state.cameraDisabled) {
        this.enableVideo();
        this.onCloseVideoDisabledDialog();
      } else {
        if (!this.props.isCaregiver) {
          this.disableVideo();
        }
        this.onOpenVideoDisabledDialog();
      }

      this.toggleVideoView();
    };

    this.joinRoom = async () => {
      this.setState({ joinRoomTriggered: true });
      if (!this.timerId) {
        document.addEventListener("mousemove", this.mouseMove);
        document.addEventListener("mousedown", this.mouseMove);
      }
      if (!this.getUnreadMessageCountInterval) {
        this.getUnreadMessageCountInterval = setInterval(() => {
          this.getUnreadMessageCount();
        }, 3000);
      }
      if (this.state.isDemo) {
        await this.connectToRoom();
        this.startTimer();
      }
    };

    this.connectToRoom = async () => {
      if (!this.messagingSession) {
        log(`messagingSession not initialized, re-attempting to fetch messaging session config`);
        this.props.clearMeetingConfig();
        return this.fetchMessagingConfig();
      }
      log("Fetching Meeting Config", this.meetingRoster.userPresenceMap);
      this.__joiningMeeting = true;
      this.props.fetchMeetingConfig(this.props.videoId);
      this.toggleNetworkDebug();
    };

    // don't allow it to be called multiple times
    const { onStartVideoCall } = this;
    this.onStartVideoCall = () => {
      if (this.startedVideoCall) {
        return console.warn("blocked repeat start call");
      }
      this.startedVideoCall = true;
      onStartVideoCall();
    };
  }

  __domEventListeners = [];
  componentWillUnmount = () => {
    super.componentWillUnmount();
    window.vc2 = null;
    this.__domEventListeners.forEach(([name, fn]) => document.removeEventListener(name, fn));
  };

  /**
   * @param {"localMedia"|"remoteMedia"|"clinicianRemoteMedia"|"lobbyLocalVideo"|"screenShareMedia"|"remoteScreenShareMedia"} refName
   * @param {HTMLElement?} prev
   * @param {HTMLElement?} cur
   */
  onRef = (refName, prev, cur) => {
    super.onRef(refName, prev, cur);

    this.meetingSession?.audioVideoController?.videoInputController?.checkVideoTileBindings();

    switch (refName) {
      case "localMedia":
        // local video output
        // audio output
        this.meetingSession?.audioVideoController?.changeAudioOutput(
          this.state.currentAudioOutput,
          this.localMedia
        );
        break;
    }
  };

  __socketQueue = [];
  emit = (...args) => {
    this.state.socketConnected
      ? this.socket.emit(...args)
      : this.__socketQueue.push(() => this.socket.emit(...args));
  };

  fetchMessagingConfig = () => {
    this.props.fetchMessagingConfig();
  };

  rebuildState = (additionalState = {}, cb = () => {}) => {
    const state = VideoChatV2.initialState(additionalState);

    // sticky flags (don't reset)
    state.clientJoined = this.state.clientJoined || state.clientJoined;
    state.hasJoinedRoom = this.state.hasJoinedRoom || state.hasJoinedRoom;

    if (this.meetingRoster)
      for (const userId of this.meetingRoster) {
        const videoTileState =
          this.audioVideoController?.videoInputController?.getUserVideoTileState(userId);
        const videoContentTileState =
          this.audioVideoController?.videoInputController?.getUserVideoContentTileState(userId);

        // precedence is to assume user is present if they are transmitting video
        const present =
          !!videoTileState ||
          !!videoContentTileState ||
          this.meetingRoster.checkUserPresence(userId, IN_CALL);
        const presentish =
          !!videoTileState ||
          !!videoContentTileState ||
          this.meetingRoster.checkUserPresence(userId, JOINING);

        const currentUser = userId == this.props.userId;
        const connected = presentish && !currentUser;
        const sharing = !!videoContentTileState;

        if (currentUser) {
          state.hasJoinedRoom = state.hasJoinedRoom || present;
        }

        // User is PRIMARY
        if (userId == this.props.primaryClinicianUserId) {
          state.primaryClinicianConnected = connected;
          state.clinicianJoined = state.clinicianJoined || (this.props.isCaregiver && present);
          state.clinicianScreenSharing = state.clinicianScreenSharing || (sharing && currentUser);

          if (!videoTileState && this.props.isCaregiver) {
            state.remoteVideoDisabled = true;
          } else if (videoTileState && this.props.userId == this.props.secondaryClinicianUserId) {
            state.remoteClinicianVideoDisabled = false;
          }
        }

        // secondaryVideoDisabled       = false // isCaregiver === true     main   full    tile for caregiver
        // remoteClinicianVideoDisabled = false // isCaregiver === false    remote preview tile for (primary?) clinician

        // User is SECONDARY
        else if (userId == this.props.secondaryClinicianUserId) {
          if (connected) {
            state.secondaryClinicianData = this.props.videoParticipants.find(
              (p) => p.user_id == userId
            );
          }
          state.secondaryClinicianConnected = connected;
          state.clinicianJoined = state.clinicianJoined || (this.props.isCaregiver && present);
          state.clinicianScreenSharing = state.clinicianScreenSharing || (sharing && currentUser);

          if (videoTileState && this.props.isCaregiver) {
            state.secondaryVideoDisabled = false;
          } else if (videoTileState && this.state.isPrimaryClinician) {
            state.remoteClinicianVideoDisabled = false;
          }
        }

        // User is CLIENT
        else if (
          userId == this.props.clientUserId ||
          userId == this.props.clientJoinedData?.user_id
        ) {
          state.clientConnected = connected;
          state.clientJoined = state.clientJoined || present;
          state.remoteVideoDisabled = false;
          state.parentScreenSharing =
            state.parentScreenSharing || (sharing && currentUser && this.props.isCaregiver);
        }
      }

    // if client is not readyToJoinCall then we can't consider the clinician's state
    if (this.props.isCaregiver && !this.state.readyToJoinCall) {
      state.clinicianJoined = false;
    }

    if (this.props.isCaregiver && !state.clinicianJoined) {
      state.roomLayout = "standard";
    }

    // demo overrides
    if (this.state.isDemo) {
      state.clientConnected = state.clientJoined = true;
    }

    addJamMetadata({ state });

    this.setState(state, cb);
  };

  onMessagingConfigReceived = async () => {
    addJamMetadata(this.messagingSessionConfig);
    this.initializeMessagingSession().catch((error) => {
      this.setState({ connectionFailedDialogOpen: true });

      let message = `User (user_id: ${this.props.userId}) failed to connect to video messaging channel
          (video_call_id: ${this.props.videoId}).
          {ERROR_CODE: ${error.code},
          ERROR_MESSAGE: ${error.message}}`;

      console.error(message);

      this.props.logError({
        errorType: "CHIME-FAILED-CONNECTION-EVENT",
        errorMessage: message,
      });

      this.emit("report-connection-failed", {
        videoId: this.props.videoId,
        userId: this.props.userId,
        ...(this.messagingSessionConfig || {}),
      });

      this.rejoinMessaging(error.code, error.message);
    });
  };

  onMeetingConfigReceived = async () => {
    this.initializeMeetingSession().catch((error) => {
      this.setState({ connectionFailedDialogOpen: true });

      let message = `User (user_id: ${this.props.userId}) failed to connect to video call
          (video_call_id: ${this.props.videoId}).
          {ERROR_CODE: ${error.code},
          ERROR_MESSAGE: ${error.message}}`;

      console.error(message);

      this.props.logError({
        errorType: "CHIME-FAILED-CONNECTION-EVENT",
        errorMessage: message,
      });

      this.emit("report-connection-failed", {
        videoId: this.props.videoId,
        userId: this.props.userId,
      });

      this.rejoinMeeting(error.code, error.message);
    });
  };

  onCurrentUserNewlyJoined = () => {
    // we need to give some time to see if we get a heartbeat from PRIMARY before determining they are absent
    // when PRIMARY joins, they should sent a heartbeat immediately
    const startTime = Date.now();
    const check = () => {
      if (this.state.callEnded) return;
      const waitTime = Date.now() - startTime;
      const primaryIsPresent = this.meetingRoster?.checkUserPresence(
        this.props.primaryClinicianUserId,
        IN_CALL
      );

      // pass controls to secondary if primary hasn't joined yet
      if (
        waitTime >= 2000 &&
        !primaryIsPresent &&
        !this.state.isPrimaryClinician &&
        !this.props.isCaregiver
      ) {
        this.emit("accept-controls", {
          videoId: this.props.videoId,
          userId: this.props.userId,
        });
        if (this.state.roomLayout !== "standard") {
          this.updateRoomLayout("standard");
        }
        if (this.state.activity.active) {
          this.endActivity();
        }
      } else if (!primaryIsPresent) {
        setTimeout(check, 100);
      }
      // else primary is present, no need to continue checking
    };
    check();
  };

  /**
   * @param {import('amazon-chime-sdk-js').VideoTileState} videoTileState
   */
  getUserVideoContainer = (videoTileState) => {
    const { userId, clientUserId, primaryClinicianUserId, isCaregiver } = this.props;
    const { isContent, localTile, boundExternalUserId } = videoTileState;

    if (localTile) {
      return this.localMedia;
    } else if (isContent && boundExternalUserId == userId) {
      return this.screenShareMedia;
    } else if (isContent) {
      return this.remoteScreenShareMedia;
    } else if (
      (boundExternalUserId == clientUserId && !isCaregiver) ||
      (boundExternalUserId == primaryClinicianUserId && isCaregiver)
    ) {
      return this.remoteMedia;
    }
    return this.clinicianRemoteMedia;
  };

  getDevices = () => ({
    audioOutputContainer: this.localMedia,
    audioInputDevice: this.state.currentAudioInput,
    audioOutputDevice: this.state.currentAudioOutput,
    videoInputDevice: this.state.currentCameraInput,
  });

  initializeMessagingSession = async () => {
    const messagingSessionConfig = {
      AppInstanceArn: this.props.AppInstanceArn,
      ChannelArn: this.props.ChannelArn,
      MemberArn: this.props.MemberArn,
    };
    addJamMetadata(messagingSessionConfig);
    this.messagingSessionConfig = messagingSessionConfig;
    this.messagingSession = new MessagingSession(messagingSessionConfig);
    this.meetingRoster = new MeetingRoster(this.messagingSession);

    // User Roster controller
    this.meetingRoster
      .onUserPresenceChange("*", (userId, curPresenceLevel, prevPresenceLevel) => {
        addJamMetadata({
          userPresence: this.meetingRoster?.userPresenceMap,
          userLastSeen: this.meetingRoster?.userUpdatedAtMap,
        });

        /*
          This is where we initially fetch the Meeting config
          It depends on at least 1 other User being presentish (i.e. they are JOINING or greater)
        */
        const canConnect =
          (this.meetingRoster.checkUserPresence(this.props.primaryClinicianUserId, JOINING) &&
            this.meetingRoster.checkUserPresence(this.props.clientUserId, JOINING)) ||
          !!this.props.startDate;

        if (
          (this.meetingRoster?.usersPresentish() > 1 || this.props.startDate) &&
          !this.__joiningMeeting &&
          !this.props.Meeting?.MeetingId &&
          this.state.readyToJoinCall &&
          canConnect
        ) {
          this.connectToRoom();
        }
        this.rebuildState({
          remoteConnectionFailedDialogOpen: false,
          connectionFailedParticipant: null,
        });
      })
      .onUserPresenceChange(this.props.userId, (userId, curPresenceLevel, prevPresenceLevel) => {
        if (curPresenceLevel >= IN_CALL && prevPresenceLevel < IN_CALL) {
          this.onCurrentUserNewlyJoined();
        }
      });

    return this.messagingSession.connect();
  };

  initializeMeetingSession = async () => {
    this.rebuildState();

    this.meetingSession = new MeetingSession(
      this.props.Meeting,
      this.props.Attendee,
      this.meetingRoster,
      this.getUserVideoContainer,
      this.getDevices,
      () => ({
        audioInputEnabled: !this.state.muted,
        audioOutputEnabled: true,
        videoInputEnabled: !this.state.cameraDisabled,
      })
    );
    this.meetingSession.onStart(() => {
      this.__joiningMeeting = false;
      this.__rejoining = false;
      addJamMetadata({ meetingSessionStarted: true });

      if (this.props.isAssignedClinician || this.props.isCaregiver) {
        this.startTimer();
      }
      this.setState({
        hasStartedCall: true,
        connectionFailedDialogOpen: false,
        socketConnected: this.socket?.connected,
        remoteConnectionFailedDialogOpen: false,
        connectionFailedParticipant: null,
        sessionFullDialogOpen: false,

        activeRoom: { disconnect: this.disconnectFromMeeting.bind(this) },
        localParticipantNetworkLevel: this.resolveLocalParticipantNetworkLevel(),
        localParticipantNetworkLevelReceived: true,
        localMediaAvailable: true,
      });

      if (this.state.isDemo) {
        // don't need this in the Demo
        this.meetingRoster.realtimeHeartbeatController.stop();
      }
    });

    await this.meetingSession.initializer;

    this.meetingRoster.meetingSession = this.meetingSession;

    // EventController manages reJOINing (not reCONNECTing) on fatal errors
    this.meetingSession.eventController.onFatalError((errorMsg, eventName) => {
      addJamMetadata({ meetingSessionFatalError: { errorMsg, eventName } });
      process.nextTick(() => {
        this.state.callEnded || this.rejoinMeeting(errorMsg, eventName);
      });
    });

    this.audioVideoController.onVideoTileChanged((params, cb) => {
      addJamMetadata({
        videoTileState: this.meetingSession?.audioVideo
          .getAllVideoTiles()
          .map((tile) => tile.state()),
      });
      this.rebuildState({}, cb);
    });

    this.meetingSession.onFatalError(this.onAvError);

    this.audioVideoController.videoInputController.onVideoQualityAutoChange(
      (videoInputQuality, videoQualitySettings, maxBitrateKbps) => {
        addJamMetadata({ videoInputQuality, ...(this.networkDebugInfo?.networkDebugInfo || {}) });
        this.setState({
          currentVideoQuality: videoInputQuality,
          localParticipantNetworkLevel: VideoInputQualityNetworkLevelMap[videoInputQuality],
          localParticipantNetworkLevelReceived: true,
        });
      }
    );

    // this.disableScreenSharing will trigger this after the content goes away
    this.meetingSession.contentShareController.onContentShareDidStart(() => {
      const { isCaregiver } = this.props;
      const { showClientScreenShare, cameraDisabled } = this.state;

      if (showClientScreenShare && !isCaregiver) {
        this.onHideClientScreenShare();
      }

      this.setState(
        {
          screenTrack: this.meetingSession.contentShareController.stream?.getVideoTracks()?.[0],
          screenTrackAudio: this.meetingSession.contentShareController.stream.getAudioTracks()?.[0],
          localVideoTrack: null,
          [isCaregiver ? "parentScreenSharing" : "clinicianScreenSharing"]: true,
        },
        this.setScreenShareOn
      );

      if (cameraDisabled) {
        this.toggleVideo();
      }
    });
    // this.disableScreenSharing will trigger this after the content goes away
    this.meetingSession.contentShareController.onContentShareDidStop(() => {
      const { isCaregiver } = this.props;
      this.setState(
        {
          screenTrack: null,
          screenTrackAudio: null,
          [isCaregiver ? "parentScreenSharing" : "clinicianScreenSharing"]: false,
        },
        this.setScreenShareOff
      );
    });

    this.meetingSession.onReconnecting(() => {
      log("reconnecting...");
      addJamMetadata({ reconnecting: true, reconnected: false });
      this.meetingRoster.setCurrentPresence(RECONNECTING);
      this.setState({ connectionFailedDialogOpen: true });
    });
    this.meetingSession.onReconnected(() => {
      log("reconnected.");
      addJamMetadata({ reconnecting: false, reconnected: true });
      this.meetingRoster.setCurrentPresence(RECONNECTED);
      this.setState({ connectionFailedDialogOpen: false });
    });

    this.meetingSession.onMeetingEnded((sessionStatus) => {
      log("meeting ended");
      addJamMetadata({ meetingEnded: true });

      this.meetingSession?.destroy();

      // disconnect from channel
      this.messagingSession?.destroy();
      this.messagingSession = null;
      this.meetingSession = null;
      this.messagingSessionConfig = null;
      this.networkDebugInfo = null;
      this.cleanupComponent();
    });

    if (this.__rejoining) {
      await this.connectToRoom();
    } else {
      this.meetingSession.start();
    }
  };

  disconnectFromMeeting() {
    console.warn("meeting ending...");
    addJamMetadata({ disconnectInitd: true });
    try {
      // stop tracking user roster
      this.meetingRoster?.destroy();

      // stop A/V
      this.meetingSession.stop();
      // this will trigger `this.meetingSession.onMeetingEnded` callback
    } catch (e) {
      console.warn(e.message);
    } finally {
      this.meetingRoster = null;
    }
  }

  enableScreenSharing = async () => {
    this.contentShareController.start();
  };

  disableScreenSharing = async () => {
    this.contentShareController.stop();
  };

  networkDebugEnabled = false;
  toggleNetworkDebug = () => {
    this.networkDebugEnabled = !this.networkDebugEnabled;
    if (!this.networkDebugEnabled) {
      clearInterval(this.__networkDebugRefresh);
      return;
    }

    this.__networkDebugRefresh = setInterval(() => {
      if (this.networkDebugEnabled && this.audioVideoController) {
        const networkDebugInfo = {
          videoDownstreamPacketLossPercent:
            this.audioVideoController.videoDownstreamPacketLossPercent.toFixed(2), // whole number
          videoUpstreamPacketLossPercent:
            this.audioVideoController.videoUpstreamPacketLossPercent.toFixed(2), // whole number
          availableIncomingBitrate: (
            this.audioVideoController.availableIncomingBitrate / 1000000
          ).toFixed(2), // Mb/s
          availableOutgoingBitrate: (
            this.audioVideoController.availableOutgoingBitrate / 1000000
          ).toFixed(2), // Mb/s
        };
        this.networkDebugInfo = {
          networkDebugInfo,
          networkDebugLinesJson: JSON.stringify([
            {
              label: "Upload Bitrate Available",
              value: networkDebugInfo.availableOutgoingBitrate,
              unit: "Mbps",
              fallback: "0",
            },
            {
              label: "Download Bitrate Available",
              value: networkDebugInfo.availableIncomingBitrate,
              unit: "Mbps",
              fallback: "0",
            },
            {
              label: "Upstream Packet Loss",
              value: networkDebugInfo.videoUpstreamPacketLossPercent,
              unit: "%",
              fallback: "0",
            },
            {
              label: "Downstream Packet Loss",
              value: networkDebugInfo.videoDownstreamPacketLossPercent,
              unit: "%",
              fallback: "0",
            },
            {
              label: "Current Video Quality",
              value: this.audioVideoController?.videoInputController?.videoInputQuality,
            },
            {
              label: "Max Video Bitrate",
              value: this.audioVideoController?.videoInputController?.maxBitrateKbps,
              unit: "Kbps",
            },
          ]),
        };
      }
    }, 1750);
  };

  onAvError = (error = new Error()) => {
    console.error("onAvError", { error });
    this.props.logError({
      errorType: "CHIME-FAILED-AV-EVENT",
      errorMessage: error.message,
    });
    this.emit("report-connection-failed", {
      videoId: this.props.videoId,
      userId: this.props.userId,
    });
  };

  rejoinMeeting = async (errorCode = "CHM_ERR_UNKNOWN", reason = "unknown") => {
    if (this.__rejoining) return;
    this.__rejoining = true;
    this.setState({ connectionFailedDialogOpen: true, socketConnected: false });
    console.warn("cx_err: attempting to rejoin meeting");
    let message = `ATTEMPTING RECONNECTION: ${this.props.userId} user disconnected from ${this.props.videoId} video call. {ERROR_CODE: ${errorCode}, ERROR_MESSAGE: ${reason}}.`;
    this.props.logError({
      errorType: "CHIME-DISCONNECTED-EVENT",
      errorMessage: message,
    });
    this.disconnectFromMeeting();
    if (this.__connectionAttempts++ < 4) {
      const delay = this.__connectionAttempts ** 2 + Math.random() * 1000;
      console.warn("rejoin;attempt=", this.__connectionAttempts, ";delay=", delay);
      setTimeout(() => this.fetchMessagingConfig(), delay);
    }
  };

  rejoinMessaging = async (errorCode = "CHM_ERR_UNKNOWN", reason = "unknown") => {
    if (this.__rejoiningMessaging) return;
    this.__rejoining = true;
    this.setState({ connectionFailedDialogOpen: true, socketConnected: false });
    console.warn("cx_err: attempting to rejoin messaging");
    let message = `ATTEMPTING RECONNECTION: ${this.props.userId} user disconnected from ${this.props.videoId} msg channel. {ERROR_CODE: ${errorCode}, ERROR_MESSAGE: ${reason}}.`;
    this.props.logError({
      errorType: "CHIME-DISCONNECTED-EVENT",
      errorMessage: message,
    });
    this.disconnectFromMeeting();
    if (this.__connectionAttempts++ < 4) {
      const delay = this.__connectionAttempts ** 2 + Math.random() * 1000;
      console.warn("rejoin;attempt=", this.__connectionAttempts, ";delay=", delay);
      setTimeout(() => this.fetchMessagingConfig(), delay);
    }
  };

  render() {
    return (
      <>
        {super.render()}
        <DebugOverlay
          keySequence={{ key: "d", ctrlKey: true }}
          linesJson={this.networkDebugInfo?.networkDebugLinesJson}
        />
      </>
    );
  }
}

const childMapStateToProps = (state) => ({
  ...mapStateToProps(state),
  ...state.video,
});

const childMapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      ...mapDispatchToProps,
      fetchMessagingConfig: function () {
        if (!this.endDate && this.videoId) {
          return actions.fetchMessagingConfig(this.videoId);
        }
        console.warn(
          "call %s - not connecting to channel",
          this.endDate ? "missing video id" : "has ended"
        );
        return actions.noop();
      },
      fetchMeetingConfig: function () {
        if (!this.endDate && this.videoId) {
          return actions.fetchMeetingConfig(this.videoId);
        }
        console.warn(
          "call %s - not connecting to meeting",
          this.endDate ? "missing video id" : "has ended"
        );
        return actions.noop();
      },
      clearMeetingConfig: function () {
        log("clearing meeting config");
        return actions.clearMeetingConfig();
      },
    },
    dispatch
  );

export default withRouter(
  connect(childMapStateToProps, childMapDispatchToProps)(withStyles(styles)(VideoChatV2))
);
