import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import CalendarSessions from "./CalendarSessions";
import SessionDetails from "./SessionDetails";
import styles from "./styles";
import Snackbar from "@mui/material/Snackbar";
import Auth from "@aws-amplify/auth";
import SessionNotesNotFoundDialog from "./SessionNotesNotFoundDialog";
import CancelSessionDialog from "./CancelSessionDialog";
import EditSelectionDialog from "./EditSelectionDialog";
import SessionScheduler from "../SessionScheduler";
import {
  getUserPermissionsList,
  getVideoBreakdown,
  getVideoBreakdownLoading,
  getCancelVideoCallSuccess,
  scheduleCallLoading,
  scheduleCallSuccess,
  getWeekStartDate,
  getShowWeekCanceledCalls,
  getCallBreakdownSuccess,
  getCallBreakdownLoading,
  getUserId,
  getCompletingOffPlatform,
  getCompleteOffPlatformSuccess,
  isDirector,
  isSupervisor,
} from "../../selectors";
import CompleteOffPlatformDialog from "../CompleteOffPlatformDialog";
import { Typography } from "@mui/material";
import SessionNotesDialog from "../SessionNotes";
import { CalendarToolbar } from "./CalendarToolbar";
import { getCancellationReasonLabel, getKeyCancellationReason } from "../../constants/cancelReasons";

const mapStateToProps = (state) => ({
  userPermissions: getUserPermissionsList(state),
  videoCallBreakdown: getVideoBreakdown(state),
  videoCallBreakdownLoading: getVideoBreakdownLoading(state),
  cancelVideoCallSuccess: getCancelVideoCallSuccess(state),
  scheduleCallLoading: scheduleCallLoading(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  weekStartDate: getWeekStartDate(state),
  showCanceledCalls: getShowWeekCanceledCalls(state),
  callBreakdownSuccess: getCallBreakdownSuccess(state),
  callBreakdownLoading: getCallBreakdownLoading(state),
  completingOffPlatform: getCompletingOffPlatform(state),
  completeOffPlatformSuccess: getCompleteOffPlatformSuccess(state),
  userId: getUserId(state),
  isDirector: isDirector(state),
  isSupervisor: isSupervisor(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
      cancelVideoCall: actions.cancelVideoCall,
      getCalendarSessions: actions.getCalendarSessions,
      clearCustomerInfo: actions.clearCustomerInfo,
      setShowWeekCanceledCalls: actions.setShowWeekCanceledCalls,
      markClientNoShow: actions.markClientNoShow,
    },
    dispatch,
  );

class WeeklyCalendarSessions extends Component {
  constructor() {
    super();
    this.state = {
      sessionDetails: {},
      detailsOpen: false,
      snackBarOpen: false,
      snackBarMessage: "",
      downloadVideoCallId: null,
      noSessionNotesDialogOpen: false,
      cancelSessionOpen: false,
      cancelReasonText: "",
      responsibleForCancellation: "",
      cancelSelectionOpen: false,
      cancelAllInstances: false,
      editSessionOpen: false,
      editSelectionOpen: false,
      editAllInstances: false,
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      completeOffPlatformOpen: false,
      offPlatformDetails: null,
      sessionNotesDialogOpen: false,
      currentBreakdown: [],
      enterInsuranceCode: false,
      cptCodeDialogOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.videoCallBreakdownLoading &&
      !this.props.videoCallBreakdownLoading &&
      this.state.downloadVideoCallId
    ) {
      if (
        this.props.videoCallBreakdown.length > 0 &&
        this.props.videoCallBreakdown.some((call) => !!call.note_id)
      ) {
        this.downloadAll(this.state.downloadVideoCallId);
        this.setState({ downloadVideoCallId: null });
      } else {
        this.setState({ noSessionNotesDialogOpen: true });
      }
    }

    if (!prevProps.cancelVideoCallSuccess && this.props.cancelVideoCallSuccess) {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: "Session canceled successfully!",
      });
    }

    if (!prevProps.scheduleCallSuccess && this.props.scheduleCallSuccess) {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: "Session rescheduled successfully!",
      });
    }
    if (
      prevProps.completingOffPlatform &&
      !this.props.completingOffPlatform &&
      this.state.markingClientNoShow
    ) {
      this.setState({
        markingClientNoShow: false,
        detailsOpen: false,
        snackBarOpen: true,
        snackBarMessage: "The event was marked as a no-show",
      });
    }
  }

  onMarkClientAsNoShow = (id) => {
    this.setState({ markingClientNoShow: true }, () => {
      this.props.markClientNoShow(id);
    });
  };

  onSelectSession = (details) => {
    if (details?.googleEvent) return; // Don't show details for google events
    this.setState({ sessionDetails: details });
    if (details?.billing_complete) this.setState({ sessionNotesDialogOpen: true });
    else this.setState({ detailsOpen: true });
  };

  onCloseDetailsDialog = () => {
    this.setState({ detailsOpen: false }, () => {
      setTimeout(() => {
        this.setState({ sessionDetails: {} });
      }, 100);
    });
  };

  copySessionURL = (videoKey, offPlatform) => {
    const baseUrl = process.env.BASE_PARENT_URL;
    const offPlatformURL = process.env.DOXY_URL;
    if (videoKey && !offPlatform) {
      navigator.clipboard.writeText(`${baseUrl}/video/${videoKey}`);
      this.setState({
        snackBarOpen: true,
        snackBarMessage: "Unique session link copied to clipboard!",
      });
    } else {
      navigator.clipboard.writeText(`${offPlatformURL}`);
      this.setState({
        snackBarOpen: true,
        snackBarMessage: "Unique session link copied to clipboard!",
      });
    }
  };

  downloadAll = async (videoCallId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
    this.setState({ downloadVideoCallId: null });
  };

  downloadAllSessionNotes = (callId) => {
    this.setState({ downloadVideoCallId: callId });
    this.props.getVideoCallBreakdown(callId);
  };

  onCloseNoSessionNotesDialog = () => {
    this.setState({ noSessionNotesDialogOpen: false });
  };

  goToSessionDetails = (callId) => {
    let videoCall = "/video-calls/" + callId;
    this.props.history.push(videoCall);
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onClickCancelSession = (recurringEvent) => {
    this.setState({ cancelSessionOpen: true });
  };

  onToggleCancelSession = () => {
    this.setState(
      {
        cancelSessionOpen: !this.state.cancelSessionOpen,
      },
      () => {
        if (!this.state.cancelSessionOpen) {
          this.setState({
            cancelReasonText: "",
            cancelAllInstances: false,
            responsibleForCancellation: "",
          });
        }
      },
    );
  };

  onChangeCancelSelection = (e) => {
    const { value } = e.target;
    this.setState({ cancelAllInstances: this.stringToBoolean(value) });
  };

  onChangeCancelReason = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    this.setState({
      responsibleForCancellation: e.target.value,
      cancelReasonText,
    });
  };

  submitCancelSession = () => {
    const { sessionDetails, cancelReasonText, cancelAllInstances, responsibleForCancellation } =
      this.state;
    const { isAdmin, clinicianSessionsPageAction } = this.props;
    let params = {
      message: cancelReasonText,
      responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
      id: sessionDetails.id,
      isAdmin,
      clinicianSessionsPageAction,
      clinicianUserId: sessionDetails.clinician_user_id,
      cancelAllInstances,
    };
    this.props.cancelVideoCall(params);
    this.onToggleCancelSession();
    this.onCloseDetailsDialog();
  };

  onClickEditSession = (recurringEvent) => {
    if (recurringEvent) {
      this.setState({ editSelectionOpen: true });
    } else {
      this.setState({ editSessionOpen: true, detailsOpen: false });
    }
  };

  onToggleEditSession = () => {
    this.setState(
      {
        editSessionOpen: !this.state.editSessionOpen,
      },
      () => {
        if (!this.state.editSessionOpen) {
          this.onCloseDetailsDialog();
          this.props.clearCustomerInfo();
        } else {
          this.setState({ detailsOpen: false });
        }
      },
    );
  };

  onToggleEditSelection = () => {
    this.setState({
      editSelectionOpen: !this.state.editSelectionOpen,
    });
  };

  onChangeEditSelection = (e) => {
    const { value } = e.target;
    this.setState({ editAllInstances: this.stringToBoolean(value) });
  };

  onContinueEditSelection = () => {
    this.onToggleEditSelection();
    this.onToggleEditSession();
  };

  onToggleCompleteOffPlatformDialog = (offPlatformDetails) => {
    this.setState({
      detailsOpen: false,
      completeOffPlatformOpen: !this.state.completeOffPlatformOpen,
      offPlatformDetails,
    });
  };

  openCptCodeDialog = async (videoCallInfo, enterCode) => {
    this.setState(
      {
        cptCodeVideoCall: videoCallInfo,
        currentBreakdown: [
          {
            ...videoCallInfo,
            client_name: `${videoCallInfo.child_first_name} ${videoCallInfo.child_last_name}`,
            insurance_code: videoCallInfo.item_type,
            billable_time_id: videoCallInfo.id,
          },
        ],
      },
      () => {
        if (!enterCode) {
          // this.setState({ sessionNotesDialogOpen: true });
        } else {
          this.setState({ cptCodeDialogOpen: true, enterInsuranceCode: true });
        }
      },
    );
  };

  closeCPTCodeDialog = () => {
    this.setState({
      cptCodeDialogOpen: false,
      cptCodeVideoCall: null,
    });
  };

  // openSessionNotesDialog = (billableTimeId) => {
  //   const { videoBreakdown } = this.props;
  //   let currentBreakdown = videoBreakdown?.filter((code) => code.id == billableTimeId);
  //   this.setState({
  //     sessionNotesDialogOpen: true,
  //     currentBreakdown,
  //   });
  // };

  // closeSessionNotesDialog = () => {
  //   this.setState({
  //     sessionNotesDialogOpen: false,
  //     currentBreakdown: [],
  //     enterInsuranceCode: false,
  //     cptCodeVideoCall: null,
  //   });
  // };

  closeSessionNotesDialog = () => {
    this.setState({ sessionNotesDialogOpen: false }, () => {
      setTimeout(() => {
        this.setState({ sessionDetails: {} });
      }, 100);
    });
  };

  render() {
    const { classes, userPermissions, toggleScheduleUtilityDrawer } = this.props;
    const {
      snackBarOpen,
      snackBarMessage,
      editSessionOpen,
      cptCodeVideoCall,
      cptCodeDialogOpen,
      sessionNotesDialogOpen,
      completeOffPlatformOpen,
      sessionDetails,
    } = this.state;

    return (
      <div className={classes.calendarContainer} id="WeeklyCalendarSessions">
        <CalendarSessions
          {...this.props}
          onSelectSession={this.onSelectSession}
          toggleScheduleUtilityDrawer={toggleScheduleUtilityDrawer}
        />
        <SessionDetails
          {...this.state}
          {...this.props}
          copySessionURL={this.copySessionURL}
          downloadAllSessionNotes={this.downloadAllSessionNotes}
          onCloseDetailsDialog={this.onCloseDetailsDialog}
          goToSessionDetails={this.goToSessionDetails}
          onClickCancelSession={this.onClickCancelSession}
          onClickEditSession={this.onClickEditSession}
          onToggleCompleteOffPlatformDialog={this.onToggleCompleteOffPlatformDialog}
          onMarkClientAsNoShow={this.onMarkClientAsNoShow}
        />
        <SessionNotesDialog
          open={sessionNotesDialogOpen}
          startDate={sessionDetails?.start_date}
          endDate={sessionDetails?.end_date}
          clinicianUserId={sessionDetails?.clinician_user_id}
          videoCallId={sessionDetails?.id}
          clientUserId={sessionDetails?.user_id}
          clientId={sessionDetails?.client_id}
          clientData={{
            child_name: sessionDetails?.name,
            child_last_name: sessionDetails?.child_last_name,
          }}
          onCloseCPTCodeDialog={this.closeSessionNotesDialog}
          billingType={sessionDetails?.billing_type}
          billableTimeId={sessionDetails?.billable_time_id}
          viewOnly={true}
          downloadViewOnly={true}
        />
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ snackBarOpen: false })}
          open={snackBarOpen}
        >
          <Typography>{snackBarMessage}</Typography>
        </Snackbar>
        <CancelSessionDialog
          sessionDetails={sessionDetails}
          open={this.state.cancelSessionOpen}
          cancelReasonText={this.state.cancelReasonText}
          responsibleForCancellation={this.state.responsibleForCancellation}
          closeDialog={this.onToggleCancelSession}
          onChangeCancelReason={this.onChangeCancelReason}
          onChangeResponsibleForCancellation={this.onChangeResponsibleForCancellation}
          submitCancelSession={this.submitCancelSession}
          cancelAllInstances={this.state.cancelAllInstances}
          onChangeCancelSelection={this.onChangeCancelSelection}
        />
        <EditSelectionDialog
          sessionDetails={sessionDetails}
          open={this.state.editSelectionOpen}
          editAllInstances={this.state.editAllInstances}
          closeDialog={this.onToggleEditSelection}
          onChangeEditSelection={this.onChangeEditSelection}
          onContinueEditSelection={this.onContinueEditSelection}
        />
        <SessionNotesNotFoundDialog
          open={this.state.noSessionNotesDialogOpen}
          closeDialog={this.onCloseNoSessionNotesDialog}
          classes={this.props.classes}
        />
        {editSessionOpen && (
          <SessionScheduler
            open={this.state.editSessionOpen}
            closeDialog={this.onToggleEditSession}
            isClinician={false}
            rescheduleCall={true}
            rescheduleDetails={sessionDetails}
            allowOverride={userPermissions?.override_clinician_schedule}
            selectScheduledClinician={userPermissions?.select_scheduled_clinician}
            rescheduleAllInstances={this.state.editAllInstances}
            demoCall={sessionDetails?.is_test}
          />
        )}
        {completeOffPlatformOpen && (
          <CompleteOffPlatformDialog
            callDetails={this.state.offPlatformDetails}
            onToggleCompleteOffPlatformDialog={this.onToggleCompleteOffPlatformDialog}
            openCptCodeDialog={this.openCptCodeDialog}
          />
        )}
        {/* {(cptCodeDialogOpen || sessionNotesDialogOpen) && (
          <VideoBreakdownDialog
            open={cptCodeDialogOpen}
            startDate={cptCodeVideoCall?.start_date}
            endDate={cptCodeVideoCall?.end_date}
            videoCallId={cptCodeVideoCall?.id}
            clientUserId={cptCodeVideoCall?.user_id}
            clientData={{
              child_name: cptCodeVideoCall?.child_first_name || cptCodeVideoCall?.name,
              child_last_name: cptCodeVideoCall?.child_last_name,
            }}
            onCloseCPTCodeDialog={this.closeCPTCodeDialog}
            inVideoCall={false}
            isDemo={false}
            enterInsuranceCode={this.state.enterInsuranceCode}
            sessionNotesOpen={this.state.sessionNotesDialogOpen}
            videoBreakdown={this.state.currentBreakdown}
            openSessionNotesDialog={this.openSessionNotesDialog}
            closeSessionNotesDialog={this.closeSessionNotesDialog}
            showExtendedInfo={true}
            billingType={cptCodeVideoCall?.billing_type}
            clientId={cptCodeVideoCall?.client_id}
          />
        )} */}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(WeeklyCalendarSessions));
