import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { format } from "date-fns";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import actions from "../../actions";
import {
  getHealthieOrgMembers,
  getHealthieOrgMembersCount,
  getHealthieOrgMembersLoading,
  getHealthieOrgMembersOffset,
  getHealthieOrgMembersPage,
  getInviteHealthieUserErrorMessage,
  getInviteHealthieUserLoading,
  getInviteHealthieUserSuccess,
} from "../../selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";
import InviteHealthieUserDialog from "./InviteHealthieUserDialog";
import Button from "@mui/material/Button";
import ResendHealthieInviteDialog from "./ResendHealthieInviteDialog";
import ResetHealthieUserPWDialog from "./ResetHealthieUserPWDialog";
import { convertToISO } from "utils/convertToISO";
import { Link } from "@mui/material";

const mapStateToProps = (state) => ({
  healthieUsers: getHealthieOrgMembers(state),
  loading: getHealthieOrgMembersLoading(state),
  page: getHealthieOrgMembersPage(state),
  offset: getHealthieOrgMembersOffset(state),
  count: getHealthieOrgMembersCount(state),
  inviteHealthieUserLoading: getInviteHealthieUserLoading(state),
  inviteHealthieUserSuccess: getInviteHealthieUserSuccess(state),
  inviteHealthieUserErrorMessage: getInviteHealthieUserErrorMessage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getHealthieOrgMembers: actions.getHealthieOrgMembers,
      setPage: actions.setHealthieOrgMembersPage,
      inviteHealthieSupportUser: actions.inviteHealthieSupportUser,
      resendHealthieInvite: actions.resendSupportUserHealthieInvite,
      resetHealthiePassword: actions.resetSupportUserHealthiePassword,
      resetHealthieSuccessFlag: actions.resetHealthieSuccessFlag,
    },
    dispatch,
  );

class HealthieUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      inviteHealthieUserOpen: false,
      inviteError: false,
      resendHealthieInviteOpen: false,
      resendInviteUser: {},
      resetHealthiePasswordOpen: false,
      resethealthiePasswordID: null,
    };
  }
  componentDidMount() {
    this.props.getHealthieOrgMembers();
    this.props.setPageDetails({
      pageName: "Healthie Users",
      currentPage: "users",
      menu: "administration",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.props.getHealthieOrgMembers();
    }
    if (prevProps.inviteHealthieUserLoading && !this.props.inviteHealthieUserLoading) {
      if (!this.props.inviteHealthieUserSuccess) {
        this.setState({ inviteError: true });
      }
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val });
  };

  onOpenInviteHealthieUser = () => {
    this.setState({ inviteHealthieUserOpen: true });
  };

  onCloseInviteHealthieUser = () => {
    this.setState({
      inviteHealthieUserOpen: false,
      firstName: "",
      lastName: "",
      email: "",
      inviteError: false,
    });
    this.props.resetHealthieSuccessFlag();
  };

  onSubmitInviteHealthieUser = () => {
    const { firstName, lastName, email } = this.state;
    this.setState({ inviteError: false });
    this.props.inviteHealthieSupportUser({ firstName, lastName, email });
  };

  onOpenResendHealthieInvite = (user) => {
    this.setState({ resendInviteUser: user, resendHealthieInviteOpen: true });
  };

  onCloseResendHealthieInvite = () => {
    this.setState({
      resendInviteUser: {},
      resendHealthieInviteOpen: false,
      inviteError: false,
    });
    this.props.resetHealthieSuccessFlag();
  };

  onSubmitResendHealthieInvite = () => {
    this.setState({ inviteError: false });
    const { first_name, email, id } = this.state.resendInviteUser;
    this.props.resendHealthieInvite({ id, first_name, email });
  };

  onOpenResetHealthiePassword = (id) => {
    this.setState({
      resetHealthiePasswordOpen: true,
      resethealthiePasswordID: id,
    });
  };

  onCloseResetHealthiePassword = () => {
    this.setState({
      resetHealthiePasswordOpen: false,
      resethealthiePasswordID: null,
    });
    this.props.resetHealthieSuccessFlag();
  };

  onSubmitResetHealthiePassword = () => {
    this.setState({ inviteError: false });
    this.props.resetHealthiePassword(this.state.resethealthiePasswordID);
  };

  render() {
    const { healthieUsers, page, offset, count, loading, classes } = this.props;
    const rows = healthieUsers;
    return (
      <PageContainer loading={loading}>
        <SectionContainer columnDirection noPadding>
          <div className={classes.header}>
            <Button onClick={this.onOpenInviteHealthieUser} style={{ width: 350 }}>
              Invite User to Healthie
            </Button>
          </div>

          <CustomTable
            count={count}
            rowsPerPage={10}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={() => {}}
            lockRowsPerPage={10}
            noResultsMessage="No Healthie Users Found"
            headerCells={[
              <CustomHeaderCell label="Healthie ID" />,
              <CustomHeaderCell label="Name" />,
              <CustomHeaderCell label="Email" />,
              <CustomHeaderCell label="Has Logged In" />,
              <CustomHeaderCell label="Created Date" />,
              <CustomHeaderCell label="Actions" />,
            ]}
          >
            {rows.length > 0 &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.has_created_password ? "Yes" : "No"}</TableCell>
                  <TableCell align="left">
                    {row.created_at
                      ? format(convertToISO(row.created_at), "PP", {
                          awareOfUnicodeTokens: true,
                        })
                      : "----"}
                  </TableCell>
                  <TableCell align="left">
                    {row.has_created_password ? (
                      <Link onClick={() => this.onOpenResetHealthiePassword(row.id)}>
                        Send Reset Password Link
                      </Link>
                    ) : (
                      <Link onClick={() => this.onOpenResendHealthieInvite(row)}>
                        Resend Invite Email
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </CustomTable>
        </SectionContainer>
        <InviteHealthieUserDialog
          onChange={this.onChange}
          onCloseInviteHealthieUser={this.onCloseInviteHealthieUser}
          onSubmitInviteHealthieUser={this.onSubmitInviteHealthieUser}
          {...this.state}
          {...this.props}
        />
        <ResendHealthieInviteDialog
          onCloseResendHealthieInvite={this.onCloseResendHealthieInvite}
          onSubmitResendHealthieInvite={this.onSubmitResendHealthieInvite}
          {...this.state}
          {...this.props}
        />
        <ResetHealthieUserPWDialog
          onCloseResetHealthiePassword={this.onCloseResetHealthiePassword}
          onSubmitResetHealthiePassword={this.onSubmitResetHealthiePassword}
          {...this.state}
          {...this.props}
        />
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HealthieUsers));
