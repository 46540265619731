import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import withStyles from "@mui/styles/withStyles";
import LoadingButton from "@mui/lab/LoadingButton";

const CreateAccountDialog = (props) => {
  const { classes, open, closeDialog, accountName, changePrimaryAccountHolder, loading } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      key="switch-clinician-dialog"
      PaperProps={{
        style: {
          backgroundColor: "#e5e5e5",
          borderRadius: 10,
          minHeight: 150,
          width: "33%",
          padding: "15px",
        },
      }}
    >
      <div className={classes.createAccountDialogContainer}>
        <Typography component="h3" className={classes.title}>
          Change Primary Account Holder
        </Typography>
        <div>
          <Typography component="p" className={classes.subtitle}>
            {accountName} will become your primary account holder. They will be automatically
            included on all non-therapy related sessions unless you manually change who the primary
            account holder is.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="text" onClick={closeDialog}>
            Cancel
          </Button>
          <LoadingButton onClick={changePrimaryAccountHolder} loading={loading}>
            CONFIRM
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

const styles = (theme) => ({
  createAccountDialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px 10px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: 19,
    fontWeight: 500,
    color: "#15171d",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#3F4456",
    lineHeight: "18px",
    marginBottom: 15,
  },
  buttonContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 15,
    "& button": {
      borderRadius: 5,
      fontSize: 13,
    },
  },
});

export default withStyles(styles)(CreateAccountDialog);
