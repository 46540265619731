import React from "react";
import Modal from "elements/Modal";
import Typography from "@mui/material/Typography";

const ResetHealthieUserPWDialog = (props) => {
  const {
    resetHealthiePasswordOpen,
    inviteHealthieUserLoading,
    inviteHealthieUserSuccess,
    inviteError,
    onSubmitResetHealthiePassword,
    onCloseResetHealthiePassword,
  } = props;

  return (
    <Modal
      open={resetHealthiePasswordOpen}
      onClose={onCloseResetHealthiePassword}
      title="Reset Healthie User Password"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          <Typography>
            Are you sure? This will email the user a link to reset their password.
          </Typography>
        </div>
      }
      loading={inviteHealthieUserLoading}
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitResetHealthiePassword}
      primaryActionDisabled={inviteHealthieUserSuccess || inviteError}
      secondaryActionText={inviteHealthieUserSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onCloseResetHealthiePassword}
      success={inviteHealthieUserSuccess}
      successMessage="Healthie password reset link has been emailed to the user"
      error={inviteError}
      errorMessage={"There was an error resetting the password. Please try again."}
    />
  );
};

export default ResetHealthieUserPWDialog;
