import React from "react";
import { Box } from "@mui/material";
import ANReadItem from "./ANReadItem";
import ANFormRow from "./ANFormRow";
import { format } from "date-fns";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import { formatJsonDate } from "../../utils/dateFromJSONDate";
import { TIMEZONES } from "../../constants";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { mapRelationshipToDisplay } from "../../utils/formatRelationship";

const ANFormView = ({ data, config, panelMode }) => {
  const renderData = (data, type, formatType) => {
    if (type === "yes_no") {
      return data ? "Yes" : "No";
    } else if (type === "mapRelationshipToDisplay") {
      return data ? mapRelationshipToDisplay(data) : "-";
    } else if (type === "money") {
      return data ? `$${data}` : "-";
    } else if (formatType === "jsonDate") {
      return data ? formatJsonDate(data) : "-";
    } else if (formatType === "date") {
      return data ? format(dateWithoutTimezone(data), "PP") : "-";
    } else if (formatType === "timezone") {
      return data ? TIMEZONES[data] : "-";
    } else if (formatType === "phone") {
      return data ? formatPhoneNumber(data) : "-";
    }
    return data || "-";
  };

  const concatDataKeys = (keys) => {
    const values = [];

    keys.split(",").forEach((key) => {
      const trimmedKey = key.trim();

      if (data.hasOwnProperty(trimmedKey)) {
        values.push(data[trimmedKey]);
      }
    });

    return values.join(" ");
  };

  const renderRows = () => {
    let rows = [];
    let currentCols = 0;
    let currentRow = [];

    config.forEach((info, index) => {
      const colSpan = panelMode ? 12 : info.cols || 12;

      if (currentCols + colSpan > 12) {
        rows.push(
          <ANFormRow key={`row-${rows.length}`} panelMode={panelMode}>
            {currentRow}
          </ANFormRow>
        );
        currentRow = [];
        currentCols = 0;
      }

      currentRow.push(
        <ANReadItem
          item
          xs={12}
          sm={colSpan}
          key={info.label}
          type={info.type}
          sx={{ "@media (min-width: 1200px)": { pl: "16px !important" } }}
          title={info.label}
          text={renderData(
            info.dataKey.includes(",") ? concatDataKeys(info.dataKey) : data[info.dataKey],
            info.type,
            info.format
          )}
          panelMode={panelMode}
          divider={info.divider}
        />
      );

      currentCols += colSpan;

      // Push the last row if it's the last item in the loop
      if (index === config.length - 1) {
        rows.push(
          <ANFormRow key={`row-${rows.length}`} panelMode={panelMode}>
            {currentRow}
          </ANFormRow>
        );
      }
    });

    return rows;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        maxWidth: "700px",
      }}
    >
      {renderRows()}
    </Box>
  );
};

export default ANFormView;
