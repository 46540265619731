import React from "react";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import { MagnifyingGlass, UserCircle } from "@phosphor-icons/react";
import momentTZ from "moment-timezone";
import { LoadingButton } from "@mui/lab";
import { addMinutesToTime, timeWithTimezoneToString } from "utils/dateTimeUtils";
import ClinicianSelect from "./ClinicianSelect";
import useStyles from "./styles";

/**
 * VideoCallSelections component.
 * Renders a form for scheduling video calls, including selecting event type, client, service type, and clinicians.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.theme - The theme object provided by the ThemeProvider.
 * @param {Object} props.values - Form values including the event details.
 * @param {boolean} props.isEditing - Whether the form is in editing mode.
 * @param {boolean} props.isOtherServicesEvent - Whether the event is for other services.
 * @param {Array} props.eventOptions - List of event options.
 * @param {Object} props.eventOptionsObject - Event options object with detailed options.
 * @param {Array} props.clientList - List of clients.
 * @param {string} props.clientInputValue - The input value for the client search.
 * @param {Function} props.setClientInputValue - Function to set the client input value.
 * @param {Object} props.serviceTypeLookup - Lookup object for service types.
 * @param {Array} props.serviceTypeOptions - List of service type options.
 * @param {boolean} props.serviceTypeError - Whether there is an error in the service type selection.
 * @param {string} props.serviceTypeHelperText - Helper text for the service type selection.
 * @param {boolean} props.canSelectBCBA - Whether BCBA selection is allowed.
 * @param {Array} props.clinicians - List of clinicians.
 * @param {Array} props.selectedClinicians - List of selected clinicians.
 * @param {Function} props.setSelectedClinicians - Function to set the selected clinicians.
 * @param {Object} props.touched - Formik touched object for form validation.
 * @param {Object} props.errors - Formik errors object for form validation.
 * @param {Function} props.handleBlur - Formik handleBlur function for form fields.
 * @param {Function} props.handleChange - Formik handleChange function for form fields.
 * @param {Function} props.setFieldValue - Formik setFieldValue function to set form values.
 * @param {Function} props.setClinicianOptions - Function to set the clinician options.
 * @param {Array} props.clinicianOptions - List of clinician options to display in the select menu.
 * @param {Array} props.timeOptions - List of time options.
 * @param {boolean} props.isToday - Whether the selected date is today.
 * @param {Array} props.durationOptions - List of duration options.
 * @param {Function} props.renderUserTimezone - Function to render the user's timezone.
 * @param {boolean} props.scheduleCallLoading - Whether the schedule call is loading.
 * @param {boolean} props.onSubmitLoading - Whether the form submission is loading.
 * @param {boolean} props.openSnackbar - Whether the snackbar is open.
 * @param {Object} props.history - History object for navigation.
 * @param {Function} props.manuallyValidateForm - Function to manually validate the form.
 * @param {Function} props.setUpdateEventDialogOpen - Function to set the update event dialog open state.
 * @param {Function} props.handleSubmit - Function to handle form submission.
 * @param {Function} props.setCancelSessionOpen - Function to set the cancel session dialog open state.
 * @param {boolean} props.hasInsuranceAuthorization - Whether the client has insurance authorization.
 * @param {Function} props.handleSelectDay - Function to handle selecting a day for repeating events.
 * @param {Function} props.getSelectedDayColor - Function to get the color of the selected day.
 * @param {Object} props.videoChatInfo - Information about the video chat.
 * @returns {React.ReactElement} - The rendered VideoCallSelections component.
 */
const VideoCallSelections = ({
  theme,
  values,
  isEditing,
  isOtherServicesEvent,
  eventOptions,
  eventOptionsObject,
  clientList,
  clientInputValue,
  setClientInputValue,
  serviceTypeLookup,
  serviceTypeOptions,
  serviceTypeError,
  serviceTypeHelperText,
  canSelectBCBA,
  clinicians,
  selectedClinicians,
  setSelectedClinicians,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setClinicianOptions,
  clinicianOptions,
  timeOptions,
  isToday,
  durationOptions,
  renderUserTimezone,
  scheduleCallLoading,
  onSubmitLoading,
  openSnackbar,
  history,
  manuallyValidateForm,
  setUpdateEventDialogOpen,
  handleSubmit,
  setCancelSessionOpen,
  hasInsuranceAuthorization,
  handleSelectDay,
  getSelectedDayColor,
  videoChatInfo,
}) => {
  const classes = useStyles();
  return (
    <Stack
      sx={{
        padding: theme.spacing(11),
        paddingTop: "23px",
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: "459px",
        minWidth: values.timeUnit === "weeks" ? "459px" : "345px",
        overflowY: "auto",
      }}
      gap={theme.spacing(7)}
    >
      <Stack>
        <Typography variant="body2">Schedule</Typography>
        <Typography variant="h3">{!isEditing && "Create"} Event</Typography>
      </Stack>
      <Stack gap={theme.spacing(5)}>
        <TextField
          select
          label="Event Type"
          name="event"
          value={values.event}
          onChange={handleChange}
          helperText={touched.event && errors.event}
          error={touched.event && Boolean(errors.event)}
          onBlur={handleBlur}
          disabled={isEditing && isOtherServicesEvent}
        >
          {eventOptions.map(({ value, label }) => (
            <MenuItem
              key={value}
              value={value}
              disabled={isEditing && value === eventOptionsObject.otherServices.value}
            >
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          options={clientList}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Clients"
              label="Client"
              helperText={touched.client && errors.client}
              onBlur={handleBlur}
              error={touched.client && Boolean(errors.client)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <MagnifyingGlass
                    color="rgba(0, 0, 0, 0.56)"
                    style={{ position: "absolute", right: "10px" }}
                  />
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option?.client_name}
          getOptionKey={(option) => option?.client_id}
          name="client"
          inputValue={clientInputValue}
          onInputChange={(event, newValue) => (event ? setClientInputValue(newValue) : null)}
          onChange={(_, newValue) => setFieldValue("client", newValue, true)}
          value={values.client}
          clearIcon={null}
          renderOption={(props, option) => (
            <MenuItem {...props}>
              <ListItemIcon>
                <UserCircle weight="duotone" />
              </ListItemIcon>
              {option?.client_name}
            </MenuItem>
          )}
          disabled={isEditing}
        />
        <TextField
          select
          label="Service"
          SelectProps={{
            renderValue: (selected) => serviceTypeLookup[selected],
          }}
          name="serviceType"
          value={values.serviceType}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value === "ORIENTATION") setFieldValue("duration", 30);
          }}
          error={serviceTypeError}
          helperText={serviceTypeHelperText}
          onBlur={handleBlur}
        >
          {serviceTypeOptions.map(({ icon, value, label }) => (
            <MenuItem key={`${value}`} value={value}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {label}
            </MenuItem>
          ))}
        </TextField>
        {canSelectBCBA && (
          <ClinicianSelect
            clinicians={clinicians}
            values={values}
            selectedClinicians={selectedClinicians}
            setSelectedClinicians={setSelectedClinicians}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setClinicianOptions={setClinicianOptions}
            clinicianOptions={clinicianOptions}
            videoChatInfo={videoChatInfo}
          />
        )}
      </Stack>
      <Divider />
      <Stack gap={theme.spacing(5)}>
        <ANDatePicker
          label="Date"
          name="eventDate"
          value={values.eventDate}
          onChange={(value) => setFieldValue("eventDate", value, true)}
          format="PP" //"MMMM d, yyyy"
          disablePast
          slotProps={{
            textField: {
              error: touched.eventDate && Boolean(errors.eventDate),
              helperText: touched.eventDate && errors.eventDate,
              onBlur: handleBlur,
            },
          }}
        />
        <Stack direction="row" gap={theme.spacing(5)}>
          <TextField
            select
            fullWidth
            label="Start Time"
            name="startTime"
            value={values.startTime}
            onChange={handleChange}
            error={touched.startTime && Boolean(errors.startTime)}
            helperText={touched.startTime && errors.startTime}
            onBlur={handleBlur}
            SelectProps={{
              MenuProps: {
                PaperProps: { style: { maxHeight: "30vh" } },
              },
            }}
          >
            {timeOptions?.map((time, i) => (
              <MenuItem
                key={i}
                value={time}
                disabled={
                  isToday(values.eventDate) ? momentTZ(time, "h:mm A").isBefore(momentTZ()) : false
                }
              >
                {time}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Duration"
            fullWidth
            name="duration"
            value={values.duration}
            onChange={handleChange}
            error={touched.duration && Boolean(errors.duration)}
            helperText={touched.duration && errors.duration}
            onBlur={handleBlur}
            SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: "30vh" } } } }}
          >
            {durationOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {values.startTime && (
          <Stack gap={theme.spacing(1)}>
            {renderUserTimezone()}
            {selectedClinicians &&
              selectedClinicians.length > 0 &&
              selectedClinicians.map((clinician) => {
                return (
                  <Typography key={clinician.user_id} variant="caption">
                    {`${timeWithTimezoneToString(
                      values.startTime,
                      clinician.timezone
                    )} - ${addMinutesToTime(
                      values.startTime,
                      values.duration,
                      clinician.timezone
                    )} for ${clinician.first_name} (${momentTZ()
                      .tz(clinician.timezone)
                      ?.format("z")})`}
                  </Typography>
                );
              })}
          </Stack>
        )}
      </Stack>
      <Divider />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch name="isRepeating" checked={values.isRepeating} onChange={handleChange} />
          }
          label="Repeat event"
        />
      </FormGroup>
      {values.isRepeating && (
        <Stack gap={theme.spacing(5)}>
          <Stack direction="row" gap={theme.spacing(5)} alignItems="flex-start">
            <TextField
              name="timeInterval"
              label="Repeat every"
              type="number"
              fullWidth
              value={values.timeInterval}
              placeholder="Select"
              error={touched.timeInterval && Boolean(errors.timeInterval)}
              helperText={touched.timeInterval && errors.timeInterval}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              name="timeUnit"
              select
              fullWidth
              value={values.timeUnit}
              onChange={handleChange}
              error={touched.timeUnit && Boolean(errors.timeUnit)}
              helperText={touched.timeUnit && errors.timeUnit}
              onBlur={handleBlur}
              sx={{ pt: "22px" }} // keeps alignment consistent with other fields with labels
            >
              <MenuItem value="days">Day</MenuItem>
              <MenuItem value="weeks">Week</MenuItem>
            </TextField>
          </Stack>
          {values.timeUnit === "weeks" && (
            <>
              <Typography variant="inputLabel" color={theme.palette.text.secondary}>
                Repeat every
              </Typography>
              <Stack direction="row" gap={theme.spacing(3)} className={classes.daysOfWeek}>
                <Button color={getSelectedDayColor(7)} onClick={() => handleSelectDay(7)}>
                  S
                </Button>
                <Button color={getSelectedDayColor(1)} onClick={() => handleSelectDay(1)}>
                  M
                </Button>
                <Button color={getSelectedDayColor(2)} onClick={() => handleSelectDay(2)}>
                  T
                </Button>
                <Button color={getSelectedDayColor(3)} onClick={() => handleSelectDay(3)}>
                  W
                </Button>
                <Button color={getSelectedDayColor(4)} onClick={() => handleSelectDay(4)}>
                  T
                </Button>
                <Button color={getSelectedDayColor(5)} onClick={() => handleSelectDay(5)}>
                  F
                </Button>
                <Button color={getSelectedDayColor(6)} onClick={() => handleSelectDay(6)}>
                  S
                </Button>
              </Stack>
            </>
          )}
          <FormControl
            error={touched.repeatEndType && Boolean(errors.repeatEndType)}
            sx={{
              paddingBottom: touched.repeatUntilDate && errors.repeatUntilDate ? "40px" : 0,
            }}
          >
            <FormLabel id="repeat-end-label">Ends</FormLabel>
            <RadioGroup
              aria-labelledby="repeat-end-label"
              name="repeatEndType"
              value={values.repeatEndType}
              onChange={handleChange}
            >
              <FormControlLabel
                control={<Radio />}
                label="End of Authorization Period"
                value="current_auth_period"
                disabled={!hasInsuranceAuthorization}
              />
              <FormControlLabel control={<Radio />} label="On" value="until_date" />
              <ANDatePicker
                name="repeatUntilDate"
                value={values.repeatUntilDate}
                onChange={(value) => setFieldValue("repeatUntilDate", value, true)}
                disabled={values.repeatEndType !== "until_date"}
                format="PP" //"MMMM d, yyyy"
                shouldDisableDate={(date) => momentTZ(date).isBefore(momentTZ(values.eventDate))}
                slotProps={{
                  textField: {
                    error: touched.repeatUntilDate && Boolean(errors.repeatUntilDate),
                    helperText: touched.repeatUntilDate && errors.repeatUntilDate,
                    onBlur: handleBlur,
                  },
                }}
                sx={{
                  position: "absolute",
                  bottom: touched.repeatEndType && errors.repeatEndType ? "25px" : 0,
                  right: 0,
                  width: "177px",
                }}
              />
            </RadioGroup>
            {touched.repeatEndType && errors.repeatEndType && (
              <FormHelperText>{errors.repeatEndType}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      )}
      <Stack gap={theme.spacing(3)}>
        <LoadingButton
          loading={scheduleCallLoading || onSubmitLoading}
          disabled={openSnackbar}
          onClick={async (e) => {
            if (isEditing) {
              const errors = await manuallyValidateForm();
              if (!errors) setUpdateEventDialogOpen(true);
            } else handleSubmit(e);
          }}
        >
          {isEditing ? "Save" : "Create Event"}
        </LoadingButton>
        <Button
          color="secondary"
          onClick={history.goBack}
          disabled={scheduleCallLoading || onSubmitLoading || openSnackbar}
        >
          Cancel
        </Button>
        {isEditing && (
          <Button
            color="error"
            variant="text"
            onClick={() => setCancelSessionOpen(true)}
            disabled={openSnackbar}
          >
            Cancel Event
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default VideoCallSelections;
