export default (theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 50px - 64px)",
  },
  headingContainer: {
    position: "fixed",
    // top: 72,
    width: "25%",
    height: 56,
    backgroundColor: "#a568d5",
    boxShadow: "-2px 1px 3px 1px rgba(55,55,55,.18)",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      top: 64,
    },
  },
  basicInfoContainer: {
    top: 55,
    width: "calc(100% - 25px)",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    backgroundColor: "#F7F3FA",
    border: "0.3px solid #D2B3EA",
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    borderRight: "none",
    zIndex: 1,
    marginLeft: 25,
    overflow: "hidden",
  },
  basicInfoHeading: {
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingLeft: 25,
    paddingBottom: 8,
    "& p": {
      fontSize: 20,
      lineHeight: "24px",
      fontWeight: 500,
      color: "#15171d",
    },
  },
  basicInfo: {
    padding: "20px 25px",
    overflow: "auto",
  },
  list: {
    "& .MuiListItem-root": {
      padding: 0,
      wordWrap: "break-word",
    },
    "& .MuiListItemText-primary": {
      color: "#3f4456",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "-2%",
      lineHeight: "20px",
    },
    "& .MuiListItemText-secondary": {
      fontSize: 16,
      color: "#15171d",
      fontWeight: 500,
      marginTop: 3,
      marginBottom: 12,
      letterSpacing: "-2%",
      lineHeight: "20px",
    },
  },
  tabsContainer: {
    position: "fixed",
    // top: 72,
    width: "75%",
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 2,
    boxShadow: "2px 1px 3px 1px rgba(55,55,55,.18)",
    [theme.breakpoints.down("md")]: {
      top: 64,
    },
  },
  tabLabel: {
    textTransform: "none",
    fontSize: 16,
    color: "#fff",
    fontWeight: "400",
  },
  tab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "fit-content",
  },
  tabText: {},
  tabIndicator: {
    width: "85%",
    height: 0.7,
    backgroundColor: "#f0f1f4",
  },
  sectionTitleContainer: {
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 50px",
    marginBottom: 10,
    paddingBottom: 12,
    "& p": {
      fontSize: 22,
      fontWeight: 500,
      color: "#a568d5",
    },
    "& p > span": {
      color: "#69718f",
    },
  },
  dashboardContainer: {
    padding: 25,
  },
  accordionContainer: {
    padding: "32px 50px",
  },
  clientDetailsTable: {
    "& th": {
      fontSize: 17,
      fontWeight: 500,
      color: "#1517d",
    },
    "& td": {
      fontSize: 16,
      color: "#3f4456",
      fontWeight: 500,
    },
  },

  clientDetailsLoadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
  },
  clientCallsMoreItemsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    "& p": {
      fontSize: 17,
    },
  },
  needEditContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 32,
    "& p": {
      fontSize: 18,
      wordWrap: "break-word",
    },
    "& :last-child": {
      color: "#35a2a2",
      textDecoration: "underline",
      marginLeft: 130,
      cursor: "pointer",
    },
  },
  offPlatformWorkContainer: {
    width: "100%",
    height: "fit-content",
    margin: "auto",
  },
  serviceTypesHeading: {
    color: "#2a2739",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 500,
    marginTop: 10,
  },
  serviceTypesContainer: {
    width: "90%",
    marginTop: 20,
  },
  serviceType: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "15px 0",
  },
  serviceTypeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  serviceTypeMinutesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0px 50px",
  },
  serviceTypeMinutes: {
    color: "#3f4456",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  serviceTypeBillableTimeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  continueButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  continueButton: {
    color: "#2a2d39",
  },
  resetPasswordButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 25,
  },
  resetPasswordSuccessText: {
    color: "#059669",
    fontWeight: 500,
    marginRight: 50,
  },
  formControlLabelRoot: {
    display: "flex",
    alignItems: "flex-start",
    margin: "20px 10px",
    "& .MuiRadio-root": {
      margin: "-5px 50px 0px 15px",
    },
    "& .MuiTypography-body1": {
      width: "100%",
    },
  },
  formLabel: {
    color: "#3F4456",
    marginBottom: 5,
    fontSize: 15,
  },
  healthieClientName: {
    color: "#2a2d39",
    fontSize: 17,
    fontWeight: 500,
    lineHeight: "20px",
  },
  clientRelationship: {
    color: "#3F4456",
    fontSize: 14,
    fontWeight: 500,
  },
  importedDateText: {
    color: "#3F4456",
    fontSize: 12,
    lineHeight: "22px",
    fontWeight: 500,
    letterSpacing: "0.025em",
  },
  primaryAccountBadge: {
    color: "#065F46",
    lineHeight: "20px",
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: "0.025em",
    fontStyle: "italic",
    marginLeft: "-30px",
    "& svg": {
      color: "#552879",
      verticalAlign: "middle",
      marginRight: 14,
    },
  },
  updateClientStatusTitle: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
  },
  updateClientStatusWarningText: {
    color: "#991b1b",
    fontWeight: 500,
    fontSize: 17,
    margin: "20px 0px 15px",
  },
  updateClientStatusSelect: {
    width: "40%",
    margin: "15px 0px",
  },
  insuranceContainer: {
    margin: "0 0 40px",
    position: "relative",
    width: "100%",
  },
  insuranceLogoContainer: {
    width: 220,
    height: 125,
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0 2px rgba(0,0,0,.1)",
    backgroundColor: "#fff",
    margin: "10px 0px",
  },
  insuranceLogo: {
    width: "100%",
    height: "80%",
    objectFit: "contain",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 25,
  },
  noInfoText: {
    fontSize: 17,
    color: "#15171D",
    fontWeight: 400,
    margin: "30px 0 20px",
    width: "100%",
    textAlign: "center",
  },
  addInsuranceButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  datePopper: {
    zIndex: 1000,
  },
  newInsuranceButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 50,
  },
  editInsuranceContainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30,
    "& p": {
      fontSize: 17,
    },
  },
  editFamilyContainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30,
    "& p": {
      fontSize: 17,
    },
  },
  uploadButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 10px 0",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: 20,
      width: "100%",
    },
  },
  uploadButton: {
    width: 200,
    height: 160,
    border: "2px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5px 20px",
    margin: "0 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  uploadIcon: {
    width: 60,
    height: 60,
    objectFit: "contain",
  },
  uploadText: {
    color: "#4b4a4b",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "1px solid #cbcbcb",
    margin: "10px 0",
  },
  documentVersionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0",
  },
  documentVersionText: {
    fontSize: 16,
    color: "#4e4e4e",
    marginRight: 40,
  },
  accordionSubtitle: {
    fontSize: 20,
    color: "#2a2739",
    fontWeight: 500,
    margin: "20px 0px",
  },
  accordionText: {
    color: "#3f4456",
    fontSize: 17,
    fontWeight: 500,
    margin: "10px 0px ",
  },
  phiReleaseRequestTitle: {
    width: "50%",
    margin: "10px 0px 20px",
  },
  successText: {
    fontSize: 17,
    color: "#059669",
    fontWeight: 500,
    marginRight: 100,
  },
  documentRequestInfoContainer: {
    width: "100%",
    height: 80,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  documentRequestInfo: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    "& p": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  approvedUnitsContainer: {
    border: "1px solid #b0e0e0",
    margin: "10px 0px 10px 20px",
    paddingLeft: 20,
    "& .MuiGrid-item": {
      padding: "12px 0",
    },
  },
  approvedUnitsDivider: {
    height: 0,
    width: "110%",
    borderBottom: "1px solid #cbcbcb",
    margin: "40px 0px 40px -20px",
  },
  addAuthorizationCodeButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "30px 0px",
  },
  newUnitContainer: {
    borderLeft: "1px solid #42caca",
    borderRight: "1px solid #42caca",
    borderBottom: "1px solid #42caca",
    margin: "40px 0px 40px -20px",
    paddingLeft: 20,
    paddingBottom: "5px",
  },
  updateUnitContainer: {
    borderLeft: "1px solid #42caca",
    borderRight: "1px solid #42caca",
    borderBottom: "1px solid #42caca",
    margin: "40px 0px 40px -20px",
    paddingLeft: 12,
    paddingBottom: "5px",
  },
  newAuthorizationButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px 0px",
  },
  smallButton: {
    fontSize: ".8rem",
    textTransform: "none",
    marginBottom: 20,
  },
  physicianLabel: {
    color: "#15171D",
  },
  physicianValue: {
    color: "#3F4456",
  },
  updateEmailButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 25,
  },
  updateEmailSuccessText: {
    color: "#059669",
    fontWeight: 500,
    marginRight: 30,
    flex: 1,
    textAlign: "center",
  },
  updateEmailErrorText: {
    color: "#DC2626",
    fontWeight: 500,
    marginRight: 30,
    flex: 1,
    textAlign: "center",
  },
  notFound: {
    fontSize: 17,
    color: "#1517d",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 10,
    marginLeft: "-38px",
  },
  iconContainer: {
    height: 28,
    width: 28,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusInfoContainer: {
    padding: 12,
    "& p": {
      color: "#2a2739",
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 5,
      letterSpacing: "-0.02em",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "50%",
    },
  },
  missingInfoText: {
    color: "#3F4456",
    fontSize: 15,
    fontWeight: 500,
    marginLeft: 20,
  },
  snackbarRoot: {
    width: "100%",
    height: 50,
    zIndex: 3001,
    top: 62,
    "& .MuiSnackbarContent-root": {
      width: "100%",
      backgroundColor: "#D1FAE5",
      boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
      color: "#047857",
      fontWeight: 500,
      lineHeight: "18px",
      fontSize: 14,
      letterSpacing: "0.05em",
    },
    "& .MuiSnackbarContent-message": {
      paddingLeft: "5%",
    },
  },
  snackBarRootError: {
    width: "100%",
    height: 50,
    zIndex: 3001,
    top: 62,
    "& .MuiSnackbarContent-root": {
      width: "100%",
      backgroundColor: "#FECACA",
      boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
      color: "#DC2626",
      fontWeight: 500,
      lineHeight: "18px",
      fontSize: 14,
      letterSpacing: "0.05em",
    },
    "& .MuiSnackbarContent-message": {
      paddingLeft: "5%",
    },
  },
  disabledEmail: {
    "& .MuiInputBase-input.Mui-disabled": {
      opacity: "0.5",
    },
    "& .MuiFormHelperText-root": {
      marginTop: "5px !important",
      "& span": {
        fontSize: "14px !important",
        opacity: "0.5",
      },
    },
  },
  accordionRoot: {
    width: "100%",
    marginBottom: 20,
    "& .Mui-expanded": {
      transform: "rotate(0deg)",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      padding: "30px 50px 0px",
    },
  },
  accordionDetailsRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "0 86px 20px 50px",
  },
  accordionDivider: {
    width: "calc(100% + 48px)",
    height: 0,
    marginBottom: 20,
    borderTop: "0.5px solid #A5AABC",
  },
  accordion: {
    backgroundColor: "#F7F3FA",
  },
  accordionSummary: {
    height: 80,
    backgroundColor: "#F7F3FA",
    borderRadius: 7,
    padding: "0px 50px",
    "& expandIcon": {
      color: "red",
    },
  },
  accordionLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "30px",
    color: "#3F4456",
  },
  accordionValue: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "30px",
    color: "#15171D",
  },
  accordionTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#15171D",
    backgroundColor: "#F7F3FA",
  },
  expandIcon: {
    color: "#2A2D39",
  },
  roadmapContainer: {
    width: "100%",
    height: "fit-content",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 0 25px",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      minWidth: 0,
      marginTop: 30,
    },
  },
  roadmapTitle: {
    color: "#2A2D39",
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 5,
  },
  roadmapStepCount: {
    color: "#2A2D39",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 15,
  },
  completedRoadmapStep: {
    width: "80%",
    borderRadius: 5,
    backgroundColor: "#ecfdf5",
    border: "1.5px solid #a7f3d0",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 12,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "& div": {
      display: "flex",
      width: "90%",
      justifyContent: "flex-start",
    },
    "& h3": {
      color: "#047857",
      fontSize: 15,
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& p": {
      color: "#064E3B",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  currentRoadmapStep: {
    width: "90%",
    borderRadius: 5,
    backgroundColor: "#D9F4F4",
    border: "2.5px solid #68D5D5",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "16px calc(5% + 16px)",
    paddingRight: 5,
    "& div": {
      display: "block",
      width: "90%",
    },
    "& h3": {
      width: "100%",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // whiteSpace: "nowrap",
      color: "#1a5151",
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 500,
    },
    "& h4": {
      color: "#0D2828",
      fontSize: 14,
      marginBottom: 5,
      fontWeight: 400,
    },
    "& h6": {
      color: "#3F4456",
      width: "100%",
      fontSize: 13,
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      fontWeight: 500,
    },
  },
  incompleteRoadmapStep: {
    width: "80%",
    borderRadius: 5,
    backgroundColor: "#F0F1F4",
    border: "0.5px solid #C3C6D2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 16,
    cursor: "pointer",
    "& div": {
      display: "block",
      width: "90%",
    },
    "& h3": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#69718F",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  roadmapIconContainer: {
    height: 26,
    maxWidth: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: "#047857",
      fontSize: 16,
    },
  },
  roadmapStepNumber: {
    color: "#15171d",
    fontSize: 16,
    fontWeight: 500,
    marginRight: 12,
  },
  roadmapStepTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  roadmapDividerCompleted: {
    height: 15,
    width: 12,
    backgroundColor: "#A7F3D0",
  },
  roadmapDivider: {
    height: 15,
    width: 12,
    backgroundColor: "#C3C6D2",
  },
  stepLabelActive: {
    fontSize: 19,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  stepLabel: {
    fontSize: 19,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  stepContent: {
    fontSize: 16,
    // fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  stepContentIncomplete: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },

  selectionBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    borderRadius: 24,
    // marginTop: 15,
  },
  tabBar: {
    height: 48,
    width: "100%",
    borderRadius: 26,
    border: "1px solid #C3C6D2;",
    "& .MuiButtonBase-root": {
      fontSize: 13,
      color: "#A5AABC",
      fontWeight: 500,
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.down("xl")]: {
        fontSize: 11,
      },
      [theme.breakpoints.down("xl")]: {
        fontSize: 12,
      },
    },
  },
  tabButton: {
    backgroundColor: "#F0F1F4",
    // border: "1px solid #C3C6D2;",
    opacity: 1,
  },
  activeTab: {
    backgroundColor: "#8E42CA",
    border: "none",
    "& span": {
      color: "#fff",
    },
  },
  indicatorColor: {
    // backgroundColor: "#987ece",
    display: "none",
  },
  timelineStatisticsContainer: {
    top: 55,
    width: "25%",
    height: "calc(100vh - 120px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    backgroundColor: "#f7f3fa",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      top: 119,
    },
  },
  timelineStatisticsHeading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingLeft: 15,
    paddingRight: 10,
    paddingTop: 20,
    paddingBottom: 10,
    marginBottom: 20,
    "& h4": {
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 500,
      color: "#2A2D39",
      marginBottom: 20,
    },
    "& p": {
      fontSize: 15,
      lineHeight: "20px",
      fontWeight: 500,
      color: "#2A2D39",
      marginTop: 5,
    },
  },
  timelineStatisticsTabbar: {
    width: "90%",
    alignSelf: "center",
  },
  timelineStatistics: {
    padding: "0px 10px 30px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  timelineHeadingContainer: {
    position: "sticky",
    top: 0,
    width: "100%",
    backgroundColor: "#f7f3fa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  },
  stepperRoot: {
    [theme.breakpoints.down("xl")]: {
      padding: 10,
    },
  },
  stepNote: {
    fontSize: 15,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  stepNoteIncomplete: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },
  statisticsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px 0 30px",
  },
  statisticsTitle: {
    width: "100%",
    alignSelf: "flex-start",
    paddingLeft: 30,
    marginBottom: 20,
  },
  statisticsAreaOfImprovement: {
    fontSize: 14,
  },
  statistic: {
    width: "100%",
    height: "fit-content",
    minHeight: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.7px solid #C3C6D2",
    "& p": {
      maxWidth: "calc(100% - 80px)",
      fontSize: 12,
    },
  },
  statisticResult: {
    height: "100%",
    width: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontSize: 15,
    },
    backgroundColor: "#ECFAFA",
  },
  taskListHeading: {
    height: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 20,
    "& h4": {
      fontSize: 20,
      lineHeight: "24px",
      fontWeight: 500,
      color: "#2A2D39",
    },
    "& p": {
      fontSize: 15,
      lineHeight: "20px",
      fontWeight: 500,
      color: "#2A2D39",
      marginTop: 5,
    },
  },
  insuranceFieldName: {
    color: "#69718F !important",
    fontSize: "15px !important",
    fontWeight: "500 !important",
  },
  insuranceValue: {
    color: "#15171D !important",
    fontSize: "17px !important",
    fontWeight: "400 !important",
    marginBottom: "24px",
  },
  iconButton: {
    backgroundColor: "#885FD5",
    padding: 6,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "rgb(104, 81, 143)" },
  },
  iconButtonEnabled: {
    backgroundColor: "#10B981",
    padding: 6,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#10B981" },
  },
  editIcon: {
    fill: "#fff",
    fontSize: 17,
  },
});
