import React, { useState, useRef, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CircularProgress from "@mui/material/CircularProgress";
import FolderIcon from "@mui/icons-material/Folder";

const DetailAccordion = (props) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { classes, loading, title, minHeight, maxHeight, enableScroll, forwardedRef, children } =
    props;

  let style = {};
  style.minHeight = minHeight ? minHeight : null;
  style.maxHeight = maxHeight ? maxHeight : null;
  style.overflowY = enableScroll ? "auto" : null;

  return (
    <div className={classes.root} ref={forwardedRef}>
      <Accordion
        elevation={0}
        onChange={() => {
          setAccordionExpanded(!accordionExpanded);
        }}
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls="block-content"
          className={classes.accordionSummary}
          expandIcon={
            accordionExpanded ? (
              <RemoveIcon className={classes.expandIcon} />
            ) : (
              <AddIcon className={classes.expandIcon} />
            )
          }
        >
          <FolderIcon style={{ color: "#a568d6", marginRight: 10 }} />
          <Typography component="p" className={classes.accordionTitle}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={style}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={25} color="primary" style={{ margin: 10 }} />
              <Typography>Loading</Typography>
            </div>
          ) : (
            children
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: 15,
    "& .Mui-expanded": {
      transform: "rotate(0deg)",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      padding: "20px 0px 0px 50px",
    },
  },
  accordion: {
    backgroundColor: "#fafafa",
  },
  accordionSummary: {
    height: 55,
    border: "1px solid #a568d6",
    borderRadius: 10,
    padding: "0px 20px",
    "& expandIcon": {
      color: "red",
    },
  },
  accordionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "#a568d6",
  },
  expandIcon: {
    color: "#a568d6",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default withStyles(styles)(DetailAccordion);
