import Request from "../utils/request";
import cookie from "react-cookies";
import { difference } from "lodash";
import { Auth } from "@aws-amplify/auth";
import { select } from "redux-saga/effects";
import { getUserAttributes } from "../selectors";
import SecureRequest from "../utils/securerequest";
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

const ALLOWED_GROUPS = [
  "Admins",
  "Clinicians",
  "Superadmins",
  "CustomerCare",
  "Caregivers",
  "ClinicianViewOnly",
];

export function* signinCognitoUser(payload, currentTry) {
  const username = payload?.username?.toLowerCase() || payload?.email?.toLowerCase();
  const password = payload.password;

  return yield new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          resolve({});
          return;
        }
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
        const diff = difference(ALLOWED_GROUPS, groups);
        if (diff.length === ALLOWED_GROUPS.length) {
          // clear the cookies no matter what
          cookie.remove("accessToken", { path: "/" });
          cookie.remove("idToken", { path: "/" });
          cookie.remove("refreshToken", { path: "/" });
          cookie.remove("userId", { path: "/" });
          Auth.signOut();
          reject(false);
          return;
        }
        resolve({
          email: user.attributes.email,
          groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function* signinCognitoUserFromOTP(payload) {
  try {
    const { idToken, accessToken, refreshToken, username } = payload;

    // Create a CognitoUser object
    const userData = {
      Username: username,
      Pool: Auth.userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    // Create a CognitoUserSession object using tokens
    const sessionData = {
      IdToken: new CognitoIdToken({ IdToken: idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
    };

    const userSession = new CognitoUserSession(sessionData);
    cognitoUser.setSignInUserSession(userSession);

    const currentUser = yield Auth.currentAuthenticatedUser();

    const groups = currentUser.signInUserSession.accessToken.payload["cognito:groups"] || [];
    const email = currentUser.attributes.email;

    return {
      email,
      groups,
    };
  } catch (e) {
    console.log(e);
  }
}

export function* signOutCognito() {
  return yield new Promise((resolve, reject) => {
    // clear the cookies no matter what
    cookie.remove("accessToken", { path: "/" });
    cookie.remove("idToken", { path: "/" });
    cookie.remove("refreshToken", { path: "/" });
    cookie.remove("userId", { path: "/" });

    Auth.signOut().then(resolve(true)).catch(reject(false));
  });
}

export function* changePassword(payload) {
  const { currentPassword, password } = payload;
  const userAttributes = yield select(getUserAttributes);
  const username = userAttributes.username || userAttributes.email;
  return yield new Promise((resolve, reject) => {
    Auth.signIn(username.toLowerCase(), currentPassword)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          return Auth.completeNewPassword(user, password)
            .then((data) => {
              resolve({
                email: data.challengeParam.userAttributes.email,
                group: data.signInUserSession.accessToken.payload["cognito:groups"][0],
              });
            })
            .catch((err) => reject(err));
        }
      })
      .catch((err) => reject(err));
  });
}

export function* changeUserPassword(payload) {
  const { currentPassword, password } = payload;
  return yield new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, password);
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

export function* forgotPassword(payload) {
  return yield new Promise((resolve, reject) => {
    Auth.forgotPassword(payload.username.toLowerCase())
      .then(resolve())
      .catch(reject("No proper user"));
  });
}

export function* resetPassword(payload) {
  const username = payload.username.toLowerCase();
  const verificationCode = payload.verificationCode;
  const newPassword = payload.password;

  return yield Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
}

export function* migrate(params) {
  return yield Request.post(`/users/migrate`, params);
}

export function* getInvitation(guid) {
  return yield Request.get(`/invitations/${guid}`);
}

export function* markInvitationUsed(guid) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/invitations/${guid}`, {});
}

export function* verify(email, params) {
  return yield Request.post(`/customers/${email}/verify`, params);
}
