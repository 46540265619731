const familyAndContactsConfig = [
  { label: "Relationship", dataKey: "relationship", cols: 12, type: "mapRelationshipToDisplay" },
  { label: "First Name", dataKey: "first_name", cols: 6 },
  { label: "Last Name", dataKey: "last_name", cols: 6 },
  { label: "Email Address", dataKey: "email", cols: 6 },
  { label: "Phone Number", dataKey: "phone_number", format: "phone", cols: 6 },
  { label: "Legal Guardian", dataKey: "legal_guardian", cols: 6, type: "yes_no" },
];

export default familyAndContactsConfig;
