import React, { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { useQuery } from "react-query";
import { getAppointmentAvailability } from "api/clinician";
import OverrideClinicianTimePicker from "elements/OverrideClinicianTimePicker";
import { mergeAvailability } from "./MergeAvailability";

export const TimeSlots = ({
  healthieAvailability,
  incompleteInfo,
  selectedClinician,
  callType,
  duration,
  overrideSchedule,
  timezone,
  classes,
  date,
  selectClinicianTime,
  onChangeOverrideSchedule,
  selectClinicianOverrideTime,
  allowOverride,
  overrideTime,
}) => {
  const parsedDate = moment(date).tz(timezone);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [mergedTimeSlots, setMergedTimeSlots] = useState([]);
  const {
    data: body,
    isLoading,
    error,
  } = useQuery(
    ["clinicianAvailableHours", selectedClinician, callType, duration, parsedDate],
    () =>
      getAppointmentAvailability({
        clinicianId: selectedClinician?.clinician_id,
        startDate: parsedDate?.format("YYYY-MM-DD"),
        endDate: parsedDate?.format("YYYY-MM-DD"),
        serviceName: callType,
        duration,
      }),
    {
      enabled: !!selectedClinician?.clinician_id && !!callType && !!parsedDate && !!duration,
      onError: (error) => {
        console.error("Error updating clinician working hours:", error);
      },
    },
  );

  // Effect to merge time slots once they're fetched or when healthieAvailability changes
  useEffect(() => {
    if (!isLoading && body?.data && healthieAvailability) {
      const mergedSlots = mergeAvailability(healthieAvailability, body.data, timezone, duration);
      setMergedTimeSlots(mergedSlots);
    }
  }, [body?.data, healthieAvailability, isLoading, timezone]);

  return (
    <>
      {incompleteInfo ? (
        <Typography className={classes.infoText}>
          Complete all fields to show available times.
        </Typography>
      ) : isLoading ? (
        <div className={classes.clinicianLoading}>
          <CircularProgress />
          <Typography component="p">Loading</Typography>
        </div>
      ) : error ? (
        <Typography className={classes.errorText}>Error loading availability.</Typography>
      ) : mergedTimeSlots && mergedTimeSlots.length > 0 ? (
        <>
          <Typography className={classes.availabilitySubHeader}>
            {`Available Times for ${selectedClinician?.first_name} ${
              selectedClinician?.last_name
            } on ${parsedDate?.format("MMMM Do")}.`}
          </Typography>

          <div className={classes.timeOptionsContainer}>
            {mergedTimeSlots.map((slot, index) => {
              return (
                <div
                  key={index}
                  style={{ minWidth: "min-content", padding: "4px" }}
                  onClick={(e) => {
                    const formattedTimeSlot = moment(slot)
                      .tz(timezone)
                      .format("YYYY-MM-DD HH:mm:ss ZZ");
                    setSelectedTimeSlot(index);
                    selectClinicianTime(selectedClinician, {
                      start: formattedTimeSlot,
                    });
                  }}
                  className={
                    index === selectedTimeSlot && !overrideSchedule
                      ? classes.timeOptionSelected
                      : classes.timeOption
                  }
                >
                  <Typography component="p">
                    {moment(slot, "YYYY-MM-DD HH:mm:ss ZZ").tz(timezone).format("h:mm A")}
                  </Typography>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <Typography className={classes.notAvailableText}>
          No available times on this day.
        </Typography>
      )}
      {allowOverride && (
        <OverrideClinicianTimePicker
          onChangeOverrideSchedule={onChangeOverrideSchedule}
          overrideSchedule={overrideSchedule}
          overrideTime={overrideTime}
          bcba={selectedClinician}
          selectClinicianOverrideTime={selectClinicianOverrideTime}
          timezone={timezone}
        />
      )}
      <Typography className={classes.noteText}>
        NOTE: All times are being displayed in {timezone}
      </Typography>
    </>
  );
};
