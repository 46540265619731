import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import {
  getSignedinState,
  getClinicianWorkflowStatus,
  getClinicianBasicInfo,
  getClinicianBasicInfoLoading,
  getClinicianBasicInfoSaving,
  isClinician,
  isSupervisor,
  isAdmin,
  getClinicianDriversLicenseSaving,
  getClinicianDriversLicenseLoading,
  getClinicianDriversLicenseSuccess,
  getClinicianDriversLicenseError,
  getClinicianBasicInfoSuccess,
  getClinicianCredentialsLoading,
  getClinicianCredentialsSaving,
  getClinicianCredentials,
  getClinicianCredentialsSuccess,
  getInsuranceProviders,
  getClinicianLicensesLoading,
  getClinicianLicensesSuccess,
  getClinicianStateLicenses,
  getClinicianCertificationLoading,
  getClinicianCertificationSaving,
  getClinicianCertificationSuccess,
  getClinicianLicensesSaving,
  getClinicianAvailability,
  getClinicianAvailabilityLoading,
  getClinicianAvailabilitySuccess,
  getClinicianAvailabilitySaving,
  getClinicianDocuments,
  getClinicianDocumentsLoading,
  getClinicianDocumentsSaving,
  getClinicianDocumentsSuccess,
  getClinicianInsurances,
  getClinicianInsuranceLoading,
  getClinicianInsuranceSaving,
  getClinicianInsuranceSuccess,
  getClinicianInsuranceSaveFail,
  getInsuranceProvidersLoading,
  getInsuranceProvidersList,
  getInsurancePlans,
  getClinicianBioSaving,
  getClinicianBioSuccess,
  getUserClinicianId,
  getClinicianApplicationLoading,
  getClinicianApplicationSuccess,
  getClinicianHealthieSaving,
  getClinicianHealthieSuccess,
  getClinicianHealthieLoading,
  getClinicianApplicationRejectionNote,
  getClinicianDeactivationReasons,
  getClinicianClientsCount,
  getClinicianClientsList,
  getClinicianPastClientsList,
  getClinicianPayStructure,
  getClinicianPayStructureLoading,
  getClinicianPayStructureSaving,
  getClinicianPayStructureSuccess,
  getUserPermissionsList,
  getClinicianLiabilityExpirationLoading,
  getClinicianLiabilityExpirationSuccess,
  getClinicianLiabilityExpirationError,
  getSendingResetPassword,
  getResetPasswordSent,
  getSentEmails,
  getSentEmailsLoading,
  getUpdateClinicianEmailLoading,
  getUpdateClinicianEmailSuccess,
  getUpdateClinicianEmailError,
  getUpdateClinicianEmailErrorMessage,
  getClinicianNotes,
  getUser,
  getClinicianNoteSaving,
  getClinicianNoteSuccess,
  getClinicianCredentialData,
  getClinicianCredentialDataLoading,
  getClinicianCredentialDataError,
  getClinicianCalendarSynced,
  getClinicianCalendarSyncLoading,
  getClinicianCalendarSyncSuccess,
  getCustomerDetails,
  getClinicianBioPictureSuccess,
  getClinicianBioPictureSaving,
  isDirector,
  isSuperAdmin,
} from "../../selectors";
import { Auth } from "@aws-amplify/auth";
import { Snackbar, Typography } from "@mui/material";
import { STATES } from "../../constants";
import { checkFeatureAccess } from "api/featureAccess";
import { Prompt, withRouter } from "react-router-dom";
import SaveBeforeNavDialog from "../ClientDetails/Dialogs/SaveBeforeNavDialog";
import CircularProgress from "@mui/material/CircularProgress";
import SubHeading from "../../elements/SubHeading";
import ClinicianDetailsPageContents from "./ClinicianDetailsPageContents";
import ClinicianDetailsTabs from "./ClinicianDetailsTabs";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import Documents from "./Documents";
import AccountSettings from "./AccountSettings";
import { useUpdateEmploymentType } from "hooks/useUpdateEmploymentType";
import { update } from "lodash";
import { updateEmploymentType } from "api/clinicians";
import withHooks from "hooks/withHooks";
import { useUpdateSupervisor } from "hooks/useUpdateSupervisor";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  customerDetails: getCustomerDetails(state),
  signedInState: getSignedinState(state),
  workflowStatus: getClinicianWorkflowStatus(state),
  basicInfo: getClinicianBasicInfo(state),
  basicInfoLoading: getClinicianBasicInfoLoading(state),
  basicInfoSaving: getClinicianBasicInfoSaving(state),
  basicInfoSuccess: getClinicianBasicInfoSuccess(state),
  driversLicenseSaving: getClinicianDriversLicenseSaving(state),
  driversLicenseLoading: getClinicianDriversLicenseLoading(state),
  driversLicenseSuccess: getClinicianDriversLicenseSuccess(state),
  driversLicenseError: getClinicianDriversLicenseError(state),
  credentials: getClinicianCredentials(state),
  credentialsLoading: getClinicianCredentialsLoading(state),
  credentialsSaving: getClinicianCredentialsSaving(state),
  credentialsSuccess: getClinicianCredentialsSuccess(state),
  isClinician: isClinician(state),
  isSupervisor: isSupervisor(state),
  isAdmin: isAdmin(state),
  insuranceProviders: getInsuranceProviders(state),
  insuranceProvidersLoading: getInsuranceProvidersLoading(state),
  insuranceProvidersList: getInsuranceProvidersList(state),
  insurancePlans: getInsurancePlans(state),
  certificationLoading: getClinicianCertificationLoading(state),
  certificationSaving: getClinicianCertificationSaving(state),
  certificationSuccess: getClinicianCertificationSuccess(state),
  stateLicenses: getClinicianStateLicenses(state),
  licensesLoading: getClinicianLicensesLoading(state),
  licensesSuccess: getClinicianLicensesSuccess(state),
  licensesSaving: getClinicianLicensesSaving(state),
  availability: getClinicianAvailability(state),
  availabilityLoading: getClinicianAvailabilityLoading(state),
  availabilitySaving: getClinicianAvailabilitySaving(state),
  availabilitySuccess: getClinicianAvailabilitySuccess(state),
  documents: getClinicianDocuments(state),
  documentsLoading: getClinicianDocumentsLoading(state),
  documentsSaving: getClinicianDocumentsSaving(state),
  documentsSuccess: getClinicianDocumentsSuccess(state),
  insurances: getClinicianInsurances(state),
  insuranceLoading: getClinicianInsuranceLoading(state),
  insuranceSaving: getClinicianInsuranceSaving(state),
  insuranceSuccess: getClinicianInsuranceSuccess(state),
  insuranceSaveFail: getClinicianInsuranceSaveFail(state),
  bioSaving: getClinicianBioSaving(state),
  bioSuccess: getClinicianBioSuccess(state),
  bioPictureSaving: getClinicianBioPictureSaving(state),
  bioPictureSuccess: getClinicianBioPictureSuccess(state),
  userClinicianId: getUserClinicianId(state),
  applicationLoading: getClinicianApplicationLoading(state),
  applicationSuccess: getClinicianApplicationSuccess(state),
  healthieSaving: getClinicianHealthieSaving(state),
  healthieLoading: getClinicianHealthieLoading(state),
  healthieSuccess: getClinicianHealthieSuccess(state),
  rejectionNote: getClinicianApplicationRejectionNote(state),
  clinicianDeactivationReasons: getClinicianDeactivationReasons(state),
  clientList: getClinicianClientsList(state),
  pastClientList: getClinicianPastClientsList(state),
  numberOfClients: getClinicianClientsCount(state),
  payStructure: getClinicianPayStructure(state),
  payStructureLoading: getClinicianPayStructureLoading(state),
  payStructureSaving: getClinicianPayStructureSaving(state),
  payStructureSuccess: getClinicianPayStructureSuccess(state),
  userPermissions: getUserPermissionsList(state),
  liabilityExpirationLoading: getClinicianLiabilityExpirationLoading(state),
  liabilityExpirationSuccess: getClinicianLiabilityExpirationSuccess(state),
  liabilityExpirationError: getClinicianLiabilityExpirationError(state),
  sendingResetPassword: getSendingResetPassword(state),
  resetPasswordSent: getResetPasswordSent(state),
  sentEmailList: getSentEmails(state),
  sentEmailsLoading: getSentEmailsLoading(state),
  updateClinicianEmailLoading: getUpdateClinicianEmailLoading(state),
  updateClinicianEmailSuccess: getUpdateClinicianEmailSuccess(state),
  updateClinicianEmailError: getUpdateClinicianEmailError(state),
  updateClinicianEmailErrorMessage: getUpdateClinicianEmailErrorMessage(state),
  clinicianNotes: getClinicianNotes(state),
  noteSaving: getClinicianNoteSaving(state),
  noteSuccess: getClinicianNoteSuccess(state),
  currentUser: getUser(state),
  clinicianCredentialData: getClinicianCredentialData(state),
  clinicianCredentialDataLoading: getClinicianCredentialDataLoading(state),
  clinicianCredentialDataError: getClinicianCredentialDataError(state),
  calendarSynced: getClinicianCalendarSynced(state),
  calendarSyncLoading: getClinicianCalendarSyncLoading(state),
  calendarSyncSuccess: getClinicianCalendarSyncSuccess(state),
  isDirector: isDirector(state),
  isSuperAdmin: isSuperAdmin(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      loadClinicianDetails: actions.loadClinicianDetails,
      saveBasicInfo: actions.saveClinicianBasicInfo,
      saveDriversLicense: actions.saveClinicianDriversLicense,
      getInsuranceProviders: actions.getInsuranceProviders,
      saveStateLicense: actions.saveClinicianStateLicense,
      updateStateLicense: actions.updateClinicianStateLicense,
      deleteStateLicense: actions.deleteClinicianStateLicense,
      saveCredentials: actions.saveClinicianCredentials,
      saveCertification: actions.saveClinicianCertification,
      saveAvailability: actions.saveClinicianAvailability,
      saveDocument: actions.saveClinicianDocument,
      rejectDocument: actions.rejectClinicianDocument,
      replaceDocument: actions.replaceClinicianDocument,
      approveDocument: actions.approveClinicianDocument,
      addInsurance: actions.addClinicianInsurance,
      updateInsurance: actions.updateClinicianInsurance,
      updateBio: actions.updateClinicianBio,
      updateProfilePicture: actions.updateClinicianPicture,
      updateClinicianStatus: actions.updateClinicianStatus,
      rejectApplication: actions.rejectClinicianApplication,
      approveApplication: actions.approveClinicianApplication,
      setClinicianCredentialed: actions.setClinicianCredentialed,
      inviteClinicianToHealthie: actions.inviteClinicianToHealthie,
      syncClinicianCalendar: actions.syncClinicianCalendar,
      toggleClinicianActivation: actions.toggleClinicianActivation,
      getClinicianDeactivationReasons: actions.getClinicianDeactivationReasons,
      getClinicianPayStructure: actions.getClinicianPayStructure,
      saveClinicianPayStructure: actions.saveClinicianPayStructure,
      deleteClinicianBonus: actions.deleteClinicianBonus,
      clearClinicianDetails: actions.clearClinicianDetails,
      inviteClinician: actions.inviteClinician,
      rejectClinicianApplicant: actions.rejectClinicianApplicant,
      interviewClinicianApplicant: actions.interviewClinicianApplicant,
      updateLiabilityExpirationDate: actions.updateLiabilityExpirationDate,
      clearClinicianDriversLicenceInfo: actions.clearClinicianDriversLicenseInfo,
      resetClinicianPassword: actions.resetUserPassword,
      clearResetUserPasswordInfo: actions.clearResetUserPasswordInfo,
      getBasicInfo: actions.getClinicianBasicInfo,
      resendHealthieInvite: actions.resendClinicianHealthieInvite,
      updateClinicianEmail: actions.updateClinicianEmail,
      getClinicianNotes: actions.getClinicianNotes,
      saveClinicianNote: actions.saveClinicianNote,
      editClinicianNote: actions.editClinicianNote,
      getClinicianCredentialData: actions.getClinicianCredentialData,
      getInsuranceProvidersList: actions.getInsuranceProvidersList,
      getInsurancePlans: actions.getInsurancePlans,
      setInsurancePlansRowsPerPage: actions.setInsurancePlansRowsPerPage,
      setClinicianCalendarSynced: actions.setSuccessfulCalendarSync,
    },
    dispatch
  );

class ClinicianDetailContainer extends Component {
  state = {
    clinicianId: 0,
    isClinicianProfile: false,
    editDriversLicenseOpen: false,
    requestChangesOpen: false,
    updateClinicianStatusOpen: false,
    approveApplicationOpen: false,
    confirmCredentialedOpen: false,
    addToHealthieOpen: false,
    resendHealthieInviteOpen: false,
    activationDialogOpen: false,
    reason: "",
    note: "",
    isRoleClinician: true,
    isDirty: false,
    rejectApplicantOpen: false,
    rejectApplicantWithoutEmail: false,
    interviewApplicantOpen: false,
    inviteClinicianOpen: false,
    markCAQHCompleteOpen: false,
    resetPasswordOpen: false,
    updateEmailOpen: false,
    currentTab: "profile",
    editButton: false,
    insuranceFormDialogOpen: false,
    fileObjects: [],
    selectedStateFilter: "",
    selectedInsuranceCompanyFilter: "",

    selectedCredentialRow: null,
    isNewCredential: true,
    formInsuranceCompany: "",
    formInsuranceCompanyId: "",

    formInsurancePlan: "",
    formInsurancePlanId: "",

    formSubmittedDate: null,
    formInitialCredentialDate: null,
    formApprovalDate: null,
    formRecredentialDate: null,
    formTermDate: null,

    dropzoneFiles: [],
    documentUploadError: false,
    confirmQuitCredentialEditDialogOpen: false,
    credentialModified: false,

    uploadingToS3: false,

    sortModel: [
      {
        field: "insuranceCompanyLink",
        sort: "asc",
      },
    ],

    paginationModel: {
      pageSize: 10,
      page: 0,
    },

    editingWorkingHours: false,

    calendarSyncToastOpen: false,
    calendarSyncToastMessage: "",
    onSchedFeatureFlag: false,
    appBio: "",
    newClinicianEmail: "",
    photoUrl: "",
    firstName: "",
    lastName: "",
    fullLegalName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    dateOfBirth: "",
    address1: "",
    address2: "",
    gender: "",
    taxNumber: "",
    timezone: "",
    updatedTaxNumber: false,
    filename: "",
    licenseNumber: "",
    licenseState: "",
    employmentType: "",
    anchorEl: null,
    accountSettings: {
      accountStatus: "",
      clinicianId: "",
    },
    supervisor: "",
  };
  editableTabs = ["profile", "account-settings", "workingHours", "licenses-certifications"];
  // This array is for tabs that are not controlled by the parent (this component) but
  // the logic is in the tab component itself
  tabsExceptions = ["licenses-certifications"];

  setSortModel = (newModel) => this.setState({ sortModel: newModel });
  setPaginationModel = (newModel) => this.setState({ paginationModel: newModel });

  handleToggleConfirmQuitCredentialEdit = () => {
    const { confirmQuitCredentialEditDialogOpen } = this.state;
    this.setState({
      confirmQuitCredentialEditDialogOpen: !confirmQuitCredentialEditDialogOpen,
    });
  };

  handleConfirmQuitCredentialEdit = () => {
    this.handleCloseInsuranceFormDialog();
  };

  handleCancelQuitCredentialEdit = () => {
    this.handleToggleConfirmQuitCredentialEdit();
  };

  getSortValue = () => {
    const rules = this.state.sortModel[0];
    if (rules?.field) {
      const direction = rules?.sort;
      const field = /status/.test(rules.field) ? "credential_status" : "i.name";
      return {
        direction,
        field,
      };
    } else {
      return {
        direction: null,
        field: null,
      };
    }
  };

  onSaveEmploymentDetails = () => {
    const { updateEmploymentType } = this.props;
    if (this.state.employmentType) {
      updateEmploymentType({
        clinicianId: this.props.match.params.id,
        employmentType: this.state.employmentType,
      });
    }
  };

  onSaveSupervisorDetails = () => {
    const { updateSupervisor } = this.props;
    if (this.state.supervisor) {
      updateSupervisor({
        clinicianId: this.props.match.params.id,
        supervisorUserId: this.state.supervisor,
      });
    }
  };

  componentDidMount() {
    if (this.props?.basicInfo?.user_id) {
      checkFeatureAccess(this.props.basicInfo.user_id, "onsched_setup").then((includeFeature) =>
        this.setState({ onSchedFeatureFlag: includeFeature })
      );
    }
    this.props.setInsurancePlansRowsPerPage(1000);
    if (this.props.match.params.id) {
      this.props.setPageDetails({
        currentPage: "cliniciandetail",
        pageName: "Clinician Detail",
        menu: "entities",
        hideDivider: true,
      });
      this.props.loadClinicianDetails(this.props.match.params.id);
      this.setState({ clinicianId: this.props.match.params.id });
      this.props.getClinicianDeactivationReasons();
      this.props.getClinicianPayStructure(this.props.match.params.id);
      this.props.getClinicianNotes(this.props.match.params.id);
    } else {
      this.props.setPageDetails({
        currentPage: "profile",
        pageName: "Profile",
        menu: "account",
        hideDivider: true,
        onEditButton: this.canEditTab("profile"),
        editButtonAction: this.onEditMode,
      });
      if (this.props.userClinicianId) {
        this.props.loadClinicianDetails(this.props.userClinicianId);
        this.setState({
          isClinicianProfile: true,
          clinicianId: this.props.userClinicianId,
        });
        const params = new URLSearchParams(this.props.location.search);
        if (params.get("calendarSyncRedirect")) {
          this.props.setClinicianCalendarSynced(this.props.userClinicianId);
        }
      }
    }
    this.props.getInsuranceProvidersList();
    this.props.getInsurancePlans();
    this.props.getInsuranceProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedInsuranceCompanyFilter,
      selectedStateFilter,
      insuranceFormDialogOpen,
      sortModel,
      paginationModel,
    } = this.state;

    if (
      (!prevProps?.basicInfo?.user_id ||
        prevProps?.basicInfo?.user_id !== this.props?.basicInfo?.user_id) &&
      this.props?.basicInfo?.user_id
    ) {
      checkFeatureAccess(this.props.basicInfo?.user_id, "onsched_setup").then((includeFeature) =>
        this.setState({ onSchedFeatureFlag: includeFeature })
      );
    }
    if (prevProps?.basicInfo != this.props?.basicInfo && !!this.props?.basicInfo) {
      const { first_name, last_name } = this.props.basicInfo;
      this.props.setPageDetails({
        altTitle: `${first_name} ${last_name}`,
        onEditButton: this.canEditTab("profile"),
        editButtonAction: this.onEditMode,
      });
    }
    if (!prevProps.userClinicianId && this.props.userClinicianId && !this.props.match.params.id) {
      this.props.loadClinicianDetails(this.props.userClinicianId);
      this.setState({
        isClinicianProfile: true,
        clinicianId: this.props.userClinicianId,
      });
      const params = new URLSearchParams(this.props.location.search);
      if (params.get("calendarSyncRedirect")) {
        this.props.setClinicianCalendarSynced(this.props.userClinicianId);
      }
    }
    if (
      (prevState?.clinicianId != this.state?.clinicianId && this.state?.clinicianId) ||
      prevState.selectedStateFilter != selectedStateFilter ||
      prevState.selectedInsuranceCompanyFilter != selectedInsuranceCompanyFilter ||
      prevState.insuranceFormDialogOpen != insuranceFormDialogOpen ||
      prevState.sortModel != sortModel ||
      prevState.paginationModel != paginationModel
    ) {
      this.props.getClinicianCredentialData({
        clinicianId: this.state.clinicianId,
        page: this.state.paginationModel.page,
        size: this.state.paginationModel.pageSize,
        state: this.state.selectedStateFilter,
        insuranceId: this.state.selectedInsuranceCompanyFilter,
        sort: this.getSortValue().field,
        direction: this.getSortValue().direction,
      });
    }

    if (
      this.state.insuranceFormDialogOpen &&
      prevProps.insuranceSaving == true &&
      prevProps.insuranceSaving != this.props.insuranceSaving &&
      !this.state.documentUploadError &&
      !this.props.insuranceSaveFail
    ) {
      this.handleCloseInsuranceFormDialog();
    }

    if (prevProps.calendarSyncLoading && !this.props.calendarSyncLoading) {
      let toastMessage = "Calendar Sync Successful";
      if (!this.props.calendarSyncSuccess) {
        toastMessage = "Error Syncing Calendar";
      }
      this.setState({
        calendarSyncToastOpen: true,
        calendarSyncToastMessage: toastMessage,
      });
    }
  }

  componentWillUnmount() {
    this.props.setInsurancePlansRowsPerPage(25);
    if (!this.state.isClinicianProfile) {
      this.props.clearClinicianDetails();
    }
    this.props.setPageDetails({
      altTitle: null,
      hideDivider: false,
    });
  }

  onToggleActivationDialog = () => {
    this.setState({ activationDialogOpen: !this.state.activationDialogOpen });
  };

  onSubmitToggleActivation = () => {
    const { reason, note, clinicianId } = this.state;
    const { is_active, user_id } = this.props.basicInfo;

    if (is_active) {
      this.props.toggleClinicianActivation({
        id: user_id,
        reason,
        note,
        clinicianId,
      });
    } else {
      this.props.toggleClinicianActivation({
        id: user_id,
        clinicianId,
      });
    }
    this.onToggleActivationDialog();
  };

  onChangeDeactivationInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /**
   * This method enable the button based on the permissions of the clinician profile
   * @param tab actual tab
   * @returns {boolean}
   */
  canEditTab = (tab) => {
    let havePermissions = false;
    if (
      tab === this.editableTabs[0] &&
      (this.props.userPermissions?.edit_clinician_basic_info ||
        this.props.userPermissions?.edit_clinician_bio)
    ) {
      havePermissions = true;
    } else if (tab === this.editableTabs[1]) {
      havePermissions = true;
    } else if (
      tab === this.editableTabs[2] &&
      this.props.userPermissions?.edit_clinician_working_hours
    ) {
      havePermissions = true;
    }

    this.setState({ editButton: havePermissions });
    return havePermissions;
  };

  onChangeWorkingHours = () => {
    this.setState({
      unsavedChanges: true,
    });
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    let val;
    switch (value) {
      case "Yes":
        val = true;
        break;
      case "No":
        val = false;
        break;
      default:
        val = type === "checkbox" ? checked : value;
    }
    if (name === "email") {
      this.setState({ newClinicianEmail: value.toLowerCase() });
    }

    this.setState({
      [name]: val,
      buttonDisabled: false,
      unsavedChanges: true,
      editedBioInformation: true,
    });
  };

  onChangeNumber = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: value.replace(/[^0-9]/g, ""),
      buttonDisabled: false,
      unsavedChanges: true,
      editedBioInformation: true,
    });
  };

  onChangeDate = (name, date) => {
    this.setState({
      [name]: date,
      buttonDisabled: false,
      unsavedChanges: true,
      editedBioInformation: true,
    });
  };

  onChangeTaxNumber = (name) => (e) => {
    const { value } = e.target;
    let ssn = value.replace(/[^0-9]/g, "");
    let number = "";
    number += ssn;
    if (number.includes("X")) {
      number = "";
    }
    if (number.length > 3) {
      number = number.split("-").join("");
      number = number.slice(0, 3) + "-" + number.slice(3);
      if (number.length > 6) {
        number = number.slice(0, 6) + "-" + number.slice(6);
      }
      if (number.length === 12) {
        return;
      }
    }
    this.setState({
      [name]: number,
      updatedTaxNumber: true,
      unsavedChanges: true,
      editedBioInformation: true,
    });
  };

  onActiveTabChange = (tab) => {
    if (tab !== this.state.currentTab) {
      if (!this.editableTabs.includes(tab)) {
        this.setState({ editMode: false });
      }
      this.resetTab(tab);
    }
  };

  resetTab = (tab) => {
    if (this.tabsExceptions.includes(tab)) {
      this.setState({ currentTab: tab });
      return;
    }
    this.clearData(
      {
        currentTab: tab,
        nextLocation: null,
      },
      () => {
        this.props.setPageDetails({
          onEditButton: this.canEditTab(tab),
          editButtonAction: this.onEditMode,
        });
        this.refreshDataTab(tab);
      }
    );

    this.setState({
      currentTab: tab,
    });
  };

  refreshDataTab = (tab) => {};

  clearData = (extra = {}, callback) => {
    this.setState(
      {
        ...{
          editMode: false,
          unsavedChanges: false,
        },
        ...extra,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  /**
   * This is the global method to enable the edit mode on all tabs
   */
  onEditMode = () => {
    this.onEditProfile();
  };

  onEditProfile = () => {
    const { basicInfo } = this.props;
    if (this.canEditTab(this.editableTabs[0])) {
      this.onOpenEditPorifle();
      this.setState({
        appBio: basicInfo?.bio || "",
        photoUrl: basicInfo?.image_url || "",
        firstName: basicInfo?.first_name || "",
        lastName: basicInfo?.last_name || "",
        fullLegalName: basicInfo?.full_legal_name || "",
        email: basicInfo?.email || "",
        phone: basicInfo?.phone || "",
        address1: basicInfo?.street_1 || "",
        address2: basicInfo?.street_2 || "",
        city: basicInfo?.city || "",
        state: basicInfo?.state || "",
        zip: basicInfo?.zip_code || "",
        dateOfBirth: basicInfo?.date_of_birth
          ? dateWithoutTimezone(basicInfo?.date_of_birth)
          : null,
        timezone: basicInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        gender: basicInfo?.gender || "",
        taxNumber: basicInfo?.last_four ? `XXX-XX-${basicInfo?.last_four}` : "",
        filename: basicInfo?.image_name || "",
        licenseState: basicInfo?.dl_state || "",
        stateIdNnumber: basicInfo?.state_id_number || "",
        employmentType: basicInfo?.employment_type,
        pronouns: basicInfo?.pronouns || "",
        supervisor: basicInfo?.supervisor_user_id || "",
      });
    }
  };

  onSubmitUpdateEmail = () => {
    const { newClinicianEmail, clinicianId } = this.state;
    this.props.updateClinicianEmail({
      clinicianId: clinicianId,
      newEmail: newClinicianEmail.toLowerCase(),
    });
  };

  onSaveBio = async () => {
    const { appBio, clinicianId, newClinicianEmail } = this.state;
    const bioData = {
      appBio,
      clinicianId,
    };
    if (newClinicianEmail && newClinicianEmail !== "") {
      this.onSubmitUpdateEmail();
    }
    this.props.updateBio(bioData);
  };

  onSaveAccountSettings = () => {
    if (this.state.accountSettings.accountStatus) {
      this.props.updateClinicianStatus({
        status: this.state.accountSettings.accountStatus,
        clinicianId: this.state.clinicianId,
      });
    }
    this.setState({ unsavedChanges: false, editMode: false });
  };

  onChangeAccountSettings = (e) => {
    this.setState({
      accountSettings: {
        accountStatus: e.value,
        clinicianId: e.accountSettingsCID,
      },
      buttonDisabled: false,
      unsavedChanges: true,
    });
  };

  onSaveBioInfo = () => {
    const { updatedTaxNumber, taxNumber, clinicianId } = this.state;
    let newTaxNumber =
      updatedTaxNumber && taxNumber.length === 11 ? taxNumber.split("-").join("") : null;
    this.props.saveBasicInfo({
      ...this.state,
      clinicianId: clinicianId,
      taxNumber: newTaxNumber,
    });
  };

  onSaveProfilePhoto = async () => {
    const { profilePhotoImage, clinicianId } = this.state;
    const { cognito_id } = this.props.basicInfo;
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        credentials,
        params: { Bucket: process.env.AWS_BUCKET },
      });
      let params = {
        Body: profilePhotoImage[0].file,
        ContentType: profilePhotoImage[0].file.type,
        Key: `${cognito_id}/profile_image.${profilePhotoImage[0].file.name.split(".").pop()}`,
      };
      let upload = await s3.upload(params).promise();
      let photoUrl = upload.Location;

      this.setState({ photoUrl: "" }, () => {
        this.setState({ photoUrl });
      });
      this.props.updateProfilePicture({
        clinicianId,
        photoUrl,
      });
    } catch (error) {
      console.log(error);
    }
  };

  openFileDialog = (name) => (e) => {
    this.setState({ [name]: true });
  };

  closeFileDialog = (name) => (e) => {
    this.setState({ [name]: false, fileObjects: [] });
  };

  addFileObjects = (fileObjects) => {
    this.setState({ fileObjects });
  };

  persistFileObjects = (name, dialog) => {
    this.setState(
      {
        [name]: this.state.fileObjects,
        fileObjects: [],
        [dialog]: false,
      },
      () => {
        this.onSaveProfilePhoto();
      }
    );
  };

  onCloseEditMode = (isCancelClicked) => {
    if (this.state.unsavedChanges) {
      this.setState({ openWarning: true, isCancelClicked: isCancelClicked ?? false });
    } else {
      this.resetTab(this.state.currentTab);
    }
  };

  onOpenEditPorifle = () => {
    const { customerDetails } = this.props;
    this.setState({
      editMode: true,
    });
    this.props.setPageDetails({
      onEditButton: false,
    });
    let customerInfo = customerDetails.details;
    //makes sure tab is at the correct placement
    this.setState({
      currentTab: this.props.match.params.section,
    });
  };

  setUnsavedChanges = (value) => {
    this.setState({ unsavedChanges: value });
  };

  onClearDeactivationInfo = () => {
    this.setState({
      reason: "",
      note: "",
    });
  };

  setIsDirty = (isDirty) => {
    this.setState({ isDirty });
  };

  onDownloadClinicianContract = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${this.props.match.params.id}/contract/${userSession.accessToken.jwtToken}`;
  };

  onInviteClinician = () => {
    this.props.inviteClinician(this.state.clinicianId);
  };

  onToggleResetPassword = () => {
    this.setState({ resetPasswordOpen: !this.state.resetPasswordOpen }, () => {
      if (!this.state.resetPasswordOpen && this.props.resetPasswordSent) {
        this.props.clearResetUserPasswordInfo();
      }
    });
  };

  onSubmitResetPassword = () => {
    let clinicianId = this.props.basicInfo?.user_id;
    this.props.resetClinicianPassword(clinicianId);
  };

  handlePageChange = (event, newPage) => {
    this.setState({ selectedTab: newPage });
    const pathSegments = this.props.history.location.pathname.split("/").filter(Boolean);

    let newPath;
    if (pathSegments[0] === "clinicians" && pathSegments.length >= 2) {
      const clinicianId = pathSegments[1];
      newPath = `/clinicians/${clinicianId}/${newPage}`;
    } else if (pathSegments[0] === "profile") {
      newPath = newPage === "profile" ? "/profile" : `/profile/${newPage}`;
    } else {
      newPath = `/${newPage}`;
    }

    this.props.history.push(newPath);
  };

  handleToggleInsuranceFormDialog = () => {
    this.setState({
      insuranceFormDialogOpen: !this.state.insuranceFormDialogOpen,
    });
  };

  handleSelectedInsuranceCompanyChange = ({ target }) => {
    const { value } = target;
    this.setState({ selectedInsuranceCompanyFilter: value });
  };

  handleSelectedStateChange = (props) => {
    const { value } = props.target;
    this.setState({ selectedStateFilter: value });
  };

  getStateFilterOptions = () => {
    const filteredStates = Object.keys(STATES).map((key) => ({
      name: STATES[key],
      value: key,
    }));

    return [{ value: "", name: "Select" }, ...filteredStates];
  };

  getInsuranceCompanyOptions = () => {
    let filteredInsuranceCompanies = [];
    const { insuranceProvidersList } = this.props;
    if (insuranceProvidersList) {
      filteredInsuranceCompanies = insuranceProvidersList
        .filter((option) => option.name != "Private Pay")
        .map(({ id, name }) => ({
          value: id,
          name,
        }));
    }

    return [{ value: "", name: "Select" }, ...filteredInsuranceCompanies];
  };

  getInsurancePlanOptions = () => {
    const { insurancePlans } = this.props;
    const { formInsuranceCompanyId } = this.state;
    let filteredInsurancePlans = [];
    if (formInsuranceCompanyId) {
      filteredInsurancePlans = insurancePlans
        .filter(({ insurance_id }) => insurance_id == formInsuranceCompanyId)
        .map(({ id, name }) => ({
          value: id,
          name,
        }));
    }
    return [{ value: "", name: "Select" }, ...filteredInsurancePlans];
  };

  setSelectedCredentialRow = (value) => {
    this.setState({ selectedCredentialRow: value });
  };

  handleInsuranceEdit = ({ row }) => {
    this.handleToggleInsuranceFormDialog();

    const {
      insuranceCompany,
      insuranceCompanyId,
      insurancePlan,
      insurancePlanId,
      submittedDate,
      approvalDate,
      initialCredentialedDate,
      reCredentialedDate,
      termDate,
    } = row;

    this.setState({
      selectedCredentialRow: row,
      isNewCredential: false,
      formInsuranceCompany: insuranceCompany,
      formInsuranceCompanyId: insuranceCompanyId,
      formInsurancePlan: insurancePlan,
      formInsurancePlanId: insurancePlanId,
      formSubmittedDate: submittedDate,
      formInitialCredentialDate: initialCredentialedDate,
      formApprovalDate: approvalDate,
      formRecredentialDate: reCredentialedDate,
      formTermDate: termDate,
    });
  };

  handleAddNewInsurance = () => {
    this.handleToggleInsuranceFormDialog();
    this.setState({ isNewCredential: true });
  };

  setInsuranceFormValues = (field) => (event) => {
    const value = event?.target?.value;
    this.setState({ [field]: value ?? event });
  };

  handleCloseInsuranceFormDialog = () => {
    this.handleToggleInsuranceFormDialog();
    this.setState({
      selectedCredentialRow: {},
      isNewCredential: true,
      formInsuranceCompany: "",
      formInsuranceCompanyId: "",
      formInsurancePlan: "",
      formInsurancePlanId: "",
      formSubmittedDate: null,
      formInitialCredentialDate: null,
      formApprovalDate: null,
      formRecredentialDate: null,
      formTermDate: null,
      documentUploadError: false,
      dropzoneFiles: [],
      confirmQuitCredentialEditDialogOpen: false,
      credentialModified: false,
    });
  };

  onSaveDocument = async (imageData) => {
    const { formInsuranceCompanyId: insuranceId, formInsurancePlanId: planId } = this.state;
    const { basicInfo } = this.props;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    let imageKey = `${basicInfo?.cognito_id}/credential-documents/insurance${insuranceId}-plan${planId}-${imageData.name}`;
    const params = {
      ACL: "authenticated-read",
      Body: imageData,
      ContentEncoding: "base64",
      ContentType: imageData.type,
      Key: imageKey,
    };
    try {
      const s3url = await s3.upload(params).promise();
      return {
        image_key: s3url.Key,
        image_name: imageData.name,
        image_size: imageData.size,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  submitInsurance = () => {
    const {
      isNewCredential,
      formInsuranceCompanyId,
      formInsurancePlanId,
      formSubmittedDate,
      formInitialCredentialDate,
      formApprovalDate,
      formRecredentialDate,
      formTermDate,
      dropzoneFiles,
      clinicianId,
      documentUploadError,
      selectedCredentialRow,
    } = this.state;

    let res;
    let documents = [];

    this.setState({ uploadingToS3: true });

    if (dropzoneFiles) {
      dropzoneFiles?.map(async (f) => {
        if (!f.errors) {
          try {
            res = this.onSaveDocument(f.file);
            documents.push(res);
          } catch (e) {
            console.log(e);
            this.setState({ documentUploadError: true });
          }
        }
      });
    }

    const remainingDocuments = (selectedCredentialRow?.files ?? []).map(({ name, key, size }) => ({
      image_name: name,
      image_key: key,
      image_size: size,
    }));

    Promise.all(documents).then(async (documents) => {
      if (!documentUploadError) {
        this.setState({ uploadingToS3: false });

        const { insurancePlans } = this.props;
        const state =
          insurancePlans && formInsurancePlanId
            ? insurancePlans.find(({ id }) => id == formInsurancePlanId)?.state
            : "";

        const body = {
          insuranceId: +formInsuranceCompanyId || null,
          insurancePlanId: +formInsurancePlanId || null,
          submittedDate: formSubmittedDate,
          initialCredentialDate: formInitialCredentialDate,
          approvalDate: formApprovalDate,
          recredentialDate: formRecredentialDate,
          termDate: formTermDate,
          documents: documents.concat(remainingDocuments),
          state,
          clinicianId,
          clinicianInsuranceId: selectedCredentialRow?.id,
        };

        if (isNewCredential) {
          await this.props.addInsurance(body);
        } else {
          await this.props.updateInsurance(body);
        }

        if (
          !this.props.insuranceSaving &&
          !this.props.insuranceSaveFail &&
          !this.state.documentUploadError
        ) {
          this.handleCloseInsuranceFormDialog();
        }
      }
    });
  };

  onCompanyChange = (e) => {
    const { target } = e;
    this.setState({ formInsuranceCompanyId: target.value });
  };

  onPlanChange = (e) => {
    const { target } = e;
    this.setInsuranceFormValues("formInsurancePlanId")(target.value);
  };

  handleFileUpload = (files) => {
    const newFiles =
      files.acceptedFiles instanceof Array
        ? files.acceptedFiles.map((file) => ({
            file,
            errors: false,
          }))
        : [{ acceptedFiles: files, errors: false }];

    const dropzoneFiles = this.state?.dropzoneFiles;
    const newFileNames = newFiles.map(({ file }) => file.name);

    const remainingDropzoneFiles = dropzoneFiles.filter(
      (file) => !newFileNames.includes(file.name)
    );

    const filesRejected = files.fileRejections
      ? files.fileRejections.map((rejection) => {
          return {
            file: rejection,
            errors: true,
          };
        })
      : [];

    this.setState({
      dropzoneFiles: [...remainingDropzoneFiles, ...newFiles, ...filesRejected],
    });
  };

  handleFileDelete = (file) => {
    const dropzoneFiles = this.state?.dropzoneFiles;
    dropzoneFiles.splice(dropzoneFiles.indexOf(file), 1);
    this.setState({ dropzoneFiles });
  };

  handleLoadedFileDelete = (file) => {
    const selectedCredentialRow = this.state?.selectedCredentialRow;
    let files = selectedCredentialRow?.files ?? [];
    files.splice(files.indexOf(file), 1);

    this.setState({
      selectedCredentialRow: { ...selectedCredentialRow, files },
    });
  };

  onViewCredentialFile = async (key) => {
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({ credentials });
    const params = {
      Bucket: process.env.AWS_USER_DOC_BUCKET,
      Key: key,
    };
    const url = s3.getSignedUrl("getObject", params);
    window.open(url, "_blank");
  };

  credentialModifiedListener = (onChangeFunction) => (props) => {
    this.setState({ credentialModified: true });
    return onChangeFunction(props);
  };

  handleToggleDialog = (name) => (e) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleToggleRejectApplicantDialog = (withoutEmail) => {
    this.setState({
      rejectApplicantOpen: !this.state.rejectApplicantOpen,
      rejectApplicantWithoutEmail: !!withoutEmail,
    });
  };

  onCloseCalendarSyncToast = () => {
    this.setState({
      calendarSyncToastOpen: false,
      calendarSyncToastMessage: "",
    });
  };

  checkFeatureFlag(flag) {
    return this.state[flag];
  }

  checkPermissions(permissions, userPermissions) {
    return permissions.every((perm) => userPermissions[perm]);
  }

  onContinueWithoutSaving = () => {
    this.clearData({ openWarning: false }, () => {
      if (this.state.nextLocation) {
        this.navigate(this.state.nextLocation);
      } else {
        this.resetTab(this.state.currentTab);
      }
    });
  };

  getTabs = () => {
    const { userPermissions, assignedToLearner, isClinician } = this.props;
    return [
      { label: "Profile", link: "profile", hide: false },
      { label: "Credentials", link: "credentials", hide: false },
      { label: "Licenses & Certifications", link: "licenses-certifications", hide: false },
      {
        label: "Documents",
        link: "documents",
        hide:
          !this.checkFeatureFlag("onSchedFeatureFlag") &&
          !this.checkPermissions(
            ["edit_clinician_working_hours", "view_clinician_working_hours"],
            userPermissions
          ),
      },
      {
        label: "Working Hours",
        link: "workingHours",
        hide: false,
      },
      {
        label: "Account Settings",
        link: "account-settings",
        hide: !(
          this.checkPermissions(["deactivate_clinician"], userPermissions) ||
          this.checkPermissions(["override_clinician_workflow_status"], userPermissions) ||
          this.checkPermissions(["send_reset_password"], userPermissions)
        ),
      },
    ];
  };

  navigate = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { isLoading, isError, error, isSuccess, data } = this.props;
    const {
      basicInfoLoading,
      applicationLoading,
      userPermissions,
      basicInfo,
      clinicianCredentialData,
      clinicianCredentialDataLoading,
      insuranceSaveFail,
      insuranceSaving,
      classes,
      snackBarOpen,
      snackBarMessage,
    } = this.props;
    const {
      editingWorkingHours,
      selectedTab,
      insuranceFormDialogOpen,
      selectedCredentialRow,
      selectedInsuranceCompanyFilter,
      selectedStateFilter,
      isNewCredential,
      formSubmittedDate,
      formApprovalDate,
      formInitialCredentialDate,
      formInsuranceCompany,
      formInsuranceCompanyId,
      formInsurancePlan,
      formInsurancePlanId,
      formRecredentialDate,
      formTermDate,
      dropzoneFiles,
      documentUploadError,
      confirmQuitCredentialEditDialogOpen,
      credentialModified,
      sortModel,
      paginationModel,
      uploadingToS3,
      calendarSyncToastOpen,
      calendarSyncToastMessage,
      clinicianId,
      unsavedChanges,
      editButton,
      currentTab,
    } = this.state;

    const viewWorkingHoursPerm = userPermissions?.view_clinician_working_hours;

    return (
      userPermissions && (
        <div>
          <Prompt
            when={(unsavedChanges || editButton) && this.editableTabs.includes(currentTab)}
            message={(nextLocation, action) => {
              if (unsavedChanges) {
                if (this.currentPath !== nextLocation.pathname && action === "POP") {
                  window.history.forward();
                }
                this.setState({
                  openWarning: true,
                  isCancelClicked: false,
                  nextLocation: nextLocation.pathname,
                });
                return false;
              }
              if (editButton) {
                this.props.setPageDetails({
                  onEditButton: false,
                  editButtonAction: null,
                });
                this.setState({ editButton: false });
              }
            }}
          />
          <SaveBeforeNavDialog
            saveBeforeNavOpen={this.state.openWarning}
            onToggleSaveBeforeNav={() => {
              this.setState({ openWarning: false });
            }}
            onContinueWithoutSaving={this.onContinueWithoutSaving}
            severetyAlert={"warning"}
            primaryActionColorButton={"error"}
            secondaryText={"Stay on Page"}
            isCancelClicked={this.state.isCancelClicked}
          />
          <ClinicianDetailsTabs
            currentTab={currentTab}
            userPermissions={userPermissions}
            classes={classes}
            tabs={this.getTabs()}
          />
          {basicInfoLoading || applicationLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
              <SubHeading>Loading</SubHeading>
            </div>
          ) : (
            <div className={classes.root}>
              <ClinicianDetailsPageContents
                {...this.props}
                {...this.state}
                visibleTabs={this.getTabs().filter(({ hide }) => !hide)}
                userPermissions={userPermissions}
                setEditMode={(newState) => this.setState({ editMode: newState })}
                onActiveTabChange={this.onActiveTabChange}
                currentTab={this.state.currentTab}
                unsavedChanges={this.state.unsavedChanges}
                handleToggleDialog={this.handleToggleDialog}
                onToggleActivationDialog={this.onToggleActivationDialog}
                isActive={basicInfo?.is_active}
                resetTab={this.resetTab}
                onChange={this.onChange}
                onChangeDate={this.onChangeDate}
                onChangeNumber={this.onChangeNumber}
                onChangeTaxNumber={this.onChangeTaxNumber}
                onChangeWorkingHours={this.onChangeWorkingHours}
                setIsDirty={this.setIsDirty}
                onCloseEditMode={this.onCloseEditMode}
                onSaveBio={this.onSaveBio}
                onSaveBioInfo={this.onSaveBioInfo}
                onSaveEmploymentDetails={this.onSaveEmploymentDetails}
                onSaveSupervisorDetails={this.onSaveSupervisorDetails}
                onSaveAccountSettings={this.onSaveAccountSettings}
                onChangeAccountSettings={this.onChangeAccountSettings}
                onSaveProfilePhoto={this.onSaveProfilePhoto}
                openFileDialog={this.openFileDialog}
                closeFileDialog={this.closeFileDialog}
                addFileObjects={this.addFileObjects}
                persistFileObjects={this.persistFileObjects}
                onDownloadClinicianContract={this.onDownloadClinicianContract}
                onInviteClinician={this.onInviteClinician}
                handleToggleRejectApplicantDialog={this.handleToggleRejectApplicantDialog}
                onToggleResetPassword={this.onToggleResetPassword}
                onSubmitToggleActivation={this.onSubmitToggleActivation}
                onChangeDeactivationInfo={this.onChangeDeactivationInfo}
                onClearDeactivationInfo={this.onClearDeactivationInfo}
                onSubmitResetPassword={this.onSubmitResetPassword}
                onCloseCalendarSyncToast={this.onCloseCalendarSyncToast}
                calendarSyncToastOpen={calendarSyncToastOpen}
                calendarSyncToastMessage={calendarSyncToastMessage}
                clinicianCredentialData={clinicianCredentialData}
                handleToggleInsuranceFormDialog={this.handleToggleInsuranceFormDialog}
                handleSelectedInsuranceCompanyChange={this.handleSelectedInsuranceCompanyChange}
                handleSelectedStateChange={this.handleSelectedStateChange}
                handleAddNewInsurance={this.handleAddNewInsurance}
                getInsuranceCompanyOptions={this.getInsuranceCompanyOptions}
                getStateFilterOptions={this.getStateFilterOptions}
                handleChangeCredentialsPageModel={this.handleChangeCredentialsPageModel}
                handleInsuranceEdit={this.handleInsuranceEdit}
                setSortModel={this.setSortModel}
                setPaginationModel={this.setPaginationModel}
                clinicianCredentialDataLoading={clinicianCredentialDataLoading}
                sortModel={sortModel}
                paginationModel={paginationModel}
                handleCloseInsuranceFormDialog={this.handleCloseInsuranceFormDialog}
                onCompanyChange={this.onCompanyChange}
                onPlanChange={this.onPlanChange}
                setInsuranceFormValues={this.setInsuranceFormValues}
                submitInsurance={this.submitInsurance}
                handleFileUpload={this.handleFileUpload}
                handleFileDelete={this.handleFileDelete}
                handleLoadedFileDelete={this.handleLoadedFileDelete}
                onViewCredentialFile={this.onViewCredentialFile}
                handleConfirmQuitCredentialEdit={this.handleConfirmQuitCredentialEdit}
                handleCancelQuitCredentialEdit={this.handleCancelQuitCredentialEdit}
                handleToggleConfirmQuitCredentialEdit={this.handleToggleConfirmQuitCredentialEdit}
                credentialModifiedListener={this.credentialModifiedListener}
                selectedInsuranceCompanyFilter={selectedInsuranceCompanyFilter}
                selectedStateFilter={selectedStateFilter}
                insuranceFormDialogOpen={insuranceFormDialogOpen}
                formInsuranceCompany={formInsuranceCompany}
                formInsuranceCompanyId={formInsuranceCompanyId}
                formInsurancePlan={formInsurancePlan}
                formInsurancePlanId={formInsurancePlanId}
                isNewCredential={isNewCredential}
                selectedCredentialRow={selectedCredentialRow}
                formSubmittedDate={formSubmittedDate}
                formInitialCredentialDate={formInitialCredentialDate}
                formApprovalDate={formApprovalDate}
                formRecredentialDate={formRecredentialDate}
                formTermDate={formTermDate}
                getInsurancePlanOptions={this.getInsurancePlanOptions}
                dropzoneFiles={dropzoneFiles}
                documentUploadError={documentUploadError}
                insuranceSaveFail={insuranceSaveFail}
                insuranceSaving={insuranceSaving || uploadingToS3}
                confirmQuitCredentialEditDialogOpen={confirmQuitCredentialEditDialogOpen}
                credentialModified={credentialModified}
                editingWorkingHours={editingWorkingHours}
                viewWorkingHoursPerm={viewWorkingHoursPerm}
                clinicianId={clinicianId}
                setUnsavedChanges={this.setUnsavedChanges}
              />
            </div>
          )}
          <Snackbar
            autoHideDuration={10000}
            onClose={() =>
              this.setState({
                snackBarOpen: false,
                snackBarError: false,
                snackBarMessage: "",
              })
            }
            open={snackBarOpen}
          >
            <Typography>{snackBarMessage}</Typography>
          </Snackbar>
        </div>
      )
    );
  }
}

export default withHooks(
  withRouter(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ClinicianDetailContainer))
  ),
  [useUpdateEmploymentType, useUpdateSupervisor]
);
