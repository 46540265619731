import {
  getInsuranceListProviderId,
  getInsuranceListState,
  getInsurancePlansPage,
  getInsurancePlansRowsPerPage,
  getInsurancePlansSort,
  getInsurancePlansSortDirection,
  getSuperBillsOffset,
} from "../selectors";
import SecureRequest from "../utils/securerequest";
import { select } from "redux-saga/effects";

export function* getInsuranceProviders(state) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let query = "";
  if (state) {
    query = `?state=${state}`;
  }
  return yield Request.get(`/insurance${query}`);
}

export function* getInsuranceProvidersNotAccepted(state) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let query = `?state=${state}&notaccepted=true`;

  return yield Request.get(`/insurance${query}`);
}

export function* getInsuranceCodes() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/codes`);
}

export function* getApprovedUnits(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/${clientId}/approved-units`);
}

export function* getApprovedUnitsByAuthId(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/${params.clientId}/approved-units/${params.authId}`);
}

export function* createApprovedUnits(unitObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/insurance/${unitObject.clientId}/create-units`, unitObject);
}

export function* updateApprovedUnits(unitObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/insurance/${unitObject.unitId}/update-units`, unitObject);
}

export function* getAuthorizationCodes(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/${clientId}/authorization`);
}

export function* createAuthorizationCode(authObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  if (authObj.isSecondaryAuth) {
    return yield Request.post(`/insurance/${authObj.clientId}/secondary-authorization`, authObj);
  }
  return yield Request.post(`/insurance/${authObj.clientId}/authorization`, authObj);
}

export function* updateAuthorizationCode(authObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/insurance/${authObj.authId}/update-authorization`, authObj);
}

export function* getAvailableInsuranceCodes(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/all-codes`, { params });
}

export function* getInsuranceCodesByUserCurrentAuth(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/${params.clientId}/codes-by-current-auth`, { params });
}

export function* getUserInsurance(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/insurance/${clientId}/insurances`);
}

export function* updateUserInsurance(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(
    `/insurance/${insuranceObj.insuranceUserId}/update-insurance`,
    insuranceObj
  );
}

export function* createUserInsurance(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/insurance/${insuranceObj.clientId}/create-insurance`, insuranceObj);
}

export async function postUpdateUserInsurance(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.put(`/insurance/${insuranceObj.insuranceUserId}/update-insurance`, insuranceObj);
}

export async function postCreateUserInsurance(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.post(`/insurance/${insuranceObj.clientId}/create-insurance`, insuranceObj);
}

export function* disableUserInsurance(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/insurance/${insuranceObj.id}/disable-insurance`, {});
}

export async function disableUserInsuranceAsync(insuranceObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.put(`/insurance/${insuranceObj.id}/disable-insurance`, {});
}

export function* getBillableTime(timeObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(
    `/v2/reporting/${timeObj.clientId}/billable-time?period=${timeObj.period}`
  );
}

export function* createOffPlatformWork(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/v2/billable-time/${data.clientId}/off-platform`, data);
}

export function* getOffPlatformWork() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/v2/billable-time/off-platform`);
}

export function* approveOffPlatformWork(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/${id}/approve`, {});
}

export function* denyOffPlatformWork(denial) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/${denial.timeId}/deny`, denial);
}

export function* updateBillableTimeInsuranceCode(billingDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/billable-time/${billingDetails.billableTimeId}/insurance-code`,
    billingDetails
  );
}

export function* getSuperBillsByClient(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const offset = yield select(getSuperBillsOffset);
  let offsetQuery = "";
  if (offset) {
    offsetQuery = `?offset=${offset}`;
  }
  return yield Request.get(`/v2/billable-time/${clientId}/super-bills${offsetQuery}`);
}

export function* getReferringPhysicians() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/physicians`, {});
}

export function* createReferringPhysician(physician) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/physicians`, physician);
}

export function* getInsuranceProvidersList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/insurance/`);
}

export function* createInsuranceProvider(provider) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/insurance/`, provider);
}

export function* updateInsuranceProvider(provider) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/insurance/${provider.id}`, provider);
}

export function* getInsurancePlans() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const state = yield select(getInsuranceListState);
  const insuranceid = yield select(getInsuranceListProviderId);
  const page = yield select(getInsurancePlansPage);
  const size = yield select(getInsurancePlansRowsPerPage);
  const sort = yield select(getInsurancePlansSort);
  const direction = yield select(getInsurancePlansSortDirection);

  const params = {
    state,
    insuranceid,
    page,
    size,
    sort,
    direction,
  };
  return yield Request.get(`/v2/insurance/plans`, { params });
}

export async function getInsurancePlansAsync({ state, insuranceid, page, size, sort, direction }) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const params = {
    state,
    insuranceid,
    page,
    size,
    sort,
    direction,
  };
  return Request.get(`/v2/insurance/plans`, { params });
}

export function* createInsurancePlan(plan) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/insurance/plan`, plan);
}

export function* updateInsurancePlan(plan) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/insurance/plan/${plan.id}`, plan);
}

export function* getInsurancePlan(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/insurance/plan/${id}`);
}

export function* getInsuranceProvider(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/insurance/${id}`);
}

export const getHasInsuranceAuthorization = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/insurance/has-authorization/${clientId}`);
};

export const getAllAvailableInsuranceCodes = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(`/insurance/all-codes`, { params });
};
