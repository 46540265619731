import React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import { addMinutes } from "date-fns";
import {
  Alert,
  AlertTitle,
  DialogContent,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  X,
  Clock,
  User,
  IdentificationBadge,
  Repeat,
  DotsThreeVertical,
  PencilSimpleLine,
  Copy,
  ArrowRight,
  CalendarX,
  ArrowSquareOut,
} from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import { formatToTimeZone } from "date-fns-timezone";
import MarkAsNoShowDialog from "./MarkAsNoShowDialog";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";

const SessionDetails = ({
  userPermissions,
  detailsOpen,
  sessionDetails,
  onCloseDetailsDialog,
  copySessionURL,
  onClickEditSession,
  userTimezone,
  userId,
  onMarkClientAsNoShow,
  history,
  isSupervisor,
  isDirector,
}) => {
  const {
    id,
    client_name,
    title,
    scheduled_date,
    call_duration,
    is_recurring,
    video_key,
    end_date,
    repeatType,
    clinician_name,
    secondary_clinician_name,
    didNotOccur,
    off_platform,
    primary_clinician_user_id,
    client_id,
    clinician_id,
    secondary_clinician_id,
    end,
    note_completed,
    no_show,
    canceled,
    secondary_clinician_user_id,
    start_date,
    is_video_call,
  } = sessionDetails;

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMarkAsNoShowDialog, setShowMarkAsNoShowDialog] = React.useState(false);
  const optionsOpen = Boolean(anchorEl);
  const handleOpenOptions = (event) => setAnchorEl(event.currentTarget);
  const isExpired = new Date(end) < new Date();
  const showNotesAlert = end_date && !didNotOccur && !note_completed && is_video_call;
  const isDoxyCall = !(video_key && !off_platform) && is_video_call;
  const isUserPrimaryClinician = userId === primary_clinician_user_id;
  const isUserSecondaryClinician = userId === secondary_clinician_user_id;
  const canMarkAsNoShow =
    !didNotOccur &&
    !start_date &&
    isUserPrimaryClinician &&
    new Date() > addMinutes(new Date(scheduled_date), 10);

  const canSeeJoinEventButton =
    !isExpired &&
    !end_date &&
    (isUserPrimaryClinician || isUserSecondaryClinician) &&
    !canceled &&
    is_video_call;

  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");

  return (
    <Dialog
      open={detailsOpen}
      onClose={onCloseDetailsDialog}
      PaperProps={{
        sx: { width: showNotesAlert || no_show || canceled ? 512 : 334 },
      }}
    >
      <DialogContent sx={{ padding: "20px 24px" }}>
        <Stack gap={theme.spacing(5)}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">{title}</Typography>
            <Stack direction="row" alignItems="center">
              <IconButton
                size="small"
                fontSize="small"
                aria-label="options"
                onClick={handleOpenOptions}
                id="open-menu-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <DotsThreeVertical />
              </IconButton>
              <IconButton
                size="small"
                fontSize="small"
                aria-label="close"
                onClick={onCloseDetailsDialog}
              >
                <X />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row" gap={theme.spacing(3)}>
            <Tooltip title="Event time" placement="top">
              <Clock fontSize={20} />
            </Tooltip>
            <Typography variant="body2">
              {moment(new Date(scheduled_date)).format("h:mm A")}
              {" - "}
              {formatToTimeZone(addMinutes(new Date(scheduled_date), call_duration), "h:mm A (z)", {
                timeZone: userTimezone,
              })}
            </Typography>
          </Stack>
          <Stack direction="row" gap={theme.spacing(3)}>
            <Tooltip title="Client" placement="top">
              <User fontSize={20} />
            </Tooltip>
            <Link
              href={`/clients/${client_id}/dashboard`}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              {client_name}
            </Link>
          </Stack>
          {(userPermissions?.view_clinician_list ||
            isUserPrimaryClinician ||
            isUserSecondaryClinician) && (
            <Stack direction="row" gap={theme.spacing(3)}>
              <Tooltip title="Clinician" placement="top">
                <IdentificationBadge fontSize={20} />
              </Tooltip>
              <Stack direction="row" flexWrap="wrap">
                <Link
                  href={`/clinicians/${clinician_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                >
                  {clinician_name}
                  {secondary_clinician_id && ","}
                </Link>
                {secondary_clinician_id && (
                  <>
                    {/* Space (\u00A0) is added here to avoid rendering the underline under it */}
                    {"\u00A0"}
                    <Link
                      href={`/clinicians/${secondary_clinician_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      {secondary_clinician_name}
                    </Link>
                  </>
                )}
              </Stack>
            </Stack>
          )}
          {is_recurring && (
            <Stack direction="row" gap={theme.spacing(3)}>
              <Tooltip title="Repeating event details" placement="top">
                <Repeat fontSize={20} />
              </Tooltip>
              <Typography variant="body2">{repeatType}</Typography>
            </Stack>
          )}
          {isDoxyCall && (
            <Stack direction="row" gap={theme.spacing(3)}>
              <Tooltip title="Off platform with Doxy" placement="top">
                <ArrowSquareOut fontSize={20} />
              </Tooltip>
              <Typography variant="body2">Off platform with Doxy</Typography>
            </Stack>
          )}
          {canSeeJoinEventButton && (
            <Button
              onClick={() => {
                const offPlatformURL = process.env.DOXY_URL;
                if (!isDoxyCall) history.push(`/video/${video_key}`);
                else window.location.href = offPlatformURL;
              }}
              endIcon={isDoxyCall ? <ArrowSquareOut /> : null}
            >
              Join Event{isDoxyCall && " with Doxy"}
            </Button>
          )}
          {showNotesAlert && (
            <Alert
              severity="info"
              action={
                <Button
                  endIcon={<ArrowRight />}
                  size="small"
                  variant="text"
                  color="info"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={() => history.push("/billing")}
                >
                  Go to Billing
                </Button>
              }
            >
              Therapy notes will be available after submission
            </Alert>
          )}
          {no_show && <Alert severity="error">The event was marked as a no-show</Alert>}
          {canceled && (
            <Alert severity="error">
              <AlertTitle>Event was canceled</AlertTitle>
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <Menu
        anchorEl={anchorEl}
        open={optionsOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ "aria-labelledby": "open-menu-button" }}
      >
        <MenuItem
          onClick={() => {
            if (schedulePageFeatureFlag)
              history.push(
                `/clinician-schedule/create-event?id=${
                  sessionDetails.id
                }&isNonVideoEvent=${!sessionDetails.is_video_call}`
              );
            else onClickEditSession(is_recurring);
            setAnchorEl(null);
          }}
          disabled={isExpired || (isUserSecondaryClinician && !isSupervisor && !isDirector)}
        >
          <ListItemIcon>
            <PencilSimpleLine />
          </ListItemIcon>
          Edit Event
        </MenuItem>
        <MenuItem
          onClick={() => {
            copySessionURL(video_key, off_platform);
            setAnchorEl(null);
          }}
          disabled={isExpired || end_date || !is_video_call}
        >
          <ListItemIcon>
            <Copy />
          </ListItemIcon>
          Copy Event URL
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowMarkAsNoShowDialog(true);
            setAnchorEl(null);
          }}
          disabled={!canMarkAsNoShow}
        >
          <ListItemIcon>
            <CalendarX />
          </ListItemIcon>
          Mark as no-show
        </MenuItem>
      </Menu>
      <MarkAsNoShowDialog
        open={showMarkAsNoShowDialog}
        handleClose={() => setShowMarkAsNoShowDialog(false)}
        onMarkClientAsNoShow={() => {
          onMarkClientAsNoShow(id);
          setShowMarkAsNoShowDialog(false);
        }}
      />
    </Dialog>
  );
};
export default SessionDetails;
