import { searchClients } from "api/customers";
import { useQuery } from "react-query";

/**
 * Custom hook for searching clients.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} inputValue - The input value for searching clients.
 * @param {boolean} activeOnly - Whether to search only active clients.
 * @param {boolean} isPrimaryOnly - Whether to search only clients where user is the primary clinician.
 * @returns {Object} - The result of the search query.
 */
export function useSearchClients(userId, inputValue, activeOnly = false, isPrimaryOnly = false) {
  return useQuery(
    ["searchClients", inputValue],
    () =>
      searchClients(userId, {
        nameInput: inputValue,
        activeOnly,
        isPrimaryOnly,
      }),
    {
      enabled: inputValue?.length >= 2 && !!userId,
    },
  );
}
