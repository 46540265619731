import React from "react";
import { withRouter } from "react-router";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "../Signin/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Logo from "../../assets/answers_now_logo_dark_purple.png";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { PasswordValidationMarkers } from "./PasswordValidationMarkers";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { Alert } from "@mui/material";

const ResetPassword = (props) => {
  const {
    classes,
    username,
    currentPassword,
    password,
    confirmPassword,
    verificationCode,
    resetPasswordSuccess,
    resetPasswordError,
    resetPasswordErrorMessage,
    onChange,
    onSubmit,
    upperCase,
    lowerCase,
    includesNumber,
    properLength,
    change,
    invitationSignin,
    onSubmitInvitationLogin,
    invitationError,
    loginInvitation,
    agreeToTermsAndPrivacy,
    toggleAgreeToTermsAndPrivacy,
    showPassword,
    showConfirmPassword,
    handleMouseDownPassword,
    toggleShowPasswords,
    toggleShowConfirmPasswords,
    setPasswordError,
    // passwordError,
    setPasswordsMatch,
    // passwordsMatchError,
  } = props;

  const buttonDisabled =
    invitationSignin && loginInvitation
      ? !loginInvitation.email ||
        !password ||
        !confirmPassword ||
        !verificationCode ||
        !upperCase ||
        !lowerCase ||
        !properLength ||
        !includesNumber ||
        password !== confirmPassword ||
        invitationError ||
        !agreeToTermsAndPrivacy
      : change
        ? !username ||
          !password ||
          !confirmPassword ||
          !verificationCode ||
          !upperCase ||
          !lowerCase ||
          !properLength ||
          !includesNumber ||
          password !== confirmPassword ||
          !currentPassword
        : !username ||
          !password ||
          !confirmPassword ||
          !verificationCode ||
          !upperCase ||
          !lowerCase ||
          !properLength ||
          !includesNumber ||
          password !== confirmPassword;

  const year = new Date().getFullYear();
  // const passwordErrorMessage = !includesNumber
  //     ? "Please enter a valid password that must contain at least 1 number"
  //     : !upperCase
  //     ? "Please enter a valid password that must contain at least 1 uppercase letter"
  //     : !lowerCase
  //     ? "Please enter a valid password that must contain at least 1 lowercase letter"
  //     : "Please enter a valid password that's at least 8 characters";

  return (
    <>
      <div className={classes.resetPasswordFormContainer}>
        <img src={Logo} className={classes.signinLogo} />
        <div className={classes.signinForm}>
          <form
            className={classes.form}
            onSubmit={invitationSignin ? onSubmitInvitationLogin : onSubmit}
          >
            <Typography component="h1" variant="h4" className={classes.title}>
              {change
                ? invitationSignin
                  ? "Create Password"
                  : "Change Password"
                : "Reset Password"}
            </Typography>
            {change && !invitationSignin && (
              <FormControl margin="normal" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#2A2D39",
                  }}
                >
                  Current Password
                </FormLabel>
                <TextField
                  name="currentPassword"
                  type="password"
                  id="currentPassword"
                  autoComplete="off"
                  value={currentPassword}
                  onChange={onChange("currentPassword")}
                  placeholder="Current password"
                />
              </FormControl>
            )}
            <FormControl margin="normal" fullWidth>
              <FormLabel
                component="legend"
                style={{
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.3px",
                  marginBottom: 8,
                }}
              >
                New password
              </FormLabel>
              <TextField
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="New password"
                autoComplete="off"
                value={password}
                onChange={onChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPasswords}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                      >
                        {showPassword ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setPasswordError(false)}
                onBlur={() =>
                  !properLength || !includesNumber || !upperCase || !lowerCase
                    ? setPasswordError(true)
                    : setPasswordError(false)
                }
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <FormLabel
                component="legend"
                style={{
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.3px",
                  marginBottom: 8,
                }}
              >
                Re-enter your new password
              </FormLabel>
              <TextField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm password"
                autoComplete="off"
                value={confirmPassword}
                onChange={onChange("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={toggleShowConfirmPasswords}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                      >
                        {showConfirmPassword ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setPasswordsMatch(false)}
                onBlur={() =>
                  password && password !== confirmPassword
                    ? setPasswordsMatch(true)
                    : setPasswordsMatch(false)
                }
              />
            </FormControl>
            {invitationSignin && (
              <FormControl>
                <FormControlLabel
                  className={classes.checkboxContainer}
                  control={
                    <Checkbox
                      checked={agreeToTermsAndPrivacy}
                      onClick={toggleAgreeToTermsAndPrivacy}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  }
                  label={
                    <Typography component="p" className={classes.agreeToTermsPrivacyText}>
                      By clicking "Create Password", you agree to the{" "}
                      <a target="_blank" href="https://www.getanswersnow.com/terms">
                        Terms of Use
                      </a>{" "}
                      and
                      <a href="https://www.getanswersnow.com/privacy" target="_blank">
                        {" "}
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
              </FormControl>
            )}
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                disabled={buttonDisabled}
                onClick={invitationSignin ? onSubmitInvitationLogin : onSubmit}
                style={{ height: 42, width: "100%", marginTop: 16 }}
                color="primary"
              >
                {change
                  ? invitationSignin
                    ? "Create Password"
                    : "Change Password"
                  : "Reset Password"}
              </Button>
            </div>
            <PasswordValidationMarkers
              {...props}
              matches={password && password === confirmPassword}
            />
            {resetPasswordError && (
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                {resetPasswordErrorMessage &&
                resetPasswordErrorMessage !== "Incorrect username or password." ? (
                  <Alert severity="error">
                    {resetPasswordErrorMessage.includes("Invalid verification code provided")
                      ? "Your reset password link has expired. Please request a new one."
                      : resetPasswordErrorMessage}
                  </Alert>
                ) : (
                  <Alert severity="error">
                    There was an error changing your password. Please check your spelling of your
                    current password and try again.
                  </Alert>
                )}
              </div>
            )}
            {invitationError && (
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Alert severity="error">{invitationError}</Alert>
              </div>
            )}
          </form>
        </div>
      </div>
      <div
        className={classes.footerContainer}
        style={{ width: "100%", justifyContent: "center", padding: 10 }}
      >
        <Typography component="p" className={classes.footerText}>
          Version: {APP_VERSION} Copyright &copy; {year} AnswersNow &#x2022; All rights reserved
        </Typography>
      </div>
    </>
  );
};

export default withRouter(withStyles(styles)(ResetPassword));
