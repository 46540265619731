import { connect } from "react-redux";
import React, { Component } from "react";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import { addDays } from "date-fns";
import moment from "moment-timezone";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Schedule from "./Schedule";
import SaveChangesDialog from "./SaveChangesDialog";
import { formatToTimeZone } from "date-fns-timezone";

import {
  getCliniciansList,
  getCliniciansLoading,
  getUser,
  getUserId,
  loadingAvailability,
  videoAvailability,
  getClinicianHealthieProviderId,
  getCustomerDetails,
  getCustomerUserId,
  getCustomerClientId,
  oneTimeVideoInfo,
  scheduleCallLoading,
  scheduleCallSuccess,
  userClinician,
} from "../../selectors";
import SessionConfirmation from "./SessionConfirmation";
import Modal from "elements/Modal";

const mapStateToProps = (state) => ({
  scheduleCallLoading: scheduleCallLoading(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  clinicians: getCliniciansList(state),
  cliniciansLoading: getCliniciansLoading(state),
  clinician: userClinician(state),
  user: getUser(state),
  userId: getUserId(state),
  availability: videoAvailability(state),
  loadingAvailability: loadingAvailability(state),
  clinicianHealthieProviderId: getClinicianHealthieProviderId(state),
  oneTimeVideoInfo: oneTimeVideoInfo(state),
  customerDetails: getCustomerDetails(state),
  customerUserId: getCustomerUserId(state),
  customerClientId: getCustomerClientId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCliniciansStatus: actions.setCliniciansStatus,
      setCliniciansPerPage: actions.setCliniciansPerPage,
      loadCliniciansList: actions.loadCliniciansList,
      getBCBAAvailability: actions.getBCBAAvailability,
      clearBCBAAvailability: actions.clearBCBAAvailability,
      getCustomerInfo: actions.getCustomerInfo,
      clearCustomerInfo: actions.clearCustomerInfo,
      scheduleVideoCall: actions.scheduleVideoCall,
      rescheduleVideoCall: actions.rescheduleVideoCall,
    },
    dispatch,
  );

function getInitialState(props) {
  return {
    date: new Date(),
    selectedClinicianId: "",
    selectedClinician: null,
    timezone: "",
    callType: "",
    callDuration: 60,
    showCallDuration: true,
    assessmentCall: false,
    allowedAssessment: false,
    clinicianTime: null,
    timeSelected: false,
    overrideSchedule: false,
    overrideTime: "",
    showConfirmation: false,
    offPlatform: false,
    offPlatformType: "video",
    isRepeating: false,
    repeatType: "",
    repeatEndType: "",
    repeatDays: [],
    repeatNumberOfWeeks: 0,
    repeatUntilDate: new Date(),
    days: [
      { name: "Sun", order: 7 },
      { name: "Mon", order: 1 },
      { name: "Tue", order: 2 },
      { name: "Wed", order: 3 },
      { name: "Thu", order: 4 },
      { name: "Fri", order: 5 },
      { name: "Sat", order: 6 },
    ],
    callInstructionsAvailable: false,
    callInstructions: "",
    saveChangesDialogOpen: false,
    isDirty: false,
    isTherapeuticConsult: false,
    rescheduleAllowed: true,
  };
}

class SessionScheduler extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
  }

  componentDidMount() {
    if (!this.props.customerDetails?.clinician?.first_name && !this.props.demoCall) {
      if (
        this.props.rescheduleCall &&
        this.props.rescheduleDetails &&
        (!this.props.customerDetails?.details?.client_id ||
          this.props.rescheduleDetails?.client_id !==
            this.props.customerDetails?.details?.client_id)
      ) {
        this.props.clearCustomerInfo();
        this.props.getCustomerInfo(this.props.rescheduleDetails?.client_id);
      }
    }
    if (this.props.clinicianQuickAction) {
      this.props.clearCustomerInfo();
      this.props.getCustomerInfo(this.props.selectedClientId);
    }
    if (this.props.selectScheduledClinician) {
      this.props.setCliniciansStatus({ status: "CREDENTIALED" });
    } else {
      this.setClinicianInfo();
    }
    this.props.setCliniciansPerPage(100);
    this.props.loadCliniciansList();
    if (this.props.oneTimeVideoInfo && this.props.oneTimeVideoInfo.billingType === "ASSESSMENT") {
      this.setState({ callDuration: 120 });
    }
    if (this.props.customerDetails?.details?.therapeutic_consultation) {
      this.setState({ isTherapeuticConsult: true });
    }
    if (this.props.offPlatform) {
      this.setState({ offPlatform: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selectScheduledClinician && this.props.selectScheduledClinician) {
      this.props.setCliniciansPerPage(100);
      this.props.setCliniciansStatus({ status: "CREDENTIALED" });
      this.props.loadCliniciansList();
    }

    if (prevProps.cliniciansLoading && !this.props.cliniciansLoading && this.props.clinicians) {
      this.setClinicianInfo();
    }

    if (
      !prevProps.customerDetails.clinician?.first_name &&
      this.props.customerDetails.clinician?.first_name &&
      !this.props.scheduleCallSuccess
    ) {
      this.setClinicianInfo();
    }

    if (
      prevState.date !== this.state.date ||
      prevState.timezone !== this.state.timezone ||
      prevState.callType !== this.state.callType ||
      prevState.callDuration !== this.state.callDuration ||
      prevState.selectedClinicianId !== this.state.selectedClinicianId
    ) {
      if (
        this.state.date &&
        this.state.timezone &&
        this.state.callType &&
        this.state.callDuration &&
        this.state.selectedClinicianId &&
        !this.state.showConfirmation
      ) {
        this.setState({
          clinicianTime: null,
          timeSelected: false,
          overrideSchedule: false,
        });
        this.getClinicianAvailability();
      }
    }

    if (!prevProps.scheduleCallSuccess && this.props.scheduleCallSuccess) {
      this.setState({ showConfirmation: true });
    }

    if (!prevProps.assessmentCall && this.props.assessmentCall) {
      this.setState({
        assessmentCall: true,
        date: addDays(new Date(), 14),
        callDuration: 120,
        showCallDuration: false,
      });
    }

    if (!prevProps.allowedAssessment && this.props.allowedAssessment) {
      this.setState({
        assessmentCall: true,
        allowedAssessment: true,
        showCallDuration: false,
      });
    }

    if (
      (!prevProps.oneTimeVideoInfo ||
        prevProps.oneTimeVideoInfo.id !== this.props.oneTimeVideoInfo.id) &&
      this.props.oneTimeVideoInfo &&
      this.props.oneTimeVideoInfo.billingType === "ASSESSMENT"
    ) {
      this.setState({ callDuration: 120 });
    }

    if (
      prevProps.customerDetails?.details?.timezone !==
        this.props.customerDetails?.details?.timezone &&
      this.props.customerDetails?.details?.timezone
    ) {
      let clientTimezone = this.props.customerDetails?.details?.timezone;
      this.setState({
        timezone: clientTimezone,
        date: formatToTimeZone(new Date(), "YYYY-MM-DD HH:mm:ss.SSS", {
          timeZone: clientTimezone,
        }),
      });
    }
  }

  componentWillUnmount() {
    this.props.clearBCBAAvailability();
  }

  closeDialog = () => {
    if (this.props.rescheduleCall && this.state.isDirty) {
      this.setState({ saveChangesDialogOpen: true });
    } else {
      this.setState({ ...getInitialState(this.props) });
      this.props.closeDialog();
    }
  };

  onChangeClinician = (e) => {
    let selectedClinicianId = e.target.value;
    let selectedClinician = this.props.clinicians.find(
      (clin) => clin.clinician_user_id == selectedClinicianId,
    );
    this.setState({ selectedClinicianId, selectedClinician });
  };

  selectClinicianTime = (clinician, time) => {
    let startDate = time.start.split(" ");
    let startDateDay = startDate[0];
    let startDateTime = startDate[1].split(":");
    let startDateHours = startDateTime[0];
    let startDateMinutes = startDateTime[1];
    let startDateOffset = startDate[2];

    let endDate = new Date(`${startDateDay}T${startDate[1]}${startDateOffset}`);
    endDate.setHours(startDateHours);
    endDate.setMinutes(startDateMinutes);
    endDate.setTime(endDate.getTime() + this.state.callDuration * 60 * 1000);
    let endDateHours = ("0" + endDate.getHours()).slice(-2);
    let endDateMinutes = ("0" + endDate.getMinutes()).slice(-2);
    let endDateTime = `${endDateHours}:${endDateMinutes}:00`;

    let clinicianTime = {
      clinicianUserId: clinician.user_id,
      bcbaEmail: clinician.email,
      startDate: `${startDateDay}T${startDate[1]}${startDateOffset}`,
      endDate: `${startDateDay}T${endDateTime}${startDateOffset}`,
      bcbaFirstName: clinician.first_name,
      bcbaLastName: clinician.last_name,
      healthieProviderId: clinician.healthie_provider_id,
    };
    this.setState({
      clinicianTime,
      timeSelected: true,
      overrideSchedule: false,
      overrideTime: "",
    });
    if (this.props.rescheduleCall) {
      this.setState({ isDirty: true });
    }
  };

  getClinicianAvailability = () => {
    const { selectedClinician, selectedClinicianId, timezone } = this.state;
    const selectedDate = new Date(this.state.date);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // add 1 since January is month 0
    const day = selectedDate.getDate();
    const date = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
    this.props.getBCBAAvailability(
      {
        email: selectedClinician.email,
        date,
        timezone: this.state.timezone,
        duration: this.state.showCallDuration ? this.state.callDuration : "",
        healthieProviderId: selectedClinician.healthie_provider_id,
        type:
          (this.props.oneTimeVideoInfo &&
            (this.props.oneTimeVideoInfo.workflow_status === "ASSESSMENT_SCHEDULED" ||
              this.props.oneTimeVideoInfo.workflow_status === "ASSESSMENT_SCHEDULED_NOW")) ||
          this.state.assessmentCall
            ? "ASSESSMENT"
            : "STANDARD",
      },
      true,
    );
  };

  setClinicianInfo = () => {
    const {
      demoCall,
      isCaregiver,
      clinicianQuickAction,
      selectScheduledClinician,
      user,
      clinicianHealthieProviderId,
      customerDetails,
      clinicians,
      rescheduleCall,
      rescheduleDetails,
    } = this.props;
    const { selectedClinician, selectedClinicianId } = this.state;

    let bcba;
    let clientClinicianId;
    let clientClinician;
    let clientTimezone;

    if (selectScheduledClinician) {
      if (rescheduleCall) {
        clientClinicianId = rescheduleDetails?.clinician_user_id;
        clientClinician = clinicians.find((clin) => clin.clinician_user_id == clientClinicianId);
        this.setRescheduleSessionDetails();
      } else {
        clientClinicianId = customerDetails?.clinician?.clinician_user_id;
        clientClinician = clinicians.find((clin) => clin.clinician_user_id == clientClinicianId);
        clientTimezone = customerDetails?.details?.timezone;
        if (clientTimezone) {
          this.setState({
            timezone: clientTimezone,
            date: formatToTimeZone(new Date(), "YYYY-MM-DD HH:mm:ss.SSS", {
              timeZone: clientTimezone,
            }),
          });
        }
      }

      bcba = {
        user_id: clientClinician?.clinician_user_id,
        first_name: clientClinician?.first_name,
        last_name: clientClinician?.last_name,
        email: clientClinician?.email,
        clinician_id: clientClinician?.clinician_id,
        healthie_provider_id: clientClinician?.healthie_provider_id,
      };
    } else {
      if (rescheduleCall) {
        clientClinicianId = rescheduleDetails?.clinician_user_id;
        clientClinician = clinicians.find((clin) => clin.clinician_user_id == clientClinicianId);
        this.setRescheduleSessionDetails();
      } else {
        if (demoCall) {
          clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else {
          clientTimezone = customerDetails?.details?.timezone;
        }
        if (clientTimezone) {
          this.setState({
            timezone: clientTimezone,
            date: formatToTimeZone(new Date(), "YYYY-MM-DD HH:mm:ss.SSS", {
              timeZone: clientTimezone,
            }),
          });
        }
      }

      bcba =
        demoCall || clinicianQuickAction || !customerDetails?.clinician?.first_name
          ? {
              user_id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              image_url: user.image_url,
              email: user.email,
              clinician_id: user?.clinician_id,
              healthie_provider_id: clinicianHealthieProviderId,
            }
          : {
              user_id: customerDetails?.clinician
                ? customerDetails.clinician.clinician_user_id
                : "",
              first_name: customerDetails?.clinician ? customerDetails.clinician.first_name : "",
              last_name: customerDetails?.clinician ? customerDetails.clinician.last_name : "",
              image_url: customerDetails?.clinician ? customerDetails.clinician.image_url : "",
              email: customerDetails?.clinician ? customerDetails.clinician.email : "",
              clinician_id: customerDetails?.clinician?.clinician_id,
              healthie_provider_id: customerDetails?.clinician
                ? customerDetails.clinician.healthie_provider_id
                : "",
            };
    }
    this.setState({
      selectedClinicianId: bcba.user_id,
      selectedClinician: bcba,
    });
  };

  setRescheduleSessionDetails = () => {
    const { rescheduleDetails, rescheduleAllInstances } = this.props;
    const {
      billing_type,
      call_timezone,
      call_duration,
      is_recurring,
      repeat_days,
      repeat_type,
      repeat_until_date,
      repeat_weeks,
      scheduled_date,
      call_instructions,
      master_call_instructions,
      clinician_user_id,
      clinician_name,
      off_platform,
      off_platform_type,
    } = rescheduleDetails;
    let clinicianTime = {
      bcbaFirstName: clinician_name,
      bcbaLastName: "",
    };
    let callInstructionsAvailable =
      (!rescheduleAllInstances && call_instructions) ||
      (rescheduleAllInstances && master_call_instructions)
        ? true
        : false;
    let callInstructions = rescheduleAllInstances ? master_call_instructions : call_instructions;
    let repeatDays = repeat_days?.split(",").map((num) => +num);
    let repeatType;
    switch (repeat_days) {
      case "1,2,3,4,5,6,7":
        repeatType = "daily";
        break;
      case "1,2,3,4,5":
        repeatType = "weekday";
        break;
      case "6,7":
        repeatType = "weekend";
        break;
      default:
        repeatType = "custom";
        break;
    }
    this.setState({
      date: scheduled_date,
      callType: billing_type,
      timezone: call_timezone,
      callDuration: call_duration,
      isRepeating: is_recurring,
      repeatType,
      repeatDays,
      repeatEndType: repeat_type,
      repeatNumberOfWeeks: repeat_weeks,
      repeatUntilDate: repeat_until_date,
      callInstructions,
      callInstructionsAvailable,
      clinicianTime,
      offPlatform: !!off_platform,
      offPlatformType: off_platform_type,
    });
  };

  onChangeDate = (name, date) => {
    this.setState({ [name]: date }, () => {
      if (name == "date" && this.state.overrideSchedule) {
        this.setState({ overrideSchedule: false, overrideTime: "" });
      }
    });
  };

  onChangeNumberOfWeeks = (e) => {
    const { value } = e.target;
    this.setState({ repeatNumberOfWeeks: value > 26 ? 26 : value });
  };

  onChange = (name) => (e) => {
    const { value, checked, type } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val }, () => {
      if (name == "isRepeating" && val == false) {
        this.setState({
          repeatType: "",
          repeatEndType: "",
          repeatDays: [],
          repeatNumberOfWeeks: 1,
        });
      }
      if ((name == "callInstructions" || name == "callType") && this.props.rescheduleCall) {
        this.setState({ isDirty: true });
      }
    });
  };

  onChangeRadio = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value === "false" ? false : true });
  };

  onChangeOverrideSchedule = (e) => {
    const { timezone, date } = this.state;
    let overrideTime = moment
      .tz(new Date(date), timezone)
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    if (new Date(overrideTime) < new Date()) {
      overrideTime = moment.tz(new Date(), timezone);
    }
    this.setState({
      overrideSchedule: e.target.checked,
      overrideTime,
    });
  };

  selectClinicianOverrideTime = (clinician, time) => {
    let clinicianTime = {
      clinicianUserId: clinician.user_id,
      bcbaEmail: clinician.email,
      bcbaFirstName: clinician.first_name,
      bcbaLastName: clinician.last_name,
      healthieProviderId: clinician.healthie_provider_id,
      startDate: moment(time).tz(this.state.timezone, true).format(),
      endDate: moment
        .tz(time, this.state.timezone)
        .add(this.state.callDuration, "minutes")
        .format(),
    };
    this.setState({ clinicianTime, overrideTime: time, timeSelected: true });
    if (this.props.rescheduleCall) {
      this.setState({ isDirty: true });
    }
  };

  onToggleCallInstuctionsAvailable = () => {
    this.setState({
      callInstructionsAvailable: !this.state.callInstructionsAvailable,
    });
  };

  onChangeRepeatType = (e) => {
    const type = e.target.value;
    this.setState({ repeatType: type }, () => {
      let repeatDays = [];
      switch (this.state.repeatType) {
        case "daily":
          repeatDays = [1, 2, 3, 4, 5, 6, 7];
          break;
        case "weekday":
          repeatDays = [1, 2, 3, 4, 5];
          break;
        case "weekend":
          repeatDays = [6, 7];
          break;
        case "custom":
          repeatDays = [];
          break;
      }
      this.setState({ repeatDays });
    });
  };

  onToggleDaySelect = (day) => {
    let days = this.state.repeatDays;
    let index;
    let repeatType;
    if (days.includes(day)) {
      index = days.indexOf(day);
      days.splice(index, 1);
    } else {
      days.push(day);
    }
    switch (days.sort().join(",")) {
      case "1,2,3,4,5,6,7":
        repeatType = "daily";
        break;
      case "1,2,3,4,5":
        repeatType = "weekday";
        break;
      case "6,7":
        repeatType = "weekend";
        break;
      default:
        repeatType = "custom";
        break;
    }
    this.setState({ repeatDays: days.sort(), repeatType });
  };

  onScheduleVideoCall = () => {
    const {
      clinicianTime,
      timezone,
      callDuration,
      callType,
      isRepeating,
      repeatDays,
      repeatEndType,
      repeatNumberOfWeeks,
      repeatUntilDate,
      callInstructions,
      offPlatform,
      offPlatformType,
      overrideSchedule,
    } = this.state;
    const {
      userId,
      rescheduleCall,
      rescheduleDetails,
      demoCall,
      isCaregiver,
      clinicianQuickAction,
      selectedClientId,
    } = this.props;
    const { startDate, endDate, bcbaEmail, clinicianUserId, healthieProviderId } = clinicianTime;

    if (clinicianTime && !rescheduleCall) {
      let videoDetails = {
        startDate,
        endDate,
        bcbaEmail,
        timezone,
        clinicianUserId,
        healthieProviderId,
        clientId: demoCall
          ? "000"
          : clinicianQuickAction
            ? selectedClientId
            : this.props.oneTimeVideoInfo.client_id && this.props.isClinician
              ? this.props.oneTimeVideoInfo.client_id
              : this.props.customerClientId,
        isTest: !!demoCall,
        billingType: callType,
        isRepeating,
        repeatDays,
        repeatEndType,
        repeatNumberOfWeeks,
        repeatUntilDate,
        callInstructions,
        offPlatform,
        offPlatformType: offPlatform ? offPlatformType : null,
        overrideSchedule,
      };
      this.props.scheduleVideoCall(videoDetails);
    }
  };

  onRescheduleVideoCall = () => {
    const {
      callType,
      timezone,
      callInstructions,
      clinicianTime,
      offPlatform,
      offPlatformType,
      repeatDays,
      repeatEndType,
      repeatNumberOfWeeks,
      repeatUntilDate,
      overrideSchedule,
    } = this.state;
    const { rescheduleDetails, rescheduleAllInstances } = this.props;
    const { scheduled_date, call_duration, clinician_user_id } = rescheduleDetails;
    const { bcbaEmail, healthieProviderId } = clinicianTime;

    let videoId = rescheduleDetails?.id;
    let healthieVideoCallId = rescheduleDetails?.healthie_video_call_id;
    let billingType = callType;
    let startDate = clinicianTime?.startDate
      ? clinicianTime.startDate
      : moment(scheduled_date).format();
    let endDate = clinicianTime?.endDate
      ? clinicianTime.endDate
      : moment(scheduled_date).add(call_duration, "minutes").format();
    let clinicianUserId = clinicianTime.clinicianUserId
      ? clinicianTime.clinicianUserId
      : clinician_user_id;

    const details = {
      startDate,
      endDate,
      billingType,
      videoId,
      healthieVideoCallId,
      clinicianUserId,
      rescheduleAllInstances,
      offPlatform,
      offPlatformType,
      timezone,
      callInstructions,
      repeatDays,
      repeatEndType,
      repeatNumberOfWeeks,
      repeatUntilDate,
      bcbaEmail,
      healthieProviderId,
      overrideSchedule,
    };
    this.props.rescheduleVideoCall(details);
  };

  onToggleConfirmationDialog = () => {
    this.setState({ showConfirmation: !this.state.showConfirmation });
  };

  saveChanges = () => {
    this.setState({ saveChangesDialogOpen: false });
    this.onRescheduleVideoCall();
  };

  onCloseSaveChangesDialog = () => {
    this.setState({ ...getInitialState(this.props) });
    this.props.closeDialog();
  };

  render() {
    const { showConfirmation, rescheduleAllowed } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        {showConfirmation ? (
          <SessionConfirmation
            open={showConfirmation}
            closeDialog={this.closeDialog}
            {...this.props}
            {...this.state}
          />
        ) : !rescheduleAllowed ? (
          <Modal
            open={!rescheduleAllowed}
            title="Unable to Reschedule Session"
            description="A session can only be rescheduled once. Please cancel or schedule a new session."
            primaryActionText={"Close"}
            primaryActionOnClick={this.closeDialog}
          />
        ) : (
          <Dialog
            open={!showConfirmation}
            onClose={this.closeDialog}
            PaperProps={{
              style: {
                minWidth: "60%",
                minHeight: "30%",
              },
            }}
          >
            <IconButton className={classes.closeIcon} onClick={this.closeDialog} size="large">
              <CloseIcon style={{ fontSize: 30, color: "#000" }} />
            </IconButton>
            <Schedule
              {...this.props}
              {...this.state}
              selectedClinician={this.state.selectedClinician}
              onChangeDate={this.onChangeDate}
              onChangeClinician={this.onChangeClinician}
              selectClinicianTime={this.selectClinicianTime}
              onScheduleVideoCall={this.onScheduleVideoCall}
              onRescheduleVideoCall={this.onRescheduleVideoCall}
              onChangeOverrideSchedule={this.onChangeOverrideSchedule}
              selectClinicianOverrideTime={this.selectClinicianOverrideTime}
              onChangeRepeatType={this.onChangeRepeatType}
              onToggleDaySelect={this.onToggleDaySelect}
              onChange={this.onChange}
              onChangeRadio={this.onChangeRadio}
              onToggleCallInstuctionsAvailable={this.onToggleCallInstuctionsAvailable}
              onChangeNumberOfWeeks={this.onChangeNumberOfWeeks}
            />
          </Dialog>
        )}
        <SaveChangesDialog
          open={this.state.saveChangesDialogOpen}
          saveChanges={this.saveChanges}
          closeDialog={this.onCloseSaveChangesDialog}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SessionScheduler));
