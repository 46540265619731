import React from "react";
import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

const ClinicianInfo = (props) => {
  const { classes, clinician, navigate, chatUrl } = props;
  let bio = clinician && clinician.bio ? clinician.bio.replace(/(?:\r\n|\r|\n)/g, "<br>") : "";
  bio = bio.replace(/\(bullet\)/g, "<br>&bull;&nbsp;&nbsp;");
  const goToChat = () => {
    props.history.push("/chat");
  };
  return (
    <div className={classes.clinicianContainer}>
      <div className={classes.clinicianInfoBlock}>
        <div className={classes.imageContainer}>
          <Avatar
            alt={`${clinician.first_name} ${clinician.last_name}`}
            src={clinician.image_url}
            className={classes.clinicianAvatar}
          />
          <Typography
            component="p"
            className={classes.clinicianName}
          >{`${clinician.first_name} ${clinician.last_name}`}</Typography>
          <Typography component="p" className={classes.clinicianRole}>
            Your Clinician
          </Typography>
        </div>
        <div className={classes.clinicianBio}>
          <Typography dangerouslySetInnerHTML={{ __html: bio }} />
        </div>
      </div>
      <div className={classes.chatContainer}>
        <Typography>Need to chat?</Typography>
        <Button onClick={goToChat}>Send Message</Button>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  clinicianContainer: {
    // width: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
    borderRadius: 3,
    padding: 50,
    marginBottom: 30,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: 20,
    },
  },
  clinicianInfoBlock: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 30,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  imageContainer: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  clinicianAvatar: {
    width: 99,
    height: 99,
  },
  clinicianName: {
    fontSize: 16,
    fontWeight: 500,
    color: "15171d",
    lineHeight: "24px",
    margin: "15px 0 10px",
  },
  clinicianRole: {
    fontSize: 16,
    color: "#3f4456",
    lineHeight: "20px",
    fontWeight: 500,
  },
  clinicianBio: {
    width: "75%",
    "& p": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "22px",
      color: "#3f4456",
    },
    [theme.breakpoints.down("xl")]: {
      width: "92%",
    },
  },
  chatContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 40,
    "& p": {
      width: "25%",
      marginRight: 30,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      textAlign: "center",
      color: "#2a2d39",
    },
    "& button": {
      height: 40,
      borderRadius: 5,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 20,
      "& p": {
        width: "40%",
      },
    },
  },
});

export default withRouter(withStyles(styles)(ClinicianInfo));
