import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import generator from "generate-password";

import styles from "../../theme/globalStyles";
import actions from "../../actions";
import Users from "./Users";
import {
  getUsersList,
  getSignedinState,
  getUsersPerPage,
  getUsersPage,
  getUsersCount,
  getUsersSort,
  getUsersSortDirection,
  getUsersSaving,
  getUserPermissionsList,
  getCreateUserLoading,
  getCreateUserSuccess,
  getCreateUserError,
  getUsersLoading,
  getCreateUserErrorMessage,
} from "../../selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CreateAdminUserDialog from "./CreateAdminUserDialog";
import Heading from "elements/Heading";

const pageName = "Users";

const mapStateToProps = (state) => ({
  users: getUsersList(state),
  page: getUsersPage(state),
  rowsPerPage: getUsersPerPage(state),
  count: getUsersCount(state),
  signedInState: getSignedinState(state),
  sort: getUsersSort(state),
  direction: getUsersSortDirection(state),
  saving: getUsersSaving(state),
  userPermissions: getUserPermissionsList(state),
  createUserLoading: getCreateUserLoading(state),
  createUserSuccess: getCreateUserSuccess(state),
  createUserError: getCreateUserError(state),
  usersLoading: getUsersLoading(state),
  createUserErrorMessage: getCreateUserErrorMessage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserList: actions.getUserList,
      setUserList: actions.setUserList,
      checkAuthState: actions.checkAuthState,
      setUsersPage: actions.setUsersPage,
      setUsersPerPage: actions.setUsersPerPage,
      setPageDetails: actions.setPageDetails,
      setUsersSort: actions.setUsersSort,
      createUser: actions.createUser,
      resetCreateUser: actions.resetCreateUser,
    },
    dispatch,
  );

class UsersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      isSuperAdmin: false,
      adminType: "Admins",
      userRole: "",
      inviteToHealthie: false,
    };
  }

  handleChangePage = async (event, page) => {
    await this.props.setUsersPage(page);
    this.props.getUserList();
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setUsersPage(0);
    this.props.setUsersPerPage(parseInt(event.target.value));
  };

  viewUserDetails = (id) => {
    let user = "/users/" + id;
    this.props.history.push(user);
  };

  handleSortClick = (name) => {
    this.props.setUsersSort({
      sort: name,
    });
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "User Management",
      currentPage: "users",
      menu: "administration",
    });
    this.props.getUserList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createUserLoading && !this.props.createUserLoading) {
      if (this.props.createUserSuccess) {
        setTimeout(() => {
          this.closeDialog();
        }, 3000);
      }
    }

    if (
      prevProps.page !== this.props.page ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.rowsPerPage !== this.props.rowsPerPage ||
      (prevProps.updateUserRolesSuccess && !this.props.updateUserRolesSuccess)
    ) {
      this.props.getUserList();
    }
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      adminType: "Admins",
      inviteToHealthie: false,
    });
    this.props.resetCreateUser();
  };

  addUser = () => {
    const { firstName, lastName, email, isSuperAdmin, adminType, inviteToHealthie } = this.state;
    const password = generator.generate({
      length: 10,
      numbers: true,
      uppercase: true,
      strict: true,
    });
    this.props.createUser({
      firstName,
      lastName,
      email: email.toLowerCase(),
      password,
      isSuperAdmin,
      adminType,
      inviteToHealthie,
    });
  };

  render() {
    const { classes, usersLoading, userPermissions } = this.props;

    return (
      <PageContainer loading={usersLoading}>
        <SectionContainer columnDirection noPadding>
          <div className={classes.header} key="users-header">
            {userPermissions?.invite_admin_users && (
              <Button onClick={this.openDialog} style={{ width: 250 }}>
                Create Admin User
              </Button>
            )}
          </div>
          <Users
            {...this.props}
            {...this.state}
            viewUserDetails={this.viewUserDetails}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            count={this.props.count}
            handleSortClick={this.handleSortClick}
            key="users-view"
          />
        </SectionContainer>
        <CreateAdminUserDialog
          openDialog={this.openDialog}
          closeDialog={this.closeDialog}
          onSubmit={this.addUser}
          onChange={this.onChange}
          {...this.state}
          {...this.props}
        />
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersContainer));
