import React, { Component } from "react";
import SideNav from "./SideNav";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getSignedinState,
  getUserPermissionsList,
  getUserId,
  getUser,
  getUnreadMessageCount,
} from "../../selectors";
import Box from "@mui/material/Box";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  userId: getUserId(state),
  user: getUser(state),
  userPermissions: getUserPermissionsList(state),
  unreadMessageCount: getUnreadMessageCount(state),
});

class SideNavContainer extends Component {
  state = {
    drawerOpen: true,
    displayHeader: true,
    refreshPageOpen: false,
  };

  componentDidMount() {
    if (
      (this.props.location &&
        (this.props.location.pathname === "/force-password-change" ||
          this.props.location.pathname === "/reset-password" ||
          this.props.location.pathname.includes("/setup/") ||
          this.props.location.pathname === "/test" ||
          this.props.location.pathname.includes("/video/") ||
          this.props.location.pathname === "/chat")) ||
      this.props.location.pathname.includes("/static-video-page") ||
      this.props.location.pathname.includes("/video/") ||
      this.props.location.pathname.includes("/chat/") ||
      this.props.location.pathname.includes("/document-viewer/")
    ) {
      this.setState({ displayHeader: false });
    } else {
      this.setState({ displayHeader: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
      if (
        this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/force-password-change" &&
        this.props.location.pathname !== "/reset-password" &&
        this.props.location.pathname !== "/chat" &&
        !this.props.location.pathname.includes("/setup/") &&
        !this.props.location.pathname.includes("/static-video-page") &&
        !this.props.location.pathname.includes("/video/") &&
        !this.props.location.pathname.includes("/chat/") &&
        !this.props.location.pathname.includes("/document-viewer/")
      ) {
        this.setState({ displayHeader: true });
      } else {
        this.setState({ displayHeader: false });
      }
    }
  }

  navigate = (routeName) => {
    this.props.history.push(routeName);
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ drawerOpen: !state.drawerOpen }));
  };

  hideDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  render() {
    if (!this.props.signedInState || !this.state.displayHeader) {
      return <React.Fragment />;
    }
    return (
      <Box sx={{ display: { xs: "none", lg: "flex" } }}>
        <SideNav
          {...this.state}
          {...this.props}
          navigate={this.navigate}
          handleDrawerToggle={this.handleDrawerToggle}
          hideDrawer={this.hideDrawer}
        />
      </Box>
    );
  }
}

export default connect(mapStateToProps)(withRouter(SideNavContainer));
