import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

function getInitialState() {
  return {
    name: "",
    nameError: false,
    contractType: "",
    contractTypeError: false,
    successToastOpen: false,
    errorToastOpen: false,
  };
}

class CreateProviderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...getInitialState() };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.createProviderLoading && !this.props.createProviderLoading) {
      if (this.props.createProviderSuccess) {
        this.onClose();
        this.setState({ successToastOpen: true }, () => {
          setTimeout(() => {
            this.setState({ successToastOpen: false });
          }, 4000);
        });
      } else {
        this.setState({ error: true });
      }
    }
  }

  onChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: this.stringToBoolean(value),
      [`${name}Error`]: false,
    });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  goBack = () => {
    this.props.history.push("/insurance");
  };

  onClickSave = () => {
    const { name, contractType } = this.state;
    this.setState(
      {
        nameError: !name,
        contractTypeError: !contractType,
      },
      () => {
        if (this.state.nameError || this.state.contractTypeError) {
          return;
        } else {
          this.props.onCreateNewInsuranceProvider({
            name,
            contractType,
          });
        }
      },
    );
  };

  onClose = () => {
    this.setState({ ...getInitialState() });
    this.props.onToggleCreateProviderDialog();
  };

  render() {
    const {
      createProviderLoading,
      classes,
      providers,
      onToggleCreateProviderDialog,
      createProviderDialogOpen,
    } = this.props;
    const { name, nameError, contractType, contractTypeError, successToastOpen, error } =
      this.state;

    return (
      <>
        <Dialog
          open={createProviderDialogOpen}
          disableEscapeKeyDown={true}
          PaperProps={{
            style: {
              maxWidth: "95%",
              width: 700,
            },
          }}
        >
          <div className={classes.dialogTitleContainer}>
            <Typography component="h2" className={classes.dialogTitle}>
              Add a New Insurance
            </Typography>
          </div>
          <div className={classes.errorMessageContianer}>
            {error && (
              <Typography component="p">
                Something went wrong on our end. Please try again.
              </Typography>
            )}
          </div>
          <DialogContent>
            <div className={classes.providerDetailsContainer}>
              <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                <Grid item xs={12}>
                  <FormLabel
                    component="legend"
                    className={classes.formLabel}
                    style={{ marginBottom: 10 }}
                  >
                    Insurance Company*
                  </FormLabel>
                  <TextField
                    id="name"
                    name="name"
                    autoComplete="off"
                    onChange={this.onChange("name")}
                    value={name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder="Enter insurance company name"
                    onBlur={() => (nameError && name ? this.setState({ nameError: false }) : null)}
                    helperText={nameError ? "Please enter an insurance name" : null}
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Contract Type*
                  </FormLabel>
                  <RadioGroup
                    aria-label="contractType"
                    name="contractType"
                    value={contractType}
                    onChange={this.onRadioButtonChange("contractType")}
                  >
                    <FormControlLabel
                      value={"group"}
                      control={<Radio color="primary" />}
                      label="Group Contract"
                      size="small"
                    />
                    <FormControlLabel
                      value={"single_case"}
                      control={<Radio color="primary" />}
                      label="Single Case Agreement"
                      size="small"
                    />
                  </RadioGroup>
                  {contractTypeError && (
                    <Typography component="p" className={classes.helperText}>
                      Please select a contract type
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ margin: "0px 15px 15px 0" }}>
            <Button onClick={this.onClose} color="secondary" disabled={createProviderLoading}>
              Cancel
            </Button>
            <LoadingButton onClick={this.onClickSave} loading={createProviderLoading}>
              Save Changes
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {successToastOpen && (
          <div className={classes.snackbarContainer}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={successToastOpen}
              classes={{ anchorOriginTopCenter: classes.snackbar }}
              onClose={() => this.setState({ successToastOpen: false })}
            >
              <Alert severity="success">
                <Typography
                  component="p"
                  style={{ fontSize: 16, fontWeight: 500, color: "#047857" }}
                >
                  New insurance provider has been added
                </Typography>
              </Alert>
            </Snackbar>
            23
          </div>
        )}
      </>
    );
  }
}

export default CreateProviderDialog;
