import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import LockIcon from "@mui/icons-material/Lock";
import ClinicianInfo from "./ClinicianInfo";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { CircularProgress } from "@mui/material";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import Button from "@mui/material/Button";
import EditInfo from "./EditInfo";
import Auth from "@aws-amplify/auth";
import { DropzoneDialog } from "mui-file-dropzone";
import * as AWS from "aws-sdk";

import {
  getSignedinState,
  getParentInfo,
  isLoaded,
  getLogin,
  getCustomerDetails,
  isAccountHolder,
  isSubscribed,
  userClinician,
  profileSaving,
  referralCode,
  isLegacyUser,
  getCustomerDocuments,
  getDocumentTypes,
  getUserCognitoId,
  getUserId,
  getUserInsurance,
  getInsuranceProviders,
  getUserPermissionsList,
  clinicianLoading,
  getCustomerDocumentsLoading,
  getUpdatingInfoInHealthie,
  getUpdatingInfoInHealthieSuccess,
  getSuperBills,
  getSuperBillsLoading,
  getSuperBillsPage,
  getSuperBillsCount,
  getClientId,
  getFormSaving,
  getFormSuccess,
  workflowStatus,
} from "../../selectors";
import Modal from "elements/Modal";
import ECommConsentForm from "../ClientDashboard/forms/ECommConsentForm";

import { convertTokens } from "@date-fns/upgrade/v2";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  login: getLogin(state),
  loaded: isLoaded(state),
  user: getParentInfo(state),
  clientId: getClientId(state),
  customer: getCustomerDetails(state),
  legacy: isLegacyUser(state),
  isAccountHolder: isAccountHolder(state),
  isSubscribed: isSubscribed(state),
  clinician: userClinician(state),
  profileSaving: profileSaving(state),
  referralCode: referralCode(state),
  userDocuments: getCustomerDocuments(state),
  documentTypes: getDocumentTypes(state),
  userCognitoId: getUserCognitoId(state),
  userId: getUserId(state),
  userInsurance: getUserInsurance(state),
  insuranceProviders: getInsuranceProviders(state),
  userPermissions: getUserPermissionsList(state),
  clinicianLoading: clinicianLoading(state),
  documentsLoading: getCustomerDocumentsLoading(state),
  updatingInfoInHealthie: getUpdatingInfoInHealthie(state),
  updatingInfoInHealthieSuccess: getUpdatingInfoInHealthieSuccess(state),
  superBills: getSuperBills(state),
  superBillsLoading: getSuperBillsLoading(state),
  superBillsPage: getSuperBillsPage(state),
  superBillsCount: getSuperBillsCount(state),
  formSaving: getFormSaving(state),
  formSuccess: getFormSuccess(state),
  workflowStatus: workflowStatus(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSigninDetails: actions.setSigninDetails,
      startSignin: actions.startSignin,
      checkAuthState: actions.checkAuthState,
      getUser: actions.getParentInfo,
      signOut: actions.signOut,
      getCustomerDetails: actions.getCustomerDetails,
      getClinician: actions.getUserClinician,
      getUserDocuments: actions.getCustomerDocuments, //id
      getDocumentTypes: actions.getDocumentTypes,
      uploadUserDocument: actions.uploadUserDocuments,
      getUserInsurance: actions.getUserInsurance,
      getInsuranceProviders: actions.getInsuranceProviders,
      updateUserInsurance: actions.updateUserInsurance,
      createUserInsurance: actions.createUserInsurance,
      setPageDetails: actions.setPageDetails,
      getCustomerSignedForm: actions.getCustomerSignedForm,
      updateClientInfo: actions.updateClientInfo,
      updateCaregiverInfo: actions.updateCaregiverInfo,
      getSuperBills: actions.getSuperBillsByClient,
      setSuperBillsPage: actions.setSuperBillsPage,
      saveCustomerSignedForm: actions.saveSignedCustomerForm,
    },
    dispatch
  );

function getInitialState(props) {
  return {
    currentDate: null,
    clientInfoEditOpen: false,
    guardianInfoEditOpen: false,
    uploadDocumentOpen: false,
    uploadDropzoneOpen: false,
    uploadDocType: "",
    uploading: false,
    uploadError: false,
    updateDropzoneOpen: false,
    updateDocType: "",
    selectedUpdateDoc: null,
    eCommConsentFormOpen: false,
    eCommConsentSigned: false,
    telehealthConsentSigned: false,
    signatureStarted: false,
    saveFormError: false,
    disableECommConsentOpen: false,
  };
}

class CaregiverAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(),
    };
    this.consentToECommSignatureRef = null;
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Answersnow - My Account",
      currentPage: "profile",
      menu: "profile",
    });

    this.props.checkAuthState();
    this.props.getInsuranceProviders();
    if (this.props.login) {
      this.props.getUser();
    }

    if (this.props.loaded && this.props.clientId) {
      this.props.getCustomerDetails();
      this.props.getUserDocuments(this.props.clientId);
      this.props.getSuperBills(this.props.clientId);
      this.props.getUserInsurance(this.props.clientId);
      if (this.props.isSubscribed) {
        this.props.getPlan();
      }
      this.props.getCustomerSignedForm({
        clientId: this.props.clientId,
        type: "E_COMM_CONSENT",
        userId: this.props.userId,
      });
      if (this.props.completedQuestionnaire) {
        this.props.getClinician(this.props.clientId);
      }
    }

    if (this.props.location?.pathname?.includes("/ecomm-consent")) {
      this.onOpenECommConsentForm();
    }

    this.props.getDocumentTypes();

    this.timer = setInterval(() => this.setState({ currentDate: new Date() }), 1000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.login && this.props.login) {
      this.props.getUser();
    }
    if ((!prevProps.loaded || !prevProps.clientId) && this.props.loaded && this.props.clientId) {
      this.props.getCustomerDetails();
      this.props.getUserDocuments(this.props.clientId);
      this.props.getSuperBills(this.props.clientId);
      this.props.getUserInsurance(this.props.clientId);
      if (this.props.isSubscribed) {
        this.props.getPlan();
      }
      if (this.props.clientId) {
        this.props.getClinician(this.props.clientId);
      }
      this.props.getCustomerSignedForm({
        clientId: this.props.clientId,
        type: "E_COMM_CONSENT",
        userId: this.props.userId,
      });
    }
    if (
      prevProps.updatingInfoInHealthie &&
      !this.props.updatingInfoInHealthie &&
      this.props.updatingInfoInHealthieSuccess
    ) {
      if (this.state.clientInfoEditOpen) {
        this.toggleClientEdit();
      }
      if (this.state.guardianInfoEditOpen) {
        this.toggleGuardianEdit();
      }
    }
    if (prevProps.superBillsPage !== this.props.superBillsPage) {
      this.props.getSuperBills(this.props.clientId);
    }
    if (
      (!prevProps.user?.form && this.props.user?.form) ||
      (prevProps.user?.form?.formType !== this.props.user?.form?.formType && this.props.user?.form)
    ) {
      if (this.props.user.form.formType === "E_COMM_CONSENT") {
        if (this.props.user.form.signed) {
          this.setState({ eCommConsentSigned: true });
        } else {
          this.setState({ eCommConsentSigned: false });
        }
      }
    }
    if (prevProps.formSaving && !this.props.formSaving) {
      if (!this.props.formSuccess) {
        this.setState({ saveFormError: true });
      } else {
        this.setState({
          eCommConsentFormOpen: false,
          eCommConsentSigned: true,
        });
        this.props.getCustomerSignedForm({
          clientId: this.props.clientId,
          type: "E_COMM_CONSENT",
          userId: this.props.userId,
        });
        window.scrollTo(0, 0);
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  viewDocument = async (document) => {
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({ credentials });
    const params = {
      Bucket: process.env.AWS_USER_DOC_BUCKET,
      Key: `${document.cognito_id}/${document.filename}`,
    };
    const url = await s3.getSignedUrl("getObject", params);
    window.open(url, "_blank");
  };

  navigate = (url) => (e) => {
    this.props.history.push(url);
  };

  formatText = (text) => {
    return text
      ?.toLowerCase()
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  formatTimezone = (text) => {
    return text
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  formatPhoneNumber = (phone) => {
    let formatedPhone = phone?.replace(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
      "($1) $2-$3"
    );
    return formatedPhone;
  };

  toggleClientEdit = () => {
    this.setState({ clientInfoEditOpen: !this.state.clientInfoEditOpen });
  };

  toggleGuardianEdit = () => {
    this.setState({ guardianInfoEditOpen: !this.state.guardianInfoEditOpen });
  };

  onDownloadSignedConsentForm = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    let url = `${baseUrl}/download/${userSession.accessToken.jwtToken}/informed-consent/${this.props.clientId}`;
    window.open(url, "_blank");
  };

  onDownloadSignedECommConsentForm = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    let url = `${baseUrl}/download/${userSession.accessToken.jwtToken}/electronic-communication-consent/${this.props.userId}`;
    window.open(url, "_blank");
  };

  onOpenUploadDropzone = (docType) => {
    this.setState({
      uploadDropzoneOpen: true,
      uploadDocType: docType,
    });
  };

  onCloseUploadDropzone = () => {
    this.setState({ uploadDropzoneOpen: false });
  };

  submitDocumentUpload = async (document) => {
    const { uploadDocType } = this.state;
    let uploadDoc = document[0];
    const { userCognitoId, userId, clientId } = this.props;
    this.setState({ uploading: true });
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentType: uploadDoc.type,
        Key: `${userCognitoId}/${uploadDoc.name.replace(" ", "-")}`,
      };
      const upload = await s3.upload(params).promise();
      const document = {
        filename: upload.Key.split("/")[1],
        clientId: clientId,
        type: uploadDocType,
        internal: false,
        billing_related: false,
      };
      this.props.uploadUserDocument([document]);

      this.setState({
        uploadDropzoneOpen: false,
        uploadDocType: "",
        uploading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({ uploadError: true, uploading: false });
    }
  };

  onOpenUpdateDropzone = (doc) => {
    this.setState({
      updateDropzoneOpen: true,
      selectedUpdateDoc: doc,
    });
  };

  onCloseUpdateDropzone = () => {
    this.setState({ updateDropzoneOpen: false, selectedUpdateDoc: null });
  };

  submitDocumentUpdate = async (document) => {
    const { selectedUpdateDoc } = this.state;
    let uploadDoc = document[0];
    const { userCognitoId, userId } = this.props;
    this.setState({ uploading: true });
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentType: uploadDoc.type,
        Key: `${userCognitoId}/${selectedUpdateDoc.filename.replace(" ", "-")}`,
      };
      const upload = await s3.upload(params).promise();
      const document = {
        filename: upload.Key.split("/")[1],
        userId: userId,
        type: selectedUpdateDoc.type,
        internal: false,
        billing_related: false,
      };

      this.setState({
        updateDropzoneOpen: false,
        selectedUpdateDoc: null,
        uploading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({ uploadError: true, uploading: false });
    }
  };

  sendConsentToECommSignatures = async () => {
    const signatureImageData = this.consentToECommSignatureRef
      .getTrimmedCanvas()
      .toDataURL("image/png");
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const buf = new Buffer(signatureImageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${this.props.userCognitoId}/consent_to_electronic_communications_signature.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      this.props.saveCustomerSignedForm({
        formId: this.props.user?.form?.formId,
        userId: this.props.userId,
        clientId: this.props.clientId,
        signatureUrl: s3url.Location,
      });
      this.setState({ signatureStarted: false });
    } catch (error) {
      console.log(error);
    }
  };

  onSkipConsentToEComm = () => {
    this.props.saveCustomerSignedForm({
      formId: this.props.user?.form?.formId,
      userId: this.props.userId,
      clientId: this.props.clientId,
      skip: true,
    });
    this.setState({
      signatureStarted: false,
      eCommConsentFormOpen: false,
      disableECommConsentOpen: false,
    });
  };

  startSignature = () => {
    this.setState({ signatureStarted: true });
  };

  onOpenECommConsentForm = () => {
    this.setState({ eCommConsentFormOpen: true });
  };

  onToggleDisableECommConsent = () => {
    this.setState({
      disableECommConsentOpen: !this.state.disableECommConsentOpen,
    });
  };

  renderMissingSign = (docType) =>
    ["INSURANCE_CARD_FRONT", "INSURANCE_CARD_BACK"].includes(docType);

  renderUpdateButton = (docType) =>
    [
      "INSURANCE_CARD_FRONT",
      "INSURANCE_CARD_BACK",
      "SECONDARY_INSURANCE_CARD_FRONT",
      "SECONDARY_INSURANCE_CARD_BACK",
    ].includes(docType);

  render() {
    const {
      user,
      loaded,
      clinician,
      classes,
      userDocuments,
      clinicianLoading,
      userPermissions,
      documentTypes,
      documentsLoading,
      login,
      userInsurance,
      formSaving,
      formSuccess,
    } = this.props;
    const {
      currentDate,
      clientInfoEditOpen,
      guardianInfoEditOpen,
      uploadDocumentOpen,
      eCommConsentSigned,
      consentFormOpen,
      disableECommConsentOpen,
    } = this.state;

    let customerInfo = user?.userDetails;
    let locationInfo = user?.userDetails?.location;
    let notificationContacts = user?.userDetails?.notification_contacts;
    let guardianInfo = notificationContacts?.filter((contact) => contact.email === login)[0];
    let clientDocumentTypes = documentTypes.filter((doc) => doc.internal == false);
    let privatePay = userInsurance?.some((insurance) => insurance.insurance_name == "Private Pay");
    let missingUserDocuments = clientDocumentTypes.filter(
      (doc) =>
        !userDocuments.some((usDoc) => usDoc.type == doc.name) && doc.name != "TREATMENT_PLAN"
    );
    if (privatePay) {
      missingUserDocuments = missingUserDocuments.filter(
        (doc) => !doc.name.includes("INSURANCE_CARD")
      );
    }
    return !loaded || documentsLoading ? (
      <div className={classes.loadingContainer}>
        <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
        <Typography variant="p">Loading</Typography>
      </div>
    ) : (
      <div className={classes.pageContainer}>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <Typography className={classes.pageTitle}>Account</Typography>
            <div className={classes.dateContainer}>
              <Typography>
                {format(new Date(currentDate), convertTokens("h:mm a, D MMMM YYYY "))}
              </Typography>
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftContainer}>
              <div className={classes.block}>
                <div className={classes.clinicianInfoHeader}>
                  <div>
                    <Typography variant="h4">
                      {customerInfo?.name || "----"} {customerInfo?.child_last_name || "----"}
                    </Typography>
                    <Typography variant="body2">Personal Information</Typography>
                  </div>
                  {!clientInfoEditOpen && userPermissions?.edit_client_info && (
                    <Button
                      variant="text"
                      size="large"
                      startIcon={<EditIcon style={{ fontSize: 14 }} />}
                      onClick={this.toggleClientEdit}
                    >
                      Edit Information
                    </Button>
                  )}
                </div>
                {clientInfoEditOpen ? (
                  <EditInfo
                    {...this.props}
                    {...this.state}
                    toggleClientEdit={this.toggleClientEdit}
                    formatPhoneNumber={this.formatPhoneNumber}
                    isClientOpen={true}
                  />
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    spacing={4}
                    classes={{ root: classes.gridRoot }}
                  >
                    <Grid item xs={6} sm={3}>
                      <Typography>Date of Birth</Typography>
                      <Typography>
                        {customerInfo?.dob
                          ? format(
                              dateWithoutTimezone(customerInfo?.dob),
                              convertTokens("MMM. D, YYYY")
                            )
                          : "----"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>Identity</Typography>
                      <Typography>{customerInfo?.gender || "----"}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography>Address</Typography>
                      <Typography>{locationInfo?.line1 || "----"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>Address 2</Typography>
                      <Typography>{locationInfo?.line2 || "----"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>Time Zone</Typography>
                      <Typography>
                        {customerInfo?.timezone
                          ? formatTimeZoneToAbbreviation(
                              this.formatTimezone(customerInfo?.timezone)
                            )
                          : "----"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>City</Typography>

                      <Typography>{locationInfo?.city || "----"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>State</Typography>
                      <Typography>{locationInfo?.state || "----"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>ZIP Code</Typography>
                      <Typography>{locationInfo?.zip || "----"}</Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
              <div className={classes.block}>
                <div className={classes.clinicianInfoHeader}>
                  <div>
                    <Typography variant="h4">
                      {guardianInfo
                        ? (guardianInfo?.first_name || "---") +
                          " " +
                          (guardianInfo?.last_name || " ---")
                        : "----"}
                    </Typography>
                    <Typography variant="body2">Guardian Information</Typography>
                  </div>
                  {!guardianInfoEditOpen && userPermissions?.edit_client_info && (
                    <Button
                      variant="text"
                      size="large"
                      startIcon={<EditIcon style={{ fontSize: 14 }} />}
                      onClick={this.toggleGuardianEdit}
                    >
                      Edit Information
                    </Button>
                  )}
                </div>
                {guardianInfoEditOpen ? (
                  <EditInfo
                    {...this.props}
                    {...this.state}
                    toggleGuardianEdit={this.toggleGuardianEdit}
                    formatPhoneNumber={this.formatPhoneNumber}
                    isGuardianOpen={true}
                  />
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    spacing={4}
                    classes={{ root: classes.gridRoot }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      <Typography>Email</Typography>
                      <Typography>
                        {guardianInfo ? guardianInfo?.email || "----" : "----"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography>Phone Number</Typography>
                      <Typography>
                        {guardianInfo
                          ? this.formatPhoneNumber(guardianInfo?.phone_number) || "----"
                          : "----"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>Relationship to Client</Typography>
                      <Typography>
                        {guardianInfo
                          ? this.formatText(guardianInfo?.relationship) || "----"
                          : "----"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
              {Object.keys(clinician).length > 0 && (
                <ClinicianInfo clinician={this.props.clinician} />
              )}
              <div className={classes.block}>
                <div className={classes.supportSection}>
                  <Typography variant="h5" style={{ margin: "10px 0 20px" }}>
                    Consent to Electronic Communications
                  </Typography>
                  <Typography variant="h6">
                    If you agree to receive messages regarding protected health information,
                    AnswersNow will be able to communicate with you by email and on your mobile
                    device through text message. This includes messages sent directly from a
                    clinician. <br /> (Message & data rates may apply)
                  </Typography>
                  {eCommConsentSigned ? (
                    <Button
                      variant="text"
                      className={classes.eCommConsentButtonDisable}
                      onClick={this.onToggleDisableECommConsent}
                    >
                      Disable
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      className={classes.eCommConsentButtonAgree}
                      onClick={this.onOpenECommConsentForm}
                    >
                      Agree
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.block}>
                <div className={classes.passwordSection}>
                  <Typography component="p">Need to change your password?</Typography>
                  <Button onClick={this.navigate("/change-password")} variant="text">
                    Change Password
                  </Button>
                </div>
              </div>
              <div className={classes.block}>
                <Typography className={classes.documentsSectionTitle}>
                  Documents and Forms
                </Typography>
                {missingUserDocuments.map((document) => {
                  return (
                    <div key={document.id} className={classes.document}>
                      <div className={classes.missingDocument}>
                        <Typography
                          component="p"
                          className={classes.documentName}
                          style={{ marginLeft: 26 }}
                        >
                          {document.display_name}
                        </Typography>
                        {this.renderMissingSign(document.name) && (
                          <Typography
                            className={classes.documentName}
                            style={{
                              color: "#b91c1c",
                              fontWeight: 500,
                            }}
                          >
                            <PriorityHighIcon
                              style={{
                                fontSize: 18,
                                marginRight: 8,
                              }}
                            />
                            MISSING DOCUMENT
                          </Typography>
                        )}
                      </div>
                      <Button onClick={() => this.onOpenUploadDropzone(document.name)}>
                        upload
                      </Button>
                    </div>
                  );
                })}

                {userDocuments.map((document) => {
                  return (
                    <div key={document.id} className={classes.document}>
                      <Typography component="p" className={classes.documentName}>
                        <DescriptionIcon
                          style={{
                            color: "#545a72",
                            fontSize: 18,
                            marginRight: 8,
                          }}
                        />
                        {document.type ? this.formatText(document.type) : "Untitled Document"}
                      </Typography>
                      <div className={classes.documentButtonContainer}>
                        {this.renderUpdateButton(document.type) && (
                          <Button
                            onClick={() => this.onOpenUpdateDropzone(document)}
                            color="secondary"
                          >
                            update
                          </Button>
                        )}
                        <Button onClick={() => this.viewDocument(document)} variant="text">
                          View
                        </Button>
                      </div>
                    </div>
                  );
                })}
                {this.props.workflowStatus !== "AWAITING_ONBOARDING" && (
                  <div key={document.id} className={classes.document}>
                    <Typography component="p" className={classes.documentName}>
                      <DescriptionIcon
                        style={{
                          color: "#545a72",
                          fontSize: 18,
                          marginRight: 8,
                        }}
                      />
                      Telehealth Consent Form
                    </Typography>
                    <Button onClick={this.onDownloadSignedConsentForm} variant="text">
                      Download
                    </Button>
                  </div>
                )}
                {eCommConsentSigned && (
                  <div key={document.id} className={classes.document}>
                    <Typography component="p" className={classes.documentName}>
                      <DescriptionIcon
                        style={{
                          color: "#545a72",
                          fontSize: 18,
                          marginRight: 8,
                        }}
                      />
                      Electronic Communications Consent
                    </Typography>
                    <Button onClick={this.onDownloadSignedECommConsentForm} variant="text">
                      Download
                    </Button>
                  </div>
                )}
              </div>

              <div className={classes.block}>
                <div className={classes.supportSection}>
                  <Typography variant="h4">Support</Typography>
                  <Typography variant="h5">
                    Need help? Give us a call at our support number and our Care Coordination team
                    will be there to assist you: <span>(804) 215-5600</span>
                  </Typography>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.supportSection}>
                  <Typography variant="h4">Legal</Typography>
                  <Button
                    variant="text"
                    startIcon={<LockIcon style={{ fontSize: 15 }} />}
                    target="_blank"
                    className={classes.linkButton}
                    href="https://www.getanswersnow.com/privacy"
                  >
                    Privacy Policy
                  </Button>
                  <Button
                    variant="text"
                    startIcon={
                      <DescriptionIcon
                        style={{
                          fontSize: 15,
                        }}
                      />
                    }
                    target="_blank"
                    className={classes.linkButton}
                    href="https://www.getanswersnow.com/terms"
                  >
                    Terms of Use
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DropzoneDialog
          open={this.state.uploadDropzoneOpen}
          filesLimit={1}
          onSave={this.submitDocumentUpload}
          acceptedFiles={[
            "image/*",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/rtf",
            "application/zip",
            "text/plain",
          ]}
          showPreviews={false}
          showPreviewsInDropzone={true}
          maxFileSize={15728640}
          onClose={this.onCloseUploadDropzone}
          dropzoneText={"Drag and drop or click here"}
          dialogProps={{
            style: { zIndex: 40003 },
          }}
        />
        <DropzoneDialog
          open={this.state.updateDropzoneOpen}
          filesLimit={1}
          onSave={this.submitDocumentUpdate}
          acceptedFiles={[
            "image/*",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/rtf",
            "application/zip",
            "text/plain",
          ]}
          showPreviews={false}
          showPreviewsInDropzone={true}
          maxFileSize={15728640}
          onClose={this.onCloseUpdateDropzone}
          dropzoneText={"Drag and drop or click here"}
          dialogProps={{
            style: { zIndex: 40003 },
          }}
        />
        <ECommConsentForm
          firstName={user.first_name}
          lastName={user.last_name}
          loading={formSaving}
          success={formSuccess}
          error={this.state.formError}
          consentToECommSignatureRef={(ref) => (this.consentToECommSignatureRef = ref)}
          startSignature={this.startSignature}
          consentToEComm={this.sendConsentToECommSignatures}
          onSkipConsentToEComm={this.onSkipConsentToEComm}
          {...this.state}
        />
        <Modal
          open={disableECommConsentOpen}
          title="Remove Consent for Electronic Communications"
          description="You can update your consent to receive protected health information via email/text message at any time."
          onClose={this.onToggleDisableECommConsent}
          loading={formSaving}
          primaryActionText="Disable"
          primaryActionOnClick={this.onSkipConsentToEComm}
          primaryActionOutlined
          secondaryActionText="Cancel"
          secondaryActionOnClick={this.onToggleDisableECommConsent}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CaregiverAccountContainer))
);
