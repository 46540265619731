import React from "react";
import { createTheme } from "@mui/material/styles";
import BasierCircle from "./fonts/BasierCircle_Font/BasierCircle-Regular.woff2";
import BasierCircleMedium from "./fonts/BasierCircle_Font/BasierCircle-Medium.woff2";
import BasierCircleSemiBold from "./fonts/BasierCircle_Font/BasierCircle-SemiBold.woff2";
import BasierCircleBold from "./fonts/BasierCircle_Font/BasierCircle-Bold.woff2";

import {
  Calendar,
  CaretDown,
  CaretRight,
  CaretLeft,
  Check,
  Minus,
  CaretDoubleRight,
  CaretDoubleLeft,
  Warning,
  Info,
  CheckCircle,
  WarningCircle,
} from "@phosphor-icons/react";
import { IconButton, Tooltip } from "@mui/material";
import { blue, blueGrey, cyan, green, indigo, orange, pink, teal } from "@mui/material/colors";
import RadioCheckmark from "../assets/RadioCheckmark.js";

/**********************************************************************
 * Globally available styles via useTheme hook
 * example:
 *     import { useTheme } from "@emotion/react";
 *     const theme = useTheme();
 **********************************************************************/
let theme = createTheme({
  /**********************************************************************
   * Typography
   **********************************************************************/
  typography: {
    fontFamily: "BasierCircle",
    h1: {
      fontSize: "34px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "123.5%" /* 41.99px */,
      letterSpacing: "0.25px",
    },
    h2: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "133.4%" /* 32.016px */,
      letterSpacing: "0.2px",
    },
    h3: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "160%" /* 32px */,
      letterSpacing: "0.15px",
    },
    h4: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "160%" /* 28.8px */,
      letterSpacing: "0.12px",
    },
    h5: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%" /* 24px */,
      letterSpacing: "0.15",
    },
    h6: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "143%" /* 20.02px */,
      letterSpacing: "0.17px",
    },
    subtitle1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "175%" /* 28px */,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "157%" /* 21.98px */,
      letterSpacing: "0.1",
    },
    subtitle3: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "175%" /* 28px */,
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%" /* 24px */,
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "143%" /* 20.02px */,
      letterSpacing: "0.17px",
    },
    caption: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
    inputLabel: {
      fontSize: "12px", // Font size
      fontStyle: "normal", // Font style
      fontWeight: 500, // Font weight
      lineHeight: "13px", // Line height
      letterSpacing: "0.3px", // Letter spacing
    },
    overline: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      textTransform: "uppercase",
      lineHeight: "266%" /* 31.92px */,
      letterSpacing: "1px",
    },
    button: {
      /* Medium size defaults */
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px" /* 171.429% */,
      letterSpacing: "0.4px",
      textTransform: "capitalize",
      position: "static",
    },
    iconButton: {
      position: "static",
    },
  },
  /**********************************************************************
   * Colors
   **********************************************************************/
  palette: {
    mode: "light",
    primary: {
      main: "#8E42CA",
      background: "#EDE7F6",
      contrast: "#FFFFFF",
      hover: "#8E42CA0A",
      selected: "#8E42CA14",
      focus: "#8E42CA1F",
      focusVisible: "#8E42CA4D",
      outlinedBorder: "#8E42CA80",
    },
    secondary: {
      main: "#9C27B0",
      dark: "#7B1FA2",
      light: "#BA68C8",
      contrast: "#FFFFFF",
      outlinedBorder: "#C0C8D0",
    },
    inherit: {
      main: "#31333A",
      background: "#fff",
      hover: "#F6F7F8",
    },
    default: {
      main: "#31333A",
      background: "#fff",
      hover: "#F6F7F8",
    },
    dark: {
      main: "#31333a",
    },
    success: {
      main: "#2E7D32",
      background: "#E8F5E9",
      backgroundContrast: "#2E7D32",
      light: "#4CAF50",
      dark: "#1B5E20",
      contrast: "#FFFFFF",
    },
    error: {
      main: "#D32F2F",
      background: "#FEEBEE",
      backgroundContrast: "#D32F2F",
      light: "#EF5350",
      dark: "#C62828",
      contrast: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      background: "#FFF3E0",
      dark: "#E65100",
      light: "#FF9800",
      contrast: "#FFFFFF",
    },
    info: {
      main: "#0288D1",
      background: "#E1F5FE",
      light: "#03A9F4",
      dark: "#01579B",
      contrast: "#FFFFFF",
    },
    text: {
      primary: "#31333a",
      secondary: "#5b6172",
      disabled: "#C0C8D0",
    },
    divider: "#CDD3DA",
    action: {
      active: "#6C7489",
      hover: "#F6F7F8",
      selected: "#EAECEF",
      disabledBackground: "#F6F7F8",
      focus: "#EAECEF",
      disabled: "#C0C8D0",
    },
    tooltip: {
      fill: "#121212",
    },
    radio: {
      border: "#A1ACB9",
    },
  },
  /**********************************************************************
   * Breakpoints
   **********************************************************************/
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1200,
      xl: 1200,
      xxl: 1536,
    },
  },
  // Use example: theme.spacing(2) = 6px
  spacing: [
    "0px",
    "4px",
    "6px",
    "8px",
    "12px",
    "16px",
    "20px",
    "24px",
    "28px",
    "32px",
    "40px",
    "48px",
  ],
  radius: {
    input: "6px",
    small: "2px",
    medium: "4px",
    large: "6px",
    "ex-large": "8px",
    button: {
      sm: "6px",
      md: "6px",
      lg: "6px",
    },
  },
});

const tableDatagridCommonStyles = {
  "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-input":
    {
      fontSize: "12px",
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
  "& .MuiTablePagination-selectLabel": {
    color: theme.palette.text.secondary,
  },
  "& .MuiSelect-select.MuiTablePagination-select.MuiInputBase-inputSizeSmall": {
    padding: "8px 36px 8px 12px",
  },
  "& .MuiTablePagination-input": {
    marginRight: "26px",
    minHeight: 0,
  },
  "& .MuiTablePagination-actions": {
    marginLeft: "26px",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
};

const generateCalendarEventColor = (color) => ({
  color: color[900],
  backgroundColor: color[100],
  "&.expired": {
    color: color[400],
    backgroundColor: color[50],
  },
  "&:hover": { backgroundColor: color[200] },
  "&:focus": {
    backgroundColor: color[600],
    color: theme.palette.primary.contrast,
    outline: "none",
  },
});

theme = createTheme(theme, {
  /**********************************************************************
   *  Set DEFAULT VARIANT and STYLES here
   **********************************************************************/
  bigCalendarCustomStyle: {
    "& .rbc-time-header-content, & .rbc-time-content > * + * > *, & .rbc-time-view": {
      border: "none",
    },
    "& .rbc-time-header.rbc-overflowing": {
      border: "none",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    },
    "& .rbc-header": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(5),
      border: "none",
      "& .rbc-button-link": {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        "& .current-date": {
          padding: theme.spacing(0, 1),
          minWidth: "22px",
        },
      },
      "&.rbc-today .current-date": {
        borderRadius: "100px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrast,
      },
    },
    "& .rbc-time-content": {
      padding: theme.spacing(5, 0, 0, 5),
      border: "none",
    },
    "& .rbc-label": {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      position: "relative",
      top: "-11px",
      padding: "0 8px",
    },
    "& .rbc-day-slot": {
      "& .rbc-time-slot": {
        borderTop: "1px solid #EAECEF",
      },
      "& .non-working-hours": { backgroundColor: "#F6F7F8" },
      "& .rbc-events-container": {
        margin: theme.spacing(0, 3),
      },
    },
    "& .rbc-time-slot": {
      textAlign: "right",
    },
    "& .rbc-timeslot-group": {
      border: "none",
      minHeight: "69.3px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-time-header-gutter": {
      marginLeft: theme.spacing(5),
    },
    "& .rbc-event": {
      border: "none",
      // !important is needed to override the inline styles added by react-big-calendar
      position: "relative !important",
      top: "0 !important",
      height: "100% !important",
      left: "0 !important",
      width: "100% !important",
      "& .MuiTypography-root": {
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16.6px",
        letterSpacing: "0.4px",
        wordWrap: "break-word",
      },
      "& .event-participant .MuiTypography-root": {
        fontWeight: 500,
      },
    },
    "& .event-container": {
      display: "flex",
    },
    "& .rbc-current-time-indicator": {
      backgroundColor: "unset",
      "& > div": {
        display: "flex",
        alignItems: "center",
        transform: "translateY(-50%)",
        gap: theme.spacing(3),
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
          fontSize: 8,
          fontWeight: "600",
        },
      },
    },
    "& .event-color-0": {
      color: theme.palette.primary.main,
      backgroundColor: "#F4ECFB",
      "&.expired": {
        color: "#C397E9",
      },
      "&:hover": { backgroundColor: "#EADBF9" },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrast,
        outline: "none",
      },
    },
    "& .event-color-1": generateCalendarEventColor(orange),
    "& .event-color-2": generateCalendarEventColor(blue),
    "& .event-color-3": generateCalendarEventColor(green),
    "& .event-color-4": generateCalendarEventColor(blueGrey),
    "& .event-color-5": generateCalendarEventColor(indigo),
    "& .event-color-6": generateCalendarEventColor(cyan),
    "& .event-color-7": generateCalendarEventColor(pink),
    "& .event-color-8": generateCalendarEventColor(teal),
    "&& .private": {
      color: theme.palette.text.secondary,
      backgroundColor: "#EAECEF",
      "&.expired": {
        color: theme.palette.text.disabled,
        backgroundColor: "#F6F7F8",
      },
      "&:hover": { backgroundColor: "#CDD3DA" },
      "&:focus": {
        backgroundColor: "#CDD3DA",
        color: theme.palette.text.secondary,
        outline: "none",
      },
    },
    "&&& .red-text": {
      color: theme.palette.error.main,
    },
    "& .rbc-show-more": {
      color: "#8E42CA !important",
      fontSize: "10px",
      wordWrap: "break-word",
      fontWeight: 400,
      lineHeight: "16.6px",
      letterSpacing: "0.4px",
      padding: "0px 10px 1px 10px",
    },
    "& .rbc-time-view": {
      "& .rbc-event-label, & .rbc-row-bg, & .rbc-allday-cell": {
        display: "none",
      },
    },
    "& .rbc-month-view": {
      // Month view only styles
      "& .rbc-button-link": {
        color: "white",
        height: "40px",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BasierCircle';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BasierCircle'), local('BasierCircle-Regular'), url(${BasierCircle}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'BasierCircle';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('BasierCircleMedium'), local('BasierCircle-Medium'), url(${BasierCircleMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'BasierCircle';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('BasierCircleSemiBold'), local('BasierCircle-SemiBold'), url(${BasierCircleSemiBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'BasierCircle';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('BasierCircleBold'), local('BasierCircle-Bold'), url(${BasierCircleBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        `,
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
        size: "medium",
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          position: "relative",
          "& svg": {
            width: "20px",
            height: "20px",
          },
          "&.MuiButton-sizeSmall": {
            padding: "4px 12px",
            fontSize: 13,
            lineHeight: "22px" /* 169.231% */,
            letterSpacing: "0.46px",
            // removing 1px from the padding for the secondary color, since the border
            // takes extra space (in this case is 1px)
            "&.MuiButton-containedSecondary": {
              padding: "3px 11px",
            },
            "& svg": {
              width: "16px",
              height: "16px",
            },
          },
          "&.MuiButton-sizeMedium": {
            padding: "6px 16px",
            "&.MuiButton-containedSecondary": {
              padding: "5px 15px",
            },
          },
          "&.MuiButton-sizeLarge": {
            padding: "8px 24px",
            fontSize: 15,
            lineHeight: "26px" /* 173.333% */,
            letterSpacing: "0.46px",
            "&.MuiButton-containedSecondary": {
              padding: "7px 23px",
            },
          },
          "&.MuiButton-containedSecondary": {
            color: theme.palette.inherit.main,
            backgroundColor: theme.palette.default.background,
            border: `1px solid ${theme.palette.secondary.outlinedBorder}`,
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              color: theme.palette.text.disabled,
            },
          },
          "&.MuiButton-textInfo": {
            "&& .MuiTouchRipple-child": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          "&.MuiButton-text": {
            "&.MuiButton-colorInherit": {
              color: "#31333A",
              "&:hover": {
                backgroundColor: "#F6F7F8",
              },
            },
          },
          "&:hover": {
            boxShadow: "none",
          },
        },
        startIcon: {
          color: "inherit",
          "& svg": {
            color: "inherit",
          },
        },
        endIcon: {
          color: "inherit",
          "& svg": {
            color: "inherit",
          },
        },
        outlined: {
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          "&.MuiButton-colorInherit": {
            border: "1px solid #C0C8D0",
          },
        },
        contained: {
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          "&:active": {
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
        size: "medium",
        loadingPosition: "start",
      },
      styleOverrides: {
        root: {
          "& .MuiLoadingButton-startIconLoadingStart": {
            display: "none",
          },
          "& .MuiLoadingButton-loadingIndicatorStart": {
            position: "static",
            marginRight: "8px",
          },
          // This is added with !important to override the inline style added by the component `style="width: 16px; height: 16px;"`
          // Without this, the loading indicator is not centered and looks like is moving around (not moving in a static position) for sizes other than small
          "& .MuiCircularProgress-root": {
            width: "inherit !important",
            height: "inherit !important",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        variant: "text",
        disableRipple: true,
        size: "medium",
        fontSize: "medium",
        color: "secondary",
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "6px",
          padding: "6px",
          "& svg": {
            width: "24px",
            height: "24px",
          },
          "&.MuiIconButton-sizeSmall": {
            padding: "4px",
          },
          ...(ownerState.fontSize === "large" && {
            padding: "4px",
            "&.MuiIconButton-sizeSmall": {
              padding: "2px",
            },
            "& svg": {
              width: "28px",
              height: "28px",
            },
          }),
          ...(ownerState.fontSize === "small" && {
            padding: "8px",
            "&.MuiIconButton-sizeSmall": {
              padding: "5px",
            },
            "& svg": {
              width: "20px",
              height: "20px",
            },
          }),
          ...(ownerState.variant === "text" && {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: theme.palette.primary.hover,
            },
            "&:active": {
              backgroundColor: theme.palette.primary.selected,
            },
            "&.MuiIconButton-colorSecondary": {
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "#F6F7F880",
              },
              "&:active": {
                backgroundColor: theme.palette.action.focus,
              },
            },
            "&.MuiIconButton-colorError": {
              "& svg": {
                color: theme.palette.error.main,
              },
              "&:hover": {
                backgroundColor: "#D32F2F0A",
              },
              "&:active": {
                backgroundColor: "#D32F2F4D",
              },
            },
          }),
          ...(ownerState.variant === "outlined" && {
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "#6C3693",
            },
            "&.MuiIconButton-colorSecondary": {
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.secondary.outlinedBorder}`,
              background: theme.palette.default.background,
              boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.15)`,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.action.focus,
              },
            },
            "&.MuiIconButton-colorPrimary": {
              color: "#FAF7FD",
              "&:active": {
                backgroundColor: theme.palette.primary.focusVisible,
              },
            },
            "&.MuiIconButton-colorError": {
              color: theme.palette.error.main,
              border: `1px solid ${theme.palette.secondary.outlinedBorder}`,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.action.focus,
              },
            },
          }),
          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            "& svg": {
              color: theme.palette.text.disabled,
              fill: theme.palette.text.disabled,
            },
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                backgroundColor: theme.palette.action.disabledBackground,
              }),
          },
          // this new prop is to have a darker color for the icon button when it is disabled in a dark background
          ...(ownerState.disabledDarkColor === true && {
            "&.Mui-disabled": {
              color: theme.palette.text.secondary,
              "& svg": {
                color: theme.palette.text.secondary,
                fill: theme.palette.text.secondary,
              },
            },
          }),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          fontSize: "14px",
          lineHeight: "150%" /* 21px */,
          letterSpacing: "0.15px",
          minHeight: 0,
          "&.Mui-focusVisible": {
            backgroundColor: theme.palette.primary.hover,
          },
          "& .MuiListItemIcon-root": {
            minWidth: "0",
            paddingRight: theme.spacing(3),
            color: "#4C505C",
            "& svg": { width: "20px", height: "20px" },
          },
          "& .MuiTypography-root": {
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(3),
          },
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          },
        },
        dense: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        // Empty value works as placeholder, since MUI doesn't support placeholder in select https://github.com/mui/material-ui/issues/11069#issuecomment-383047365
        SelectProps: {
          displayEmpty: true,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& .MuiFormHelperText-root": {
            margin: "8px 0 0 0",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "166%" /* 19.92px */,
            letterSpacing: "0.4px",
          },
          "& .MuiSelect-select": {
            fontSize: 14,
            padding: "8px 12px",
            ...(ownerState.size === "medium" && {
              paddingTop: "16px",
              paddingBottom: "16px",
            }),
            ...(!ownerState.value && {
              color: theme.palette.text.disabled,
            }),
          },
          // Style overrides for start and end adornments
          "& .MuiInputAdornment-root": {
            "& svg": {
              height: "20px",
              width: "20px",
            },
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        displayEmpty: true,
        IconComponent: (props) => <CaretDown fontSize={"20px"} {...props} />,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          "&& .MuiSelect-icon, && .MuiNativeSelect-icon": {
            width: "20px",
            height: "20px",
            right: "12px",
          },
          "& .MuiSelect-select": {
            fontSize: 14,
            padding: "8px 12px",
            ...(ownerState.size === "medium" && {
              paddingTop: "16px",
              paddingBottom: "16px",
            }),
            ...(!ownerState.value && {
              color: theme.palette.text.disabled,
            }),
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#fff",
          "& svg": {
            width: "20px",
            height: "20px",
          },
          "& input": {
            padding: "16px 12px",
            fontSize: "16px",
            lineHeight: "150%" /* 24px */,
            letterSpacing: "0.15px",
            minHeight: "24px",
          },
          "&.MuiInputBase-sizeSmall, .MuiInputBase-multiline": {
            padding: 0,
            "& input, textarea": {
              padding: "8px 12px",
              fontSize: "14px",
              lineHeight: "22px" /* 157.143% */,
              letterSpacing: "0.28px",
            },
          },
          "&.MuiInputBase-adornedEnd": {
            paddingRight: "12px",
            "& input": {
              paddingRight: "0px",
            },
          },
          "&.MuiInputBase-adornedStart": {
            paddingLeft: "12px",
            "& input": {
              paddingLeft: "0px",
            },
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& .MuiTextField-root": {
            ...(ownerState.fullWidth && {
              width: "100%",
            }),
          },
          "&:has(.MuiSelect-select), &:has(.MuiNativeSelect-select)": {
            ...(ownerState.fullWidth && {
              width: "100%",
            }),
            "& .MuiSelect-select": {
              paddingLeft: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
              ...(ownerState.size === "medium" && {
                paddingTop: "16px",
                paddingBottom: "16px",
              }),
            },
            "&& .MuiSelect-icon, && .MuiNativeSelect-icon": {
              width: "20px",
              height: "20px",
              right: "12px",
            },
          },
          "& .MuiFormHelperText-root": {
            margin: "8px 0 0 0",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "166%" /* 19.92px */,
            letterSpacing: "0.4px",
          },
          "& label": {
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "14px",
            letterSpacing: "0.3px",
            marginBottom: "8px",
            position: "relative",
            transform: "none",
            "&.Mui-focused:not(.Mui-error)": {
              color: theme.palette.text.secondary,
            },
          },
          "& .MuiFormGroup-root:has(.MuiCheckbox-root)": {
            gap: "8px",
            alignItems: "flex-start",
          },
          "&:has(.MuiRadio-root) label, &:has(.MuiRadio-root) .MuiFormHelperText-root": {
            margin: 0,
          },
          '& .MuiFormGroup-root[role="radiogroup"]': {
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              lineHeight: "22px" /* 157.143% */,
              letterSpacing: "0.28px",
            },
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "#5B6172",
          fontSize: "12px",
          fontStyle: "normal",
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: (props) => <Calendar fontSize={"20px"} {...props} />,
        },
        slotProps: {
          inputBase: {
            size: "small",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "40px",
          alignItems: "center",
          lineHeight: "22px",
          borderRadius: "6px",
          "& fieldset": {
            border: `1px solid ${theme.palette.divider}`,
            "& legend": {
              maxWidth: "0px",
            },
          },
          "&& .MuiTypography-root": {
            fontSize: "14px",
            lineHeight: "22px",
          },
          "&:hover:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `2px solid inherit`,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.tooltip.fill,
          color: "#FFFFFF",
          fontSize: "10px",
          lineHeight: "14px" /* 140% */,
          "& .MuiTooltip-arrow": {
            color: theme.palette.tooltip.fill,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <span className="CustomCheckBoxIcon" />,
        checkedIcon: (
          <span className="CustomCheckBoxIcon CustomCheck">
            <Check size={14} weight="bold" />
          </span>
        ),
        indeterminateIcon: (
          <span className="CustomCheckBoxIcon CustomCheck">
            <Minus size={14} weight="bold" />
          </span>
        ),
      },
      styleOverrides: {
        root: {
          padding: "0px 8px 0px 0px",
          "&:hover": {
            "& .CustomCheckBoxIcon": {
              backgroundColor: theme.palette.action.hover,
            },
          },
          "& .CustomCheckBoxIcon": {
            width: "20px",
            height: "20px",
            borderRadius: "6px",
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&.CustomCheck": {
              backgroundColor: theme.palette.primary.main,
              border: "none",
              color: theme.palette.primary.contrast,
            },
          },
          "&.Mui-disabled": {
            "& .CustomCheckBoxIcon, & .CustomCheckBoxIcon.CustomCheck": {
              backgroundColor: theme.palette.action.disabledBackground,
              color: theme.palette.action.disabled,
              border: `1px solid ${theme.palette.action.disabled}`,
            },
          },
          "~ .MuiFormControlLabel-label": {
            fontSize: "14px",
            lineHeight: "143%" /* 20.02px */,
            letterSpacing: "0.17px",
          },
          "&.Mui-focusVisible .CustomCheckBoxIcon": {
            outline: `2px solid ${theme.palette.primary.focus}`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&:has(.MuiCheckbox-root), &:has(.MuiRadio-root)": {
            margin: "0px",
          },
          "&:has(.Mui-focusVisible .CustomCheckBoxIcon)": {
            outline: `2px solid ${theme.palette.primary.focus}`,
            borderRadius: "6px",
            "& > .MuiCheckbox-root.Mui-focusVisible": {
              "& .CustomCheckBoxIcon": {
                outline: "none",
              },
            },
          },
          "& .Mui-disabled + .MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
          },
          "&:hover .CustomRadioButtonIcon": {
            backgroundColor: theme.palette.action.hover,
          },
          "&:focus, &:active, &:focus-within": {
            "& .CustomRadioButtonIcon": {
              outline: `2px solid ${theme.palette.primary.focusVisible}`,
            },
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        component: "div",
      },
      variants: [
        {
          props: { variant: "body2Secondary" },
          style: {
            color: "#5b6172",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "143%",
            letterSpacing: "0.17px",
          },
        },
        {
          props: { variant: "subtitle2Success" },
          style: {
            color: "#2E7D32",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            // lineHeight: "157%",
          },
        },
        {
          props: { variant: "subtitle2Error" },
          style: {
            color: "#D32F2F",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            // lineHeight: "157%",
          },
        },
        {
          props: { variant: "captionSecondary" },
          style: {
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: "21px",
            color: "#5b6172",
          },
        },
      ],
    },
    MuiSnackbar: {
      defaultProps: {
        elevation: 1,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        sx: {
          padding: "6px 16px",
          backgroundColor: "#121212",
          color: "var(--inherit-white-main, #FFF)",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Basier Circle",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "& span": {
            padding: "3px 10px",
            lineHeight: "18px" /* 138.462% */,
            letterSpacing: "0.16px",
            textTransform: "capitalize",
          },
          "& MuiChip-sizeMedium span": {
            paddingTop: "7px",
            paddingBottom: "7px",
          },
          "&.MuiChip-clickable": {
            "&.MuiChip-filledDefault:hover ": {
              backgroundColor: "#0000001F",
            },
          },
          "&.MuiChip-outlinedDefault": {
            backgroundColor: "#F6F7F8",
          },
          "&.MuiChip-outlinedPrimary": {
            backgroundColor: theme.palette.primary.background,
          },
          "&.MuiChip-outlinedError": {
            backgroundColor: theme.palette.error.background,
          },
          "&.MuiChip-outlinedWarning": {
            backgroundColor: theme.palette.warning.background,
          },
          "&.MuiChip-outlinedInfo": {
            backgroundColor: theme.palette.info.background,
          },
          "&.MuiChip-outlinedSuccess": {
            backgroundColor: theme.palette.success.background,
          },
          "&.MuiChip-colorError": {
            "& .MuiChip-avatar": {
              backgroundColor: theme.palette.error.dark,
              color: theme.palette.error.contrast,
            },
          },
          "&.MuiChip-colorWarning": {
            "& .MuiChip-avatar": {
              backgroundColor: theme.palette.warning.dark,
              color: theme.palette.warning.contrast,
            },
          },
          "&.MuiChip-colorInfo": {
            "& .MuiChip-avatar": {
              backgroundColor: theme.palette.info.dark,
              color: theme.palette.info.contrast,
            },
          },
          "&.MuiChip-colorSuccess": {
            "& .MuiChip-avatar": {
              backgroundColor: theme.palette.success.dark,
              color: theme.palette.success.contrast,
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            backgroundColor: "#F6F7F8",
            color: theme.palette.text.primary,
            fontSize: "14px",
            "& .MuiTableSortLabel-root": {
              "& .MuiTableSortLabel-icon": {
                color: theme.palette.action.active,
              },
            },
          },
          "& .MuiTableBody-root .MuiTableCell-root": {
            borderColor: theme.palette.divider,
          },
          "& .MuiTableCell-footer": { borderStyle: "none" },
          ...tableDatagridCommonStyles,
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        slotProps: {
          select: {
            variant: "outlined",
            size: "small",
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        slotProps: {
          pagination: {
            ActionsComponent: ({ className, count, page, rowsPerPage, onPageChange }) => (
              <div className={className}>
                <Tooltip title="First page">
                  <IconButton
                    onClick={(event) => onPageChange(event, 0)}
                    disabled={page === 0}
                    aria-label="First Page"
                  >
                    {theme.direction === "rtl" ? <CaretDoubleRight /> : <CaretDoubleLeft />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Previous Page">
                  <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="Previous Page"
                  >
                    {theme.direction === "rtl" ? <CaretRight /> : <CaretLeft />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Next Page">
                  <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                  >
                    {theme.direction === "rtl" ? <CaretLeft /> : <CaretRight />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Last Page">
                  <IconButton
                    onClick={(event) =>
                      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
                    }
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                  >
                    {theme.direction === "rtl" ? <CaretDoubleLeft /> : <CaretDoubleRight />}
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderStyle: "none",
          // !important is added to override the inline style added by the component
          "&.MuiDataGrid-root--densityStandard": {
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#F6F7F8",
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            },
            "& .MuiDataGrid-cell": {
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            },
            "& .MuiDataGrid-cellCheckbox": {
              minWidth: "0px !important",
              paddingLeft: "4px",
              paddingRight: 0,
            },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              minWidth: "0px !important",
              width: "auto !important",
              paddingLeft: "4px",
              paddingRight: 0,
            },
          },
          "&.MuiDataGrid-root--densityCompact": {
            "& .MuiDataGrid-cellCheckbox": {
              minWidth: "0px !important",
              paddingLeft: "16px",
            },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              minWidth: "0px !important",
              width: "auto !important",
              paddingLeft: "16px",
            },
          },
          "&& .MuiDataGrid-cellCheckbox ~ div": {
            paddingLeft: 0,
          },
          "&& .MuiDataGrid-columnHeaderCheckbox ~ div": {
            paddingLeft: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F6F7F8",
            color: theme.palette.text.primary,
            fontSize: "14px",
            borderRadius: 0,
          },
          "& .MuiDataGrid-root--densityStandard": {},
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-row .MuiDataGrid-withBorderColor": {
            borderColor: theme.palette.divider,
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
            color: theme.palette.action.active,
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeaderCheckbox": {
            fontWeight: 500,
          },
          ...tableDatagridCommonStyles,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& svg": {
            width: "24px",
            height: "24px",
          },
          "& svg ~ .MuiBadge-badge:not(.MuiBadge-colorPrimary):not(.MuiBadge-colorSecondary):not(.MuiBadge-colorError):not(.MuiBadge-colorWarning):not(.MuiBadge-colorInfo):not(.MuiBadge-colorSuccess)":
            {
              color: theme.palette.action.active,
            },
          "& svg ~ .MuiBadge-badge": {
            lineHeight: "20px" /* 166.667% */,
            letterSpacing: "0.14px",
            top: "4px",
            right: "4px",
          },
          "& svg ~ .MuiBadge-dot": {
            top: "-2px",
            right: "-1px",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        icon: (
          <span className="CustomRadioButtonIcon">
            <RadioCheckmark stroke={theme.palette.primary.main} />
          </span>
        ),
        checkedIcon: (
          <span className="CustomRadioButtonIcon CustomChecked">
            <RadioCheckmark stroke={theme.palette.primary.main} />
          </span>
        ),
      },
      styleOverrides: {
        root: {
          "&:hover .CustomRadioButtonIcon": {
            backgroundColor: theme.palette.action.hover,
          },
          "& .CustomRadioButtonIcon": {
            width: 20,
            height: 20,
            backgroundColor: "#fff",
            borderRadius: "50%",
            border: `1px solid ${theme.palette.radio.border}`,
            // Antialiasing trick
            boxShadow: `0 0 1px 0 ${theme.palette.radio.border} inset, 0 0 1px 0 ${theme.palette.radio.border}`,
            transition:
              "background-color 150ms ease-in-out, outline 300ms cubic-bezier(0.4, 0, 1, 1) 0ms",
            outline: `2px solid transparent`,
            position: "relative",
            overflow: "hidden",
            // Bullet Checkmark
            "& svg": {
              position: "absolute",
              left: "50%",
              top: "50%",
              margin: "-12px 0 0 -12px",
              transition: `transform 200ms cubic-bezier(0.4, 0, 1, 1) 0ms`,
              transform: `scale(0)`,
            },
          },
          "&.Mui-checked .CustomChecked": {
            border: `1px solid ${theme.palette.primary.main}`,
            "& svg": {
              transform: "scale(1)",
            },
          },
          "&.MuiRadio-sizeLarge .CustomRadioButtonIcon": {
            width: 24,
            height: 24,
          },
          "&.MuiRadio-sizeSmall .CustomRadioButtonIcon": {
            width: 18,
            height: 18,
          },
          "&.Mui-disabled": {
            "& .CustomRadioButtonIcon": {
              color: theme.palette.action.disabled,
              border: `1px solid ${theme.palette.action.disabled}`,
            },
            "& .MuiFormControlLabel-label": {
              color: theme.palette.action.disabled,
            },
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 1100,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "& .MuiDialogContent-root": {
            padding: theme.spacing(7),
          },
          "& .MuiDialogActions-root": {
            padding: theme.spacing(5, 7),
            "& .MuiButton-root": {
              variant: "text",
            },
          },
          "& .close-button": {
            position: "absolute",
            right: theme.spacing(7),
            top: "10px",
            "& svg": {
              width: "20px",
              height: "20px",
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h4",
      },
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& svg": {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircle />,
          error: <WarningCircle />,
          info: <Info />,
          warning: <Warning />,
        },
      },
      styleOverrides: {
        message: {
          display: "flex",
          gap: theme.spacing(1),
          flexDirection: "column",
          "& .MuiAlertTitle-root": {
            margin: theme.spacing(0),
          },
        },
        action: {
          "& .MuiIconButton-root": {
            padding: theme.spacing(2),
            "& svg": {
              width: "20px",
              height: "20px",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "& .MuiAccordionSummary-content": {
            margin: 0,
            alignItems: "center",
            gap: theme.spacing(5),
            "& .MuiTypography-root": {
              fontWeight: 500,
              lineHeight: "175%" /* 28px */,
              "&:nth-of-type(2)": {
                color: theme.palette.text.secondary,
                letterSpacing: "0.17px",
                lineHeight: "143%" /* 20.02px */,
                fontWeight: 400,
                fontSize: "14px",
              },
            },
          },
          "& .MuiAccordionSummary-expandIconWrapper svg": {
            fontSize: "20px",
            margin: theme.spacing(2),
          },
          "& .MuiAccordionDetails-root": {
            padding: theme.spacing(3, 5, 5),
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <CaretDown fontSize={"24px"} />,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 5),
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: "medium",
      },
      styleOverrides: {
        root: {
          position: "relative",
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: theme.radius.large,
          "& svg": {
            color: theme.palette.text.secondary,
          },
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          "&.Mui-disabled": {
            border: `1px solid ${theme.palette.divider}`,
            "& svg": {
              color: theme.palette.action.disabled,
            },
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            "& svg": {
              color: theme.palette.text.primary,
            },
          },
        },
        sizeLarge: {
          padding: theme.spacing(5),
        },
        sizeMedium: {
          padding: theme.spacing(4),
        },
        sizeSmall: {
          padding: theme.spacing(3),
          "& svg": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: theme.radius.large,
          "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderLeft: `1px solid ${theme.palette.divider}`,
          },
          "& .MuiToggleButton-root": {
            boxShadow: "none",
          },
          "& .Mui-disabled": {},
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: "14px",
            lineHeight: "143%" /* 20.02px */,
            letterSpacing: "0.17px",
          },
          "& .MuiTimelineConnector-root": {
            width: "1px",
            backgroundColor: theme.palette.action.disabled,
            height: "24px",
          },
          "& .MuiTimelineItem-root": {
            minHeight: "auto",
          },
          "& .MuiTimelineDot-root": {
            backgroundColor: "#4C505C",
            boxShadow: "none",
            padding: "2px",
          },
          "& .MuiTimelineDot-outlined": {
            backgroundColor: "transparent",
            borderColor: "#4C505C",
          },
          "& .MuiTimelineSeparator-root": {
            padding: "0px 2px",
          },
          "& svg": {
            width: "20px",
            height: "20px",
            fill: "#4C505C",
            margin: "6px auto",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        variant: "body2",
        tabIndex: 0,
      },
      styleOverrides: {
        root: {
          textUnderlineOffset: 0,
          "&.Mui-focusVisible": {
            outlineColor: theme.palette.primary.main,
            outlineOffset: "2px",
          },
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation2: {
          boxShadow: `0px 0px 0px 1px rgba(0, 0, 0, 0.06),
            0px 1px 1px -0.5px rgba(0, 0, 0, 0.06),
            0px 3px 3px -1.5px rgba(0, 0, 0, 0.06),
            0px 6px 6px -3px rgba(0, 0, 0, 0.06),
            0px 12px 12px -6px rgba(0, 0, 0, 0.06),
            0px 24px 24px -12px rgba(0, 0, 0, 0.06)`,
        },
        elevation3: {
          boxShadow: `0px 0px 0px 1px rgba(0, 0, 0, 0.05), 
            0px 1px 1px 0px rgba(0, 0, 0, 0.05),
            0px 2px 2px 0px rgba(0, 0, 0, 0.05),
            0px 4px 4px 0px rgba(0, 0, 0, 0.05),
            0px 8px 8px 0px rgba(0, 0, 0, 0.05),
            0px 16px 16px 0px rgba(0, 0, 0, 0.05)`,
        },
        rounded: {
          borderRadius: theme.radius.large,
        },
      },
    },
  },
});

export default theme;
