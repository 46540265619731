import React from "react";
import Typography from "@mui/material/Typography";
import Modal from "elements/Modal";

const HealthieEditConfirmDialog = (props) => {
  const { healthieEditConfimOpen, onToggleHealthieEditConfirmDialog, goToHealthie } = props;
  return (
    <Modal
      open={healthieEditConfimOpen}
      title="Navigate to Healthie?"
      content={
        <div
          style={{
            width: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography component="p" style={{ margin: "20px 0" }}>
            This will open a new tab with the client's info available to edit in Healthie.
          </Typography>
        </div>
      }
      primaryActionText="Go to Healthie"
      primaryActionOnClick={goToHealthie}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleHealthieEditConfirmDialog}
    />
  );
};

export default HealthieEditConfirmDialog;
