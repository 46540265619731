const ACTIONS = {
  GET_VIDEO_IS_RUNNING: "GET_VIDEO_IS_RUNNING",
  GET_VIDEO_IS_RUNNING_SUCCESS: "GET_VIDEO_IS_RUNNING_SUCCESS",
  SET_VIDEO_ROOM_ID: "SET_VIDEO_ROOM_ID",
  GET_BCBA_AVAILABILITY: "GET_BCBA_AVAILABILITY",
  GET_BCBA_AVAILABILITY_SUCCESS: "GET_BCBA_AVAILABILITY_SUCCESS",
  CLEAR_BCBA_AVAILABILITY: "CLEAR_BCBA_AVAILABILITY",
  SEND_VIDEO_SIGNATURES: "SEND_VIDEO_SIGNATURES",
  SEND_VIDEO_SIGNATURES_SUCCESS: "SEND_VIDEO_SIGNATURES_SUCCESS",
  SEND_VIDEO_SIGNATURES_FAIL: "SEND_VIDEO_SIGNATURES_FAIL",
  SEND_PARENT_VIDEO_SIGNATURES: "SEND_PARENT_VIDEO_SIGNATURES",
  SEND_PARENT_VIDEO_SIGNATURES_SUCCESS: "SEND_PARENT_VIDEO_SIGNATURES_SUCCESS",
  SEND_PARENT_VIDEO_SIGNATURES_FAIL: "SEND_PARENT_VIDEO_SIGNATURES_FAIL",
  GET_VIDEO_CALL_INFO: "GET_VIDEO_CALL_INFO",
  GET_VIDEO_CALL_INFO_SUCCESS: "GET_VIDEO_CALL_INFO_SUCCESS",
  GET_VIDEO_CALL_INFO_FAIL: "GET_VIDEO_CALL_INFO_FAIL",
  SCHEDULE_VIDEO_CALL: "SCHEDULE_VIDEO_CALL",
  SCHEDULE_VIDEO_CALL_SUCCESS: "SCHEDULE_VIDEO_CALL_SUCCESS",
  SCHEDULE_VIDEO_CALL_FAIL: "SCHEDULE_VIDEO_CALL_FAIL",
  SCHEDULE_VIDEO_CALL_RESET_STATE: "SCHEDULE_VIDEO_CALL_RESET_STATE",

  START_VIDEO_CALL: "START_VIDEO_CALL",
  START_VIDEO_CALL_SUCCESS: "START_VIDEO_CALL_SUCCESS",
  END_VIDEO_CALL: "END_VIDEO_CALL",
  GET_UPCOMING_VIDEO: "GET_UPCOMING_VIDEO",
  GET_UPCOMING_VIDEO_SUCCESS: "GET_UPCOMING_VIDEO_SUCCESS",
  SET_ONE_TIME_VIDEO_INFO: "SET_ONE_TIME_VIDEO_INFO",
  SET_ONE_TIME_VIDEO_INFO_SUCCESS: "SET_ONE_TIME_VIDEO_INFO_SUCCESS",
  CLEAR_ONE_TIME_VIDEO_INFO: "CLEAR_ONE_TIME_VIDEO_INFO",
  UPDATE_ROOM_LAYOUT: "UPDATE_ROOM_LAYOUT",
  UPDATE_ROOM_LAYOUT_SUCCESS: "UPDATE_ROOM_LAYOUT_SUCCESS",
  UPDATE_ROOM_LAYOUT_FAIL: "UPDATE_ROOM_LAYOUT_FAIL",
  GET_ANIMATIONS: "GET_ANIMATIONS",
  GET_ANIMATIONS_SUCCESS: "GET_ANIMATIONS_SUCCESS",
  GET_ACTIVITIES: "GET_ACTIVITIES",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_CARDS_ACTIVITY: "GET_CARDS_ACTIVITY",
  GET_CARDS_ACTIVITY_SUCCESS: "GET_CARDS_ACTIVITY_SUCCESS",
  END_VIDEO_CALL_SUCCESS: "END_VIDEO_CALL_SUCCESS",
  RESCHEDULE_VIDEO_CALL: "RESCHEDULE_VIDEO_CALL",
  RESCHEDULE_VIDEO_CALL_SUCCESS: "RESCHEDULE_VIDEO_CALL_SUCCESS",
  RESCHEDULE_VIDEO_CALL_FAIL: "RESCHEDULE_VIDEO_CALL_FAIL",
  CANCEL_VIDEO_CALL: "CANCEL_VIDEO_CALL",
  CANCEL_VIDEO_CALL_SUCCESS: "CANCEL_VIDEO_CALL_SUCCESS",

  SET_WEEK_START_DATE: "SET_WEEK_START_DATE",
  SET_WEEK_END_DATE: "SET_WEEK_END_DATE",
  SET_SHOW_WEEK_CANCELED_CALLS: "SET_SHOW_WEEK_CANCELED_CALLS",
  GET_CALENDAR_SESSIONS: "GET_CALENDAR_SESSIONS",
  GET_VIDEO_CALLS_LIST: "GET_VIDEO_CALLS_LIST",
  GET_VIDEO_CALLS_FUTURE: "GET_VIDEO_CALLS_FUTURE",
  GET_VIDEO_CALLS_PAST: "GET_VIDEO_CALLS_PAST",
  SET_VIDEO_CALLS_LIST: "SET_VIDEO_CALLS_LIST",
  SET_VIDEO_CALLS_PAGE: "SET_VIDEO_CALLS_PAGE",
  SET_VIDEO_CALLS_PER_PAGE: "SET_VIDEO_CALLS_PER_PAGE",
  SET_VIDEO_CALLS_SORT: "SET_VIDEO_CALLS_SORT",
  SET_VIDEO_CALLS_TYPE: "SET_VIDEO_CALLS_TYPE",
  SET_VIDEO_FILTER_START_DATE: "SET_VIDEO_FILTER_START_DATE",
  SET_VIDEO_FILTER_END_DATE: "SET_VIDEO_FILTER_END_DATE",
  SET_CALENDAR_SESSIONS_LIST: "SET_CALENDAR_SESSIONS_LIST",

  GET_VIDEO_CHAT_INFO: "GET_VIDEO_CHAT_INFO",
  GET_VIDEO_CHAT_INFO_SUCCESS: "GET_VIDEO_CHAT_INFO_SUCCESS",
  GET_VIDEO_CHAT_INFO_FAIL: "GET_VIDEO_CHAT_INFO_FAIL",

  GET_VIDEO_CALL_BREAKDOWN: "GET_VIDEO_CALL_BREAKDOWN",
  GET_VIDEO_CALL_BREAKDOWN_SUCCESS: "GET_VIDEO_CALL_BREAKDOWN_SUCCESS",
  SEND_BILLABLE_TIME_FOR_VIDEO: "SEND_BILLABLE_TIME_FOR_VIDEO",
  SEND_BILLABLE_TIME_FOR_VIDEO_SUCCESS: "SEND_BILLABLE_TIME_FOR_VIDEO_SUCCESS",
  SEND_BILLABLE_TIME_FOR_VIDEO_FAIL: "SEND_BILLABLE_TIME_FOR_VIDEO_FAIL",

  GET_USER_VIDEO_CALLS: "GET_USER_VIDEO_CALLS",
  GET_USER_VIDEO_CALLS_SUCCESS: "GET_USER_VIDEO_CALLS_SUCCESS",
  GET_USER_VIDEO_CALLS_FAIL: "GET_USER_VIDEO_CALLS_FAIL",
  SET_USER_VIDEO_CALLS_PAGE: "SET_USER_VIDEO_CALLS_PAGE",
  SET_USER_VIDEO_CALLS_PER_PAGE: "SET_USER_VIDEO_CALLS_PER_PAGE",
  SET_USER_VIDEO_CALLS_SORT: "SET_USER_VIDEO_CALLS_SORT",
  SET_USER_VIDEO_CALLS_TYPE: "SET_USER_VIDEO_CALLS_TYPE",
  SET_USER_VIDEO_FILTER_START_DATE: "SET_USER_VIDEO_FILTER_START_DATE",
  SET_USER_VIDEO_FILTER_END_DATE: "SET_USER_VIDEO_FILTER_END_DATE",

  MARK_CLIENT_NO_SHOW: "MARK_CLIENT_NO_SHOW",

  GET_NEXT_CLINICIAN_CALL: "GET_NEXT_CLINICIAN_CALL",
  GET_NEXT_CLINICIAN_CALL_SUCCESS: "GET_NEXT_CLINICIAN_CALL_SUCCESS",
  GET_NEXT_CLINICIAN_CALL_FAIL: "GET_NEXT_CLINICIAN_CALL_FAIL",

  GET_CLINICIAN_CALLS_WEEK: "GET_CLINICIAN_CALLS_WEEK",
  GET_CLINICIAN_CALLS_WEEK_SUCCESS: "GET_CLINICIAN_CALLS_WEEK_SUCCESS",
  GET_CLINICIAN_CALLS_WEEK_FAIL: "GET_CLINICIAN_CALLS_WEEK_FAIL",

  GET_CLINICIAN_CALENDAR_CALLS: "GET_CLINICIAN_CALENDAR_CALLS",
  GET_CLINICIAN_CALENDAR_CALLS_SUCCESS: "GET_CLINICIAN_CALENDAR_CALLS_SUCCESS",

  GET_CLINICIAN_PAST_CALLS: "GET_CLINICIAN_PAST_CALLS",
  GET_CLINICIAN_PAST_CALLS_SUCCESS: "GET_CLINICIAN_PAST_CALLS_SUCCESS",
  GET_CLINICIAN_UPCOMING_CALLS: "GET_CLINICIAN_UPCOMING_CALLS",
  GET_CLINICIAN_UPCOMING_CALLS_SUCCESS: "GET_CLINICIAN_UPCOMING_CALLS_SUCCESS",
  SET_CLINICIAN_PAST_CALLS_SORT: "SET_CLINICIAN_PAST_CALLS_SORT",
  SET_CLINICIAN_UPCOMING_CALLS_SORT: "SET_CLINICIAN_UPCOMING_CALLS_SORT",

  CHECK_VIDEO_CALL_ACCESS: "CHECK_VIDEO_CALL_ACCESS",
  CHECK_VIDEO_CALL_ACCESS_SUCCESS: "CHECK_VIDEO_CALL_ACCESS_SUCCESS",
  CHECK_VIDEO_CALL_ACCESS_FAIL: "CHECK_VIDEO_CALL_ACCESS_FAIL",
  SECONDARY_CLINICIAN_JOIN_CALL: "SECONDARY_CLINICIAN_JOIN_CALL",
  SECONDARY_CLINICIAN_JOIN_CALL_SUCCESS: "SECONDARY_CLINICIAN_JOIN_CALL_SUCCESS",
  SECONDARY_CLINICIAN_LEAVE_CALL: "SECONDARY_CLINICIAN_LEAVE_CALL",
  SECONDARY_CLINICIAN_LEAVE_CALL_SUCCESS: "SECONDARY_CLINICIAN_LEAVE_CALL_SUCCESS",

  SET_SECONDARY_CLINICIAN_SESSION_TIMES: "SET_SECONDARY_CLINICIAN_SESSION_TIMES",

  GET_VIDEO_ROOM_STATE: "GET_VIDEO_ROOM_STATE",
  GET_VIDEO_ROOM_STATE_SUCCESS: "GET_VIDEO_ROOM_STATE_SUCCESS",
  GET_VIDEO_ROOM_STATE_FAIL: "GET_VIDEO_ROOM_STATE_FAIL",

  GET_CUSTOMER_UPCOMING_CALLS: "GET_CUSTOMER_UPCOMING_CALLS",
  GET_CUSTOMER_UPCOMING_CALLS_SUCCESS: "GET_CUSTOMER_UPCOMING_CALLS_SUCCESS",
  CLEAR_VIDEO_INFO: "CLEAR_VIDEO_INFO",

  GET_VIDEO_CALL_BILLING_ISSUES: "GET_VIDEO_CALL_BILLING_ISSUES",
  GET_VIDEO_CALL_BILLING_ISSUES_SUCCESS: "GET_VIDEO_CALL_BILLING_ISSUES_SUCCESS",
  GET_VIDEO_CALL_BILLING_ISSUES_FAIL: "GET_VIDEO_CALL_BILLING_ISSUES_FAIL",

  SET_DEMO_CALL: "SET_DEMO_CALL",
  SET_DEMO_CALL_SUCCESS: "SET_DEMO_CALL_SUCCESS",
  SET_DEMO_CALL_FAIL: "SET_DEMO_CALL_FAIL",

  GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS: "GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS",
  GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS_SUCCESS: "GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS_SUCCESS",

  GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS: "GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS",
  GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_SUCCESS:
    "GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_SUCCESS",
  GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_FAIL:
    "GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_FAIL",
  COMPLETE_OFF_PLATFORM_CALL: "COMPLETE_OFF_PLATFORM_CALL",
  COMPLETE_OFF_PLATFORM_CALL_SUCCESS: "COMPLETE_OFF_PLATFORM_CALL_SUCCESS",
  COMPLETE_OFF_PLATFORM_CALL_FAIL: "COMPLETE_OFF_PLATFORM_CALL_FAIL",

  CLEAR_MEETING_CONFIG: "CLEAR_MEETING_CONFIG",
  FETCH_MEETING_CONFIG: "FETCH_MEETING_CONFIG",
  FETCH_MEETING_CONFIG_ERROR: "FETCH_MEETING_CONFIG_ERROR",
  FETCH_MEETING_CONFIG_SUCCESS: "FETCH_MEETING_CONFIG_SUCCESS",

  FETCH_MESSAGING_CONFIG: "FETCH_MESSAGING_CONFIG",
  FETCH_MESSAGING_CONFIG_ERROR: "FETCH_MESSAGING_CONFIG_ERROR",
  FETCH_MESSAGING_CONFIG_SUCCESS: "FETCH_MESSAGING_CONFIG_SUCCESS",
};

const ACTION_CREATORS = {
  clearMeetingConfig() {
    return {
      type: ACTIONS.CLEAR_MEETING_CONFIG,
      payload: null,
    };
  },
  fetchMeetingConfig(videoId) {
    return {
      type: ACTIONS.FETCH_MEETING_CONFIG,
      payload: [videoId],
    };
  },
  fetchMeetingConfigError(error) {
    return {
      type: ACTIONS.FETCH_MEETING_CONFIG_ERROR,
      payload: error,
    };
  },
  fetchMeetingConfigSuccess(payload) {
    return {
      type: ACTIONS.FETCH_MEETING_CONFIG_SUCCESS,
      payload,
    };
  },
  fetchMessagingConfig(videoId) {
    return {
      type: ACTIONS.FETCH_MESSAGING_CONFIG,
      payload: [videoId],
    };
  },
  fetchMessagingConfigError(error) {
    return {
      type: ACTIONS.FETCH_MESSAGING_CONFIG_ERROR,
      payload: error,
    };
  },
  fetchMessagingConfigSuccess(payload) {
    return {
      type: ACTIONS.FETCH_MESSAGING_CONFIG_SUCCESS,
      payload,
    };
  },
  startVideoCall(videoDetails) {
    return {
      type: ACTIONS.START_VIDEO_CALL,
      payload: videoDetails,
    };
  },
  startVideoCallSuccess(videoId) {
    return {
      type: ACTIONS.START_VIDEO_CALL_SUCCESS,
      payload: videoId,
    };
  },
  endVideoCall(videoId) {
    return {
      type: ACTIONS.END_VIDEO_CALL,
      payload: videoId,
    };
  },
  endVideoCallSuccess() {
    return {
      type: ACTIONS.END_VIDEO_CALL_SUCCESS,
    };
  },
  setVideoRoomId(videoData) {
    return {
      type: ACTIONS.SET_VIDEO_ROOM_ID,
      payload: videoData,
    };
  },
  getUpcomingVideo(clinicianUserId) {
    return {
      type: ACTIONS.GET_UPCOMING_VIDEO,
      payload: clinicianUserId,
    };
  },
  getUpcomingVideoSuccess(upcomingVideo) {
    return {
      type: ACTIONS.GET_UPCOMING_VIDEO_SUCCESS,
      payload: upcomingVideo,
    };
  },
  setOneTimeVideoInfo(details) {
    return {
      type: ACTIONS.SET_ONE_TIME_VIDEO_INFO,
      payload: details,
    };
  },
  setOneTimeVideoInfoSuccess(details) {
    return {
      type: ACTIONS.SET_ONE_TIME_VIDEO_INFO_SUCCESS,
      payload: details,
    };
  },
  clearOneTimeVideoInfo() {
    return {
      type: ACTIONS.CLEAR_ONE_TIME_VIDEO_INFO,
    };
  },
  getVideoIsRunning(roomId) {
    return {
      type: ACTIONS.GET_VIDEO_IS_RUNNING,
      payload: roomId,
    };
  },
  getVideoIsRunningSuccess(isRunning) {
    return {
      type: ACTIONS.GET_VIDEO_IS_RUNNING_SUCCESS,
      payload: isRunning,
    };
  },
  updateVideoRoomLayout(stateObject) {
    return {
      type: ACTIONS.UPDATE_ROOM_LAYOUT,
      payload: stateObject,
    };
  },
  updateVideoRoomLayoutSuccess() {
    return {
      type: ACTIONS.UPDATE_ROOM_LAYOUT_SUCCESS,
    };
  },
  updateVideoRoomLayoutFail() {
    return {
      type: ACTIONS.UPDATE_ROOM_LAYOUT_FAIL,
    };
  },
  getAnimations() {
    return {
      type: ACTIONS.GET_ANIMATIONS,
    };
  },
  getAnimationsSuccess(payload) {
    return {
      type: ACTIONS.GET_ANIMATIONS_SUCCESS,
      payload,
    };
  },
  getActivities() {
    return {
      type: ACTIONS.GET_ACTIVITIES,
    };
  },
  getActivitiesSuccess(payload) {
    return {
      type: ACTIONS.GET_ACTIVITIES_SUCCESS,
      payload,
    };
  },
  getCardsActivity() {
    return {
      type: ACTIONS.GET_CARDS_ACTIVITY,
    };
  },
  getCardsActivitySuccess(payload) {
    return {
      type: ACTIONS.GET_CARDS_ACTIVITY_SUCCESS,
      payload,
    };
  },
  sendVideoSignatures(signatures) {
    return {
      type: ACTIONS.SEND_VIDEO_SIGNATURES,
      payload: signatures,
    };
  },
  sendVideoSignaturesSuccess() {
    return {
      type: ACTIONS.SEND_VIDEO_SIGNATURES_SUCCESS,
    };
  },
  sendVideoSignaturesFail() {
    return {
      type: ACTIONS.SEND_VIDEO_SIGNATURES_FAIL,
    };
  },
  sendParentVideoSignatures(signatures) {
    return {
      type: ACTIONS.SEND_PARENT_VIDEO_SIGNATURES,
      payload: signatures,
    };
  },
  sendParentVideoSignaturesSuccess() {
    return {
      type: ACTIONS.SEND_PARENT_VIDEO_SIGNATURES_SUCCESS,
    };
  },
  sendParentVideoSignaturesFail() {
    return {
      type: ACTIONS.SEND_PARENT_VIDEO_SIGNATURES_FAIL,
    };
  },

  getVideoCallInfo(videoId) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_INFO,
      payload: videoId,
    };
  },
  getVideoCallInfoSuccess(payload) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_INFO_SUCCESS,
      payload,
    };
  },
  getVideoCallInfoFail() {
    return {
      type: ACTIONS.GET_VIDEO_CALL_INFO_FAIL,
    };
  },
  getBCBAAvailability(details) {
    return {
      type: ACTIONS.GET_BCBA_AVAILABILITY,
      payload: details,
    };
  },
  getBCBAAvailabilitySuccess(availability) {
    return {
      type: ACTIONS.GET_BCBA_AVAILABILITY_SUCCESS,
      payload: availability,
    };
  },
  clearBCBAAvailability() {
    return {
      type: ACTIONS.CLEAR_BCBA_AVAILABILITY,
    };
  },
  scheduleVideoCall(videoDetails) {
    return {
      type: ACTIONS.SCHEDULE_VIDEO_CALL,
      payload: videoDetails,
    };
  },
  scheduleVideoCallSuccess(videoDetails) {
    return {
      type: ACTIONS.SCHEDULE_VIDEO_CALL_SUCCESS,
      payload: videoDetails,
    };
  },
  scheduleVideoCallFail() {
    return {
      type: ACTIONS.SCHEDULE_VIDEO_CALL_FAIL,
    };
  },
  resetScheduleVideoCallState() {
    return {
      type: ACTIONS.SCHEDULE_VIDEO_CALL_RESET_STATE,
    };
  },
  rescheduleVideoCall(videoDetails) {
    return {
      type: ACTIONS.RESCHEDULE_VIDEO_CALL,
      payload: videoDetails,
    };
  },
  rescheduleVideoCallSuccess(videoDetails) {
    return {
      type: ACTIONS.RESCHEDULE_VIDEO_CALL_SUCCESS,
      payload: videoDetails,
    };
  },
  rescheduleVideoCallFail() {
    return {
      type: ACTIONS.RESCHEDULE_VIDEO_CALL_FAIL,
    };
  },
  cancelVideoCall(videoCall) {
    return {
      type: ACTIONS.CANCEL_VIDEO_CALL,
      payload: videoCall,
    };
  },
  cancelVideoCallSuccess() {
    return {
      type: ACTIONS.CANCEL_VIDEO_CALL_SUCCESS,
    };
  },
  setWeekStartDate(startDate) {
    return {
      type: ACTIONS.SET_WEEK_START_DATE,
      payload: {
        startDate,
      },
    };
  },
  setWeekEndDate(endDate) {
    return {
      type: ACTIONS.SET_WEEK_END_DATE,
      payload: {
        endDate,
      },
    };
  },
  setShowWeekCanceledCalls(payload) {
    return {
      type: ACTIONS.SET_SHOW_WEEK_CANCELED_CALLS,
      payload,
    };
  },
  getCalendarSessions(payload) {
    return {
      type: ACTIONS.GET_CALENDAR_SESSIONS,
      payload,
    };
  },
  getVideoCallsList() {
    return {
      type: ACTIONS.GET_VIDEO_CALLS_LIST,
    };
  },
  getVideoCallsFuture() {
    return {
      type: ACTIONS.GET_VIDEO_CALLS_FUTURE,
    };
  },
  getVideoCallsPast() {
    return {
      type: ACTIONS.GET_VIDEO_CALLS_PAST,
    };
  },
  setVideoCallsList(videoCalls, count) {
    return {
      type: ACTIONS.SET_VIDEO_CALLS_LIST,
      payload: {
        videoCalls,
        count,
      },
    };
  },
  setVideoCallsPage(page) {
    return {
      type: ACTIONS.SET_VIDEO_CALLS_PAGE,
      payload: {
        page,
      },
    };
  },
  setVideoCallsPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_VIDEO_CALLS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  setVideoCallsSort(payload) {
    return {
      type: ACTIONS.SET_VIDEO_CALLS_SORT,
      payload,
    };
  },
  setVideoCallsType(type) {
    return {
      type: ACTIONS.SET_VIDEO_CALLS_TYPE,
      payload: type,
    };
  },
  setVideoFilterStartDate(payload) {
    return {
      type: ACTIONS.SET_VIDEO_FILTER_START_DATE,
      payload,
    };
  },
  setVideoFilterEndDate(payload) {
    return {
      type: ACTIONS.SET_VIDEO_FILTER_END_DATE,
      payload,
    };
  },
  setCalendarSessionsList(payload) {
    return {
      type: ACTIONS.SET_CALENDAR_SESSIONS_LIST,
      payload,
    };
  },
  getVideoChatInfo(id, isOtherServices) {
    return {
      type: ACTIONS.GET_VIDEO_CHAT_INFO,
      payload: {
        id,
        isOtherServices,
      },
    };
  },
  getVideoChatInfoSuccess(videoChatDetails) {
    return {
      type: ACTIONS.GET_VIDEO_CHAT_INFO_SUCCESS,
      payload: videoChatDetails,
    };
  },
  getVideoChatInfoFail() {
    return {
      type: ACTIONS.GET_VIDEO_CHAT_INFO_FAIL,
    };
  },
  getVideoCallBreakdown(payload) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_BREAKDOWN,
      payload,
    };
  },
  getVideoCallBreakdownSuccess(payload) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_BREAKDOWN_SUCCESS,
      payload,
    };
  },
  sendBillableTimeForVideo(billableTime) {
    return {
      type: ACTIONS.SEND_BILLABLE_TIME_FOR_VIDEO,
      payload: billableTime,
    };
  },
  sendBillableTimeForVideoSuccess(payload) {
    return {
      type: ACTIONS.SEND_BILLABLE_TIME_FOR_VIDEO_SUCCESS,
      payload,
    };
  },
  sendBillableTimeForVideoFail(payload) {
    return {
      type: ACTIONS.SEND_BILLABLE_TIME_FOR_VIDEO_FAIL,
      payload,
    };
  },
  getUserVideoCalls(clientId) {
    return {
      type: ACTIONS.GET_USER_VIDEO_CALLS,
      payload: clientId,
    };
  },
  getUserVideoCallsSuccess(videoCalls, count) {
    return {
      type: ACTIONS.GET_USER_VIDEO_CALLS_SUCCESS,
      payload: {
        videoCalls,
        count,
      },
    };
  },
  getUserVideoCallsFail(payload) {
    return {
      type: ACTIONS.GET_USER_VIDEO_CALLS_FAIL,
      payload,
    };
  },
  setUserVideoCallsPage(page) {
    return {
      type: ACTIONS.SET_USER_VIDEO_CALLS_PAGE,
      payload: {
        page,
      },
    };
  },
  setUserVideoCallsPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_USER_VIDEO_CALLS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  setUserVideoCallsSort(payload) {
    return {
      type: ACTIONS.SET_USER_VIDEO_CALLS_SORT,
      payload,
    };
  },
  setUserVideoCallsType(type) {
    return {
      type: ACTIONS.SET_USER_VIDEO_CALLS_TYPE,
      payload: type,
    };
  },
  setUserVideoFilterStartDate(payload) {
    return {
      type: ACTIONS.SET_USER_VIDEO_FILTER_START_DATE,
      payload,
    };
  },
  setUserVideoFilterEndDate(payload) {
    return {
      type: ACTIONS.SET_USER_VIDEO_FILTER_END_DATE,
      payload,
    };
  },
  markClientNoShow(id) {
    return {
      type: ACTIONS.MARK_CLIENT_NO_SHOW,
      payload: { id },
    };
  },
  getNextClinicianCall(payload) {
    return {
      type: ACTIONS.GET_NEXT_CLINICIAN_CALL,
      payload,
    };
  },
  getNextClinicianCallSuccess(payload) {
    return {
      type: ACTIONS.GET_NEXT_CLINICIAN_CALL_SUCCESS,
      payload,
    };
  },
  getNextClinicianCallFail() {
    return {
      type: ACTIONS.GET_NEXT_CLINICIAN_CALL_FAIL,
    };
  },
  getClinicianCallsWeek(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALLS_WEEK,
      payload,
    };
  },
  getClinicianCallsWeekSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALLS_WEEK_SUCCESS,
      payload,
    };
  },
  getClinicianCallsWeekFail() {
    return {
      type: ACTIONS.GET_CLINICIAN_CALLS_WEEK_FAIL,
    };
  },
  getClinicianCalendarCalls(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALENDAR_CALLS,
      payload,
    };
  },
  getClinicianCalendarCallsSuccess(callList) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALENDAR_CALLS_SUCCESS,
      payload: {
        callList,
      },
    };
  },

  getClinicianPastCalls(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAST_CALLS,
      payload,
    };
  },
  getClinicianPastCallsSuccess(pastCalls) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAST_CALLS_SUCCESS,
      payload: {
        pastCalls,
      },
    };
  },
  setClinicianPastCallsSort(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_PAST_CALLS_SORT,
      payload,
    };
  },
  getClinicianUpcomingCalls() {
    return {
      type: ACTIONS.GET_CLINICIAN_UPCOMING_CALLS,
    };
  },
  getClinicianUpcomingCallsSuccess(upcomingCalls) {
    return {
      type: ACTIONS.GET_CLINICIAN_UPCOMING_CALLS_SUCCESS,
      payload: {
        upcomingCalls,
      },
    };
  },
  setClinicianUpcomingCallsSort(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_UPCOMING_CALLS_SORT,
      payload,
    };
  },
  checkVideoCallAccess(payload) {
    return {
      type: ACTIONS.CHECK_VIDEO_CALL_ACCESS,
      payload,
    };
  },
  checkVideoCallAccessSuccess(payload) {
    return {
      type: ACTIONS.CHECK_VIDEO_CALL_ACCESS_SUCCESS,
      payload,
    };
  },
  checkVideoCallAccessFail() {
    return {
      type: ACTIONS.CHECK_VIDEO_CALL_ACCESS_FAIL,
    };
  },
  secondaryClinicianJoinCall(videoDetails) {
    return {
      type: ACTIONS.SECONDARY_CLINICIAN_JOIN_CALL,
      payload: videoDetails,
    };
  },
  secondaryClinicianJoinCallSuccess(secondaryClinicianDetails) {
    return {
      type: ACTIONS.SECONDARY_CLINICIAN_JOIN_CALL_SUCCESS,
      payload: secondaryClinicianDetails,
    };
  },
  secondaryClinicianLeaveCall(videoDetails) {
    return {
      type: ACTIONS.SECONDARY_CLINICIAN_LEAVE_CALL,
      payload: videoDetails,
    };
  },
  secondaryClinicianLeaveCallSuccess(secondaryClinicianDetails) {
    return {
      type: ACTIONS.SECONDARY_CLINICIAN_LEAVE_CALL_SUCCESS,
      payload: secondaryClinicianDetails,
    };
  },
  setSecondaryClinicianSessionTimes(session) {
    return {
      type: ACTIONS.SET_SECONDARY_CLINICIAN_SESSION_TIMES,
      payload: session,
    };
  },
  getVideoRoomState(videoRoomId) {
    return {
      type: ACTIONS.GET_VIDEO_ROOM_STATE,
      payload: videoRoomId,
    };
  },
  getVideoRoomStateSuccess(payload) {
    return {
      type: ACTIONS.GET_VIDEO_ROOM_STATE_SUCCESS,
      payload,
    };
  },
  getVideoRoomStateFail() {
    return {
      type: ACTIONS.GET_VIDEO_ROOM_STATE_FAIL,
    };
  },
  getCustomerUpcomingCalls(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_UPCOMING_CALLS,
      payload: clientId,
    };
  },
  getCustomerUpcomingCallsSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_UPCOMING_CALLS_SUCCESS,
      payload,
    };
  },
  clearVideoInfo() {
    return {
      type: ACTIONS.CLEAR_VIDEO_INFO,
    };
  },
  getVideoCallBillingIssues(clinicianUserId) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_BILLING_ISSUES,
      payload: clinicianUserId,
    };
  },
  getVideoCallBillingIssuesSuccess(payload) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_BILLING_ISSUES_SUCCESS,
      payload,
    };
  },
  getVideoCallBillingIssuesFail() {
    return {
      type: ACTIONS.GET_VIDEO_CALL_BILLING_ISSUES_FAIL,
    };
  },
  setDemoCall(videoDetails) {
    return {
      type: ACTIONS.SET_DEMO_CALL,
      payload: videoDetails,
    };
  },
  setDemoCallSuccess(videoDetails) {
    return {
      type: ACTIONS.SET_DEMO_CALL_SUCCESS,
      payload: videoDetails,
    };
  },
  setDemoCallFail() {
    return {
      type: ACTIONS.SET_DEMO_CALL_FAIL,
    };
  },
  getUserCalendarSessions(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS,
      payload,
    };
  },
  getUserCalendarSessionsSuccess(sessionList) {
    return {
      type: ACTIONS.GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS_SUCCESS,
      payload: sessionList,
    };
  },
  getClinicianIncompleteOffPlatformCalls(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS,
      payload,
    };
  },
  getClinicianIncompleteOffPlatformCallsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_SUCCESS,
      payload,
    };
  },
  completeOffPlatformCall(payload) {
    return {
      type: ACTIONS.COMPLETE_OFF_PLATFORM_CALL,
      payload,
    };
  },
  completeOffPlatformCallSuccess(payload) {
    return {
      type: ACTIONS.COMPLETE_OFF_PLATFORM_CALL_SUCCESS,
      payload,
    };
  },
  completeOffPlatformCallFail() {
    return {
      type: ACTIONS.COMPLETE_OFF_PLATFORM_CALL_FAIL,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
