import { Avatar, Link } from "@mui/material";
import {
  Calendar,
  ChatTeardropDots,
  Envelope,
  Pencil,
  PhoneCall,
  PhoneIncoming,
  PhoneOutgoing,
  Printer,
  SealCheck,
} from "@phosphor-icons/react";
import React from "react";
import AvatarInitials from "components/Header/HeaderSections/AvatarInitials";
import { CLIENT_STATUS, BILLING_TYPES } from "constants";
import { differenceInSeconds, format, formatDistanceToNow, isSameYear } from "date-fns";
import { formatFilename } from "utils/snakeCasetoTitleCase";
import { getForm } from "selectors";
import { getFormattedVOBLogs } from "./VOBUtils";

export const filtersMap = {
  EMAIL: ["email"],
  EVENT: ["event", "video"],
  SYSTEM: ["system", "admin", "auth", "comment", "document","authorization"],
  MESSAGE: ["message", "sms", "chat"],
  PHONE: ["phone", "call"],
  FAX: ["fax"],
  VOB: ["vob"],
};

export const filtersMenuItems = [
  {
    label: "Emails",
    value: "EMAIL",
    icon: <Envelope weight="duotone" />,
  },
  {
    label: "Events",
    value: "EVENT",
    icon: <Calendar weight="duotone" />,
  },
  {
    label: "System",
    value: "SYSTEM",
    icon: <Pencil weight="duotone" />,
  },
  {
    label: "Messages",
    value: "MESSAGE",
    icon: <ChatTeardropDots weight="duotone" />,
  },
  {
    label: "Phone Calls",
    value: "PHONE",
    icon: <PhoneCall weight="duotone" />,
  },
  {
    label: "Faxes",
    value: "FAX",
    icon: <Printer weight="duotone" />,
  },
  {
    label: "VOBs",
    value: "VOB",
    icon: <SealCheck weight="duotone" />,
  },
];

export const formatLogText = (...texts) => {
  return texts.map((text, index) => {
    if (index % 2 === 0) {
      return text + " ";
    } else {
      return (
        <span key={index} style={{ fontWeight: 500, color: "#31333a" }}>
          {text}{" "}
        </span>
      );
    }
  });
};

export function getArticle(word) {
  if (!word || typeof word !== "string") return;
  const vowels = ["a", "e", "i", "o", "u"];
  return vowels.includes(word[0].toLowerCase()) ? "an" : "a";
}

function getAuthorizationText(text) {
  text = String(text);

  const wordMap = {
    pre_auth: "Pre-Authorization",
    re_auth: "Re-Authorization",
    auth: "Ongoing Authorization",
    authorization_type: "Authorization Type",
    authorization_code: "Authorization Number",
    end_date: "Authorization End Date",
    start_date: "Authorization Start Date"
  };
  for (const [key, value] of Object.entries(wordMap)) {
    text = text.replace(new RegExp(`\\b${key}\\b`, "g"), value);
  }
  return text;
}

const getFormattedAuthLog = (obj, clinicianList = []) => {
  const { data_changes, event_action, after, before } = obj;

  if (!data_changes) {
    if (event_action === "INSERT" && after.code) {
      return formatLogText(`added`, `${after.code}`, `to an ongoing authorization`);
    }
  } else {
    let dataChangeKey = Object.keys(data_changes)?.[0];

    if (dataChangeKey === "approved_units") {
      if (after && before) {
        return formatLogText(
          `changed the authorization`,
          `${after.code}`,
          `units to`,
          `${after.approved_units} `,
          "from",
          `${before.approved_units} `
        );
      }
    }

    if (dataChangeKey === "code") {
      return formatLogText(`added`, `${data_changes.code?.new_value}`, `to an authorization`);
    }

    if (dataChangeKey === "pending_auth") {
      return formatLogText(
        `turned`,
        `${data_changes.pending_auth?.new_value ? "On" : "Off"}`,
        `temporary and pending authorization`
      );
    }

    if (dataChangeKey === "clinician_id") {
      const oldClinicianName = clinicianList.find(
        (clinician) => clinician.clinician_id == data_changes.clinician_id?.old
      );
      const newClinicianName = clinicianList.find(
        (clinician) => clinician.clinician_id == data_changes.clinician_id?.new_value
      );
      return formatLogText(
        `Changed the authorization approved clinician to`,
        `${newClinicianName.first_name || ""} ${newClinicianName.last_name || ""}`,
        "from",
        `${oldClinicianName.first_name || ""} ${oldClinicianName.last_name || ""} `
      );
    }

    const dataKeysOnChanges = [
      "authorization_type",
      "approved_time",
      "authorization_number",
      "end_date",
      "start_date",
      "authorization_code",
    ];

    if (dataKeysOnChanges.includes(dataChangeKey))
      return formatLogText(
        `Changed the`,
        `${formatFilename(getAuthorizationText(dataChangeKey))}`,
        `to`,
        `${data_changes[dataChangeKey]?.new_value !== "" ? getAuthorizationText(data_changes[dataChangeKey].new_value) : "none"}`,
        "from",
        `${data_changes[dataChangeKey]?.old !== "" ? getAuthorizationText(data_changes[dataChangeKey].old) : "none"}`
      );
  }
};

const getProfileUpdateText = (data_changes) => {
  let dataChangeKey = Object.keys(data_changes)?.[0];
  const logMessages = {
    zip_code: "zip code",
    name: "child's name",
    gender: "gender",
    pronouns: "pronouns",
    diagnosis: "diagnosis",
    date_of_birth: "date of birth",
    child_last_name: "child's last name",
    identified_gender: "identified gender",
    preferred_first_name: "preferred first name",
    transgender: "transgender",
    city: "city",
    state: "state",
    street_1: "address",
    street_2: "address 2",
    ethnicity: "ethnicity",
    lives_with: "lives with",
    primary_language: "primary language",
    needs_interpreter: "needs interpreter",
    pre_auth_required: "pre-authorization required",
    medical_conditions: "medical conditions",
    requires_tablet: "requires tablet",
    phone: "phone number",
    legal_guardian: "legal guardian",
    relationship_to_client: "relationship to client",
    email: "email",
    timezone: "time zone",
    first_name: "first name",
    last_name: "last name",
  };

  if (logMessages[dataChangeKey]) {
    let { new_value, old } = data_changes[dataChangeKey];
    if (old === true) old = "yes";
    if (old === false) old = "no";
    if (new_value === true) new_value = "yes";
    if (new_value === false) new_value = "no";
    let actionWord = "changed";
    if (!old) actionWord = "updated";
    else if (!new_value) actionWord = "removed";
    let newValue = new_value;
    let oldValue = old;
    // Validatation to avoid logs showing changes from null values to "" for example
    if (!old && !new_value) return null;
    let formattedLogText = formatLogText(
      `${actionWord} ${logMessages[dataChangeKey]} to`,
      newValue,
      "from",
      oldValue
    );
    if (actionWord === "removed")
      formattedLogText = formatLogText(`${actionWord} ${logMessages[dataChangeKey]}`);
    if (actionWord === "updated")
      formattedLogText = formatLogText(`${actionWord} ${logMessages[dataChangeKey]} to`, newValue);
    return formattedLogText;
  } else return null; // Show logs of known fields only
};

export const getLogText = (
  clientName = "AnswersNow Client",
  documentTypes,
  logObject,
  clinicianList = []
) => {
  const {
    type: logType,
    is_outbound,
    event_action,
    billing_type,
    new_status,
    old_status,
    document_type,
    data_changes,
  } = logObject;
  const type = logType.toLowerCase();
  if (type === "email")
    return formatLogText("sent an email to", is_outbound ? clientName : "AnswersNow");
  if (filtersMap.EVENT.includes(type)) {
    if (type === "video") return "hosted a video event";
    return formatLogText(
      `${event_action.toLowerCase()} ${getArticle(BILLING_TYPES[billing_type]) || "an"} `,
      BILLING_TYPES[billing_type],
      " event"
    );
  }

  if (type === "authorization") {
    if (logObject.table_name === "secondary_insurance_authorizations" && logObject.data_changes) {
      return getFormattedAuthLog(logObject, clinicianList);
    } else if (logObject.table_name === "secondary_insurance_authorizations")
      return formatLogText("added a new ", "Secondary ", "authorization");

    if (
      logObject.table_name === "insurance_authorizations" &&
      logObject.data_changes &&
      Object.keys(data_changes)?.[0] !== "inactive_date"
    ) {
      return getFormattedAuthLog(logObject, clinicianList);
    }

    if (logObject.after?.authorization_type && event_action === "INSERT" ) {
      return formatLogText(
        "added a new",
        getAuthorizationText(logObject.after?.authorization_type)
      );
    }

    //checks if changes come from table approved_time
    if (logObject.table_name === "approved_time") {
      return getFormattedAuthLog(logObject);
    }
  }

  if (type === "vob") {
    return getFormattedVOBLogs(logObject);
  }

  if (type === "system") {
    try {
      if (event_action === "UPDATE") return getProfileUpdateText(data_changes);
      else if (new_status && old_status) {
        return formatLogText(
          "changed status to",
          CLIENT_STATUS[new_status] || new_status,
          "from",
          CLIENT_STATUS[old_status] || old_status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  if (type === "document") {
    const docDisplayName = documentTypes.find((doc) => doc.name === document_type)?.display_name;
    return formatLogText(
      `${event_action.toLowerCase()} ${getArticle(document_type)}`,
      docDisplayName || document_type,
      "document"
    );
  }
  if (filtersMap.MESSAGE.includes(type)) {
    if (type === "sms")
      return formatLogText(
        "started a SMS conversation with",
        is_outbound ? clientName : "AnswersNow"
      );
    if (type === "chat")
      return formatLogText("sent a chat message to ", is_outbound ? clientName : "AnswersNow");
    return "sent a message";
  }
  if (filtersMap.PHONE.includes(type))
    return formatLogText("placed a call to", is_outbound ? clientName : "AnswersNow");
  if (filtersMap.FAX.includes(type)) return "faxed a document";
};

export const getLogIcon = (theme, { type: logType, is_outbound, profile_img, user_initials }) => {
  const iconProps = {
    size: "16px",
    weight: "duotone",
    color: theme.palette.action.active,
  };
  const type = logType.toLowerCase();
  if (filtersMap.EMAIL.includes(type)) return <Envelope {...iconProps} />;
  if (filtersMap.EVENT.includes(type)) return <Calendar {...iconProps} />;
  if (filtersMap.SYSTEM.includes(type)) {
    if (type === "comment") {
      if (profile_img) return <Avatar src={profile_img} sx={{ width: 16, height: 16 }} />;
      else return <AvatarInitials userInitials={user_initials} size={16} />;
    }
    return <Pencil {...iconProps} />;
  }
  if (filtersMap.MESSAGE.includes(type)) return <ChatTeardropDots {...iconProps} />;
  if (filtersMap.PHONE.includes(type))
    return is_outbound ? <PhoneOutgoing {...iconProps} /> : <PhoneIncoming {...iconProps} />;
  if (filtersMap.FAX.includes(type)) return <Printer {...iconProps} />;
  if (filtersMap.VOB.includes(type)) return <SealCheck {...iconProps} />;
  return <Pencil {...iconProps} />;
};

export const getOpenConversation = (
  history,
  {
    type: logType,
    activity_date,
    chat_conversation_id,
    client_id,
    conversation_count,
    sent_by_user_id,
  }
) => {
  const type = logType.toLowerCase();

  const url =
    type === "chat"
      ? `/chats/${chat_conversation_id}?date=${activity_date}`
      : `/clients/${client_id}/logs?sms-open=true&date=${activity_date}`;
  const element = (
    <>
      {" "}
      (
      <Link underline="hover" onClick={() => history.push(url)}>
        open conversation
      </Link>
      )
    </>
  );
  if (type === "chat" && conversation_count) {
    if (!chat_conversation_id) return;
    return element;
  } else if (type === "sms" && conversation_count && sent_by_user_id) {
    return element;
  }
};

function formatDistanceToNowCustom(date) {
  const distance = formatDistanceToNow(date, { addSuffix: true });
  return distance === "less than a minute ago" ? "Just now" : distance;
}

export function formattedLogDate(logDate) {
  const logDateFormat = isSameYear(logDate, new Date()) ? "MMMM do" : "PPP";
  const formattedDate = format(logDate, `${logDateFormat} p`);

  // Up to third option in
  // formatDistanceToNow table https://date-fns.org/v3.6.0/docs/formatDistanceToNow
  const maxDurationInSeconds = 44 * 60 + 30; // 44 mins 30 secs in seconds
  const timeDifferenceInSeconds = differenceInSeconds(new Date(), logDate);

  if (timeDifferenceInSeconds <= maxDurationInSeconds) {
    const timeAgo = formatDistanceToNowCustom(logDate);
    return timeAgo;
  }

  return formattedDate;
}
